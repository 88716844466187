import { DialogTitle } from '@material-ui/core';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import { logError } from '../../../../lib/src/helpers/Validate';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { PrimaryButton } from '../button';
import Modal, { ModalProps, PARAM_DELIMITER } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen, { FullSizeContent } from '../common/Screen';
import DetailsAttendeesTab from './DetailsAttendeesTab';
import DetailsHeader from './DetailsHeader';

@inject('api', 'invitationDraft')
@observer
export default class InvitationDraft extends Modal {
    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedInvitationDraftProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => {
            if (this.injected.api.account.verified) {
                const draft = this.injected.invitationDraft.get();

                return !draft.key && !!draft.date;
            }

            return false;
        }).get();
    }

    public render() {
        const draft = this.injected.invitationDraft.get();

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.handleClose}
                fullHeight={true}
                FooterComponent={
                    <DialogTitle>
                        <PrimaryButton onClick={this.saveDraft}>
                            {Env.i18n.t('SendInvitation')}
                        </PrimaryButton>
                    </DialogTitle>
                }
            >
                <DetailsHeader draft={draft} onCancel={this.onBackPress} onNavigate={this.redirectTo} />
                <FullSizeContent>
                    <SafariFix.ColumnStretchWrapper>
                        <DetailsAttendeesTab draft={draft} />
                    </SafariFix.ColumnStretchWrapper>
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private handleClose() {
        Alert.confirmInvitationDiscard(this.injected.invitationDraft.get(), confirmed => {
            if (confirmed) {
                this.close();
            }
        });
    }

    @bind
    private onBackPress() {
        const draft = this.injected.invitationDraft.get();
        const [ originModal, ...originParams ] = draft.origin.split(PARAM_DELIMITER);

        Alert.confirmInvitationDiscard(draft, confirmed => {
            if (confirmed) {
                this.redirectTo(originModal.toLowerCase(), originParams);
            }
        });
    }

    @bind
    private saveDraft() {
        this.injected.api
            .waitFor(this.injected.invitationDraft.complete())
            .then(() => {
                this.onBackPress();
                Env.snackbar.success(Env.i18n.t('SuccessInvitationSent'));
            })
            .catch(error => {
                logError('InvitationDetailsScreen.saveDraft', error);
                Env.snackbar.error(Env.i18n.t('ErrorInvitationNotSent'));
            });
    }
}
