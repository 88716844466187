import Constants from '../../../lib/src/Constants';
import Env from '../../../lib/src/Env';
import { sanitizeUrl } from '../../../lib/src/helpers/url';

export function confirm(message: string, onConfirm: () => void) {
    if (window.confirm(message)) {
        onConfirm();
    }
}

const LinkTo = {
    url: (url: string, target?: string) => {
        window.open(sanitizeUrl(url), target, 'noopener,noreferrer');
    },
    imprint: () => {
        LinkTo.url('https://www.lunchnow.com/impressum/', '_blank');
    },
    gdpr: () => {
        LinkTo.url('https://www.lunchnow.com/datenschutzverordnung/', '_blank');
    },
    support: () => {
        LinkTo.url(Constants.SUPPORT_URL, '_blank');
    },
    phoneApp: (number: string) => {
        window.open(`tel:${number}`, '_self');
    },
    bookaTable: (bookatableId: string) => {
        confirm('Jetzt mit Bookatable reservieren', () => {
            LinkTo.url('https://reservation.lunchnow.com/bookatable.html?restaurantId=' + bookatableId, '_blank');
        });
    },
    email: (email: string, subject = '') => {
        window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}`);
    },
    paymentEmail: () => {
        LinkTo.email(Constants.PAYMENT_SUPPORT_EMAIL, Env.i18n.t('OrderEmail'));
    },
    whatsApp: () => {
        LinkTo.url(`https://wa.me/491794416434?text=${encodeURIComponent(Env.i18n.t('WhatsAppMessage'))}`, '_blank');
    },
    lunchnow: () => {
        window.location.href = Constants.HOST
    },
    partner: () => {
        LinkTo.url(Constants.BECOME_A_PARTNER_URL);
    }
};

export default LinkTo;
