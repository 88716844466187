import { Dialog, DialogContent, withMobileDialog } from '@material-ui/core';
import { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { bind } from 'decko';
import * as React from 'react';
import styled from 'styled-components';

import { SCREEN_PADDING } from '../../styles/base';
import { theme } from '../../theme';

const ScreenContainer = styled(Dialog).attrs({
    maxWidth: 'sm',
    scroll: 'paper',
    'aria-labelledby': 'scroll-dialog-title'
})`
    padding: 18px 0;

    ${theme.breakpoints.down('xs')} {
        padding: 0;
    }

    .MuiDialog-paper {
        overflow: hidden;
    }
`;

const MIN_CONTENT_WIDTH = 600;

const ScreenContent = styled(DialogContent)`
    && {
        border-top: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        min-width: ${MIN_CONTENT_WIDTH}px;
        padding: ${SCREEN_PADDING}px;
    }

    @media (max-width: ${MIN_CONTENT_WIDTH}px) {
        && {
            min-width: 0;
        }
    }
`;

export const FullWidthContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 ${SCREEN_PADDING * -1}px;
`;

// @ts-ignore ts(2615)
export const FullSizeContent = styled(FullWidthContent)`
    &:first-child {
        margin-top: ${SCREEN_PADDING * -1}px;
    }

    &:last-child {
        margin-bottom: ${SCREEN_PADDING * -1}px;
    }
`;

export type JSX = React.ComponentType<any> | React.ReactElement | null;

interface Props extends Partial<WithMobileDialog> {
    open?: boolean;
    className?: string;
    dividers?: boolean;
    handleClose?: () => void;
    fullHeight?: boolean;
    HeaderComponent?: JSX;
    FooterComponent?: JSX;
    contentRef?: React.RefObject<HTMLDivElement>;
    contentStyle?: React.CSSProperties;
}

class Screen extends React.PureComponent<Props> {
    @bind
    private handleClose() {
        const { handleClose } = this.props;

        if (handleClose) {
            handleClose();
        }
    }

    private renderComponentProp(componentProp?: JSX) {
        if (typeof componentProp === 'function') {
            componentProp = (componentProp as () => JSX)();
        }

        return componentProp;
    }

    public render() {
        const { fullHeight, className, open, fullScreen } = this.props;
        const { dividers, contentRef, contentStyle, HeaderComponent, FooterComponent } = this.props;
        const PaperProps = fullHeight
            ? {
                style: { height: '100%' }
            }
            : undefined;

        return (
            <ScreenContainer
                className={className}
                open={!!open}
                fullScreen={fullScreen}
                onClose={this.handleClose}
                PaperProps={PaperProps}
            >
                {this.renderComponentProp(HeaderComponent)}
                <ScreenContent dividers={dividers !== false} ref={contentRef} style={contentStyle}>
                    {this.props.children}
                </ScreenContent>
                {this.renderComponentProp(FooterComponent)}
            </ScreenContainer>
        );
    }
}

export default withMobileDialog({ breakpoint: 'xs' })(Screen);
