import { observer } from 'mobx-react';
import React from 'react';

import Perishable, { PerishableValue } from '../../../../lib/src/store/Perishable';

interface Props {
    generator: () => PerishableValue<string>;
    style?: React.CSSProperties;
    className?: string;
}

@observer
export default class PerishableText extends React.PureComponent<Props> {
    private perishable = new Perishable<string>(this.props.generator);

    public componentDidMount() {
        this.perishable.initialize();
    }

    public componentWillUnmount() {
        this.perishable.release();
    }

    public render() {
        const { generator, children, ...props } = this.props;

        return (
            <span {...props}>
                {this.perishable.value}
            </span>
        );
    }
}
