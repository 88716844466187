import mangoPayTypes from 'mangopay2-nodejs-sdk';

import Env from '../../../lib/src/Env';
import RestaurantEntry from '../../../lib/src/types/models/RestaurantEntry';
import { GetCardsResponse, SupportedCardType } from '../managers/PaymentManager';
import { PaymentOption } from '../types/models/Order';
import { CardTypeAlias } from '../types/models/Payment';
import { capitalizeFirstLetter, formatDayAndTime, formatNumber } from './formatting';

export const LUNCHIT_ENABLED = false as boolean;
export const LUNCHIT_URL = 'https://www.spendit.de/lunchit/';
export const LUNCHIT_DISCOUNT = 650; // in Euro cents

const CURRENCY = '€';

// see node_modules/payment/lib/index.js
const CARDS = [
    {
        type: CardTypeAlias.MAESTRO,
        pattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
        maxLength: 19
    },
    {
        type: CardTypeAlias.MASTERCARD,
        pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
        maxLength: 16
    },
    {
        type: CardTypeAlias.VISA,
        pattern: /^4(026|17500|405|508|844|91[37])/,
        maxLength: 16
    },
    // TODO: doesn't this entry make the previous one obsolete?
    {
        type: CardTypeAlias.VISA,
        pattern: /^4/,
        maxLength: 19
    }
];

export type CardData = mangoPayTypes.card.CardData;

function unknownError(error: any) {
    console.warn('Unknown payment error occurred', error);

    return Env.i18n.t('ErrorUnknown');
}

export function paymentError(error: any, withCode = true) {
    // error can be a number or has properties ResultCode (string), e.code (number), or ???
    if (error) {
        if (typeof error?.message === 'string') {
            const [ domain, code ] = error.message.split('/', 2);

            switch (domain) {
                case 'mangopay-error':
                    return mangoPayError(code, withCode);
                case 'firebase':
                    return backendError(code);
                case 'api-error':
                    return; // ignoring Partner API errors
            }

            /*
             * 404 error can have an error message, e.g.
             * ```
             * <!DOCTYPE html> <html lang="en"> <head> <meta charset="utf-8"> <title>Error</title> </head> <body>
             * <pre>Cannot POST /payment/orders</pre> </body> </html>
             * ```
             * Don't display such message. Are there other cases where `errorMessage` should be returned?
             */
        }

        try {
            const parsedErrorMessage = JSON.parse(error.message);

            if (parsedErrorMessage.details?.length) {
                return paypalError(parsedErrorMessage);
            }
        } catch (e) {}

        if (error.ResultCode) {
            return mangoPayError(error.ResultCode, withCode);
        }

        if (typeof error === 'number' || typeof error?.code === 'number') {
            return httpError(typeof error === 'number' ? error : error.code);
        }

        // how to handle errors with by the error code ??
        if (typeof error?.code === 'string') {
            return backendError(error);
        }
    }

    return unknownError(error);
}

// see https://docs.mangopay.com/guide/errors
function mangoPayError(code: string, withCode: boolean) {
    const mangoPayErrorCodes = [
        // Operation failed
        '001001', '001002',
        '001011', '001012', '001013', '001014',
        '001999',

        // PayIn Web errors
        '101001', '101002',
        '001030', '001031', '001032', '001033', '001034',

        // Refund transaction errors
        '001401', '001403',
        '005403', '005404', '005405', '005407',

        // Card input errors
        '105101', '105102', '105103', '105104',

        // Transaction Refused
        // TODO: translate rest codes
        '101410',

        // Secure mode / 3DSecure errors
        '101301', '101302', '101303', '101304',
        '101399',

        // Tokenization / Card registration errors
        '02101',
        '02624',  '02625',  '02626',  '02627',  '02628',  '02631',  '02632',
        '09101',  '09102',  '01902',  '09104',
        '09201',
        '001599',
        '105202', '105203', '105204', '105205', '105206',
        '105299',

        // Specific JS Kit card registration errors
        '009999',
        '001596', '001597', '001598', '001599',
        '101699',
        '105202', '105203', '105204',

        // Transaction fraud issue
        // TODO:

        // Technical errors
        '009101', '009103',
        '009199',
        '009499',
        '009999',
        '101202'
    ];

    return mangoPayErrorCodes.includes(code)
        ? `${withCode ? `${code}: ` : ''}${Env.i18n.t(`ErrorMangoPay_${code}`)}`
        : unknownError(code);
}

function httpError(code: number) {
    return [ 400, 401, 403, 404 ].includes(code)
        ? Env.i18n.t(`ErrorHttp_${code}`)
        : unknownError(code);
}

function backendError(error: any) {
    const paymentErrors = [
        'DiscountLimitExceeded',
        'MissingRequiredFields',
        'NoCard',
        'NoCardId',
        'NoCardType',
        'NoLegalUser',
        'NoMeetUpDate',
        'NoPaymentUser',
        'NoPreauthorizationId',
        'NoItems',
        'NoTransactionId',
        'OrderMealsChanged',
        'OrderNotCancelable',
        'OrderNotFound',
        'TotalPrice',
        'UserNotFound',
        'WrongTransactionAuthor',
    ];

    return paymentErrors.includes(error)
        ? Env.i18n.t(`ErrorPayment_${error}`)
        : unknownError(error);
}

export function showPaymentError(error: any) {
    const errorMessage = paymentError(error);

    if (errorMessage) {
        Env.snackbar.error(errorMessage);
    }
}

function paypalError(error: any) {
    return Env.i18n.t(`ErrorPayPal_${error.details[0].issue}`);
}

// amount is price in cents. For now is euro currency only supported
export function getFormattedPrice(amount: number, currency?: string) {
    return formatNumber(amount / 100, 2) + ` ${currency || CURRENCY}`;
}

export function getCardAlias(card?: CardData) {
    return card?.Alias.replace(/(.*)X/g, '**** ') || '';
}

export function getCardProvider(card: CardData) {
    return capitalizeFirstLetter(card.CardProvider.toLocaleLowerCase());
}

export function getCardMeta(cardNumber: string) {
    return CARDS.find(card => card.pattern.test(cardNumber));
}

export function getCartId(restaurant?: RestaurantEntry): string {
    return restaurant?.routingName || '';
}

export function getCardLogo(paymentOption: PaymentOption, card?: GetCardsResponse) {
    if (paymentOption === 'card') {
        return Env.assets.logos[CardTypeAlias[card?.data.typeAlias || ''] || CardTypeAlias.VISA];
    }

    return Env.assets.logos.PAYPAL;
}

export function formatCardNumber(dirty: string, selectionStart: number | null, selectionEnd: number | null) {
    const regexFormat = /(\d{1,4})/g;
    const regexCleanUp = /\D/g;
    const start = selectionStart || 0;
    const end = selectionEnd || 0;
    let cardNumber = dirty.replace(regexCleanUp, '');
    const cardMeta = getCardMeta(cardNumber);
    cardNumber = cardNumber.slice(0, Math.min(cardMeta?.maxLength || 16, cardNumber.length));
    // the cursor must be in the correct position
    // e.g. 0000 00000| shift = 1 (0000 0000 0|)
    // e.g. 0000 0000000000| shift = 2 (0000 0000 0000 00|)
    // e.g. 0000 000000xxx0000| shift = 2 - 3 = -1 (0000 0000 0000 00|)
    const parts = dirty.slice(0, selectionEnd || 0).split(' ');
    const lastPart = parts[parts.length - 1];
    const lastPartOnlyDigits = lastPart.replace(regexCleanUp, '');
    const newAddedSpaces = Math.max(0, Math.ceil(lastPartOnlyDigits.length / 4) - 1);
    const nonDigitChars = lastPart.length - lastPartOnlyDigits.length;
    const shift = newAddedSpaces - nonDigitChars;
    // array of 4 digits group
    const matches = cardNumber.match(regexFormat);
    const formatted = matches?.join(' ') || cardNumber;

    return {
        formatted,
        selectionStart: Math.min(start + shift, formatted.length),
        selectionEnd: Math.max(end + shift, 0),
        cardNumber
    };
}

export function formatDate(dirty: string) {
    const expirationDate = dirty.replace(/\D/g, '');
    const month = expirationDate.slice(0, 2);
    const year = expirationDate.slice(2, 4);
    let formatted = month;

    if (month.length === 2 && year.length > 0) {
        formatted += ' / ' + year;
    }

    return { formatted, expirationDate };
}

export function getMangoPayCardType(cardTypeAlias?: CardTypeAlias) {
    switch (cardTypeAlias) {
        case CardTypeAlias.MAESTRO:
            return SupportedCardType.MAESTRO;
        case CardTypeAlias.VISA:
        case CardTypeAlias.MASTERCARD:
        case CardTypeAlias.LUNCHIT:
        default:
            return SupportedCardType.CB_VISA_MASTERCARD;
    }
}

export function formatMeetUpDate(date: Date = new Date(), override?: boolean): string {
    return date
        ? formatDayAndTime(date, !override ? {} : {
            nextWeek: Env.i18n.t('nextWeekAt'),
            sameElse: Env.i18n.t('sameElseAt')
        })
        : '';
}
