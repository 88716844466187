import { Radio, RadioGroup as MuiRadioGroup } from '@material-ui/core';
import { bind } from 'decko';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { clickableEntry } from '../contacts/ContactEntry';

const OptionContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 0;
    flex-direction: row;
    border-radius: 5px;
    background-color: ${colors.white};
    margin-bottom: ${GRID_SIZE * 2}px;
    padding: ${GRID_SIZE * 2}px ${GRID_SIZE * 3}px;

    &.clickable {
        ${clickableEntry};
    }

    &&.active {
        background-color: ${colors.teal_50}
    }
`;

const OptionContent = styled.div`
    flex: 1;
    margin-left: ${GRID_SIZE * 2}px;
`;

interface Props<T> {
    options: T[];
    value?: T;
    renderOption: (option: T, index: number) => React.ReactElement;
    onChange: (value: T) => void;
    /** If `true`, clicking the option container selects the radio button */
    clickable?: boolean;
    EmptyComponent?: React.ReactElement;
    /** Class name of option containers */
    className?: string;
    /** Style of option containers */
    style?: CSSProperties;
    /** Style of selected option container */
    activeStyle?: CSSProperties;
}

export default class RadioGroup<T> extends React.PureComponent<Props<T>> {
    public render() {
        const { options, value, renderOption, clickable, EmptyComponent, className, style, activeStyle } = this.props;

        if (EmptyComponent && options.length < 1) {
            return EmptyComponent;
        }

        // empty string as fallback is important to make MuiRadioGroup a controlled component!
        const currentKey = value !== undefined ? options.indexOf(value).toString() : '';
        const optionClasses = (className || '') + (clickable ? ' clickable' : '');

        return (
            <MuiRadioGroup value={currentKey} onChange={this.handleChange}>
                {options.map((option, index) => {
                    const key = index.toString();
                    const active = (key === currentKey);
                    const classes = optionClasses + (active ? ' active' : '');
                    const styles = active ? { ...style, ...activeStyle } : style;

                    return (
                        <OptionContainer key={key} onClick={() => this.handleClick(key)} className={classes} style={styles}>
                            <Radio color="default" value={key} onClick={event => event.stopPropagation()} />
                            <OptionContent>
                                {renderOption(option, index)}
                            </OptionContent>
                        </OptionContainer>
                    );
                })}
            </MuiRadioGroup>
        );
    }

    @bind
    private handleChange(event: any, key: string) {
        const option = this.props.options[key];

        if (option !== this.props.value) {
            this.props.onChange(option);
        }
    }

    private handleClick(key: string) {
        if (this.props.clickable) {
            this.handleChange(undefined, key);
        }
    }
}
