import { Collapse, Link } from '@material-ui/core';
import { bind } from 'decko';
import React from 'react';

import Env from '../../../../lib/src/Env';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { RegularText, SectionHeadline } from '../text';

interface State {
    expanded: boolean;
}

interface Props {
    restaurant: RestaurantEntry;
}

export default class Description extends React.PureComponent<Props, State> {
    public readonly state: State = {
        expanded: false
    };

    @bind
    private toggle() {
        this.setState(state => ({ expanded: !state.expanded }));
    }

    public render() {
        const restaurant = this.props.restaurant.data;

        if (!restaurant) {
            return null;
        }

        const needsCollapse = restaurant.description && restaurant.description.length > 400;
        const content = (
            <RegularText>
                {restaurant.description}
            </RegularText>
        );

        // TODO: Ask Terence before trying another approach. All the truncate libraries are shit
        return (
            <div>
                <SectionHeadline>
                    {Env.i18n.t('About', { restaurant: restaurant.name })}
                </SectionHeadline>
                {needsCollapse
                    ? (
                        <>
                            <Collapse collapsedHeight="60px" in={this.state.expanded}>
                                {content}
                            </Collapse>
                            <Link onClick={this.toggle} color="inherit">
                                {Env.i18n.t(this.state.expanded ? 'ShowLess' : 'ShowMore')}
                            </Link>
                        </>
                    )
                    : content
                }
            </div>
        );
    }
}
