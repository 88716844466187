import * as firebase from 'firebase/app';

import { SubscriptionListType } from './SubscriptionList';
import { DataListEntry } from '../../store/DataList';
import { Timestamp } from '../Timestamps';

/**
 * This interface was referenced by `Users`'s JSON-Schema definition
 * via the `patternProperty` "^[a-zA-Z0-9_-]*$".
 */

// "public" part of an User
export interface ContactUser extends DataListEntry {
    displayName: string;
    photoURL?: string;
}

type NotificationSettingsSubscriptionLists = {
    [key in SubscriptionListType]?: string | false;
}

export interface NotificationSettings extends NotificationSettingsSubscriptionLists {
    dailies?: boolean;
    invitations?: boolean;
    connect?: boolean;
}

export default interface User extends DataListEntry, firebase.UserInfo {
    /** Use `key` instead! */
    uid: never;
    firstName?: string;
    lastName?: string;
    visible?: boolean;
    favorites?: string[];
    tasteKeys?: string[];
    connectionLink?: string;
    connectionCode?: string;
    policyAccepted?: Timestamp;
    notificationSettings?: NotificationSettings;
    deleted?: Timestamp;
    verificationCode?: string | boolean;
    selectedDiscountId?: string;
}

export function createUserFromSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
    let user;

    if (snapshot.exists) {
        user = { ...snapshot.data(), key: snapshot.id } as User;
    }

    return user;
}

export interface UserContactsEntry {
    isFavorite: boolean;
}

export interface UserContactGroupsEntry {
    isFavorite: boolean;
    members: string[];
    name: string;
}
