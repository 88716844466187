import { CircularProgress } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { SCREEN_PADDING } from '../../styles/base';
import { RegularText } from '../text';

const IndicatorContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: ${SCREEN_PADDING}px;
`;

// @ts-ignore ts(2615)
const IndicatorHint = styled(RegularText)`
    color: ${colors.grey_02};
    font-size: 1rem;
    margin-top: 1em;
    text-align: center;
`;

interface Props {
    icon: string;
    hint: string;
    waitFor: any;
    style?: CSSProperties;
    className?: string;
}

export default class EmptyListIndicator extends React.PureComponent<Props> {
    public render() {
        return (
            <IndicatorContainer className={this.props.className} style={this.props.style}>
                {this.props.waitFor ? (
                    <>
                        <img src={this.props.icon} alt="" />
                        <IndicatorHint>
                            {this.props.hint}
                        </IndicatorHint>
                    </>
                ) : (
                    <CircularProgress style={{ margin: 'auto' }} />
                )}
            </IndicatorContainer>
        );
    }
}
