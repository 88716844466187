import { bind } from 'decko';

import Env from '../Env';
import { DataListEntry } from '../store/DataList';
import Timestamps, { Timestamp } from '../types/Timestamps';
import Cache from './Cache';

type DocumentType = 'gdpr' | 'imprint';
type DocumentTypeData = {
    [type in DocumentType]: string;
};

interface DocumentData extends DocumentTypeData {
    version: Timestamp;
}

type AllDocuments = DocumentData & DataListEntry;
type DateListener = (version: Date) => void;

export default abstract class Documents {
    private static cache = new Cache<AllDocuments>();
    private static versionListeners: DateListener[] = [];

    private static _defaultLanguage: string;
    private static _language: string;
    private static _collection: firebase.firestore.CollectionReference;

    private static get defaultLanguage() {
        if (!this._defaultLanguage) {
            this._defaultLanguage = Env.i18n.defaultLocale.substr(0, 2).toLowerCase();
        }

        return this._defaultLanguage;
    }

    private static get language() {
        if (!this._language) {
            this._language = Env.currentLanguageCode().toLowerCase();
        }

        return this._language;
    }

    private static get collection() {
        if (!this._collection) {
            this._collection = Env.firebase.firestore().collection('documents') as any;
        }

        return this._collection;
    }

    public static async get() {
        let documents = await this.getDocuments(this.language);

        if (!documents && this.language !== this.defaultLanguage) {
            documents = await this.getDocuments(this.defaultLanguage);
        }

        return documents;
    }

    public static async addVersionListener(listener: DateListener) {
        const initialize = this.versionListeners.length < 1;
        const newIndex = this.versionListeners.push(listener) - 1;
        let cancelDefaultLanguageObserver: () => void | undefined;
        let cancelLanguageObserver: () => void | undefined;

        if (initialize) {
            const languageRef = this.collection.doc(this.language);
            const languageSnapshot = await languageRef.get();

            cancelLanguageObserver = languageRef.onSnapshot(snapshot => {
                if (snapshot.exists && cancelDefaultLanguageObserver) {
                    cancelDefaultLanguageObserver();
                }

                this.notifyVersionListeners(snapshot);
            });

            if (!languageSnapshot.exists) {
                cancelDefaultLanguageObserver = this.collection.doc(this.defaultLanguage).onSnapshot(this.notifyVersionListeners);
            }
        }

        return () => {
            this.versionListeners = this.versionListeners.splice(newIndex, 1);

            if (this.versionListeners.length < 1) {
                if (cancelDefaultLanguageObserver) {
                    cancelDefaultLanguageObserver();
                }

                if (cancelLanguageObserver) {
                    cancelLanguageObserver();
                }
            }
        };
    }

    private static async getDocuments(language: string) {
        const key = language;

        return this.cache.get(key, async () => {
            const ref = this.collection.doc(language);
            const snapshot = await ref.get();
            const data = snapshot.data() as DocumentData;

            if (snapshot.exists && data) {
                const version = data.version;

                return { ...data, version, key };
            }
        });
    }

    @bind
    private static notifyVersionListeners(snapshot: firebase.firestore.DocumentSnapshot) {
        const data = snapshot.data() as DocumentData;

        if (snapshot.exists) {
            const version = Timestamps.toDate(data.version);

            this.cache.clear();
            this.versionListeners.forEach(listener => listener(version));
        }
    }
}
