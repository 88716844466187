import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import Validate, { Validation } from '../../../../lib/src/helpers/Validate';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryButton } from '../button';
import Modal, { ModalProps } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Input, { InputValue } from '../forms/Input';
import { RegularText, TealLink, TitleText } from '../text';

const HintContainer = styled(FullSizeContent)`
    padding: ${SCREEN_PADDING}px;
    padding-top: ${GRID_SIZE}px;
`;

const HintTitle = styled(TitleText)`
    margin-bottom: ${GRID_SIZE / 2}px;
`;

const HintText = styled(RegularText)`
    margin-bottom: ${GRID_SIZE * 3}px;
`;

const HintLinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${GRID_SIZE * 4}px;

    & > * {
        margin-left: ${GRID_SIZE * 2}px;

        &:first-child {
            margin-left: 0;
        }
    }
`;

@inject('api')
@observer
export default class Profile extends Modal {
    private codeInputRef = React.createRef<Input>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps;
    }

    protected async hydrateParams(params: string[]) {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.loggedIn).get();
    }

    public render() {
        const { verified } = this.injected.api.account;

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.close}
                // fullHeight={true} // TODO: uncomment when we have a profile screen again
            >
                <ScreenHeader title="" onClose={this.close} />
                {verified ? this.renderProfile() : this.renderVerificationHint()}
            </Screen>
        );
    }

    // TODO: implement
    @bind
    private renderProfile() {
        return (
            <TitleText style={{ textAlign: 'center' }}>
                — Coming Soon —
            </TitleText>
        );
    }

    @bind
    private renderVerificationHint() {
        const { email } = this.injected.api.account.data;

        return (
            <HintContainer>
                <HintTitle>
                    {Env.i18n.t('VerifyCallToActionTitle')}
                </HintTitle>
                <HintText>
                    {Env.i18n.t('VerifyCallToActionDescription', { email })}
                </HintText>
                <Input
                    ref={this.codeInputRef}
                    placeholder={Env.i18n.t('VerificationCode')}
                    validate={this.validateVerificationCode}
                    onSubmitEditing={this.submitVerificationCode}
                    style={{ flex: 'initial', marginBottom: GRID_SIZE * 1.5 }}
                />
                <HintLinks>
                    <TealLink onClick={this.injected.api.account.requestVerificationCode}>
                        {Env.i18n.t('ResendCode')}
                    </TealLink>
                    <TealLink onClick={this.changeEmail}>
                        {Env.i18n.t('ChangeEmail')}
                    </TealLink>
                </HintLinks>
                <PrimaryButton onClick={this.submitVerificationCode}>
                    {Env.i18n.t('Confirm')}
                </PrimaryButton>
            </HintContainer>
        );
    }

    @bind
    private editProfilePicture() {
        this.redirectTo('editpicture');
    }


    @bind
    private validateVerificationCode(code: InputValue) {
        return Validate.verificationCode(code.toString());
    }

    @bind
    private async submitVerificationCode() {
        const codeInput = this.codeInputRef.current;
        const code = codeInput?.validateValue();

        if (code !== undefined) {
            const correct = await this.injected.api.waitFor(Backend.verifyEmail(code.toString()));

            if (correct) {
                Env.snackbar.success(Env.i18n.t('VerificationCodeAccepted'));
                this.back(); // TODO: remove this line once we have a profile screen again
            } else {
                codeInput?.setError(Validation.error(Env.i18n.t('ErrorVerificationCodeIncorrect')));
            }
        }
    }

    @bind
    private changeEmail() {
        this.redirectTo('changeemail');
    }
}
