import { IconButton } from '@material-ui/core';
import AddOutlined from '@material-ui/icons/AddOutlined';
import RemoveOutlined from '@material-ui/icons/RemoveOutlined';
import { bind } from 'decko';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';

const CounterWrapper = styled.div`
    align-Items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    white-space: nowrap;
`;

const CounterButton = styled(IconButton)`
    padding: 0px !important;
    &.MuiIconButton-root {
        background-color: ${colors.teal_50};
    }

    &.MuiIconButton-root:hover {
        background-color: ${colors.teal_50};
    }

    &.Mui-disabled {
        background-color: ${colors.grey_04} !important;
        color: ${colors.grey_02} !important;
     }
`;

const CounterValue = styled.span`
    display: inline-block;
    line-height: 20px;
    height: 20px;
    text-align: center;
    margin: 0 6px;
    color: ${colors.matte_black};
`;

interface Props {
    value: number;
    min?: number;
    max?: number;
    onChange: (val: number) => void;
    className?: string;
    style?: CSSProperties;
}

export default class Counter extends React.PureComponent<Props> {
    @bind
    private increment() {
        this.props.onChange(this.props.value + 1);
    }

    @bind
    private decrement() {
        this.props.onChange(this.props.value - 1);
    }

    public render() {
        const { value, min, max, className, style } = this.props;

        return (
            <CounterWrapper className={className} style={style}>
                <CounterButton aria-label="remove" onClick={this.decrement} disabled={value <= (min || 0)}>
                    <RemoveOutlined style={{ fontSize: 16 }}/>
                </CounterButton>
                <CounterValue>
                    {value}
                </CounterValue>
                <CounterButton aria-label="add" onClick={this.increment} disabled={value >= (max || Number.MAX_SAFE_INTEGER)}>
                    <AddOutlined style={{ fontSize: 16 }}/>
                </CounterButton>
            </CounterWrapper>
        );
    }
}
