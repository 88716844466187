import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { GRID_SIZE } from '../../styles/base';
import { theme } from '../../theme';
import Modal, { CloseButton } from '../common/Modal';
import { GreyTitleText } from '../text';

const LinkContainer = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${GRID_SIZE}px ${GRID_SIZE * 1.5}px;

    @media (min-width: ${theme.breakpoints.values.sm}px) and (orientation: landscape) {
        flex-direction: row;
    }
`;

interface LinkProps {
    url: string;
    label: string;
    image: string;
}

class Link extends React.PureComponent<LinkProps> {
    // TODO: use `LinkTo.url()` instead of `<a />`?
    public render() {
        return (
            <DialogContentText style={{ padding: `0 ${GRID_SIZE * 1.5}px`, textAlign: 'center' }}>
                <span style={{display: 'block', marginBottom: GRID_SIZE * 2 }}>
                    {this.props.label}
                </span>
                <a href={this.props.url} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: 200, height: 66.84 }} src={this.props.image} />
                </a>
            </DialogContentText>
        );
    }
}

export default class StoreModal extends Modal {
    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return true;
    }

    public render() {
        return (
            <Dialog open={this.paramsAreValid()} onClose={this.close}>
                <DialogTitle>
                    Lade dir jetzt die App herunter
                    <CloseButton onClick={this.close} />
                </DialogTitle>
                <DialogContent>
                    <GreyTitleText style={{ textAlign: 'center' }}>
                        und nutze den vollen LunchNow-Service
                    </GreyTitleText>
                </DialogContent>
                <LinkContainer>
                    <Link
                        label="für iOS"
                        url="https://apps.apple.com/de/app/lunchnow/id1179477701"
                        image={require('../../assets/svg/appstore.svg')}
                    />
                    <Link
                        label="für Android"
                        url="https://play.google.com/store/apps/details?id=com.lunchnow.customer"
                        image={require('../../assets/svg/google-play.svg')}
                    />
                </LinkContainer>
            </Dialog>
        );
    }
}
