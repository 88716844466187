import { IconButton, Modal } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { bind } from 'decko';
import * as React from 'react';
import Carousel from 'react-images';
import styled from 'styled-components';

import colors, { alpha } from '../../../../lib/src/styles/colors';
import { Medium } from '../../../../lib/src/types/lunchnow';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { GRID_SIZE } from '../../styles/base';

const Overlay = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const CloseButton = styled(IconButton)`
    background-color: ${alpha(colors.white, .2)} !important;
    position: absolute !important;
    right: ${GRID_SIZE * 2}px;
    top: ${GRID_SIZE * 2}px;
`;

const CarouselView = styled.div`
    line-height: 0;
    position: relative;
    text-align: center;
    box-sizing: border-box;
`;

const CarouselVideoWrapper = styled.div`
    display: inline-block;
    position: relative;
`;

const CarouselVideo = styled.iframe.attrs({
    allowFullScreen: true
})`
    background-color: #000;
    border: none;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
`;

const CarouselImage = styled.img`
    height: auto;
    max-height: 100%;
    max-width: 100%;
    user-select: none;
`;

class GalleryView extends React.PureComponent<{ data: Medium }> {
    public render() {
        const { data } = this.props;
        const imageUrl = RestaurantEntry.getMediaUrl(data);
        const videoUrl = RestaurantEntry.getVideoUrl(data);

        return (
            <CarouselView>
                {videoUrl ? (
                    <CarouselVideoWrapper>
                        <CarouselImage src={imageUrl} />
                        <CarouselVideo src={videoUrl} />
                    </CarouselVideoWrapper>
                ) : (
                    <CarouselImage src={imageUrl} />
                )}
            </CarouselView>
        );
    }
}

// only for setting default props
const Slider = styled(Carousel).attrs({
    components: {
        Header: undefined,
        Footer: undefined,
        View: GalleryView
    },
    styles: {
        container: () => ({})
    }
})``;

interface State {
    media: Medium[];
    currentIndex?: number;
}

export default class MediaGalleryModal extends React.Component<{}, State> {
    private static instance?: MediaGalleryModal;

    public readonly state: State = {
        media: []
    };

    public static open(media: Medium[], currentIndex = 0) {
        if (this.instance) {
            this.instance.setState({ media, currentIndex });
        }
    }

    public componentDidMount() {
        MediaGalleryModal.instance = this;
    }

    public componentWillUnmount() {
        MediaGalleryModal.instance = undefined;
    }

    @bind
    private close() {
        this.setState({ currentIndex: undefined });
    }

    @bind
    private closeViaBackground(event: any) {
        if ([ 'div', 'main' ].includes(event.target.nodeName.toLowerCase())) {
            this.close();
        }
    }

    @bind
    private setCarousel(obj: any) {
        if (obj) {
            obj.focusViewFrame();
        }
    }

    public render() {
        const { currentIndex, media } = this.state;

        return (
            <Modal open={currentIndex !== undefined} onEscapeKeyDown={this.close}>
                <Overlay onClick={this.closeViaBackground}>
                    <Slider ref={this.setCarousel} views={media} currentIndex={currentIndex} />
                    <CloseButton onClick={this.close}>
                        <Close style={{ color: colors.white }} />
                    </CloseButton>
                </Overlay>
            </Modal>
        );
    }
}
