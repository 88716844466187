import ArrowForward from '@material-ui/icons/ArrowForward';
import { bind, debounce } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import Backend from '../../../../lib/src/helpers/Backend';
import Validate, { assert, logError } from '../../../../lib/src/helpers/Validate';
import ContactEntity, { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryFab } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Input, { InputValue } from '../forms/Input';
import SearchBar from '../forms/SearchBar';
import { ErrorText, ImprintText, ListFooterHint, RegularText, TealLink } from '../text';
import ContactChips from './ContactChips';
import ContactList from './ContactList';

// TODO: contacts import?

interface State extends ModalState<{}> {
    selection: ContactEntity[];
    contacts: ContactPerson[];
    inputValid?: boolean;
    preview: boolean;
    searching?: boolean;
    error?: string;
}

@inject('api')
export default class AddContacts extends Modal<{}, State> {
    public readonly state: State = {
        selection: [],
        contacts: [],
        preview: true,
        params: {}
    };

    private get injected() {
        return this.props as ModalProps & InjectedApiProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified).get();
    }

    public componentDidMount() {
        super.componentDidMount();

        this.injected.api.account.contactSuggestions.then(contacts => {
            if (this.state.preview) {
                if (contacts.length) {
                    this.setState({ contacts });
                } else {
                    this.setState({ preview: false });
                }
            }
        });
    }

    public render() {
        const resultsFound = this.state.contacts.length !== 0;

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={Env.i18n.t('AddContactHeader')} onBack={this.back}>
                    <RegularText>
                        {Env.i18n.t('AddContactExplanation')}
                        <TealLink onClick={this.handleCodeButtonPress}>
                            {Env.i18n.t('UseCode')}
                        </TealLink>
                    </RegularText>
                    <ContactChips contacts={this.state.selection} toggleContact={this.handleContactPress}/>
                    {this.state.error && (
                        <ErrorText>
                            {this.state.error}
                        </ErrorText>
                    )}
                </ScreenHeader>
                <SearchBar
                    onInput={this.handleInput}
                    placeholderText={Env.i18n.t('SearchForContact')}
                    loading={this.state.searching}
                    style={{ marginTop: GRID_SIZE }}
                />
                <FullSizeContent style={{ marginTop: GRID_SIZE * 1.5 }}>
                    <ContactList
                        contacts={this.state.contacts}
                        disableSearch={true}
                        renderItemContent={this.renderUserLabel}
                        onItemPress={this.handleContactPress}
                        selection={this.state.selection.map(contact => contact.key)}
                        ListEmptyComponent={this.renderEmptyIndicator()}
                        ListFooterComponent={resultsFound ? this.renderFooter() : undefined}
                    />
                    <PrimaryFab onClick={this.addContacts} style={{ margin: SCREEN_PADDING }}>
                        <ArrowForward />
                    </PrimaryFab>
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private addContacts() {
        try {
            assert(this.state.selection.length > 0, Env.i18n.t('ErrorNoUsers'));

            const { selection } = this.state;
            const contacts = selection.map(contact => contact.key);

            this.injected.api.waitFor(Backend.addContacts(contacts))
                .then(() => {
                    this.back();
                    Env.snackbar.success(Env.i18n.t('SuccessContactsAdded', { count: contacts.length }));
                })
                .catch(error => {
                    logError('AddContactsScreen.addContacts', error);
                    Env.snackbar.error(Env.i18n.t('ErrorContactsNotAdded', { count: contacts.length }));
                });
        } catch (error) {
            this.setState({ error });
        }
    }

    @bind
    private handleContactPress(contact: ContactEntity) {
        this.setState(state => ({
            selection: Arrays.toggle(state.selection.slice(), contact),
            error: undefined
        }));
    }

    @bind
    @debounce(800)
    private async handleInput(input: string) {
        const inputValid = input.length > 1;
        let contacts: ContactPerson[] = [];

        if (inputValid) {
            this.setState({ searching: true });
            contacts = await Backend.searchForUsers(input);
        }

        this.setState({ contacts, inputValid, searching: false, preview: false });
    }

    @bind
    private handleCodeButtonPress() {
        const codeInputRef = React.createRef<Input>();
        const validateCode = (code: InputValue) => Validate.connectionCode(code.toString());
        const submitCode = () => {
            const code = codeInputRef.current?.validateValue();

            if (code !== undefined) {
                this.injected.api.account.connectUser(code.toString());
            } else {
                return false; // keep Alert from closing
            }
        };
        const content = (
            <Input
                ref={codeInputRef}
                placeholder={Env.i18n.t('Code')}
                validate={validateCode}
                onSubmitEditing={submitCode}
                style={{ marginBottom: GRID_SIZE }}
            />
        );

        Alert.notify(Env.i18n.t('EnterCode'), content, true, submitCode);
    }

    @bind
    private renderFooter() {
        return (
            <ListFooterHint>
                {Env.i18n.t('NotFound')}
            </ListFooterHint>
        );
    }

    @bind
    private renderUserLabel() {
        return this.state.preview && (
            <ImprintText>
                {Env.i18n.t('SuggestedContact')}
            </ImprintText>
        );
    }

    @bind
    private renderEmptyIndicator() {
        const emptyHint = this.state.searching
            ? Env.i18n.t('IsSearching')
            : Env.i18n.t(this.state.inputValid ? 'NoUserFound' : 'NoValidSearch');

        return (
            <EmptyListIndicator
                waitFor={!this.state.preview}
                icon={require('../../assets/svg/empty_state_user.svg')}
                hint={emptyHint}
            />
        );
    }
}
