import ArrowForward from '@material-ui/icons/ArrowForward';
import { bind, debounce } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import Backend from '../../../../lib/src/helpers/Backend';
import { assert, logError } from '../../../../lib/src/helpers/Validate';
import ContactEntity, { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryFab } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import SearchBar from '../forms/SearchBar';
import { ErrorText, ListFooterHint } from '../text';
import ContactChips from './ContactChips';
import ContactList from './ContactList';

interface State extends ModalState<{}> {
    selection: ContactEntity[];
    users: ContactPerson[];
    inputValid?: boolean;
    searching?: boolean;
    error?: string;
}

@inject('api')
export default class AddBlockedUsers extends Modal<{}, State> {
    public readonly state: State = {
        selection: [],
        users: [],
        params: {}
    };

    private get injected() {
        return this.props as ModalProps & InjectedApiProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified).get();
    }

    public render() {
        const emptyHint = this.state.searching
            ? Env.i18n.t('IsSearching')
            : Env.i18n.t(this.state.inputValid ? 'NoUserFound' : 'NoValidSearch');
        const resultsFound = this.state.users.length !== 0;

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={Env.i18n.t('AddBlockedUsers')} onBack={this.back}>
                    <ContactChips contacts={this.state.selection} toggleContact={this.handleUserPress}/>
                    {this.state.error && (
                        <ErrorText>
                            {this.state.error}
                        </ErrorText>
                    )}
                </ScreenHeader>
                <SearchBar
                    onInput={this.handleInput}
                    placeholderText={Env.i18n.t('SearchForContact')}
                    loading={this.state.searching}
                    style={{ marginTop: GRID_SIZE }}
                />
                <FullSizeContent style={{ marginTop: GRID_SIZE * 1.5 }}>
                    <ContactList
                        contacts={this.state.users}
                        disableSearch={true}
                        onItemPress={this.handleUserPress}
                        selection={this.state.selection.map(contact => contact.key)}
                        ListEmptyComponent={
                            <EmptyListIndicator waitFor={true} icon={require('../../assets/svg/empty_state_user.svg')} hint={emptyHint}/>
                        }
                        ListFooterComponent={
                            resultsFound ? this.renderFooter() : undefined
                        }
                    />
                    <PrimaryFab onClick={this.blockUsers} style={{ margin: SCREEN_PADDING }}>
                        <ArrowForward />
                    </PrimaryFab>
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private blockUsers() {
        try {
            assert(this.state.selection.length > 0, Env.i18n.t('ErrorNoUsers'));

            const { selection } = this.state;
            const users = selection.map(contact => contact.key);
            const count = users.length;

            this.injected.api.waitFor(Backend.blockUsers(users))
                .then(() => {
                    this.back();
                    Env.snackbar.success(Env.i18n.t('SuccessBlockedUsersAdded', { count }));
                })
                .catch(error => {
                    logError('AddBlockedUsersScreen.blockUsers', error);
                    Env.snackbar.error(Env.i18n.t('ErrorBlockedUsersNotAdded', { count }));
                });
        } catch (error) {
            this.setState({ error });
        }
    }

    @bind
    private handleUserPress(user: ContactEntity) {
        this.setState(state => ({
            selection: Arrays.toggle(state.selection.slice(), user),
            error: undefined
        }));
    }

    @bind
    @debounce(800)
    private handleInput(input: string) {
        const inputValid = input.length > 2;

        if (inputValid) {
            this.setState({ searching: true });
        } else {
            this.setState({ users: [], inputValid, searching: false });
            return;
        }

        Backend.searchForBlockableUsers(input).then(users =>
            this.setState({ users, inputValid, searching: false })
        );
    }

    @bind
    private renderFooter() {
        return (
            <ListFooterHint>
                {Env.i18n.t('NotFound')}
            </ListFooterHint>
        );
    }
}
