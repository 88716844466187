import { DialogTitle, IconButton as MuiIconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { getCardAlias, getCardLogo, showPaymentError } from '../../../../lib/src/helpers/Payment';
import { GetCardsResponse } from '../../../../lib/src/managers/PaymentManager';
import colors from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { SublineText } from '../../components/text';
import Alert from '../../helpers/Alert';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE } from '../../styles/base';
import { PrimaryButton } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Modal, { ModalProps } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen from '../common/Screen';
import ScreenModal from '../common/ScreenModal';
import RadioGroup from '../forms/RadioGroup';
import CardRegistrationModal from '../payment/CardRegistrationModal';
import { Header, HeaderWrapper, PaymentCard, ScreenTitle } from '../payment/Styled';

const CardContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
`;

const CardLogo = styled.img`
    margin-bottom: ${GRID_SIZE}px;
`;

@inject('api', 'payment')
@observer
export default class PaymentOptions extends Modal {
    private cardRegistrationModalRef = React.createRef<ScreenModal>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedPaymentProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified).get();
    }

    @bind
    private addCreditCard() {
        this.cardRegistrationModalRef.current?.open();
    }

    @bind
    private async deactivateCard(card: GetCardsResponse) {
        Alert.confirm(
            Env.i18n.t('RemoveCard'),
            Env.i18n.t('RemoveCardConfirmation'),
            async confirmed => {
                if (confirmed) {
                    this.injected.api
                        .waitFor(this.injected.payment.deactivateCard(card.mangoPayData.Id))
                        .catch(showPaymentError);
                }
            }
        );
    }

    @bind
    private async setDefaultCard(card: GetCardsResponse) {
        const cardId = card.mangoPayData.Id;

        if (this.injected.payment.defaultCardId !== cardId) {
            this.injected.api
                .waitFor(this.injected.payment.setDefaultCard(cardId))
                .catch (showPaymentError);
        }
    }

    @bind
    private renderHeader() {
        return (
            <HeaderWrapper>
                <Header>
                    <MuiIconButton onClick={this.back} size="small">
                        <ArrowBack style={{ color: colors.matte_black }} />
                    </MuiIconButton>
                    <MuiIconButton onClick={this.close} size="small">
                        <Close style={{ color: colors.matte_black }} />
                    </MuiIconButton>
                </Header>
            </HeaderWrapper>
        );
    }

    @bind
    private renderFooter() {
        return (
            <DialogTitle>
                <PrimaryButton onClick={this.addCreditCard}>
                    {Env.i18n.t('AddCreditCard')}
                </PrimaryButton>
            </DialogTitle>
        );
    }

    @bind
    private renderCard(card: GetCardsResponse) {
        const cardLogo = getCardLogo('card', card);

        return (
            <CardContainer>
                <div>
                    {cardLogo && (
                        <CardLogo src={cardLogo} />
                    )}
                    <SublineText>
                        {getCardAlias(card.mangoPayData)}
                    </SublineText>
                </div>
                <MuiIconButton aria-label="delete" size="small" onClick={() => this.deactivateCard(card)} >
                    <Delete />
                </MuiIconButton>
            </CardContainer>
        );
    }

    public render() {
        const cards = (this.injected.payment.cards || []).filter(card => card.data.typeAlias);
        const defaultCardId = this.injected.payment.defaultCardId;
        const defaultCard = cards.find(card => card.mangoPayData.Id === defaultCardId);

        return (
            <Screen
                open={this.paramsAreValid()}
                className="payment paper"
                dividers={false}
                handleClose={this.close}
                HeaderComponent={this.renderHeader}
                FooterComponent={this.renderFooter}
                fullHeight
            >
                <SafariFix.OverflowWrapper>
                    <PaymentCard>
                        <ScreenTitle>
                            {Env.i18n.t('PaymentOptions')}
                        </ScreenTitle>
                    </PaymentCard>
                    <RadioGroup<GetCardsResponse>
                        options={cards}
                        value={defaultCard}
                        onChange={this.setDefaultCard}
                        renderOption={this.renderCard}
                        EmptyComponent={
                            <EmptyListIndicator
                                waitFor={true}
                                icon={require('../../assets/svg/empty_state_cards.svg')}
                                hint={Env.i18n.t('NoPaymentOptions')}
                            />
                        }
                    />
                </SafariFix.OverflowWrapper>
                <CardRegistrationModal modalRef={this.cardRegistrationModalRef} />
            </Screen>
        );
    }
}
