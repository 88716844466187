import Env from '../Env';

export type Timestamp = firebase.firestore.Timestamp | number | Date;

export default {
    toDate: (timestamp: Timestamp) => {
        const FirebaseTimestamp = Env.firebase.firestore.Timestamp;

        return (FirebaseTimestamp && timestamp instanceof FirebaseTimestamp)
            ? timestamp.toDate()
            : new Date(timestamp as Date);
    }
};
