import { Container, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { HeadlineFilter } from '../text';
import { JSX } from './Screen';

const ButtonContainer = styled.div`
    right: ${GRID_SIZE}px;
    position: absolute;
    top: ${GRID_SIZE}px;
`;

const ContentContainer = styled.div`
    margin: ${GRID_SIZE * -1.5}px 0 ${GRID_SIZE * 1.5}px;
`;

interface Props {
    title: string | JSX;
    onClose?: () => void;
    onBack?: () => void;
}

export default class ScreenHeaderFilter extends React.PureComponent<Props> {
    public render() {
        return (
            <Container>
                    <HeadlineFilter style={{ textAlign: 'center', marginBottom: -30}}>
                    {this.props.title}
                    </HeadlineFilter>
                <ButtonContainer style={{ zIndex: 100, backgroundColor: 'white', borderRadius: 50}}>
                    {this.props.onClose && (
                        <IconButton onClick={this.props.onClose} size="small">
                            <Close style={{ color: colors.matte_black }} />
                        </IconButton>
                    )}
                    {this.props.onBack && (
                        <IconButton onClick={this.props.onBack} size="small">
                            <ArrowBack style={{ color: colors.matte_black }} />
                        </IconButton>
                    )}
                </ButtonContainer>
                {this.props.children && (
                    <ContentContainer>
                        {this.props.children}
                    </ContentContainer>
                )}
            </Container>
        );
    }
}


