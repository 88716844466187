import styled, { css } from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import BackgroundImage from '../common/BackgroundImage';
import Image from '../common/Image';

export const PROFILE_PICTURE_SIZE = GRID_SIZE * 9;

const ProfilePictureDimensions = css`
    width: ${PROFILE_PICTURE_SIZE}px;
    height: ${PROFILE_PICTURE_SIZE}px;
    border-radius: ${PROFILE_PICTURE_SIZE / 2}px;
`;

const ProfilePictureContainerStyle = css`
    ${ProfilePictureDimensions};
    border-radius: ${PROFILE_PICTURE_SIZE / 2}px;
    overflow: hidden;
`;

export const ProfileCachedImage = styled(Image)`
    ${ProfilePictureDimensions};
`;

export const ProfilePictureContainer = styled.div`
    ${ProfilePictureContainerStyle};
`;

export const ProfileImageBackground = styled(BackgroundImage)`
    ${ProfilePictureContainerStyle};
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const GreyProfileBackground = styled.div`
    ${ProfilePictureContainerStyle};
    background-color: ${colors.grey_05};
    align-items: center;
    display: flex;
    justify-content: center;
`;
