import Env from '../../lib/src/Env';
import ApiManager from '../../lib/src/managers/ApiManager';
import Account from './Account';

export interface InjectedApiProps {
    api: Api;
}

export default class Api extends ApiManager<Account> {
    public addForegroundListener(listener: (foreground: boolean) => void, fireImmediately = false) {
        const handler = () => listener(document.visibilityState === 'visible');

        document.addEventListener('visibilitychange', handler);

        if (fireImmediately) {
            listener(true);
        }

        return () => document.removeEventListener('visibilitychange', handler);
    }

    protected handleOutdatedVersion() {
        this.denyAccess(Env.i18n.t('ReloadTitle'), Env.i18n.t('ReloadMessage'), Env.i18n.t('Refresh'), () =>
            window.location.reload()
        );
    }

    protected restart() {
        window.location.reload();
    }
}
