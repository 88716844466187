import React from 'react';
import ClampLines from 'react-clamp-lines';
import styled from 'styled-components';
import Env from '../../../../lib/src/Env';

import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { Badge, BADGE_FONT_SIZE, DescriptionText, RegularText, TitleText } from '../../components/text';
import { GRID_SIZE } from '../../styles/base';
import Distance from './Distance';

const ShadowContainer = styled.div<{ clickable?: boolean }>`
    background-color: ${colors.white};
    box-shadow: 0px 1px 3px #00000029;
    cursor: ${props => props.clickable ? 'pointer' : 'auto'};
    display: flex;
    flex-direction: column;
    margin: ${GRID_SIZE * 2}px;
    margin-top: 0;
    position: relative;
`;

const LargeCardContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const LogoContainer = styled.div`
    width: 80px;
    height: 80px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    margin: ${GRID_SIZE * 2}px 0 ${GRID_SIZE * 2}px ${GRID_SIZE * 2}px;
`;

// @ts-ignore ts(2615)
const BeforeTitleText = styled(RegularText)`
    font-weight: 500;
    color: ${colors.matte_black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledTitleText = styled(TitleText).attrs({
    as: 'div'
})`
    width: 100%;
    flex-shrink: 1;
    margin-right: ${GRID_SIZE}px;
`;

const Price = styled.div`
    flex-grow: 0;
    margin-left: ${GRID_SIZE}px;
    text-align: center;
`;

const DiscountPrice = styled(DescriptionText)`
    font-size: ${BADGE_FONT_SIZE}px;
    text-decoration: line-through;
`;

const ContentText = styled(DescriptionText)`
    margin-top: ${GRID_SIZE}px;
`;

const DiscountQuantity = styled(DescriptionText)`
    font-size: 10px;
    color: ${colors.discount};
    padding-left: ${GRID_SIZE / 2}px;
    font-weight: 300;
    margin: ${GRID_SIZE / 2}px;
    margin-left: 0;
    padding: ${GRID_SIZE / 2}px ${GRID_SIZE * 1.5}px;
    flex: 1;
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${GRID_SIZE * 2}px;
    margin-top: ${GRID_SIZE}px;
    margin-right: ${GRID_SIZE * 2}px;
    margin-bottom: ${GRID_SIZE}px;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
`;

const DistanceContainer = styled.div`
    align-items: flex-end;
    display: flex;
    flex: 1;
    margin-top: ${GRID_SIZE}px;
    margin-right: -15px
    justify-content: space-between;
`;



const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
`;

const Footer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const FooterContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const Hint = styled(DescriptionText)`
    background-color: ${colors.beige};
    bottom: 0;
    color: ${colors.grey_01};
    left: 0;
    padding: ${GRID_SIZE / 2}px;
    position: absolute;
    right: 0;
`;

const IconContentContainer = styled(ShadowContainer)`
    display: flex;
    align-self: flex-end;
    margin: 0;
`;

interface BaseProps {
    title?: string;
    restaurant?: RestaurantEntry;
    onPress?: () => void;
    afterTitleContent?: React.ReactNode;
    overlayContent?: React.ReactNode;
    iconContent?: React.ReactNode;
    hint?: string;
    badgeLabel?: string;
    badgeColor?: string;
    style?: React.CSSProperties;
    className?: string;
    quantityLeft?: number;
}

export class LargeCardBase extends React.PureComponent<BaseProps> {
    public render() {
        const { restaurant, onPress, title, hint, badgeLabel, badgeColor, style, className, quantityLeft } = this.props;
        const { afterTitleContent, overlayContent, iconContent, children } = this.props;
        const logoUrl = restaurant?.logo?.url;

        return (
            <ShadowContainer onClick={onPress} clickable={!!onPress} style={style} className={className}>
                <LargeCardContainer>
                    {!!logoUrl && (
                        <LogoContainer style={{ backgroundImage: `url(${logoUrl})` }} />
                    )}
                    <CardContent>
                        {!!title && restaurant && (
                            <BeforeTitleText>
                                {restaurant?.name}
                            </BeforeTitleText>
                        )}
                        <TitleContainer>
                            <StyledTitleText>
                                <ClampLines id="" text={title || restaurant?.name || ''} lines={2} buttons={false} />
                            </StyledTitleText>
                            {!!afterTitleContent && afterTitleContent}
                        </TitleContainer>
                        {children}
                    </CardContent>
                    {!!overlayContent && (
                        <Overlay>
                            {overlayContent}
                        </Overlay>
                    )}
                </LargeCardContainer>
                <Footer>
                    <FooterContent>
                        {!!badgeLabel && (
                            <Badge style={{ backgroundColor: badgeColor || colors.grey_01 }}>
                                {badgeLabel}
                            </Badge>
                        )}
                        {!!badgeLabel && (
                            <DiscountQuantity>
                                {Env.i18n.t('DiscountQuantityHint', { count: quantityLeft })}
                            </DiscountQuantity>
                        )}
                        {!!hint && (
                            <Hint>
                                {hint}
                            </Hint>
                        )}
                    </FooterContent>
                    {!!iconContent && (
                        <div onClick={event => event.stopPropagation()}>
                            {iconContent}
                        </div>
                    )}
                </Footer>
            </ShadowContainer>
        );
    }
}

interface Props extends BaseProps {
    price?: string;
    discountPrice?: string;
}

// used as large RestaurantCard or MealCard
export default class LargeCard extends React.PureComponent<Props> {
    public render() {
        const { price, discountPrice, children, ...baseProps } = this.props;
        const { restaurant, badgeColor, afterTitleContent, iconContent, quantityLeft } = baseProps;

        if (price) {
            baseProps.afterTitleContent = (
                <>
                    {afterTitleContent}
                    <Price>
                        <TitleText style={discountPrice ? { color: colors.discount } : undefined}>
                            {discountPrice || price}
                        </TitleText>
                        {!!discountPrice && (
                            <DiscountPrice style={{ marginBottom: children ? BADGE_FONT_SIZE * -1 : 0 }}>
                                {price}
                            </DiscountPrice>
                        )}
                    </Price>
                </>
            )
        }

        if (!badgeColor) {
            baseProps.badgeColor = colors.discount;
        }

        if (iconContent) {
            baseProps.iconContent = (
                <IconContentContainer>
                    {iconContent}
                </IconContentContainer>
            );
        }

        return (
            <LargeCardBase {...baseProps}>
                {!!children && (
                    <ContentText>
                        {children}
                    </ContentText>
                )}
                {restaurant && (
                    <>
                        <DistanceContainer>
                            <Distance restaurant={restaurant} />
                            {quantityLeft && (
                                <DiscountQuantity style= {{textAlign : "right"}} >
                                    {Env.i18n.t('DiscountQuantityHint', { count: quantityLeft })}
                                </DiscountQuantity>
                            )}
                        </DistanceContainer>
                    </>
                )}
            </LargeCardBase>
        );
    }
}
