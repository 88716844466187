import { bind } from 'decko';
import { computed, observable } from 'mobx';

import Env from '../Env';
import { assert } from '../helpers/Validate';
import { UserContactGroupsEntry } from '../types/models/User';
import { DataListEntry } from './DataList';

type ContactGroupEntry = UserContactGroupsEntry & DataListEntry;

// TODO: extract common super-class with InvitationDraft?
export default class ContactGroupDraft {
    public origin?: string;

    @observable
    private group: Partial<ContactGroupEntry>;

    @observable
    private _dirty: boolean;

    @observable
    private _loading: boolean;

    public constructor(from: Partial<ContactGroupEntry> = {}) {
        this.group = { ...from }; // copy the group, a reference is problematic with changes done on this reference
        this._dirty = !this.isComplete(); // TODO: rather check for something like key/id (indicating `from` comes from db)
        this._loading = false;
    }

    @computed
    public get key() {
        return this.group.key;
    }

    public set key(key: string | undefined) {
        this.group.key = key;
        this._dirty = true;
    }

    @computed
    public get isFavorite() {
        return this.group.isFavorite;
    }

    public set isFavorite(isFavorite: boolean | undefined) {
        this.group.isFavorite = isFavorite;
        this._dirty = true;
    }

    @computed
    public get name() {
        return this.group.name;
    }

    public set name(name: string | undefined) {
        this.group.name = name;
        this._dirty = true;
    }

    @computed
    public get members() {
        return this.group.members;
    }

    public set members(members: string[] | undefined) {
        this.group.members = members;
        this._dirty = true;
    }

    @computed
    public get dirty() {
        return this._dirty;
    }

    @computed
    public get loading() {
        return this._loading;
    }

    @bind
    public isComplete() {
        return !!(this.isFavorite !== undefined && this.name && this.members);
    }

    @bind
    public async send(): Promise<ContactGroupEntry> {
        assert(this.isComplete(), 'Group draft is incomplete');

        const currentUser = Env.firebase.auth().currentUser;

        if (currentUser) {
            try {
                this._loading = true;

                const collection = Env.firebase.firestore().collection('users').doc(currentUser.uid).collection('contactGroups');
                const doc = this.key ? collection.doc(this.key) : collection.doc();

                await doc.set({
                    name: this.name,
                    isFavorite: this.isFavorite,
                    contacts: this.members || []
                });
            } finally {
                this._loading = false;
            }
        }

        this._dirty = false;

        return this.group as ContactGroupEntry;
    }
}
