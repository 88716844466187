import React from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { bind } from 'decko';

import { InjectedChatProps } from '../../../../lib/src/managers/ChatManager';
import Env from '../../../../lib/src/Env';
import ContactEntity from '../../../../lib/src/types/models/ContactEntity';
import Arrays from '../../../../lib/src/helpers/Arrays';
import Constants from '../../../../lib/src/Constants';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import { InjectedApiProps } from '../../Api';
import Screen, { FullSizeContent } from '../common/Screen';
import { PrimaryButton } from '../button';
import NewScreenHeader from '../common/NewScreenHeader';
import ContactChips from '../contacts/ContactChips';
import { ErrorText, SublineText } from '../text';
import ContactList from '../contacts/ContactList';
import { EmptyContactsList } from '../social/EmptyList';
import EmptyListIndicator from '../common/EmptyListIndicator';

interface State extends ModalState<{}> {
    selection: ContactEntity[];
    fadeOutUnselectedContacts: boolean;
    showMaxAttendeesWarning: boolean;
}

@inject('api', 'chatManager')
@observer
export default class ChatInboxScreen extends Modal<{}, State> {
    public readonly state: State = {
        params: {},
        selection: [],
        fadeOutUnselectedContacts: false,
        showMaxAttendeesWarning: false
    };

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedChatProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.loggedIn).get();
    }

    public render() {
        const { contacts } = this.injected.api.account;
        const { selection, fadeOutUnselectedContacts, showMaxAttendeesWarning } = this.state;

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.back}
                fullHeight={true}
                HeaderComponent={<NewScreenHeader title={Env.i18n.t('InviteContacts')} onBack={this.back} />}
                FooterComponent={this.renderCta}
            >
                <ContactChips contacts={selection} toggleContact={this.toggleContactSelection} />

                {selection.length <= 0 && (
                    <SublineText>
                        {Env.i18n.t('AddContactCTA')}
                    </SublineText>
                )}
                {showMaxAttendeesWarning && (
                    <ErrorText>
                        {Env.i18n.t('MaxAttendeesWarning', { count: Constants.MAX_ATTENDEES - 1 })}
                    </ErrorText>
                )}

                <FullSizeContent>
                    <ContactList
                        contacts={contacts.list}
                        onItemPress={this.toggleContactSelection}
                        selection={selection.map(contact => contact.key)}
                        fadeOutUnselectedContacts={fadeOutUnselectedContacts}
                        ListEmptyComponent={this.renderEmptyList()}
                    />
                </FullSizeContent>
            </Screen>
        )
    }

    @bind
    private renderCta() {
        return (
            <PrimaryButton onClick={this.state.selection.length ? this.onCreateNewChat : undefined}>
                {Env.i18n.t('NewMessage')}
            </PrimaryButton>
        );
    }

    @bind
    private toggleContactSelection(contact: ContactEntity) {
        this.setState(state => {
            const newSelection = Arrays.toggle(state.selection.slice(), contact);
            const newNumberOfAttendees = newSelection.length;
            const showMaxAttendeesWarning = newNumberOfAttendees >= Constants.MAX_ATTENDEES - 1;

            const selection = newNumberOfAttendees > Constants.MAX_ATTENDEES - 1 ? state.selection : newSelection;
            const numberOfAttendees = selection.length;
            const fadeOutUnselectedContacts = numberOfAttendees >= Constants.MAX_ATTENDEES - 1;

            return {
                selection,
                showMaxAttendeesWarning,
                fadeOutUnselectedContacts
            };
        });
    }

    private renderEmptyList() {
        return this.injected.api.account.contacts.empty ? (
            <EmptyContactsList onPress={() => this.redirectTo('addcontacts')} />
        ) : (
                <EmptyListIndicator
                    waitFor={true}
                    icon={require('../../assets/svg/empty_state_user.svg')}
                    hint={Env.i18n.t('AllUsersInvited')}
                />
            );
    }

    @bind
    private async onCreateNewChat() {
        const chatKey = await this.injected.chatManager.createNewChat(this.state.selection.map(contact => contact.key));
        this.redirectTo('chatmessages', [chatKey]);
    }
}
