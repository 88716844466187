import { bind } from 'decko';
import React, { ChangeEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import { Validation } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { ErrorText } from '../text';

const Container = styled.div`
    flex: 0;
    margin: ${GRID_SIZE * 1.5}px 0;
`;

const InputContainer = styled.div`
    align-content: center;
    align-items: center;
    background-color: ${colors.grey_05};
    border-radius: ${GRID_SIZE / 4}px;
    display: flex;
    flex-direction: row;
    padding: 0 ${GRID_SIZE}px;
    position: relative;
`;

export const INPUT_TEXT_STYLE = css`
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 1.3;

    /*
    * for iPhone
    * @see https://css-tricks.com/snippets/css/media-queries-for-standard-devices
    */
    @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) {
        font-size: 16px; /* minimum font-size needed to prevent zoom on iOS */
    }
`;

const InputField = styled.input`
    ${INPUT_TEXT_STYLE};
    background-color: transparent;
    border: none;
    color: ${colors.matte_black};
    display: flex;
    flex: 1;
    padding: ${GRID_SIZE}px 0;

    &::placeholder {
        color: ${colors.grey_02};
    }
`;

const LengthHint = styled(ErrorText)`
    color: ${colors.grey_02};
    margin: 0;
    text-align: right;
`;

export type InputValue = string | number | ReadonlyArray<string>;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Called on change, on blur and when `value` is set and not `undefined` */
    validate?: (value: InputValue) => Validation;
    sanitizeValue?: boolean;
    /** Called after `validate` */
    onChangeText?: (value: InputValue) => void;
    /** Called before `validate` */
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onSubmitEditing?: () => void;
    nextInput?: React.RefObject<Input>;
    fieldStyle?: React.CSSProperties;
    className?: string;
    style?: React.CSSProperties;
    children?: ReactNode;
}

interface State {
    value: InputValue; // sanitized input value
    displayValue: InputValue; // input value as entered
    validation: Validation;
}

export default class Input extends React.PureComponent<Props, State> {
    // probably won't react on prop changes. Use setValue() instead if needed
    private defaultValue = this.props.defaultValue || '';

    public readonly state: State = {
        value: this.defaultValue,
        displayValue: this.defaultValue,
        validation: Validation.success
    };

    private textInputRef = React.createRef<HTMLInputElement>();

    private static sanitizeValue(value: InputValue): InputValue {
        if (Array.isArray(value)) {
            return value.map(this.sanitizeValue) as string[];
        } else if (typeof value === 'string') {
            return value.trim();
        } else {
            return value;
        }
    }

    public componentDidUpdate() {
        if (this.props.value !== undefined) {
            this.setValue(this.props.value);
        }
    }

    public componentDidMount() {
        this.componentDidUpdate();
    }

    @bind
    public setError(validation = Validation.success) {
        this.setState({ validation });
    }

    /**
     * @param force If `true` (default), the error message will always be reset. Otherwise, will only be unset (if valid)
     *
     * @returns The current value of the `Input` component if it is valid, otherwise `undefined`
     */
    @bind
    public validateValue(force = true) {
        let validation = Validation.success;

        if (this.props.validate) {
            validation = this.props.validate(this.state.value) || undefined;

            if (validation.valid || force) {
                this.setError(validation);
            }
        }

        return validation.valid ? this.state.value : undefined;
    }

    @bind
    public setValue(newValue: InputValue) {
        const value = (this.props.sanitizeValue === false ? newValue : Input.sanitizeValue(newValue));

        this.setState({ value, displayValue: newValue }, () => this.validateValue(false));

        if (this.props.onChangeText) {
            this.props.onChangeText(value);
        }
    }

    @bind
    public focus() {
        this.textInputRef.current?.focus();
    }

    @bind
    public blur() {
        this.textInputRef.current?.blur();
    }

    @bind
    public reset() {
        this.setValue(this.defaultValue);
        this.setError();
    }

    public render() {
        const { children, fieldStyle, className, style, onChangeText, onSubmitEditing, nextInput, ...inputProps } = this.props;
        const { displayValue, value, validation } = this.state;
        const sanitizedProps: React.InputHTMLAttributes<HTMLInputElement> = {
            ...inputProps,
            autoCorrect: 'off',
            value: displayValue.toString(),
            onChange: this.handleValueChange,
            onBlur: this.handleBlur,
            onKeyDown: this.handlePossibleSubmit
        };

        return (
            <Container className={className} style={style}>
                <InputContainer style={fieldStyle}>
                    <InputField {...sanitizedProps} ref={this.textInputRef} />
                    {children}
                </InputContainer>
                {!!inputProps.maxLength && (
                    <LengthHint>
                        {value.toString().length} / {inputProps.maxLength} {Env.i18n.t('TextfieldLabel')}
                    </LengthHint>
                )}
                {validation.message && (
                    <ErrorText>
                        {validation.message}
                    </ErrorText>
                )}
            </Container>
        );
    }

    @bind
    private handleBlur(event: React.FocusEvent<HTMLInputElement>) {
        this.setValue(this.state.value);
        this.validateValue(true);

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    @bind
    private handleValueChange(event: ChangeEvent<HTMLInputElement>) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }

        this.setValue(event.target.value);
    }

    @bind
    private handlePossibleSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
        const { nextInput, onSubmitEditing } = this.props;

        if ((nextInput || onSubmitEditing) && event.keyCode === 13) { // === Enter key
            nextInput?.current?.focus();

            if (onSubmitEditing) {
                onSubmitEditing();
            }
        }
    }
}
