import moment, { Moment, unitOfTime } from 'moment';

import { DataListEntry } from '../store/DataList';
import List from '../types/List';

interface CacheEntry<T extends DataListEntry> {
    expires: Moment;
    data: T;
}

type Predicate<T> = (data: T) => boolean;

export default class Cache<T extends DataListEntry> {
    private cache: List<CacheEntry<T>> = {};
    private lifetime: number;
    private lifetimeUnit: unitOfTime.Base;

    constructor(lifetime = 0, lifetimeUnit: unitOfTime.Base = 'seconds') {
        this.lifetime = Math.max(0, lifetime);
        this.lifetimeUnit = lifetimeUnit;
    }

    public set(data: T) {
        const expires = moment().add(this.lifetime, this.lifetimeUnit);

        this.cache[data.key] = { data, expires };
    }

    public remove(dataOrKey: T | string) {
        const key = typeof dataOrKey === 'string' ? dataOrKey : dataOrKey.key;

        delete this.cache[key];
    }

    public clear() {
        this.cache = {};
    }

    /**
     * Returns a cached entry by its `key` property.
     * This is equivalent to `find(entry => entry.key === key, onMiss)`, but faster.
     *
     * @param   key     `key` property of the desired cache entry
     * @param   onMiss  Function that generates the desired entry if it was not found in the cache (or has expired)
     */
    public get(key: string, onMiss: () => Promise<T | undefined>) {
        return this.handleEntry(this.cache[key], onMiss);
    }

    /**
     * Returns the first cached entry that makes the `predicate` return `true`.
     *
     * @param   predicate   Predicate function
     * @param   onMiss      Function that generates the desired entry if it was not found in the cache (or has expired)
     */
    public find(predicate: Predicate<T>, onMiss: () => Promise<T | undefined>) {
        return this.handleEntry(Object.values(this.cache).find(entry => predicate(entry.data)), onMiss);
    }

    private async handleEntry(cacheEntry: CacheEntry<T> | undefined, onMiss: () => Promise<T | undefined>) {
        const expired = cacheEntry && this.lifetime && moment().isAfter(cacheEntry.expires);
        let data = cacheEntry?.data;

        if (!data || expired) {
            data = await onMiss();

            if (data) {
                this.set(data);
            }
        }

        return data;
    }
}
