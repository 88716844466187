import { Fab } from '@material-ui/core';
import { bind } from 'decko';
import React from 'react';

import GoogleMapsApi from '../../../../lib/src/helpers/GoogleMapsApi';
import Locations from '../../../../lib/src/helpers/Locations';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import LinkTo from '../../helpers/LinkTo';
import { BUTTON_ICON_SIZE } from '../common/Icon';
import MapImage from '../map/MapImage';
import Marker from '../map/Marker';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    restaurant: RestaurantEntry;
}

export default class StaticMap extends React.PureComponent<Props> {
    @bind
    private linkToMap() {
        const coords = Locations.normalize(this.props.restaurant.location);
        const name = encodeURI(this.props.restaurant.name || '');
        const mapUrl = `https://maps.google.com/?q=${name}&center=${coords.latitude},${coords.longitude}`;

        LinkTo.url(mapUrl, '_blank');
    }

    public render() {
        const { restaurant, ...divProps } = this.props;

        if (!restaurant.location) {
            return null;
        }

        const locationCoords = GoogleMapsApi.latlngToCoords(restaurant.location);

        return (
            <MapImage location={restaurant.location} zoom={15} width={600} height={300} {...divProps}>
                <Marker {...locationCoords} secondary={restaurant.type === CooperationType.NonPartner} />
                <Fab size="medium" style={{ position: 'absolute', right: 20, top: 20 }} onClick={this.linkToMap}>
                    <img
                        alt="Öffne Google Maps"
                        src={require('../../assets/svg/ic_primary_openinnewwindow.svg')}
                        width={BUTTON_ICON_SIZE}
                        height={BUTTON_ICON_SIZE}
                    />
                </Fab>
            </MapImage>
        );
    }
}
