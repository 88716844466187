import Clock from '@material-ui/icons/Schedule';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Validate from '../../../../lib/src/helpers/Validate';
import Cart from '../../../../lib/src/store/Cart';
import colors from '../../../../lib/src/styles/colors';
import { getDefaultOrderTime, getMinOrderDate } from '../../../../lib/src/types/models/Order';
import { InjectedAccountProps } from '../../Account';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import DatePicker from '../forms/DatePicker';
import SwitchGroup from '../forms/SwitchGroup';
import { RegularText, SectionHeadline } from '../text';
import { ICON_SIZE } from './LinkButton';

const TIME_BUTTON_PADDING = GRID_SIZE * 1.5;

const TimeButton = styled.div<{ set: boolean }>`
    align-items: center;
    align-self: center;
    background-color: ${props => props.set ? colors.teal_50 : colors.grey_05};
    border-radius: ${ICON_SIZE / 2 + TIME_BUTTON_PADDING}px;
    color: ${props => props.set ? colors.teal_900 : colors.navy_blue};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-right: ${TIME_BUTTON_PADDING}px;
`;

// @ts-ignore ts(2615)
const TimeButtonLabel = styled(RegularText)`
    border-right: 1px solid ${colors.white};
    color: inherit;
    display: flex;
    justify-content: center;
    line-height: ${ICON_SIZE}px;
    margin-right: ${TIME_BUTTON_PADDING}px;
    padding: ${TIME_BUTTON_PADDING}px;
`;

const MissingValueHint = styled(RegularText)`
    color: ${colors.warn};
    margin-top: 3px;
    display: flex;
    justify-content: center;
`;

interface Props {
    cart: Cart;
    showHint?: boolean;
}

@inject('account', 'payment')
@observer
export default class PlaceAndTimeOptions extends React.PureComponent<Props> {
    private timePickerRef = React.createRef<DatePicker>();

    private get injected() {
        return this.props as Props & InjectedAccountProps & InjectedPaymentProps;
    }

    public render() {
        const { cart } = this.props;

        if (cart.meetUp) {
            return null;
        }

        const timeSet = !!cart.meetUpDate;
        const onlyTakeAway = cart.restaurant.onlyTakeAway;

        return (
            <>
                {onlyTakeAway ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <RegularText style={{ color: colors.warn }} >
                            {Env.i18n.t('OnlyTakeAway')}
                        </RegularText >
                    </div > ) : (
                        <>
                            <SectionHeadline>
                                {Env.i18n.t('ChooseBetween')}
                            </SectionHeadline>
                            <SwitchGroup<boolean | undefined>
                                values={[false, true]}
                                selected={[cart.isTakeAway]}
                                onClick={this.selectTakeAwayOption}
                                renderLabel={value => Env.i18n.t(value ? 'TakeAway' : 'InStore')}
                                style={{ margin: 0 }}
                            />
                            {this.props.showHint && cart.isTakeAway === undefined && (
                                <MissingValueHint>
                                    {Env.i18n.t('HintChooseAPlace')}
                                </MissingValueHint >
                        )}
                        </>

                    )}
                <SectionHeadline>
                    {Env.i18n.t('OrderMeetUpTitle')}
                </SectionHeadline>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TimeButton set={timeSet} onClick={this.openTimePicker}>
                        <TimeButtonLabel>
                            {timeSet
                                ? Env.i18n.t('OrderForDate', {
                                    date: Env.i18n.t('Today'),
                                    time: moment(cart.meetUpDate).format(Env.i18n.t('TimeFormat'))
                                })
                                : Env.i18n.t('ChooseOnlyTime')
                            }
                        </TimeButtonLabel>
                        <Icon src={Clock} color="currentColor" width={ICON_SIZE} height={ICON_SIZE} />
                    </TimeButton>
                </div>
                { this.props.showHint && !cart.meetUpDate && (
                        <MissingValueHint>
                            {Env.i18n.t('HintChooseATime')}
                        </MissingValueHint >
                )}


                <DatePicker
                    ref={this.timePickerRef}
                    preselected={cart.meetUpDate || getDefaultOrderTime().toDate()}
                    min={getMinOrderDate().toDate()}
                    onChange={this.confirmDatePicker}
                    validate={this.validateTime}
                    only="time"
                />
            </>
        );
    }

    @bind
    private openTimePicker() {
        this.timePickerRef.current?.open();
    }

    @bind
    private selectTakeAwayOption(value?: boolean) {
        const { cart } = this.props;

        if (!cart.meetUpDate && cart.isTakeAway === undefined) {
            this.openTimePicker();
        }

        cart.isTakeAway = value;
    }

    @bind
    private confirmDatePicker(date: Date) {
        this.props.cart.setTime(date);
    }

    @bind
    private validateTime(time: Date) {
        return Validate.orderTime(this.props.cart, time, this.injected.account);
    }
}
