import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { bind } from 'decko';
import React, { CSSProperties } from 'react';

import Icon, { IconType } from './Icon';

interface Action {
    label: string;
    icon: IconType;
    action: () => void;
}

interface Props {
    options: Action[];
    closeOnSelect?: boolean;
    style?: CSSProperties;
    className?: string;
}

interface State {
    open: boolean;
}

export default class ActionButton extends React.PureComponent<Props, State> {
    public readonly state: State = {
        open: false
    };

    @bind
    public open() {
        this.setState({ open: true });
    }

    @bind
    public close() {
        this.setState({ open: false });
    }

    public render() {
        return (
            <SpeedDial
                ariaLabel="SpeedDial"
                icon={<SpeedDialIcon />}
                onClose={this.close}
                onOpen={this.open}
                open={this.state.open}
                direction="up"
                style={{ bottom: 0, position: 'absolute', right: 0, ...this.props.style }}
                className={this.props.className}
                FabProps={{
                    color: 'primary',
                    size: 'large'
                }}
            >
                {this.props.options.map((action, index) => (
                    <SpeedDialAction
                        key={index}
                        tooltipTitle={action.label}
                        icon={<Icon src={action.icon} />}
                        onClick={() => this.call(action)}
                    />
                ))}
            </SpeedDial>
        );
    }

    private call(action: Action) {
        action.action();

        if (this.props.closeOnSelect !== false) {
            this.close();
        }
    }
}
