import Env, { Stage } from './Env';

type Staged<T> = {
    [stage in Stage]: T;
};

function bundleSelect<T>(values: Staged<T>) {
    return values[Env.stage];
}

export default class Constants {
    public static readonly WEB_VERSION = '5.0.1';
    public static readonly IOS_APP_STORE_ID = '1179477701';
    public static readonly HOST = 'https://www.lunchnow.com';
    public static readonly LOGO = 'http://public.lunchnow.com/assets/ln_logo_no_claim.png';
    public static readonly MAPS_API_HOST = 'https://maps.googleapis.com/maps/api/';
    public static readonly SUPPORT_URL = 'http://support.lunchnow.com';
    public static readonly SUPPORT_EMAIL = 'support@lunchnow.com';
    public static readonly PAYMENT_SUPPORT_EMAIL = 'orders@lunchnow.com';
    public static readonly HIT_SLOP_SIZE = 44;
    public static readonly MOIA_API_KEY = 'Ebur6ieThec1zah1zuDootoochashe8xae1weewo';
    public static readonly INFO_SEPARATOR = ' · ';
    public static readonly INFO_SEPARATOR_CAROUSEL = ', ';
    public static readonly BECOME_A_PARTNER_URL = 'https://www.lunchnow.com/partner/';
    public static readonly LUNCH_MEAL_TYPES = [ 'Menü', 'Mittagstisch' ]; // TODO: this should be define in the partner database
    public static readonly MAX_ATTENDEES = 6; // for invitations and chat

    public static get API_KEY() {
        return bundleSelect({
            dev: 'AIzaSyDmYeEkAwz9h2pSx0KbtpiZR9N-bZtB_uM',
            beta: 'AIzaSyDpi_UoJxfYE3ygEerOE5w57d6sST3bD3E',
            prod: 'AIzaSyCeu2ilx5EU3BYcH3_ovlYVZm8GcCktVQQ'
        });
    }

    public static get BACKEND_HOST() {
        return bundleSelect({
            // dev: 'http://localhost:5000/lunchnow-customer-development/europe-west1',
            dev: 'https://europe-west1-lunchnow-customer-development.cloudfunctions.net',
            beta: 'https://europe-west1-lunchnow-customer-beta.cloudfunctions.net',
            prod: 'https://europe-west1-lunchnow-customer-production.cloudfunctions.net'
        });
    }

    public static get PARTNER_BACKEND_HOST() {
        return bundleSelect({
            dev: 'https://europe-west1-lunchnow-partner-development.cloudfunctions.net',
            beta: 'https://europe-west1-lunchnow-partner-beta.cloudfunctions.net',
            prod: 'https://europe-west1-lunchnow-partner-production.cloudfunctions.net'
        });
    }

    public static get URL_SCHEME_PREFIX() {
        return bundleSelect({
            dev: 'lunchnowdev://',
            beta: 'lunchnowbeta://',
            prod: 'lunchnow://'
        });
    }

    public static get ANDROID_PACKAGE_NAME() {
        return bundleSelect({
            dev: 'com.lunchnow.customer.dev',
            beta: 'com.lunchnow.customer.beta',
            prod: 'com.lunchnow.customer'
        });
    }

    public static get IOS_BUNDLE_IDENTIFIER() {
        return bundleSelect({
            dev: 'com.lunchnow.LunchNowCustomer.dev',
            beta: 'com.lunchnow.LunchNowCustomer.beta',
            prod: 'com.lunchnow.LunchNowCustomer'
        });
    }

    public static get FIREBASE_CONFIG() {
        return bundleSelect({
            dev: {
                apiKey: 'AIzaSyAyEltENLxxeYV7Mx-MgHwmOcYGwukJwZ4',
                authDomain: 'lunchnow-customer-development.firebaseapp.com',
                databaseURL: 'https://lunchnow-customer-development.firebaseio.com',
                projectId: 'lunchnow-customer-development',
                storageBucket: 'lunchnow-customer-development.appspot.com',
                messagingSenderId: '81285432470',
                appId: '1:81285432470:web:645137813f0739fa',
                measurementId: undefined
            },
            beta: {
                apiKey: 'AIzaSyDpi_UoJxfYE3ygEerOE5w57d6sST3bD3E',
                authDomain: 'lunchnow-customer-beta.firebaseapp.com',
                databaseURL: 'https://lunchnow-customer-beta.firebaseio.com',
                projectId: 'lunchnow-customer-beta',
                storageBucket: 'lunchnow-customer-beta.appspot.com',
                messagingSenderId: '676708718784',
                appId: '1:676708718784:web:f13f3cfd7f1aa3cf51b525',
                measurementId: undefined
            },
            prod: {
                apiKey: 'AIzaSyDdissx9A-hdzMvXqHIzvJy9AvL9nWF5q0',
                authDomain: 'lunchnow-customer-production.firebaseapp.com',
                databaseURL: 'https://lunchnow-customer-production.firebaseio.com',
                projectId: 'lunchnow-customer-production',
                storageBucket: 'lunchnow-customer-production.appspot.com',
                messagingSenderId: '947374575467',
                appId: '1:947374575467:web:ce6570539caf8de3e0062e',
                measurementId: 'G-YYZGD1BY8E'
            }
        });
    }

    public static get PARTNER_FIREBASE_CONFIG() {
        return bundleSelect({
            dev: {
                clientID: '73038592107-730mgsgesfaocdqvd056b78pin2v8h97.apps.googleusercontent.com',
                apiKey: 'AIzaSyBr-YARBcyZH1k1zxVtq0iikmjT5hpD9fw',
                authDomain: 'lunchnow-partner-development.firebaseapp.com',
                databaseURL: 'https://lunchnow-partner-development.firebaseio.com',
                projectId: 'lunchnow-partner-development',
                storageBucket: 'lunchnow-partner-development.appspot.com',
                messagingSenderId: '73038592107',
                appId: 'lunchnow-partner-development' // TODO: looks wrong
            },
            beta: {
                clientID: '146808453648-g1nesn3t3apgcvr72qh5c7821t4006qm.apps.googleusercontent.com',
                apiKey: 'AIzaSyA73OqLHq5VBBTzeTOJELIlXNlhbvhesQU',
                authDomain: 'lunchnow-partner-beta.firebaseapp.com',
                databaseURL: 'https://lunchnow-partner-beta.firebaseio.com',
                projectId: 'lunchnow-partner-beta',
                storageBucket: 'lunchnow-partner-beta.appspot.com',
                messagingSenderId: '146808453648',
                appId: '1:146808453648:web:0476befaa880b916'
            },
            prod: {
                clientID: '609656116105-0o2qkiljjj1avtvioq9t6lp1aq33kgot.apps.googleusercontent.com',
                apiKey: 'AIzaSyB0KAkoW4VT-poK6hFn8h41Xrg-IXfOa8c',
                authDomain: 'lunchnow-partner-production.firebaseapp.com',
                databaseURL: 'https://lunchnow-partner-production.firebaseio.com',
                projectId: 'lunchnow-partner-production',
                storageBucket: 'lunchnow-partner-production.appspot.com',
                messagingSenderId: '609656116105',
                appId: '1:609656116105:web:cc55672f0bac6b45'
            }
        });
    }

    public static get PARTNER_PROJECT_USER() {
        return bundleSelect({
            dev: 'clientapp@lunchnow.com',
            beta: 'clientapp@lunchnow.com',
            prod: 'clientapp@lunchnow.com'
        });
    }

    public static get PARTNER_PROJECT_PASSWORD() {
        return bundleSelect({
            dev: 'wGR$XrXfJ&4@HduuPVUP+rZqE=QVSsf3eRxAqC=4H6Hw4hdk!=^XYn9%qn2uanUe',
            beta: 'bn!b@?arK_5^xXJgbD^t-Uwy*Qany&zS#V*EGAmHz4J4NtwzJgbuTMGtpEbJeQ5q',
            prod: 'qX4E3KbCXT7Lv#vFNsPYutL#kP%_XMeWXLFSP3$_hYxyQnsNdx*sXM_xmwBKXnTu'
        });
    }

    public static get GOOGLE_SIGNIN_WEBCLIENTID() {
        return bundleSelect({
            dev: '81285432470-e02pbgvnr5ff7ub2tom20ovdspgsqbkl.apps.googleusercontent.com',
            beta: '676708718784-glcq01id47qbd5gp2ep5q1dqlms01t2j.apps.googleusercontent.com',
            prod: '947374575467-fr9blrg5c9tmhrr7ds3grcbhmqi8nj2i.apps.googleusercontent.com'
        });
    }

    public static get MANGOPAY_HOST() {
        return bundleSelect({
            dev: 'https://api.sandbox.mangopay.com',
            beta: 'https://api.sandbox.mangopay.com',
            prod: 'https://api.mangopay.com'
        });
    }

    public static get MANGOPAY_CLIENT_ID() {
        return bundleSelect({
            dev: 'lnsb',
            beta: 'lnsb',
            prod: 'lunchnow_prod'
        });
    }
}
