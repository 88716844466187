import { Snackbar } from '@material-ui/core';
import { bind } from 'decko';
import React from 'react';

import colors from '../../../../lib/src/styles/colors';

interface State {
    message?: string;
    color?: string;
}

export default class GlobalSnackbar extends React.PureComponent<{}, State> {
    public readonly state: State = {};

    public render() {
        const { message, color } = this.state;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!this.state.message}
                onClose={this.handleClose}
                ContentProps={{ 'aria-describedby': 'message-id' }}
                message={
                    <span id="message-id" style={{ color }}>
                        {message}
                    </span>
                }
            />
        );
    }

    public show(message: string, color = colors.white) {
        this.setState({ message, color });
    }

    @bind
    private handleClose() {
        this.setState({ message: undefined, color: undefined });
    }
}
