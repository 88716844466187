import moment from 'moment';

import Env from '../Env';
import { TimesTimeSpan } from '../types/lunchnow';
import { AddressResponse } from '../types/models/Response';

export function formatMilliseconds(milliseconds: number) {
    const m = Math.floor(milliseconds / (1000 * 60)) % 60;
    const s = Math.floor(milliseconds / 1000) % 60;
    const ms = Math.floor(milliseconds % 1000);

    return `${m}:${s < 10 ? '0' : ''}${s}.${ms}`;
}

export function formatNumber(number: number, precision?: number) {
    const decimalPoint = Env.i18n.t('decimalPoint');
    const thousandsSeparator = Env.i18n.t('thousandsSeparator');
    const [base, exponent] = number.toString().split('e');
    const [integer, fractional] = base.split('.');
    const buffer = [integer.substr(-3)];

    for (let i = integer.length - 6; i > -3; i -= 3) {
        buffer.unshift(integer.substr(Math.max(0, i), Math.min(3, i + 3)) + thousandsSeparator);
    }

    if (precision !== 0) {
        let fraction = fractional;

        if (precision !== undefined && precision > 0) {
            fraction = fraction || '0';

            if (fraction.length > precision) {
                fraction = Math.round(+fraction.substr(0, precision + 1) / 10).toString();
            } else {
                while (fraction.length < precision) {
                    fraction += '0';
                }
            }
        }

        if (fraction) {
            buffer.push(decimalPoint + fraction);
        }
    }

    if (exponent) {
        buffer.push('e' + exponent);
    }

    return buffer.join('');
}

interface HumanDateFormat {
    sameDay: string;
    nextDay: string;
    nextWeek: string;
    lastDay: string;
    lastWeek: string;
    sameElse: string;
}

export function formatDayAndTime(date: Date = new Date(), overrides: Partial<HumanDateFormat> = {}) {
    const humanDate = moment(date);

    return humanDate.calendar(undefined, {
        sameDay: Env.i18n.t('sameDay'),
        nextDay: Env.i18n.t('nextDay'),
        nextWeek: Env.i18n.t('nextWeek'),
        lastDay: Env.i18n.t('lastDay'),
        lastWeek: Env.i18n.t('lastWeek'),
        sameElse: Env.i18n.t('sameElse'),
        ...overrides
    });
}

export function formatTime(time: string = '') {
    let formattedTime = time;

    if (time && Env.i18n.hourFormat !== 24) {
        const timeParts = time.split(':');
        const hour = Number(timeParts[0]);
        let pm = hour > 12;

        if (pm) {
            timeParts[0] = String(hour - 12);
        } else {
            pm = hour === 12 && timeParts.slice(1).some(part => Number(part) > 0);
        }

        formattedTime = timeParts.join(':') + (pm ? 'pm' : 'am');
    }

    return formattedTime.replace(/^0+(?=\d)/, '');
}

export function formatTimeSpan(timespan: TimesTimeSpan) {
    return `${formatTime(timespan.start)} – ${formatTime(timespan.end)}`;
}

/*
 * This is a helper method, which will try to return always a pretty formatted address string.
 * Use this instead of the variable, to avoid empty address string fields.
 */
export function getFormatedAddress(address?: AddressResponse) {
    if (!address) {
        return '';
    }

    if (address.formattedAddress) {
        return address.formattedAddress;
    }

    const substring1 = `${address.street || ''}`;
    const substring2 = `${address.postalCode || ''} ${address.locality || ''}`;
    let string = `${substring1.trim()}, ${substring2.trim()}`;
    string = string.trim();
    if (string.startsWith(',')) {
        string = string.substr(1, string.length - 1);
    }
    if (string.endsWith(',')) {
        string = string.substr(0, string.length - 1);
    }
    return string.trim();
}

export function breakAtSpecialChars(text?: string) {
    return text
        ? text.replace(/(\W)/g, '\u200b$1\u200b')
        : text;
}

export function formatEnumeration(enumeration: string[]) {
    const parts = enumeration.slice();

    if (enumeration.length > 1) {
        parts.splice(-2, 2, parts.slice(-2).join(` ${Env.i18n.t('And')} `));
    }

    return parts.join(', ');
}

export function formatDuration(minutes: number) {
    return minutes < 60
        ? Env.i18n.t('Minutes', { minutes })
        : Env.i18n.t('Hours', { hours: formatNumber(Math.ceil(minutes / 6) / 10) });
}

export function capitalizeFirstLetter(text: string, capitalize: boolean = true) {
    const first = text.charAt(0);
    return (capitalize ? first.toUpperCase() : first.toLowerCase()) + text.slice(1);
}

// calculating relative to today because start day of the week depends on locale
const monday = moment().subtract(moment().isoWeekday() - 1, 'days');

/**
 * @param day 0 == Monday
 */
export function formatShortDay(day: number) {
    // `locale()` is set globally by `Env` and shouldn't be needed here, but somehow it is...
    return moment(monday).add(day, 'days').locale(Env.currentLanguageCode()).format(Env.i18n.t('WeekDayFormat'));
}

export function printPrice(price = 0, currency = '') {
    return formatNumber(price, 2) + currency;
}
