import { bind } from 'decko';
import { action, computed, observable } from 'mobx';
import moment, { Moment } from 'moment';

export interface PerishableValue<T> {
    value?: T;
    expires?: Moment;
}

export default class Perishable<T> {
    private _generator: () => PerishableValue<T>;
    private _timeout: any;

    @observable
    private _value?: T;

    constructor(generator: () => PerishableValue<T>, initializeImmediately = false) {
        this._generator = generator;

        if (initializeImmediately) {
            this.initialize();
        }
    }

    @computed
    public get value() {
        return this._value;
    }

    public initialize() {
        if (this._timeout === undefined) {
            this.update();
        }
    }

    public release() {
        clearTimeout(this._timeout);
        this._timeout = undefined;
    }

    @bind
    @action
    private update() {
        const { value, expires } = this._generator();

        this._value = value;

        if (expires) {
            this._timeout = setTimeout(this.update, expires.diff(moment()));
        }
    }
}
