import styled from 'styled-components';

/**
 * These wrapper components are generally unnecessary,
 * but fix flexbox bugs in Apple products
 */

const OverflowWrapper = styled.div``;

const ColumnStretchWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`;

export default {
    /**
     * Wrap flexbox elements with this component,
     * if they need to overflow their parent container
     */
    OverflowWrapper,
    /**
     * Wrap a single flexbox element with this component,
     * if it doesn't correctly span accross the available height
     * due to too many nested (column) flexbox containers
     */
    ColumnStretchWrapper
};
