import { IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { ScreenHeadline } from '../text';
import { JSX } from './Screen';

const ButtonContainer = styled.div`
    left: ${GRID_SIZE}px;
    position: absolute;
    top: ${GRID_SIZE}px;
`;

const ContentContainer = styled.div`
    margin: ${GRID_SIZE * -1.5}px 0 ${GRID_SIZE * 1.5}px;
`;

interface Props {
    title: string | JSX;
    onClose?: () => void;
    onBack?: () => void;
    iconColor?: string;
}

export default class ScreenHeader extends React.PureComponent<Props> {
    public render() {
        const { title, iconColor, onClose, onBack, children } = this.props;
        const color = iconColor || colors.matte_black;

        return (
            <div>
                <ButtonContainer>
                    {onClose && (
                        <IconButton onClick={onClose} size="small">
                            <Close style={{ color }} />
                        </IconButton>
                    )}
                    {onBack && (
                        <IconButton onClick={onBack} size="small">
                            <ArrowBack style={{ color }} />
                        </IconButton>
                    )}
                </ButtonContainer>
                <ScreenHeadline>
                    {title}
                </ScreenHeadline>
                {children && (
                    <ContentContainer>
                        {children}
                    </ContentContainer>
                )}
            </div>
        );
    }
}


