import { bind } from 'decko';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import LinkTo from '../../helpers/LinkTo';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import Modal from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import { TitleText } from '../text';



const SettingsButton = styled(TitleText)`
    cursor: pointer;
    margin: ${GRID_SIZE * 2}px 0;
`;

interface Support {
    label: string;
    icon: string;
    action: () => void;
    url?: string;
}

@observer
export default class SupportOptions extends Modal {

    protected async hydrateParams(params: string[]) {
        return {};
    }

    protected validateParams(params: Partial<{}>) {
        return true;
    }

    public render() {
        const generalSupports: Support[] = [
            {
                label: Env.i18n.t('SupportMail'),
                icon: require('../../assets/svg/mail.svg'),
                action: () => this.GeneralSupport()
            }
        ];
        const paymentSupports: Support[] = [
            {
                label: Env.i18n.t('MessageUs'),
                icon: require('../../assets/svg/whatsapp.svg'),
                action: () => this.whatsAppSupport()
            },
            {
                label: Env.i18n.t('SupportMail'),
                icon: require('../../assets/svg/mail.svg'),
                action: () => this.paymentEmailSupport()
            }
        ];


        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close}>
                <ScreenHeader title={Env.i18n.t('Support')} onBack={this.back} />
                <div>
                    <h3> {Env.i18n.t('GeneralSupport')}</h3>
                    {this.renderSupports(generalSupports)}
                </div>
                <div style={{ borderTopColor: colors.grey_04, borderTopStyle: 'solid', borderTopWidth: 1 }}>
                    <h3>{Env.i18n.t('OrdersSupport')}</h3>
                    {this.renderSupports(paymentSupports)}
                </div>

            </Screen>
        );
    }

    @bind
    private GeneralSupport() {
        LinkTo.support();
    }


    @bind
    private whatsAppSupport() {
        LinkTo.whatsApp();
    }

    @bind
    private paymentEmailSupport() {
        LinkTo.paymentEmail();
    }

    @bind
    private renderSupports(supports: Support[]) {
        return supports
            .map((support, index) => (
                <SettingsButton key={index} onClick={support.action}>
                    <Icon src={support.icon} style={{ color: colors.grey_02, marginRight: GRID_SIZE * 2 }} />
                    {support.label}
                </SettingsButton>
            ));
    }
}
