import React from 'react';

import Env from '../../../lib/src/Env';
import { Link, RegularText } from '../components/text';
import Alert from './Alert';
import LinkTo from './LinkTo';

function createPromisedAlert(title: string, message: React.ReactNode, cancelable = true) {
    return new Promise<boolean>(resolve => {
        Alert.confirm(title, message, resolve, cancelable);
    });
}

const Legal = {
    confirmVisibility: () => createPromisedAlert(Env.i18n.t('VisibilityTitle'), Env.i18n.t('VisibilityDescription')),
    acceptInitialPolicy: () => createPromisedAlert(
        Env.i18n.t('GDPR'),
        <RegularText>
            {'Hast du unsere ' /* must be a string so trailing spaces are respected */}
            <Link onClick={LinkTo.gdpr}>
                {Env.i18n.t('GDPRLink')}
            </Link>
            {' gelesen und akzeptierst sie?'}
        </RegularText>
    ),
    acceptUpdatedPolicy: () => createPromisedAlert(
        Env.i18n.t('UpdatedPoliyTitle'),
        <RegularText>
            {'Bitte akzeptiere die neueste Version unser ' /* must be a string so trailing spaces are respected */}
            <Link onClick={LinkTo.gdpr}>
                {Env.i18n.t('GDPRLink')}
            </Link>
            {', um fortzufahren. Andernfalls wirst du ausgeloggt.'}
        </RegularText>,
        false
    )
};

export default Legal;
