import { bind } from 'decko';
import { computed } from 'mobx';

import Env from '../Env';
import { assert } from '../helpers/Validate';
import List from '../types/List';
import User from '../types/models/User';
import { Timestamp } from '../types/Timestamps';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';

type AccountType = AccountManager<ApiManager<AccountType>>;

enum AuthProvider {
    GOOGLE = 'google',
    FACEBOOK = 'facebook',
    PASSWORD = 'password',
    APPLE = 'apple'
}

export interface Credentials {
    authCredential: firebase.auth.AuthCredential | null;
    userCredential?: firebase.auth.UserCredential | null;
}

export default abstract class AuthManager<A extends AccountType> {
    private _promise: Promise<void>;

    protected _account: A;

    constructor(account: A) {
        this._account = account;
        this._promise = new Promise(resolve => {
            if (Env.firebase.auth().currentUser) {
                resolve();
            } else {
                const unsubscribeAuthChange = Env.firebase.auth().onAuthStateChanged(async user => {
                    unsubscribeAuthChange();

                    if (!user) {
                        await this.logout();
                    }

                    resolve();
                });
            }
        });
    }

    public get awaitSignIn() {
        return this._promise;
    }

    @computed
    public get user() {
        return this._account.user;
    }

    @computed
    public get provider(): AuthProvider {
        const providerIds: List<AuthProvider> = {
            'google.com': AuthProvider.GOOGLE,
            'facebook.com': AuthProvider.FACEBOOK,
            'apple.com': AuthProvider.APPLE
        };
        const providerData = this._account.user?.providerData
            .filter(data => !!data)
            .find(data => !!providerIds[data!.providerId]);

        return providerData ? providerIds[providerData.providerId] : AuthProvider.PASSWORD;
    }

    @computed
    public get isThirdPartyUser() {
        return this.provider !== AuthProvider.PASSWORD;
    }

    @bind
    public login(email: string, password: string) {
        // Not done via EmailAuthProvider.credential() since it would also accept non-existing credentials.
        return Env.firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then(this._account.schedulePolicyAcceptanceCheck);
    }

    @bind
    public loginWithApple() {
        return this.getAppleCredential().then(this.registerWithCredential);
    }

    @bind
    public loginWithGoogle() {
        return this.getGoogleCredential().then(this.registerWithCredential);
    }

    @bind
    public loginWithFacebook() {
        return this.getFacebookCredential().then(this.registerWithCredential);
    }

    @bind
    public async confirmAccount(password?: string) {
        let credentials: Credentials | undefined;

        assert(this._account.user, 'No current user');
        assert(this._account.loggedIn, 'Current user is anonymous');
        assert(this._account.user?.email, 'Current user has no email address');

        switch (this.provider) {
            case 'google':
                credentials = await this.getGoogleCredential(true);
                break;
            case 'facebook':
                credentials = await this.getFacebookCredential(true);
                break;
            case 'apple':
                credentials = await this.getAppleCredential(true);
                break;
            default:
                credentials = await this.getCredential(this._account.user!.email!, password);
                assert(credentials, 'Account.confirmAccount() requires 1 parameter for email/password accounts');
        }

        if (credentials) {
            await this._account.user!.reauthenticateWithCredential(credentials.authCredential!);
        }

        return !!credentials;
    }

    public async changeEmail(email: string, password: string) {
        const { user } = this;

        if (user) {
            await this.confirmAccount(password)
                .then(async () => {
                    await user.updateEmail(email);
                    await Env.firebase
                        .firestore()
                        .collection('users')
                        .doc(user.uid)
                        .set({ email }, { merge: true });
                    // FIXME: refresh login, otherwise subsequent call fails
                })
                .then(() => Env.snackbar.success(Env.i18n.t('SuccessChangeEmail')));
        }

    }

    public async logout() {
        // overriding user instead of actual log-out to be always signed in
        await Env.firebase.auth().signInAnonymously();
    }

    public abstract register(email: string, password: string, visible: boolean): Promise<any>;

    protected abstract registerWithCredential(credentials?: Credentials): Promise<boolean>;
    protected abstract getAppleCredential(reauthenticate?: boolean): Promise<Credentials | undefined>;
    protected abstract getGoogleCredential(reauthenticate?: boolean): Promise<Credentials | undefined>;
    protected abstract getFacebookCredential(reauthenticate?: boolean): Promise<Credentials | undefined>;
    protected abstract handleRegistration(credentials?: Credentials): Promise<any>;

    @bind
    protected async createUserData(now: Timestamp, credentials?: Credentials) {
        const { currentUser } = Env.firebase.auth();
        const userData = this.getUserDataFromProvider(credentials?.userCredential?.additionalUserInfo?.profile);

        /*
         * Preset here for immediate effect (as opposed to trigger).
         * Also distinguishing user from those existing before introduction of verification.
         */
        userData.verificationCode = currentUser?.emailVerified;

        // assume privacy policy was accepted
        userData.policyAccepted = now;

        // get higher resolution profile picture from Facebook
        if (this.provider === AuthProvider.FACEBOOK) {
            const authCredential = credentials?.authCredential as any;
            const accessToken = authCredential?.accessToken || authCredential?.token;

            if (accessToken) {
                await fetch(`https://graph.facebook.com/v4.0/me?fields=picture.type(large)&access_token=${accessToken}`)
                    .then(response => response.json())
                    .then(json => {
                        const { url } = json.picture.data;

                        if (typeof url === 'string') {
                            userData.photoURL = url;
                        }
                    })
                    .catch(() => console.warn('ERROR GETTING DATA FROM FACEBOOK'));
            }
        }

        return Env.firebase
            .firestore()
            .collection('users')
            .doc(currentUser?.uid)
            .set(userData, { merge: true });
    }

    @bind
    protected async getCredential(email?: string, password?: string) {
        let authCredential;

        if (email && password) {
            authCredential = Env.firebase.auth.EmailAuthProvider.credential(email, password);
        }

        return this.createCredentials(authCredential);
    }

    @bind
    protected createCredentials(authCredential?: firebase.auth.AuthCredential, userCredential?: firebase.auth.UserCredential) {
        return authCredential
            ? { authCredential, userCredential } as Credentials
            : undefined;
    }

    @bind
    private getUserDataFromProvider(profile: any) {
        const { currentUser } = Env.firebase.auth();
        const userProviderData = [ ...(currentUser?.providerData || []), currentUser ];
        const copyFields: (keyof firebase.UserInfo)[] = [ 'email', 'photoURL', 'displayName', 'phoneNumber' ];
        const userData: Partial<User> = {};

        copyFields.forEach(field => {
            const value = userProviderData
                .map(data => data && data[field])
                .find(val => val);

            if (value) {
                userData[field as any] = value;
            }
        });

        // set names
        if (profile) {
            // NOTE: Firestore can't handle `undefined`, needs `null` instead
            userData.firstName = profile.given_name || profile.first_name || null;
            userData.lastName = profile.family_name || profile.last_name || null;
        }

        // create displayName
        if (!userData.displayName) {
            if (userData.firstName || userData.lastName) {
                userData.displayName = `${userData.firstName} ${userData.lastName}`.trim();
            } else if (userData.email) {
                userData.displayName = userData.email.split('@')[0] || '';
            }
        }

        return userData;
    }
}
