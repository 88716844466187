import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { bind } from 'decko';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import { formatMeetUpDate, getFormattedPrice } from '../../../../lib/src/helpers/Payment';
import { OrderTime } from '../../../../lib/src/managers/PaymentManager';
import OrderList from '../../../../lib/src/store/OrderList';
import colors from '../../../../lib/src/styles/colors';
import { computeRefundedPrice, Order } from '../../../../lib/src/types/models/Order';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import TransactionsList from '../common/TransactionsList';
import { RegularText } from '../text';
import { H2, Header, HeaderWrapper, MeetUpDate, PaymentCard, ScreenTitle } from './Styled';

// @ts-ignore ts(2615)
const OrderCardContainer = styled(PaymentCard)`
    cursor: pointer;
    margin-bottom: ${GRID_SIZE * 2}px;
`;

const OrderCard = styled(H2)`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
`;

const OrderTitle = styled.span`
    overflow: hidden;
    padding-right: ${GRID_SIZE}px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const OrderDetails = styled.span`
    text-align: right;
`;

const OrderPrice = styled.span`
    color: ${colors.grey_02};
    display: block;
    font-size: 0.75em;
`;

// @ts-ignore ts(2615)
const MarkerText = styled(RegularText)<{ color?: string }>`
    ${({ color }) => color && css`
        color: ${color};
    `};
    flex: 1;
    text-align: right;
`;

interface State extends ModalState<{}> {
    orders?: OrderList;
}

@inject('api')
@observer
export default class Orders extends Modal<{}, State> {
    public readonly state: State = {
        params: {}
    };

    private ordersUnsubscriber?: IReactionDisposer;

    private get injected() {
        return this.props as ModalProps & InjectedApiProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return true;
    }

    public componentDidMount() {
        super.componentDidMount();

        const { api } = this.injected;

        this.ordersUnsubscriber = reaction(
            () => api.account.user?.uid,
            userId => this.setState({ orders: userId ? new OrderList(userId, api) : undefined }),
            { fireImmediately: true }
        );
    }

    public componentWillUnmount() {
        if (this.ordersUnsubscriber) {
            this.ordersUnsubscriber();
            this.state.orders?.stopWatching();
        }
    }

    public render() {
        return (
            <Screen
                open={this.paramsAreValid()}
                className="paper payment"
                handleClose={this.close}
                HeaderComponent={this.renderHeader}
                dividers={false}
                fullHeight={true}
            >
                <PaymentCard>
                    <ScreenTitle>
                        {Env.i18n.t('Orders')}
                    </ScreenTitle>
                </PaymentCard>
                <FullSizeContent style={{ margin: 0 }}>
                    <TransactionsList<Order>
                        transactions={this.state.orders}
                        renderItem={this.renderOrder}
                        ListEmptyComponent={this.renderEmptyList}
                        contentContainerStyle={{ paddingTop: GRID_SIZE * 2 }}
                    />
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private renderOrder(order: Order) {
        const { totalPrice, restaurant, accepted, refunded, meetUpDate } = order;
        const totalPriceAfterRefund = totalPrice - computeRefundedPrice(order);
        let markerColor: string | undefined;
        let markerLabelKey = 'Pending';

        if (totalPriceAfterRefund !== totalPrice) {
            markerColor = colors.red_900;
            markerLabelKey = refunded?.some(({ reason }) => reason.startsWith('restaurant_declined'))
                ? 'CanceledByRestaurant'
                : (totalPriceAfterRefund === 0) ? 'CanceledCompletely' : 'CanceledPartly';
        } else if (accepted) {
            markerColor = colors.teal_900;
            markerLabelKey = 'Confirmed';
        }

        return (
            <OrderCardContainer onClick={() => this.openOrderDetails(order)}>
                <OrderCard>
                    <OrderTitle>
                        {restaurant?.name}
                        <MeetUpDate>
                            {formatMeetUpDate(meetUpDate)}
                        </MeetUpDate>
                    </OrderTitle>
                    <OrderDetails>
                        <OrderPrice>
                            {Env.i18n.t('TotalPrice')}
                        </OrderPrice>
                        {getFormattedPrice(totalPriceAfterRefund)}
                        <MarkerText color={markerColor}>
                            {Env.i18n.t(markerLabelKey)}
                        </MarkerText>
                    </OrderDetails>
                </OrderCard>
            </OrderCardContainer>
        );
    }

    @bind
    private renderHeader() {
        return (
            <HeaderWrapper>
                <Header>
                    <IconButton onClick={this.close} size="small">
                        <Close style={{ color: colors.matte_black }} />
                    </IconButton>
                </Header>
            </HeaderWrapper>
        );
    }

    @bind
    private renderEmptyList(time: OrderTime) {
        const hint = Env.i18n.t((time === 'past') ? 'EmptyOrderListPast' : 'EmptyOrderListFuture');

        return (
            <EmptyListIndicator waitFor={true} icon={require('../../assets/svg/empty_state_menu.svg')} hint={hint} />
        );
    }

    @bind
    private openOrderDetails(order: Order) {
        this.redirectTo('order', [ order.key ]);
    }
}
