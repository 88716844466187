import { bind } from 'decko';

import Env from '../Env';
import DiscountEntry from '../types/models/DiscountEntry';
import DataList from './DataList';

export default class DiscountList extends DataList<DiscountEntry> {
    private userId?: string;
    private watchingListener?: () => void;
    private refreshingTimeout: any;

    private get query() {
        const { userId } = this;
        const collection = Env.partnerFirebase.firestore().collection('discount');

        return userId
            ? collection.where('owners', 'array-contains', userId)
            : collection.where('owners', '==', false);
    }

    /**
     * Automatically refreshes the list when either
     * - its first item expires (for today) or
     * - the discounts in the Firestore database are updated.
     */
    @bind
    public startWatching(user?: firebase.User) {
        if (!this.watchingListener || user?.uid !== this.userId) {
            this.stopWatching();
            this.userId = user?.uid;
            this.watchingListener = this.query.onSnapshot(this.refresh);
        }
    }

    @bind
    public stopWatching() {
        if (this.watchingListener) {
            clearTimeout(this.refreshingTimeout);
            this.watchingListener();
            this.watchingListener = undefined;
        }
    }

    @bind
    private refresh(querySnapshot: firebase.firestore.QuerySnapshot) {
        const userId = this.userId || '';
        const discounts: DiscountEntry[] = [];
        let timeUntilRefresh = Number.POSITIVE_INFINITY;

        querySnapshot.docs.forEach(snapshot => {
            const discount = DiscountEntry.fromSnapshot(snapshot);

            if (discount?.availableForUser(userId)) {
                const timeLeftToday = discount.millisecondsLeftToday ?? Number.POSITIVE_INFINITY;

                discounts.push(discount);

                if (0 < timeLeftToday && timeLeftToday < timeUntilRefresh ) {
                    timeUntilRefresh = timeLeftToday;
                }
            }
        });
        this.set(...discounts);
        clearTimeout(this.refreshingTimeout);

        if (0 < timeUntilRefresh && timeUntilRefresh < Number.POSITIVE_INFINITY) {
            this.refreshingTimeout = setTimeout(() => this.query.get().then(this.refresh), timeUntilRefresh);
        }
    }
}
