import 'moment/locale/de';

import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import { formatShortDay, formatTimeSpan } from '../../../../lib/src/helpers/formatting';
import colors from '../../../../lib/src/styles/colors';
import { Times } from '../../../../lib/src/types/lunchnow';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { SCREEN_PADDING } from '../../styles/base';
import { RegularText, SectionHeadline } from '../text';
import PerishableText from '../text/PerishableText';

interface HighlightProp {
    highlight: boolean;
}

const Row = styled.div<HighlightProp>`
    color: ${props => props.highlight ? colors.matte_black : colors.grey_01};
    background-color: ${props => props.highlight ? colors.grey_05 : 'transparent'};
    display: flex;
    flex-direction: row;
    font-family: ${props => props.highlight ? 'Roboto-Bold' : 'Roboto'};
    justify-content: space-between;
    padding: 1%;
`;

// @ts-ignore ts(2615)
const Cell = styled(RegularText)`
    color: currentColor;
    font-family: inherit;
    font-weight: inherit;
`;

const OpeningHoursInfo = styled(RegularText)`
    color: ${colors.matte_black};
    font-weight: bold;
`;

interface OpeningHoursRowProps {
    restaurant?: RestaurantEntry;
    day: keyof Times;
    today?: boolean;
}

class OpeningHoursRow extends React.PureComponent<OpeningHoursRowProps> {
    public render() {
        const { restaurant, day, today } = this.props;
        const openingHours = restaurant!.data!.openingHours![day] || [];
        const dayName = today ? Env.i18n.t('Today') : formatShortDay(day - 1);

        return (
            <Row highlight={!!today}>
                <Cell style={{ width: '20%' }}>
                    {dayName}
                </Cell>
                {openingHours.length > 0 ? (
                    openingHours.map((timespan, index) => (
                        <Cell key={index}>
                            {formatTimeSpan(timespan)}
                        </Cell>
                    ))
                ) : (
                    <Cell>
                        —
                    </Cell>
                )}
            </Row>
        );
    }
}

interface OpeningSectionProps {
    restaurant: RestaurantEntry;
}

export default class OpeningHours extends React.PureComponent<OpeningSectionProps> {
    public render() {
        const { restaurant } = this.props;

        if (!restaurant.data?.openingHours) {
            return null;
        }

        const today = new Date().getDay() as keyof Times;
        const todayLunchTimes = restaurant.getLunchTimes();

        return (
            <div>
                <SectionHeadline>
                    {Env.i18n.t('OpeningHours')}
                </SectionHeadline>
                <OpeningHoursInfo>
                    <PerishableText generator={() => restaurant.getOpeningHoursHint()} />
                </OpeningHoursInfo>
                <div style={{ margin: `${SCREEN_PADDING}px 0` }}>
                    {Arrays.range(1, 7).map(index => (
                        <OpeningHoursRow
                            key={index}
                            restaurant={this.props.restaurant}
                            day={index as keyof Times}
                            today={index === today}
                        />
                    ))}
                </div>
                {todayLunchTimes.length > 0 && (
                    <OpeningHoursInfo>
                        {Env.i18n.t('LunchTime', { time: todayLunchTimes.map(formatTimeSpan).join(', ') })}
                    </OpeningHoursInfo>
                )}
            </div>
        );
    }
}
