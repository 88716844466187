import { bind } from 'decko';
import * as firebase from 'firebase/app';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { logError } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { InjectedAuthProps } from '../../Auth';
import Alert from '../../helpers/Alert';
import LinkTo from '../../helpers/LinkTo';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import Modal, { ModalProps } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import { ImprintText, TitleText } from '../text';

const SettingsButton = styled(TitleText)`
    cursor: pointer;
    margin: ${GRID_SIZE * 2}px 0;
`;

const PrimaryButtonColor = colors.teal_900;
const SecondaryButtonColor = colors.grey_02;

interface Setting {
    label: string;
    icon: string;
    action: () => void;
    hideFor?: boolean;
}

@inject('api', 'auth')
@observer
export default class Settings extends Modal {
    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedAuthProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return true;
    }

    public render() {
        const { loggedIn, verified, data } = this.injected.api.account;
        const isVisible = data.visible;
        const thirdPartyUser = this.injected.auth.isThirdPartyUser;
        const primarySettings: Setting[] = [
            {
                label: Env.i18n.t('BecomeVisible'),
                icon: require('../../assets/svg/hide.svg'),
                action: () => this.injected.api.account.setVisibility(true),
                hideFor: !loggedIn || isVisible
            },
            {
                label: Env.i18n.t('BecomeInvisible'),
                icon: require('../../assets/svg/show.svg'),
                action: () => this.injected.api.account.setVisibility(false),
                hideFor: !loggedIn || !isVisible
            },
            {
                label: Env.i18n.t('EditProfilePicture'),
                icon: require('../../assets/svg/avatar.svg'),
                action: this.openEditProfilePicture,
                hideFor: !loggedIn
            },
            {
                label: Env.i18n.t('ChangeEmail'),
                icon: require('../../assets/svg/mail.svg'),
                action: this.openChangeEmail,
                hideFor: !loggedIn || thirdPartyUser
            },
            {
                label: Env.i18n.t('ResetPassword'),
                icon: require('../../assets/svg/edit.svg'),
                action: this.openResetPasswordDialog,
                hideFor: !loggedIn || thirdPartyUser
            },
            {
                label: Env.i18n.t('EditDisplayName'),
                icon: require('../../assets/svg/nametag.svg'),
                action: this.openEditDisplayName,
                hideFor: !loggedIn
            },
            // {
            //     label: Env.i18n.t('EditTastes'),
            //     icon: require('../../assets/svg/meal.svg'),
            //     action: this.openEditTastes
            // },
            // {
            //     label: Env.i18n.t('PushSettings'),
            //     icon: require('../../assets/svg/notification.svg'),
            //     action: this.openPushSettings,
            //     hideFor: !loggedIn
            // },
            {
                label: Env.i18n.t('PaymentOptions'),
                icon: require('../../assets/svg/ic_leading_eccard.svg'),
                action: this.openPaymentOptions,
                hideFor: !verified
            }
        ];
        const secondarySettings: Setting[] = [
            {
                label: Env.i18n.t('BlockedUsers'),
                icon: require('../../assets/svg/avatar_disabled.svg'),
                action: this.openBlockedContacts,
                hideFor: !verified
            },
            {
                label: Env.i18n.t('DeleteAccount'),
                icon: require('../../assets/svg/trash.svg'),
                action: this.openDeleteAccount,
                hideFor: !loggedIn
            },
            {
                label: Env.i18n.t('Imprint'),
                icon: require('../../assets/svg/legal.svg'),
                action: LinkTo.imprint
            },
            {
                label: Env.i18n.t('GDPR'),
                icon: require('../../assets/svg/legal.svg'),
                action: LinkTo.gdpr
            },
        ];
        const stage = Env.stage;
        const versionSuffix = stage === 'prod' ? '' : ` (${stage})`;

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close}>
                <ScreenHeader title={Env.i18n.t('Settings')} onClose={this.close} />
                <div>
                    {this.renderSettings(PrimaryButtonColor, primarySettings)}
                </div>
                <div style={{ borderTopColor: colors.grey_04, borderTopStyle: 'solid', borderTopWidth: 1 }}>
                    {this.renderSettings(SecondaryButtonColor, secondarySettings)}
                </div>
                <ImprintText style={{ marginTop: GRID_SIZE * 3 }}>
                    {Env.i18n.t('Version', { version: Env.info.version + versionSuffix })}
                </ImprintText>
            </Screen>
        );
    }

    @bind
    private openResetPasswordDialog() {
        const currentUser = this.injected.auth.user;

        if (currentUser?.email) {
            Alert.confirm(
                Env.i18n.t('ResetPassword'),
                Env.i18n.t('ResetPasswordMessage'),
                confirmed => {
                    if (confirmed) {
                        firebase
                            .auth()
                            .sendPasswordResetEmail(currentUser.email!)
                            .then(() => {
                                Env.snackbar.success(Env.i18n.t('SuccessResetPassword'));
                                // this.redirectTo('login', [ 'only-login' ]); // TODO: interprete param and force login?
                            })
                            .catch(error => {
                                logError('SettingsScreen.openResetPasswordDialog', error);
                                Env.snackbar.error(Env.i18n.t('FailResetPassword'));
                            });
                    }
                }
            );
        }
    }

    @bind
    private openDeleteAccount() {
        this.redirectTo('deleteaccount');
    }

    @bind
    private openEditProfilePicture() {
        this.redirectTo('editpicture');
    }

    @bind
    private openChangeEmail() {
        this.redirectTo('changeemail');
    }

    @bind
    private openEditDisplayName() {
        this.redirectTo('editname');
    }

    @bind
    private openBlockedContacts() {
        this.redirectTo('blockedusers');
    }

    @bind
    private openPaymentOptions() {
        this.redirectTo('paymentoptions');
    }


    @bind
    private renderSettings(iconColor: string, settings: Setting[]) {
        return settings
            .filter(setting => !setting.hideFor)
            .map((setting, index) => (
                <SettingsButton key={index} onClick={setting.action}>
                    <Icon src={setting.icon} style={{ color: iconColor, marginRight: GRID_SIZE * 2 }} />
                    {setting.label}
                </SettingsButton>
            ));
    }
}
