import Check from '@material-ui/icons/Check';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Validate, { logError, Validation } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import ContactEntity, { ContactGroup } from '../../../../lib/src/types/models/ContactEntity';
import { UserContactGroupsEntry } from '../../../../lib/src/types/models/User';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryFab } from '../button';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Input from '../forms/Input';
import { ContactAvatar } from './ContactEntry';
import ContactList from './ContactList';

interface State extends ModalState<{}> {
    members: ContactEntity[];
    group?: UserContactGroupsEntry;
}

@inject('api')
export default class EditGroupName extends Modal<{}, State> {
    public readonly state: State = {
        members: [],
        params: {}
    };

    private displayNameInputRef = React.createRef<Input>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified).get();
    }

    public componentDidMount() {
        super.componentDidMount();

        const { name } = this.injected.api.account.groupDraft;

        this.setState({ members: this.injected.api.account.getGroupDraftMembers() });

        if (name) {
            this.validateGroupName(name);
        }
    }

    public render() {
        const { groupDraft } = this.injected.api.account;
        const { members } = this.state;
        const contactGroup = new ContactGroup({
            isFavorite: groupDraft.isFavorite || false,
            name: groupDraft.name || '',
            members: groupDraft.members || [],
            key: 'new'
        });

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={''} onBack={this.back}>
                    <div
                        style={{
                            borderBottomColor: colors.grey_04,
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: GRID_SIZE * 2
                        }}
                    >
                        <ContactAvatar size={GRID_SIZE * 6} contact={contactGroup} style={{ marginTop: GRID_SIZE * 1.75 }}/>
                        <Input
                            ref={this.displayNameInputRef}
                            placeholder={Env.i18n.t('GroupName')}
                            defaultValue={groupDraft.name}
                            validate={input => this.validateGroupName(input.toString())}
                            onSubmitEditing={this.submitGroupName}
                            style={{ flexGrow: 1 }}
                        />
                    </div>
                </ScreenHeader>
                <FullSizeContent>
                    <ContactList
                        contacts={members}
                        selection={members.map(contact => contact.key)}
                        disableSearch={true}
                    />
                    <PrimaryFab onClick={this.submitGroupName} style={{ margin: SCREEN_PADDING }}>
                        <Check />
                    </PrimaryFab>
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private validateGroupName(groupName: string) {
        let validation = Validate.groupName(groupName);

        if (validation.valid) {
            const { account } = this.injected.api;
            const existingGroup = account.contactGroups.list.find(group => group.name === groupName);

            if (existingGroup && existingGroup.key !== account.groupDraft.key) {
                validation = Validation.error(Env.i18n.t('ErrorGroupNameTaken'));
            }
        }

        return validation;
    }

    @bind
    private async submitGroupName() {
        const name = this.displayNameInputRef.current?.validateValue();

        if (name !== undefined) {
            const { api } = this.injected;
            const { account } = api;
            const { groupDraft } = account;
            const isEdit = !!groupDraft.name;

            groupDraft.name = name.toString();
            api.waitFor(account.sendGroupDraft())
                .then(() => {
                    this.redirectTo('contacts'); // TODO: can we just go back instead?
                    Env.snackbar.success(Env.i18n.t(isEdit ? 'SuccessGroupUpdated' : 'SuccessGroupSent'));
                })
                .catch(error => {
                    logError('EditGroupNameScreen.submitGroupName', error);
                    Env.snackbar.error(Env.i18n.t(isEdit ? 'ErrorGroupNotUpdated' : 'ErrorGroupNotSent'));
                });
        }
    }
}
