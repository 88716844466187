import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import RestaurantDetails from '../../../../lib/src/helpers/RestaurantDetails';
import { InjectedApiProps } from '../../Api';
import { PrimaryButton } from '../button';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import Switch from '../forms/Switch';

interface Params {}

interface State extends ModalState<Params> {}

@inject('api')
@observer
export default class TagSelectionScreen extends Modal<Params, State> {
    private get injected() {
        return this.props as ModalProps & InjectedApiProps
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return true;
    }

    public render() {
        const { api } = this.injected;
        const footer = (
            <PrimaryButton onClick={this.close} style={{ padding: '4%' }}>
                {Env.i18n.t('ShowResults', { numberOfResults: api.restaurants.filteredList.length.toString() })}
            </PrimaryButton>
        )

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.close}
                fullHeight={true}
                FooterComponent={footer}
                contentStyle={{ alignItems: 'flex-start' }}
            >
                <ScreenHeader title="" onBack={this.close} />
                {RestaurantDetails.cuisines(api).map(item => (
                    <Switch key={item.key} selected={api.tagFilter === item} onClick={() => api.toggleTag(item)}>
                        {item.toString()}
                    </Switch>
                ))}
            </Screen>
        )
    }
}
