import colors from '../styles/colors';
import Action from '../types/Action';

export type SnackbarTrigger = (title: string, color: string, action?: Action) => void;

export default class Snackbar {
    private trigger: SnackbarTrigger;

    constructor(trigger: SnackbarTrigger) {
        this.trigger = trigger;
    }

    public info(title: string, action?: Action) {
        this.trigger(title, colors.white, action);
    }

    public success(title: string, action?: Action) {
        this.trigger(title, colors.white, action);
    }

    public error(title: string, action?: Action) {
        this.trigger(title, colors.warn, action);
    }
}
