import { bind } from 'decko';
import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import RestaurantDetails, {
    RestaurantFilterConfig,
    RestaurantTextConfig
} from '../../../../lib/src/helpers/RestaurantDetails';
import { FilterLists } from '../../../../lib/src/managers/ApiManager';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import { PrimaryButton, SecondaryButton } from '../button';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen from '../common/Screen';
import ScreenHeaderFilter from '../common/ScreenHeaderFilter';
import Switch from '../forms/Switch';
import SwitchGroup from '../forms/SwitchGroup';
import { SectionHeadline } from '../text';

const FiltersSection = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 ${GRID_SIZE / -2}px;
`;

interface State extends FilterLists, ModalState<{}> {
    searchInput?: string;
}

@inject('api')
export default class Filters extends Modal<{}, State> {
    public readonly state: State = {
        params: {}
    };

    private commonFilters = [
        ...RestaurantDetails.discount(this.injected.api),
        ...RestaurantDetails.services,
        ...RestaurantDetails.availability
    ];

    private get injected() {
        return this.props as ModalProps & InjectedApiProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return true;
    }

    public componentDidMount() {
        super.componentDidMount();

        const { filters } = this.injected.api;
        const price =  filters.price?.slice();
        const property = filters.property?.slice();
        const searchInput = filters.text?.map(String).join(' ');

        this.setState({ searchInput, price, property });
    }

    public render() {
        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} FooterComponent={this.renderFooter}>
                <SafariFix.OverflowWrapper>
                    <ScreenHeaderFilter title={Env.i18n.t('Filter')} onClose={this.close} />
                    <SectionHeadline>
                        {Env.i18n.t('SetPriceRange')}:
                    </SectionHeadline>
                    <FiltersSection>
                        <SwitchGroup
                            values={RestaurantDetails.priceCategories}
                            selected={this.state.price}
                            onClick={filter => this.toggleFilter('price', filter)}
                            style={{ flex: 1 }}
                        />
                    </FiltersSection>
                    {this.renderPropertyFilters(Env.i18n.t('CommonFilters'), this.commonFilters)}
                    {this.renderPropertyFilters(Env.i18n.t('PaymentOptionsInhouse'), RestaurantDetails.payment)}
                    {this.renderPropertyFilters(Env.i18n.t('AdditionalFilters'), RestaurantDetails.vouchers, RestaurantDetails.features)}
                    {/* @ts-ignore */}
                    <SecondaryButton onClick={this.reset} style={{ marginTop: GRID_SIZE * 2, boxShadow: 'none' }}>
                        {Env.i18n.t('ResetFilter')}
                    </SecondaryButton>
                </SafariFix.OverflowWrapper>
            </Screen>
        );
    }

    @bind
    private renderPropertyFilters(title: string, ...filters: RestaurantFilterConfig[][]) {
        return (
            <>
                <SectionHeadline>
                    {title}:
                </SectionHeadline>
                <FiltersSection>
                    {Arrays.concat(...filters).map((filter, index) => (
                        <Switch
                            key={index}
                            icon={filter.icon}
                            selected={this.state.property?.includes(filter)}
                            onClick={() => this.toggleFilter('property', filter)}
                        >
                            {filter.toString()}
                        </Switch>
                    ))}
                </FiltersSection>
            </>
        );
    }

    @bind
    private renderFooter() {
        return (
            <PrimaryButton onClick={this.apply} style={{ padding: '4%' }}>
                {Env.i18n.t('ApplyFilter')}
            </PrimaryButton>
        );
    }

    @bind
    private apply() {
        const { searchInput, price, property } = this.state;
        const text = searchInput
            ? [ new RestaurantTextConfig('text', searchInput) ]
            : undefined;

        this.injected.api.filters = {
            text, // TODO: do we still need this?
            price: price?.slice(),
            property: property?.slice()
        };
        this.close();
    }

    @bind
    private reset() {
        this.setState({ searchInput: undefined, text: undefined, price: undefined, property: undefined }, this.apply);
    }

    @bind
    private toggleFilter(filterTypesKey: keyof FilterLists, filter: RestaurantFilterConfig) {
        const prevFilterTypes = this.state[filterTypesKey] || [];
        const nextFilterTypes = Arrays.toggle(prevFilterTypes, filter);

        if (prevFilterTypes.length < nextFilterTypes.length) {
            Env.logEvent(`save_filter_${filter.key}`);
        }

        this.setState({ [filterTypesKey]: nextFilterTypes } as Pick<State, typeof filterTypesKey>);
    }
}
