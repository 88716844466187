import { action, computed, IReactionDisposer, observable, reaction } from 'mobx';

import Env from '../Env';
import HttpRequest from '../helpers/HttpRequest';
import List from '../types/List';
import { Chat, ChatMessage, EnhancedChatMessage, ChatParticipant, ChatMessageType } from '../types/models/Chat';
import Timestamps from '../types/Timestamps';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';
import RestaurantsBackend from '../helpers/RestaurantsBackend';
import { Order } from 'src/types/models/Order';
import RestaurantEntry from 'src/types/models/RestaurantEntry';
import UserInvitation from 'src/types/models/UserInvitation';
import { getPaymentUserRef } from '../helpers/Firestore';

type ApiType = ApiManager<AccountManager<ApiType>>;

export interface InjectedChatProps {
    chatManager: ChatManager;
}

export default class ChatManager {
    private static readonly PAGE_SIZE = 50;

    @observable
    private _chats: Array<Chat> = [];

    @observable
    private _lastMessages: List<ChatMessage | undefined> = {};

    @observable
    private _participants: List<Array<ChatParticipant> | undefined> = {};

    @observable
    private _currentChatKey?: string;

    @observable
    private _messages: Array<EnhancedChatMessage> = [];

    @observable
    private _loadingMessagesKeys: Set<string> = new Set();

    private inboxInitialized = false;
    private _api: ApiType;
    private _pageSize = 0;
    private _hasMore = false;
    private _loadingMore = false;
    private _unsubscribeChats?: () => void;
    private _unsubscribeChatPreviews: List<() => void> = {};
    private _unsubscribeChatChanges?: () => void;
    private _unsubscribeMessages?: () => void;
    private _unsubscribeListDataChanged?: IReactionDisposer;
    private _unsubscribeInvitations: Array<() => void> = [];
    private _unsubscribeOrders: Array<() => void> = [];
    private _invitations: List<UserInvitation> = {};
    private _orders: List<Order> = {};
    private _invitationsOrdersInitialized = false;

    constructor(api: ApiType) {
        this._api = api;

        reaction(
            () => this._api.account.user?.uid,
            async userId => {
                if (this._unsubscribeChats) {
                    this._unsubscribeChats();
                }

                if (userId) {
                    // subscribe to all chats the user is a participant of
                    this._unsubscribeChats = Env.firebase.firestore()
                        .collection('chats')
                        .where('participantKeys', 'array-contains', userId)
                        .onSnapshot(({ docs }) =>
                            this._chats = docs.map(doc => ({ key: doc.id, ...doc.data() } as Chat))
                        );
                } else {
                    this._chats = [];
                }
            },
            { fireImmediately: true }
        );
    }

    public initalizeChatsOverview() {
        if (!this.inboxInitialized) {
            this.inboxInitialized = true;
            this._unsubscribeChatChanges = reaction(
                () => this.chats,
                chats => chats.forEach(({ key }) => {
                    if (!this._unsubscribeChatPreviews[key]) {
                        // get the most recent message of every chat to display it in the inbox list
                        const unsubscribeLastMessages = this.getMessagesRef(key, 1).onSnapshot(({ docs }) => {
                            if (docs.length) {
                                this._lastMessages[key] = docs[0].data() as ChatMessage;
                            } else {
                                delete this._lastMessages[key];
                            }
                        });

                        // get participants of every chat
                        const unsubscribeParticipants = this.getChatRef(key).collection('participants').onSnapshot(({ docs }) => {
                            if (docs.length) {
                                this._participants[key] = docs.map(doc =>
                                    ({ key: doc.id, ...doc.data() }) as ChatParticipant
                                );
                            } else {
                                delete this._participants[key];
                            }
                        });

                        this._unsubscribeChatPreviews[key] = () => {
                            unsubscribeLastMessages();
                            unsubscribeParticipants();
                        };
                    }
                }),
                { fireImmediately: true }
            );
        }
    }

    public uninitalizeChatsOverview() {
        this.inboxInitialized = false;

        if (this._unsubscribeChatChanges) {
            this._unsubscribeChatChanges();
        }

        Object.values(this._unsubscribeChatPreviews).forEach(unsubscribe => unsubscribe());
        this._unsubscribeChatPreviews = {};
        this._lastMessages = {};
        this._participants = {};
    }

    public initalizeChat(chatKey: string, limit = ChatManager.PAGE_SIZE, fromReset = false) {
        this._currentChatKey = chatKey;
        this._pageSize = limit;

        if (this._unsubscribeMessages) {
            this._unsubscribeMessages();
        }

        return new Promise<void>((resolve, reject) => {
            try {
                this._unsubscribeMessages = this.getMessagesRef(chatKey, limit).onSnapshot(async querySnap => {
                    // for some reason in web it first returns 1 item and then is called again with all items
                    // to not break scrolling, the incomplete call has to be ignored
                    // it would render 1 item and reset the scroll position
                    if ((this._pageSize <= ChatManager.PAGE_SIZE && !fromReset) || querySnap.docs.length !== 1) {
                        const userId = this._api.account.user?.uid;
                        const messages = querySnap.docs.map(doc => {
                            const data = doc.data();
                            const isOwn = (data.sender === userId);

                            return { key: doc.id, isOwn, ...data } as ChatMessage;
                        });

                        const reversedMessages = messages.reverse();

                        this._hasMore = (reversedMessages.length === limit);

                        await this.startWatchingInvitations(reversedMessages);

                        this._invitationsOrdersInitialized = true;

                        resolve();
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public uninitalizeChat() {
        if (this._unsubscribeMessages) {
            this._unsubscribeMessages();
        }

        if (this._unsubscribeListDataChanged) {
            this._unsubscribeListDataChanged();
        }

        this._unsubscribeInvitations.map(unsubscriber => unsubscriber());
        this._unsubscribeOrders.map(unsubscriber => unsubscriber());

        this._currentChatKey = undefined;
        this._messages = [];
    }

    public async loadMore() {
        if (this._hasMore && !this._loadingMore && this._currentChatKey) {
            this._loadingMore = true;
            await this.initalizeChat(this._currentChatKey, this._pageSize + ChatManager.PAGE_SIZE);
            // wait some time to render the messages
            setTimeout(() => this._loadingMore = false, 100);
        }
    }

    public resetLimit() {
        if (this._currentChatKey && this._pageSize !== ChatManager.PAGE_SIZE) {
            this.initalizeChat(this._currentChatKey, undefined, true);
        }
    }

    private async startWatchingInvitations(messages: Array<ChatMessage>) {
        const invitations = messages.map(message => message.content === 'invitation' && message.contentId).filter(Boolean) as Array<string>;

        await Promise.all(invitations.map(async invitationKey => {
            if (!this._invitations[invitationKey]) {
                await new Promise((resolve) => {
                    this._unsubscribeInvitations.push(
                        Env.firebase.firestore()
                            .collection('users').doc(this._api.account.user?.uid)
                            .collection('invitations')
                            .doc(invitationKey)
                            .onSnapshot(async (invitationSnap) => {
                                const data = {
                                    key: invitationSnap.id,
                                    ...invitationSnap.data()
                                } as UserInvitation;

                                if (data) {
                                    this._invitations[invitationSnap.id] = data;

                                    if (this._invitationsOrdersInitialized) {
                                        await this.initializeMessagesExtraData(this._messages);
                                    }
                                }

                                resolve(undefined);
                            })
                    )
                })
            }
        }));

        await this.startWatchingOrders(messages);
    }

    private async startWatchingOrders(messages: Array<ChatMessage>) {
        const orders = Object.values(this._invitations).map(invitation => invitation.order).filter(Boolean) as Array<string>

        await Promise.all(orders.map(async orderKey => {
            if (!this._orders[orderKey]) {
                await new Promise((resolve) => {
                    this._unsubscribeOrders.push(
                        getPaymentUserRef(this._api.account.user?.uid || '')
                            .collection('orders')
                            .doc(orderKey)
                            .onSnapshot((orderSnap) => {
                                const data = {
                                    key: orderSnap.id,
                                    ...orderSnap.data()
                                } as Order;

                                if (data) {
                                    this._orders[orderSnap.id] = data;

                                    if (this._invitationsOrdersInitialized) {
                                        this.initializeMessagesExtraData(this._messages)
                                    }
                                }

                                resolve(undefined);
                            })
                    )
                })
            }
        }));

        await this.initializeMessagesExtraData(messages);
    }

    @action
    private async initializeMessagesExtraData(messages: Array<ChatMessage | EnhancedChatMessage>) {
        const changedMessages = await Promise.all(messages.map(async message => {
            let restaurant: RestaurantEntry | undefined;
            let invitation: UserInvitation | undefined;
            let order: Order | undefined;

            if (message.content === 'restaurant' && message.contentId) {
                restaurant = await RestaurantsBackend.getRestaurantById(message.contentId);
            } else if (message.content === 'invitation' && message.contentId) {
                invitation = this._invitations[message.contentId];
                restaurant = await RestaurantsBackend.getRestaurantById(invitation?.restaurant);
                order = invitation?.order ? this._orders[invitation.order] : undefined;
            }

            return {
                ...message,
                restaurant,
                invitation,
                order,
            }
        }))

        this._messages = changedMessages;

        this._loadingMessagesKeys.clear();
    }

    public async addMessage(content: string) {
        if (this._currentChatKey) {
            await this.getChatRef(this._currentChatKey).collection('messages').add({
                sender: this._api.account.user?.uid,
                timestamp: Env.firebase.firestore.FieldValue.serverTimestamp(),
                content
            })
        }
    }

    public async createNewChat(participantKeys: Array<string>) {
        return HttpRequest.post('/api/chat/create', { participantKeys });
    }

    // share a restaurant or invitation with the selected userKeys
    public async sendContentMessage(userKeys: Array<string>, type: ChatMessageType, restaurantKey: string) {
        return HttpRequest.post('/api/chat/sendContentMessage', {
            userKeys,
            type,
            contentId: restaurantKey,
        });
    }

    public async sendReminder(messageKey: string) {
        this.setMessageLoading(messageKey);

        await HttpRequest.post('/api/chat/sendInvitationReminder', {
            chatKey: this.currentChatKey,
            messageKey
        });

        Env.snackbar.success(Env.i18n.t('SuccessReminderSent'));
    }

    public setMessageLoading(key: string) {
        this._loadingMessagesKeys.add(key);
    }

    @computed
    public get chats() {
        return this._chats.slice().sort((a, b) => {
            // sort chats by the timestamp of the last message or by chat creation date if empty
            const aLastUpdate = this.lastMessages[a.key]?.timestamp || a.createdAt;
            const bLastUpdate = this.lastMessages[b.key]?.timestamp || b.createdAt;

            return Timestamps.toDate(bLastUpdate).getTime() - Timestamps.toDate(aLastUpdate).getTime();
        });
    }

    @computed
    public get lastMessages() {
        return this._lastMessages;
    }

    @computed
    public get participants() {
        return this._participants;
    }

    @computed
    public get currentChatKey() {
        return this._currentChatKey;
    }

    @computed
    public get messages() {
        return this._messages;
    }

    @computed
    public get loadingMessagesKeys() {
        return this._loadingMessagesKeys;
    }

    @computed
    public get lastMessageKey() {
        return this._messages[this._messages.length - 1]?.key;
    }

    public getOtherParticipants(participants: Array<ChatParticipant>) {
        return participants.filter(participant => participant.key !== this._api.account.user?.uid)
    }

    private getChatRef(chatId: string) {
        return Env.firebase.firestore().collection('chats').doc(chatId);
    }

    private getMessagesRef(chatId: string, limit: number) {
        return this.getChatRef(chatId).collection('messages').orderBy('timestamp', 'desc').limit(limit);
    }
}
