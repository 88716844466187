import { bind } from 'decko';
import { autorun, computed, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import Slider from 'react-slick';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Lists from '../../../../lib/src/helpers/Lists';
import { Menu } from '../../../../lib/src/managers/ApiManager';
import Perishable from '../../../../lib/src/store/Perishable';
import colors from '../../../../lib/src/styles/colors';
import List from '../../../../lib/src/types/List';
import RestaurantEntry, { MealEntry } from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { FullWidthContent } from '../common/Screen';
import Switch from '../forms/Switch';
import MealCard from '../restaurants/MealCard';
import EmptyIndicator from './EmptyIndicator';
import { COLLAPSED_HEADER_HEIGHT } from './Header';

const MealTypeTabContainer = styled(ScrollContainer)`
    background-color: ${colors.navy_blue};
    overflow: auto;
    position: sticky;
    top: calc(${COLLAPSED_HEADER_HEIGHT} - ${SCREEN_PADDING}px - 1px); // subtracting 1px extra to avoid occasional gap
    z-index: 1;
`;

const MealTypeTabContent = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: ${GRID_SIZE}px;
    padding-right: ${GRID_SIZE}px;
`;

const MealCardContainer = styled.div`
    padding-top: ${GRID_SIZE * 2}px;
`;

interface Props {
    restaurant: RestaurantEntry;
    parentScrollView?: React.RefObject<HTMLDivElement>;
}

interface State {
    menu: Menu;
    currentSlide: number;
    orderingDisabledHint?: Perishable<string>;
    lunchOrderingDisabledHint?: Perishable<string>;
}

@inject('api', 'payment')
@observer
export default class Menus extends React.PureComponent<Props, State> {
    public readonly state: State = {
        menu: [],
        currentSlide: 0
    };

    private containerRef = React.createRef<HTMLDivElement>();
    private tabsRef = React.createRef<HTMLDivElement>();
    private sliderRef = React.createRef<Slider>();
    private mealsListenerDisposer?: () => void;
    private orderingDisabledHintListenerDisposer?: IReactionDisposer;

    private get injected() {
        return this.props as Props & InjectedApiProps & InjectedPaymentProps;
    }

    @computed
    private get cart() {
        const { payment, restaurant } = this.injected;

        return payment.getCart(restaurant);
    }

    public showMealType(mealTypeBaseName: string) {
        const typeIndex = Object.values(this.state.menu).findIndex(mealType => mealType.baseName === mealTypeBaseName);

        if (typeIndex >= 0) {
            this.sliderRef.current?.slickGoTo(typeIndex);
        }
    }

    public componentDidMount() {
        this.changeRestaurant();
        this.orderingDisabledHintListenerDisposer = autorun(() => {
            const { cart } = this;

            if (this.state.orderingDisabledHint?.value && cart?.totalPrice) {
                // clear cart if ordering becomes disabled
                this.injected.payment.resetCart(this.props.restaurant);
            }
        });
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.restaurant.key !== this.props.restaurant.key) {
            this.changeRestaurant();
        }
    }

    public componentWillUnmount() {
        this.release();

        if (this.orderingDisabledHintListenerDisposer) {
            this.orderingDisabledHintListenerDisposer();
        }
    }

    public render() {
        const { menu } = this.state;

        return (
            <FullWidthContent ref={this.containerRef}>
                {this.renderEmptyIndicator() || (
                    <>
                        {/* TODO: exract common component with TabSelectionBar */}
                        <MealTypeTabContainer innerRef={this.tabsRef}>
                            <MealTypeTabContent>
                                {menu.map(({ name }) => name).map(this.renderTab)}
                            </MealTypeTabContent>
                        </MealTypeTabContainer>
                        <Slider ref={this.sliderRef} arrows={false} dots={false} infinite={false} beforeChange={this.changeSlide}>
                            {menu.map(({ entries }) => entries).map(this.renderMeals)}
                        </Slider>
                    </>
                )}
            </FullWidthContent>
        );
    }

    private renderEmptyIndicator() {
        const { restaurant } = this.props;
        let title: string | undefined;
        let text: string | undefined;

        if (restaurant.isResting()) {
            title = Env.i18n.t('ClosedToday');
            text = this.injected.api.getRestTimeMessage(restaurant);
        } else if (restaurant.getOpeningHours().length < 1) {
            title = Env.i18n.t('RestDay');
            text = Env.i18n.t('RestDayExplanation', { day: moment().format(Env.i18n.t('DayFormat')) });
        } else if (!restaurant.isStillOpenToday) {
            title = Env.i18n.t('ClosedToday');
            text = Env.i18n.t('ErrorRestaurantAlreadyClosed');
        } else if (this.state.menu.length < 1) {
            title = Env.i18n.t('NoLunchListed');
            text = Env.i18n.t('NoLunchListedDescription');
        }

        return title && text && (
            <div style={{ padding: `0 ${SCREEN_PADDING}px` }}>
                <EmptyIndicator title={title} message={text} />
            </div>
        );
    }

    @bind
    private renderTab(item: string, index: number) {
        const selected = (index === this.state.currentSlide);

        return (
            <Switch key={index} variant="dark" selected={selected} onClick={() => this.selectTab(index)}>
                {item}
            </Switch>
        );
    }

    @bind
    private renderMeals(meals: List<MealEntry>, index: number) {
        let disableReason = this.state.orderingDisabledHint?.value;

        if (!disableReason && this.injected.api.lunchMealTypeKeys.includes(Lists.index(meals, 0)?.typeKey || '')) {
            disableReason = this.state.lunchOrderingDisabledHint?.value;
        }

        return (
            <MealCardContainer key={index}>
                {Object.entries(meals).map(([ key, meal ]) => (
                    <MealCard key={key} meal={meal} cart={this.cart} disabledBecause={disableReason} />
                ))}
            </MealCardContainer>
        );
    }

    @bind
    private selectTab(index: number) {
        this.setState({ currentSlide: index })
        this.sliderRef.current?.slickGoTo(index);
    }

    @bind
    private changeSlide(currentSlide: number, nextSlide: number) {
        const scrollView = this.props.parentScrollView?.current;

        this.setState({ currentSlide: nextSlide });

        if (scrollView) {
            const containerView = this.containerRef.current;
            const tabsView = this.tabsRef.current;

            if (containerView && tabsView) {
                scrollView.scrollBy({ top: containerView.offsetTop - tabsView.offsetTop });
            }
        }
    }

    private release() {
        this.state.orderingDisabledHint?.release();
        this.state.lunchOrderingDisabledHint?.release();

        if (this.mealsListenerDisposer) {
            this.mealsListenerDisposer();
        }
    }

    private changeRestaurant() {
        const { restaurant, api } = this.injected;
        const orderingDisabledHint = new Perishable(restaurant.validateTimeForOrdering, true);
        const lunchOrderingDisabledHint = new Perishable(restaurant.validateLunchTimeForOrdering, true);

        this.release();
        this.mealsListenerDisposer = restaurant.addMealsListener(meals => this.setState({ menu: api.groupMealsByType(meals) }));
        this.setState({ orderingDisabledHint, lunchOrderingDisabledHint });
    }
}
