import 'firebase/messaging';

import Env from '../../lib/src/Env';
import AccountManager from '../../lib/src/managers/AccountManager';
import Api from './Api';
import Auth from './Auth';
import Legal from './helpers/Legal';

export interface InjectedAccountProps {
    account: Account;
}

export default class Account extends AccountManager<Api> {
    private _auth?: Auth;

    constructor(waitFor: Promise<any> = Promise.resolve()) {
        super(waitFor);

        // triggers on log-in and log-out, but not on registration (because it only links the account)
        Env.firebase.auth().onAuthStateChanged(this.onUserChange);
    }

    public setAuth(auth: Auth) {
        this._auth = auth;
    }

    // TODO: if possible, move to super class later
    public async connectUsers() {
    // TODO: re-activate for contacts
    //     if (this.verified) {
    //         const contactsToAdd = await LocalData.get('CONNECT_USERS');

    //         if (contactsToAdd && Array.isArray(contactsToAdd) && contactsToAdd.length > 0) {
    //             try {
    //                 await Backend.putConnectUsers(contactsToAdd);
    //                 LocalData.remove('CONNECT_USERS');
    //                 Env.snackbar.success(Env.i18n.t('SuccessContactsAdded', { count: contactsToAdd.length }));
    //             } catch (error) {
    //                 logError('Account.connectUsers', error);
    //             }
    //         }
    //     }
    }

    // TODO: if possible, move to super class later
    protected handleUserDataChange() {}

    // TODO: if possible, move to super class later
    protected reactivateDeletedAccount() {
        return Legal.confirmVisibility();
    }

    // TODO: if possible, move to super class later
    protected demandPolicyAcceptance() {
        this.api.schedule(Legal.acceptUpdatedPolicy).then((accepted: boolean) => {
            if (accepted) {
                this.acceptPolicy();
            } else {
                this.api.waitFor(this._auth!.logout())
                    .then(() => Env.snackbar.success(Env.i18n.t('SuccessLogout')))
                    .catch(() => undefined);
            }
        });
    }
}
