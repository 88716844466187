import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import { RegularText } from '../../components/text';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { clickableEntry } from '../contacts/ContactEntry';
import LargeCard from './LargeCard';
import RestaurantInfo from './RestaurantInfo';

const Message = styled.div`
    align-items: center;
    background-color: rgba(52, 52, 52, 0.8);
    bottom: 0;
    justify-content: center;
    left: 0;
    padding: ${SCREEN_PADDING}px;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
`;

// @ts-ignore ts(2615)
const MessageText = styled(RegularText)`
    color: ${colors.white};
    font-size: 1.2rem;
    text-align: center;
`;

export const RESTAURANT_CARD_SMALL_HEIGHT = 61;

const RestaurantInfoSmall = styled(RestaurantInfo)`
    ${clickableEntry};
    box-sizing: border-box;
    height: ${RESTAURANT_CARD_SMALL_HEIGHT}px;
    padding: ${GRID_SIZE}px ${SCREEN_PADDING}px;
    overflow: hidden;
`;

interface Props {
    restaurant: RestaurantEntry;
    onPress: (restaurant: RestaurantEntry) => void;
    className?: string;
    style?: CSSProperties;
}

@inject('api')
@observer
class RestaurantCardLarge extends React.Component<Props> {
    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    public render() {
        const { restaurant, onPress } = this.props;

        const cuisine = (restaurant.tagIds || [])
            .map(tagId => this.injected.api.tags.get(tagId))
            .find(tag => tag?.type === 'cuisine');

        let restingMessage: string | undefined;

        if (restaurant.data) {
            if (restaurant.isResting()) {
                restingMessage = this.injected.api.getRestTimeMessage(restaurant);
            } else if (!restaurant.getOpeningHours().length) {
                restingMessage = Env.i18n.t('ClosedToday');
            }
        }

        return (
            <LargeCard
                restaurant={restaurant}
                onPress={() => onPress(restaurant)}
                overlayContent={restingMessage && (
                    <Message>
                        <MessageText style={{ textAlign: 'center' }}>
                            {restingMessage}
                        </MessageText>
                    </Message>
                )}
            >
                {cuisine?.translations[Env.currentLanguageCode()] || cuisine?.name}
            </LargeCard>
        );
    }
}

@observer
class RestaurantCardSmall extends React.PureComponent<Props> {
    public render() {
        const { restaurant, className, style } = this.props;

        return (
            <RestaurantInfoSmall restaurant={restaurant} className={className} style={style} onClick={this.handleClick} hidePremiumHighlight={true} />
        );
    }

    @bind
    handleClick() {
        this.props.onPress(this.props.restaurant);
    }
}

export default {
    Large: RestaurantCardLarge,
    Small: RestaurantCardSmall
};
