import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Lists from '../../../../lib/src/helpers/Lists';
import RestaurantDetails, { RestaurantFilterConfig } from '../../../../lib/src/helpers/RestaurantDetails';
import colors from '../../../../lib/src/styles/colors';
import List from '../../../../lib/src/types/List';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import { RegularText, SectionHeadline } from '../text';

const SectionContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Item = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: ${GRID_SIZE}px;
    padding-left: 0;
    width: 50%;
`;

// @ts-ignore ts(2615)
const ItemIcon = styled(Icon).attrs({
    color: colors.navy_blue
})`
    margin-right: ${GRID_SIZE}px;
`;

// @ts-ignore ts(2615)
const ItemLabel = styled(RegularText)`
    color: ${colors.matte_black};
`;

interface Props {
    restaurant: RestaurantEntry;
}

interface State {
    details: List<RestaurantFilterConfig[]>;
}

export default class AdditionalDetails extends React.PureComponent<Props, State> {
    public readonly state: State = {
        details: {}
    };

    public componentDidMount() {
        const configs = {
            DetailServices: RestaurantDetails.services,
            DetailPayment: RestaurantDetails.payment,
            DetailFeatures: [...RestaurantDetails.vouchers, ...RestaurantDetails.features]
        };
        const details: List<RestaurantFilterConfig[]> = {};

        Object.entries(configs).forEach(([sectionKey, detailConfigs]) => {
            const availableDetails = detailConfigs.filter(detailConfig => detailConfig.availableFor(this.props.restaurant));

            if (availableDetails.length) {
                details[sectionKey] = availableDetails;
            }
        });

        this.setState({ details });
    }

    public render() {
        if (Lists.isEmpty(this.state.details)) {
            return null;
        }

        return (
            <>
                {Object.entries(this.state.details).map(([sectionKey, detailConfigs]) => (
                    <div key={sectionKey}>
                        <SectionHeadline>
                            {Env.i18n.t(sectionKey)}
                        </SectionHeadline>
                        <SectionContent>
                            {detailConfigs.map(detailConfig => (
                                <Item key={detailConfig.key}>
                                    {detailConfig.icon && (
                                        <ItemIcon src={detailConfig.icon} />
                                    )}
                                    <ItemLabel>
                                        {detailConfig.toString()}
                                    </ItemLabel>
                                </Item>
                            ))}
                        </SectionContent>
                    </div>
                ))}
            </>
        );
    }
}
