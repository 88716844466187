import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import InvitationDraft from '../../../../lib/src/store/InvitationDraft';
import ContactEntity, { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import ActionSheet from '../common/ActionSheet';
import ContactList from '../contacts/ContactList';
import StaticMap from '../details/StaticMap';

interface Props {
    draft: InvitationDraft;
}

type Marker = 'contact' | 'blocked' | 'stranger';

interface SelectedUser {
    user: ContactPerson;
    marker: Marker;
}

interface State {
    selectedUser?: SelectedUser;
}

@inject('api')
@observer
export default class DetailsAttendeesTab extends React.Component<Props, State> {
    public readonly state: State = {};

    private contactListRef = React.createRef<ContactList>();
    private actionSheetRef = React.createRef<ActionSheet>();

    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    @bind
    private editContact(selectedContact: ContactEntity, target?: HTMLElement) {
        const { contacts, blockedUsers, user } = this.injected.api.account;

        if (
            !this.state.selectedUser &&
            selectedContact instanceof ContactPerson && // is contact person
            (user && selectedContact.key !== user.uid) // is not me
        ) {
            const marker: Marker =
                contacts.get(selectedContact.key) ? 'contact' :
                    blockedUsers.get(selectedContact.key) ? 'blocked' :
                        'stranger';
            const selectedUser = { user: selectedContact, marker };

            this.setState({ selectedUser }, () => this.actionSheetRef.current?.show(target));
        }
    }

    @bind
    private cancelEditContact() {
        this.setState({ selectedUser: undefined });
    }

    // TODO: same as in ContactsTab. Somehow extract? Integrate into ContactList?
    @bind
    private async handlePromise(
        promiseMaker: (contact: ContactEntity) => Promise<any>,
        successMessage: string,
        errorMessage: string
    ) {
        const user = this.state.selectedUser?.user;

        if (user) {
            this.contactListRef.current?.waitFor(user, promiseMaker, successMessage, errorMessage);
        }
    }

    @bind
    private async addContact() {
        this.handlePromise(
            user => Backend.addContacts([user.key]),
            Env.i18n.t('SuccessContactsAdded', { count: 1 }),
            Env.i18n.t('ErrorContactsNotAdded', { count: 1 })
        );
    }

    @bind
    private async blockContact() {
        this.handlePromise(
            contact => Backend.blockUsers([contact.key]),
            Env.i18n.t('SuccessBlockedUserSet'),
            Env.i18n.t('ErrorBlockedUserNotSet')
        );
    }

    @bind
    private async unblockUser(addAsContact: boolean) {
        this.handlePromise(
            user => Backend.unblockUsers([user.key], addAsContact),
            Env.i18n.t('SuccessUnblockUser'),
            Env.i18n.t('ErrorUnblockUser')
        );
    }

    @bind
    private async removeAttendee() {
        const user = this.state.selectedUser?.user;
        const { draft } = this.props;

        if (user && draft.attendees) {
            draft.attendees = draft.attendees.filter(attendee => attendee.key !== user.key);
        }
    }

    @bind
    private renderFooter() {
        const { draft } = this.props;
        const location = draft.restaurant?.location;

        return (
            <div style={{ justifyContent: 'center', alignItems: 'center', padding: SCREEN_PADDING }}>
                {location && (
                    <StaticMap restaurant={draft.restaurant!} />
                )}
            </div>
        );
    }

    public render() {
        const { draft } = this.props;
        const marker = this.state.selectedUser?.marker;
        const actions = !marker ? [] : [
            {
                label: Env.i18n.t('RemoveAttendee'),
                action: this.removeAttendee,
                hideFor: !!draft.key
            },
            {
                label: Env.i18n.t('AddContact'),
                action: this.addContact,
                hideFor: marker !== 'stranger'
            },
            {
                label: Env.i18n.t('ContactBlock'),
                action: this.blockContact,
                destructive: true,
                hideFor: marker === 'blocked'
            },
            {
                label: Env.i18n.t('UnblockUser'),
                action: () => this.unblockUser(false),
                hideFor: marker !== 'blocked'
            },
            {
                label: Env.i18n.t('UnblockAndAddUser'),
                action: () => this.unblockUser(true),
                hideFor: marker !== 'blocked'
            }
        ];

        return (
            <>
                <ContactList
                    ref={this.contactListRef}
                    contacts={draft.attendees || []}
                    disableSearch={true}
                    ListHeaderComponent={<div/>}
                    ListHeaderLayout={GRID_SIZE} // needed to cause vertical offset
                    ListFooterComponent={this.renderFooter()}
                    ListEmptyComponent={this.renderFooter()}
                    onItemPress={this.editContact}
                />
                <ActionSheet
                    ref={this.actionSheetRef}
                    title={this.state.selectedUser?.user.name}
                    onClose={this.cancelEditContact}
                    options={actions}
                />
            </>
        );
    }
}
