import distance from '@turf/distance';
import { point } from '@turf/helpers';

import Constants from '../Constants';
import LatLng, { Region } from '../types/LatLng';
import { AddressComponentType, Coords, GeocodeResponse } from '../types/models/Response';
import Locations from './Locations';
import { createUrl } from './url';

export default class GoogleMapsApi {
    private static latlngToArray(location: LatLng): [ number, number ] {
        return [ location.latitude, location.longitude ];
    }

    // in kilometers
    public static getDistance(p1: LatLng, p2: LatLng) {
        return distance(point(this.latlngToArray(p1)), point(this.latlngToArray(p2)), {});
    }

    // in kilometers. Note: Just an approximation!
    public static getDiagonalDistance(region: Region) {
        const corner = {
            latitude: region.latitude - region.latitudeDelta / 2,
            longitude: region.longitude - region.longitudeDelta / 2
        };
        const oppositeCorner = {
            latitude: region.latitude + region.latitudeDelta / 2,
            longitude: region.longitude + region.longitudeDelta / 2
        };

        return this.getDistance(corner, oppositeCorner);
    }

    public static latlngToCoords(latlng: LatLng): Coords {
        return {
            lat: latlng.latitude,
            lng: latlng.longitude
        };
    }

    public static getAddressString(addresses: GeocodeResponse[]) {
        // get address with street number or at least street, if possible
        const bestAddress =
            addresses.find(address => this.getAddressComponentName(address, 'street_number')) ||
            addresses.find(address => this.getAddressComponentName(address, 'route')) ||
            addresses.find(address => this.getAddressComponentName(address, 'sublocality_level_1')) ||
            addresses.find(address => this.getAddressComponentName(address, 'sublocality')) ||
            addresses[0];
        const streetNumber = this.getAddressComponentName(bestAddress, 'street_number');
        let streetOrDistrict =
            this.getAddressComponentName(bestAddress, 'route') ||
            this.getAddressComponentName(bestAddress, 'sublocality_level_1') ||
            this.getAddressComponentName(bestAddress, 'sublocality') ||
            '';
        let cityOrCountry =
            this.getAddressComponentName(bestAddress, 'locality') ||
            this.getAddressComponentName(bestAddress, 'country') ||
            '';

        if (streetNumber) {
            streetOrDistrict += ` ${streetNumber}`;
        }

        if (streetOrDistrict) {
            streetOrDistrict += ', ';
        }

        return `${streetOrDistrict}${cityOrCountry}`;
    }

    public static getAddressComponentName(address: GeocodeResponse | undefined, type: AddressComponentType) {
        return address?.address_components.find(({ types }) => types.includes(type))?.long_name;
    }

    public static url(path: string, params?: object) {
        return createUrl(Constants.MAPS_API_HOST + path, { key: Constants.API_KEY, ...params });
    }

    public static async get(path: string, params?: object) {
        const response = await fetch(this.url(path, params), { method: 'GET' });

        return await response.json();
    }

    public static getImageUrl(width: number, height: number, location: LatLng, zoom: number) {
        return GoogleMapsApi.url('staticmap', {
            center: Locations.toString(location),
            scale: 2, // The default value is 1. Accepted values are 2 and 4 (4 is only available to Google Maps APIs Premium Plan customers.)
            size: `${width}x${height}`,
            zoom,
            style: 'feature:poi|visibility:off'
        });
    }
}
