import Env from '../Env';
import AccountManager from '../managers/AccountManager';
import ApiManager from '../managers/ApiManager';
import List from '../types/List';
import { Detail } from '../types/lunchnow';
import RestaurantEntry, { MealEntry, Tag } from '../types/models/RestaurantEntry';

export abstract class RestaurantFilterConfig {
    public readonly key: string;
    public readonly icon?: any;

    constructor(key: string, icon?: any) {
        this.key = key;
        this.icon = icon;
    }

    // can't override when using @bind ...
    public abstract availableFor: (restaurant: RestaurantEntry) => boolean;

    public toString() {
        return Env.i18n.t(this.key);
    }
}

export abstract class MealFilterConfig extends RestaurantFilterConfig {
    public availableFor = (restaurant: RestaurantEntry) => {
        return (restaurant.offers || []).some(meal => this.availableForMeal(meal));
    };

    // can't override when using @bind ...
    public abstract availableForMeal: (meal: MealEntry) => boolean;
}

type RestaurantDetailKey = keyof Detail;

class RestaurantDetailConfig extends RestaurantFilterConfig {
    private fields: RestaurantDetailKey[];

    constructor(key: string, icon?: any, ...fields: RestaurantDetailKey[]) {
        super(key, icon);

        this.fields = fields;
    }

    public availableFor = (restaurant: RestaurantEntry) => {
        const details = restaurant.data?.details;

        return !!details && this.fields.some(field => details[field]);
    };
}

class RestaurantPriceConfig extends RestaurantFilterConfig {
    public static readonly Limits = {
        LOW: 7.5,
        HIGH: 15
    };

    private minPrice: number;
    private maxPrice?: number;

    constructor(key: string, minPrice: number, maxPrice?: number) {
        super(key);

        this.minPrice = minPrice;
        this.maxPrice = maxPrice;
    }

    public availableFor = (restaurant: RestaurantEntry) => {
        const meanPrice = restaurant.data?.meanPrice;

        return !!meanPrice && meanPrice >= this.minPrice && (!this.maxPrice || meanPrice < this.maxPrice);
    };
}

export class RestaurantTagConfig extends RestaurantFilterConfig {
    public readonly tag: Tag;

    constructor(tag: Tag) {
        super(tag.key);

        this.tag = tag;
    }

    public availableFor = (restaurant: RestaurantEntry) => {
        return !!restaurant.tagIds?.includes(this.tag.key);
    };

    public toString() {
        return this.tag.translations[Env.i18n.currentLocale().substr(0, 2)];
    }
}

export class RestaurantTextConfig extends RestaurantFilterConfig {
    private words: string[];

    constructor(key: string, input: string) {
        super(key);

        this.words = input.trim().split(/\s+/).map(word => word.toLocaleLowerCase());
    }

    public availableFor = (restaurant: RestaurantEntry) => {
        const name = restaurant.name?.toLocaleLowerCase();

        return this.words.every(word => name?.includes(word))
    }

    public toString() {
        return this.words.join(' ');
    }
}

type ApiType = ApiManager<AccountManager<ApiType>>;

export class RestaurantDiscountConfig extends MealFilterConfig {
    private api: ApiType;

    constructor(key: string, icon: any, api: ApiType) {
        super(key, icon);

        this.api = api;
    }

    public availableFor = (restaurant: RestaurantEntry) => {
        return this.api.account.allDiscounts.some(discount => discount.availableForRestaurant(restaurant));
    }

    public availableForMeal = (meal: MealEntry) => {
        return this.api.account.allDiscounts.some(discount => discount.availableForMeal(meal));
    }
}

class RestaurantOpenConfig extends RestaurantFilterConfig {
    public availableFor = (restaurant: RestaurantEntry) => {
        return restaurant.getOpeningHours().length > 0;
    };
}

class RestaurantPaymentConfig extends RestaurantFilterConfig {
    public availableFor = (restaurant: RestaurantEntry) => {
        return restaurant.hasOrderableMeals;
    }
}

/**
 * NOTE: The property key is also the translation key!
 */
export default class RestaurantDetails {
    private static cache: List<RestaurantFilterConfig[]> = {};

    private static lazy<T extends RestaurantFilterConfig>(key: string, initialize: () => T[]) {
        if (!this.cache[key]) {
            this.cache[key] = initialize();
        }

        return this.cache[key] as T[];
    }

    public static discount(api: ApiType) {
        return this.lazy('discount', () => [
            new RestaurantDiscountConfig('Discounts', Env.assets.icons.Offer, api)
        ]);
    }

    public static get services() {
        return this.lazy('services', () => [
            new RestaurantPaymentConfig('PreOrderAndPay', Env.assets.icons.PreorderRestaurant),
            new RestaurantDetailConfig('Wifi', Env.assets.icons.Wifi, 'wifi')
        ]);
    }

    public static get availability() {
        return this.lazy('availability', () => [
            new RestaurantOpenConfig('Open', Env.assets.icons.AccessTime)
        ]);
    }

    public static get payment() {
        return this.lazy('payment', () => [
            new RestaurantDetailConfig('EcCard', Env.assets.icons.EcCard, 'paymentEC'),
            new RestaurantDetailConfig('Visa', Env.assets.icons.Visa, 'paymentVS'),
            new RestaurantDetailConfig('Mastercard', Env.assets.icons.Mastercard, 'paymentMC'),
            new RestaurantDetailConfig('Dinersclub', Env.assets.icons.DinersClub, 'paymentDC'),
            new RestaurantDetailConfig('AmericanExpress', Env.assets.icons.AmEx, 'paymentAE'),
            new RestaurantDetailConfig('PayPal', Env.assets.icons.PayPal, 'paymentPP'),
            new RestaurantDetailConfig('GooglePay', Env.assets.icons.GooglePay, 'paymentGP'),
            new RestaurantDetailConfig('ApplePay', Env.assets.icons.ApplePay, 'paymentAP'),
            new RestaurantDetailConfig('CashPayment', Env.assets.icons.CashPayment, 'paymentCash')
        ]);
    }

    public static get vouchers() {
        return this.lazy('vouchers', () => [
            new RestaurantDetailConfig('Sodexo', undefined, 'sodexo'),
            new RestaurantDetailConfig('TicketRestaurant', undefined, 'ticketRestaurant')
        ]);
    }

    public static get features() {
        return this.lazy('features', () => [
            new RestaurantDetailConfig('Accessible', undefined, 'handicap'),
            new RestaurantDetailConfig('ParkingSpace', undefined, 'parkingExternal', 'parkingRestaurant'),
            new RestaurantDetailConfig('SmokingArea', undefined, 'smokingOutside', 'smokingInside'),
            new RestaurantDetailConfig('SeatsOutside', undefined, 'seatsOutside')
        ]);
    }

    public static get priceCategories() {
        return this.lazy('priceCategories', () => [
            new RestaurantPriceConfig('PriceCategoryLow', 0, RestaurantPriceConfig.Limits.LOW),
            new RestaurantPriceConfig('PriceCategoryMedium', RestaurantPriceConfig.Limits.LOW, RestaurantPriceConfig.Limits.HIGH),
            new RestaurantPriceConfig('PriceCategoryHigh', RestaurantPriceConfig.Limits.HIGH)
        ]);
    }

    public static cuisines(api: ApiType) {
        return this.lazy('cuisines', () =>
            api.tags.list.filter(tag => [ 'cuisine', 'product' ].includes(tag.type)).map(tag => new RestaurantTagConfig(tag))
        );
    }
}
