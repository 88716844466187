import Locations from '../../../lib/src/helpers/Locations';
import CurrentLocation from '../../../lib/src/store/CurrentLocation';

export default class UserLocation {
    public static get() {
        return new Promise<CurrentLocation | undefined>(async resolve => {
            const permission = (await navigator.permissions?.query({ name: 'geolocation' }))?.state || 'granted';

            if (permission === 'granted' && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    ({ coords }) => {
                        resolve(new CurrentLocation(Locations.normalize(coords), undefined, true));
                    },
                    /*
                     * If you are testing on localhost you will get an error message:
                     *  - on Android Chrome: "Only secure origins are allowed (see https://goo.gl/Y0ZkNV)"
                     *  - on iOS Safari: "Origin does not have permission to use Geolocation service."
                     * See https://stackoverflow.com/questions/43856894/origin-does-not-have-permission-to-use-geolocation-service-even-over-https
                     */
                    ({ message }) => {
                        console.error(message);
                        resolve(undefined);
                    }
                );
            } else {
                resolve(undefined);
            }
        })
    }
}
