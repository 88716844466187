import qs from 'qs';

import PaymentManager from '../../lib/src/managers/PaymentManager';
import Cart from '../../lib/src/store/Cart';
import Api from './Api';

export interface InjectedPaymentProps {
    payment: Payment;
}

export default class Payment extends PaymentManager<Api> {
    public externalRedirect = false;

    constructor(api: Api, waitFor: Promise<any> = Promise.resolve()) {
        super(api, waitFor);

        this.awaitPaymentUser.then(async () => {
            const params = qs.parse(window.location.search.substr(1));

            // this is a redirect back from paypal, so we restore the cart to finish the order
            if (params.token) {
                const order = await this.loadOrder('payInId', params.token as string);

                if (order?.restaurant) {
                    this.setCart(Cart.fromOrder(order, this._api));
                    window.location.hash = `details/${order.restaurant.routingName}/cart`;
                }
            }
        })
    }
}
