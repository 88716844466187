import Env from '../../Env';
import { DataListEntry } from '../../store/DataList';
import { avatarColors } from '../../styles/colors';
import { InvitationStatus } from './Invitation';
import { ContactUser, UserContactGroupsEntry, UserContactsEntry } from './User';

export enum ContactEntityFlags {
    IS_FAVORITE = 'isFavorite'
}

export type ContactStatus = 'selected' | 'pending' | InvitationStatus;

// must be immutable!
export default abstract class ContactEntity implements DataListEntry {
    public readonly key: string;
    public readonly name: string;
    public readonly isFavorite: boolean;

    private _status?: ContactStatus;

    public static compare(entity1: ContactEntity, entity2: ContactEntity) {
        const myUid = Env.firebase.auth().currentUser?.uid;
        const byIdentity = Number(entity2.key === myUid) - Number(entity1.key === myUid);
        const byFavorite = Number(entity2.isFavorite) - Number(entity1.isFavorite);

        return byIdentity || byFavorite || entity1.name.localeCompare(entity2.name);
    }

    public get status() {
        return this._status;
    }

    public constructor(key: string, name: string, isFavorite: boolean, status?: ContactStatus) {
        this.key = key;
        this.name = name || ''; // fail-safe for invalid parameter
        this.isFavorite = isFavorite;
        this._status = status;
    }

    /** Creates a new instance */
    public withStatus(status: ContactStatus) {
        const copy = this.clone();

        copy._status = status;

        return copy;
    }

    private clone() {
        const self = this;

        return Object.assign(Object.create(Object.getPrototypeOf(self)), self) as typeof self;
    }
}

export class ContactPerson extends ContactEntity implements ContactUser, UserContactsEntry {
    public readonly displayName: string;
    public readonly photoURL?: string;
    public readonly displayInitials: string;
    public readonly color: string;

    constructor(contact: UserContactsEntry & DataListEntry, displayName: string, photoURL?: string, status?: ContactStatus) {
        super(contact.key, displayName, contact.isFavorite, status);

        this.displayName = displayName;
        this.photoURL = photoURL;

        this.displayInitials = this.name
            .toLocaleUpperCase()
            .split(/\s+/u)
            .map(name => name.charAt(0))
            // remove non-letter characters (only regarding code-points up to \u00FF)
            .filter(letter => /[^\u0000-\u0040\u005B-\u0060\u007B-\u00BF]/u.test(letter))
            .slice(0, 2)
            .join('');
        this.color = avatarColors[Math.abs(ContactPerson.hash(this.name)) % avatarColors.length];
    }

    public static createDeleted() {
        const key = Math.random().toString();
        const name = Env.i18n.t('DeletedUser');

        return new this({ key, isFavorite: false }, name, undefined, 'deleted');
    }

    public static createFrom(uid: string, displayName: string, photoURL?: string, status?: ContactStatus) {
        return new this(
            {
                key: uid,
                isFavorite: false
            },
            displayName,
            photoURL,
            status
        );
    }

    // see https://stackoverflow.com/a/8831937/4483389
    private static hash(ofString: string) {
        const length = ofString.length;
        let hashCode = 0;

        if (length > 0) {
            for (let i = 0; i < length; i++) {
                hashCode = (hashCode << 5) - hashCode + ofString.charCodeAt(i);
                hashCode = hashCode & hashCode; // Convert to 32bit integer
            }
        }

        return hashCode;
    }
}

export class ContactGroup extends ContactEntity implements UserContactGroupsEntry {
    public readonly members: string[];

    constructor(group: UserContactGroupsEntry & DataListEntry) {
        super(group.key, group.name, group.isFavorite);

        this.members = group.members;
    }
}

export const getContactsStatus = (contacts: ContactPerson[]): ContactStatus => {
    const currentUser = Env.firebase.auth().currentUser;
    const attendee = contacts.find(user => user.key === currentUser?.uid);

    return attendee?.status || 'pending';
};

export const countContacts = (contactEntities: Array<ContactEntity>) => {
    return contactEntities.reduce((count, entity) => count + ((entity instanceof ContactGroup) ? entity.members.length : 1), 0);
}
