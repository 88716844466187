import { action } from 'mobx';

import { ContactGroup } from '../types/models/ContactEntity';
import DataList, { Snapshot } from './DataList';

export default class ContactGroupList extends DataList<ContactGroup> {
    @action
    public addQuerySnapshotChildren(snapshot: Snapshot, override = false) {
        const write = override ? this.set : this.add;

        write.bind(this)(...snapshot.docs.map(childSnapshot => {
            const group = childSnapshot.data() as any;

            return new ContactGroup({
                key: childSnapshot.id,
                name: group.name,
                isFavorite: group.isFavorite || false,
                members: (group.contacts as string[]) || []
            });
        }));
    }
}
