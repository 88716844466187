import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import { InjectedChatProps } from '../../../../lib/src/managers/ChatManager';
import colors from '../../../../lib/src/styles/colors';
import { Chat } from '../../../../lib/src/types/models/Chat';
import { InjectedApiProps } from '../../Api';
import { PrimaryButton } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Modal, { ModalProps } from '../common/Modal';
import NewScreenHeader from '../common/NewScreenHeader';
import Screen from '../common/Screen';
import ChatCard from './inbox/ChatCard';

@inject('api', 'chatManager')
@observer
export default class ChatInboxScreen extends Modal {
    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedChatProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.loggedIn).get();
    }

    public componentDidMount() {
        this.injected.chatManager.initalizeChatsOverview();
    }

    public componentWillUnmount() {
        this.injected.chatManager.uninitalizeChatsOverview();
    }

    render() {
        const { chats } = this.injected.chatManager;
        const backgroundColor = chats.length ? colors.light_background : colors.white;

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.close}
                fullHeight={true}
                HeaderComponent={this.renderHeader}
                FooterComponent={this.renderFooter}
                contentStyle={{ backgroundColor }}
            >
                {/* TODO: use FlatList */}
                {chats.length ? (
                    chats.map(this.renderChatCard)
                ) : (
                    <EmptyListIndicator
                        waitFor={true}
                        icon={require('../../assets/svg/empty_state_user.svg')}
                        hint={Env.i18n.t('NoChats')}
                    />
                )}
            </Screen>
        );
    }

    @bind
    private renderHeader() {
        return (
            <NewScreenHeader title={Env.i18n.t('Inbox')} onBack={this.back} />
        );
    }

    @bind
    private renderFooter() {
        return (
            <PrimaryButton onClick={this.goToChatCreateScreen}>
                {Env.i18n.t('NewMessage')}
            </PrimaryButton>
        );
    }

    @bind
    private renderChatCard(item: Chat, index: number) {
        return (
            <ChatCard key={item.key} chat={item} onPress={() => this.props.navigation.open('chatmessages', [ item.key ])} />
        );
    }

    @bind
    private goToChatCreateScreen() {
        this.props.navigation.open('chatcreate');
    }
}
