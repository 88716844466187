import styled from 'styled-components';

import colors, { alpha } from '../../../lib/src/styles/colors';

export const GRID_SIZE = 8;
export const SCREEN_PADDING = GRID_SIZE * 3;

export const WhiteOverlay = styled.div.attrs(() => ({
    onClick: event => event.stopPropagation()
}))`
    align-items: center;
    background-color: ${alpha(colors.white, 0.5)};
    cursor: default;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2000;
    width: 100%;
`;

export const InteractiveArea = styled.div`
    border: ${GRID_SIZE / 4}px dashed ${colors.grey_03};
    border-radius: ${GRID_SIZE}px;
    padding: ${GRID_SIZE * 2}px;
    text-align: center;
    word-break: break-word;
`;
