import { CircularProgress } from '@material-ui/core';
import { bind } from 'decko';
import { observer } from 'mobx-react';
import React, { CSSProperties, ReactNode } from 'react';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import { logError } from '../../../../lib/src/helpers/Validate';
import ContactEntity from '../../../../lib/src/types/models/ContactEntity';
import { GRID_SIZE, WhiteOverlay } from '../../styles/base';
import EmptyListIndicator from '../common/EmptyListIndicator';
import FilteredFlatList from '../common/FilteredFlatList';
import ContactEntry from './ContactEntry';

type ContactFilter = (contact: ContactEntity) => boolean;

interface Props {
    contacts: ContactEntity[];
    renderItemContent?: (contact: ContactEntity) => ReactNode;
    onItemPress?: (contact: ContactEntity, target?: HTMLElement) => void;
    ListHeaderComponent?: React.ReactElement;
    ListHeaderLayout?: number;
    ListFooterComponent?: React.ReactElement;
    ListFooterLayout?: number;
    ListEmptyComponent?: React.ReactElement;
    selection?: string[];
    disableSearch?: boolean;
    filterBy?: ContactFilter;
    dependsOn?: any;
    className?: string;
    style?: CSSProperties;
    fadeOutUnselectedContacts?: boolean;
    additionalHeaderContent?: React.ReactNode;
}

interface State {
    loading: string[];
}

@observer
export default class ContactList extends React.PureComponent<Props, State> {
    public readonly state: State = {
        loading: []
    };

    @bind
    public async waitFor(
        contact: ContactEntity,
        promise: Promise<any> | ((contact: ContactEntity) => Promise<any>),
        successMessage: string,
        errorMessage: string
    ) {
        this.setState(state => ({
            loading: Arrays.add(state.loading.slice(), contact.key)
        }));

        try {
            if (typeof promise === 'function') {
                promise = promise(contact);
            }

            await promise;
            Env.snackbar.success(successMessage);
        } catch (error) {
            logError('ContactList.waitFor', error);
            Env.snackbar.error(errorMessage);
        } finally {
            this.setState(state => ({
                loading: Arrays.remove(state.loading.slice(), contact.key)
            }));
        }
    }

    public render() {
        const { ListHeaderComponent, ListHeaderLayout, ListFooterComponent, ListFooterLayout, ListEmptyComponent } = this.props;
        const { contacts, filterBy, disableSearch, className, style } = this.props;
        let items: ContactEntity[] = contacts.slice();

        if (filterBy) {
            items = items.filter(filterBy);
        };

        items.sort(ContactEntity.compare);

        return (
            <FilteredFlatList<ContactEntity>
                data={items}
                renderItem={this.renderContact}
                getItemLayout={this.getItemLayout}
                ListHeaderComponent={ListHeaderComponent}
                ListHeaderLayout={ListHeaderLayout}
                ListFooterComponent={ListFooterComponent}
                ListFooterLayout={ListFooterLayout}
                ListEmptyComponent={ListEmptyComponent}
                ListNoResultComponent={
                    <EmptyListIndicator
                        waitFor={true}
                        icon={require('../../assets/svg/empty_state_user.svg')}
                        hint={Env.i18n.t('NoUserFoundByName')}
                    />
                }
                onSearch={disableSearch ? undefined : this.filterItems}
                className={className}
                style={style}
                additionalHeaderContent={this.props.additionalHeaderContent}
            />
        );
    }

    @bind
    private filterItems(input: string) {
        const needles = input.toLocaleLowerCase().split(' ').filter(needle => needle);

        return (contact: ContactEntity) => {
            const name = contact.name.toLocaleLowerCase() || '';

            return needles.every(needle => name.includes(needle));
        };
    }

    @bind
    private renderContact(contact: ContactEntity) {
        const { selection, fadeOutUnselectedContacts, onItemPress, renderItemContent } =  this.props;
        const status = (selection || []).includes(contact.key) ? 'selected' : contact.status;
        const fadeOut = fadeOutUnselectedContacts && (status !== 'selected');

        return (
            <ContactEntry.Medium contact={contact} onPress={onItemPress} status={status} style={{ opacity: fadeOut ? 0.5 : 1 }}>
                {renderItemContent && renderItemContent(contact)}
                {this.state.loading.includes(contact.key) && (
                    <WhiteOverlay>
                        <CircularProgress style={{ height: GRID_SIZE * 6, width: GRID_SIZE * 6 }} />
                    </WhiteOverlay>
                )}
            </ContactEntry.Medium>
        );
    }

    @bind
    private getItemLayout() {
        return GRID_SIZE * 9;
    }
}
