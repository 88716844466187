import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Cart from '../../../../lib/src/store/Cart';
import colors from '../../../../lib/src/styles/colors';
import DiscountEntry from '../../../../lib/src/types/models/DiscountEntry';
import { InjectedAccountProps } from '../../Account';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimarySmallButton, SecondarySmallButton } from '../button';
import { RegularText } from '../text';
import LinkButton from './LinkButton';

const VouchersList = styled.ul`
    border-top: 1px solid ${colors.grey_03};
    margin: ${SCREEN_PADDING}px 0 0;
    list-style: none;
    padding: 0;
`;

const VoucherItem = styled.li`
    align-items: center;
    border-bottom: 1px solid ${colors.grey_03};
    display: flex;
    flex-direction: row;
    padding: ${GRID_SIZE}px 0;
`;

// @ts-ignore ts(2615)
const VoucherLabel = styled(RegularText)<{ active: boolean }>`
    color: ${props => props.active ? colors.matte_black : colors.grey_02};
    flex: 1;
    margin-right: ${GRID_SIZE}px;
`;

interface Props {
    cart: Cart;
}

interface State {
    discountOptionsOpen: boolean;
}

@inject('account')
@observer
export default class VoucherOptions extends React.PureComponent<Props, State> {
    public readonly state: State = {
        discountOptionsOpen: false
    };

    private get injected() {
        return this.props as Props & InjectedAccountProps;
    }

    public componentDidMount() {
        this.setState({ discountOptionsOpen: !!this.props.cart.discount });
    }

    public render() {
        const { cart } = this.props;
        const { discountOptionsOpen } = this.state;
        const discountOptions = this.injected.account.accountDiscounts.list.filter(discount =>
            discount.forAllMeals || discount.availableForRestaurant(cart.restaurant)
        );

        return (discountOptions?.length && !cart.hasItemDiscount)
            ? (
                <div style={{ marginTop: SCREEN_PADDING }}>
                    <LinkButton
                        icon={require('../../assets/svg/gift.svg')}
                        label={Env.i18n.t('RedeemVoucher')}
                        expanded={discountOptionsOpen}
                        onPress={this.toggleDiscountOptions}
                    />
                    {discountOptionsOpen && (
                        <VouchersList>
                            {discountOptions.map(this.renderVoucher)}
                        </VouchersList>
                    )}
                </div>
            )
            : null;
    }

    @bind
    private renderVoucher(discount: DiscountEntry, index: number) {
        const { cart } = this.props;
        const redeemedVoucher = cart.discount;
        const redeemed = (discount.key === redeemedVoucher?.id);
        const active = !redeemedVoucher || redeemed;
        const Button = active ? PrimarySmallButton : SecondarySmallButton;

        return (
            <VoucherItem key={index}>
                <VoucherLabel active={active}>
                    {`${discount.valueText} ${discount.name[Env.currentLanguageCode()]}`}
                </VoucherLabel>
                <Button onClick={() => cart.toggleDiscount(discount)}>
                    {Env.i18n.t(redeemed ? 'Redeemed' : 'Redeem')}
                </Button>
            </VoucherItem>
        );
    }

    @bind
    private toggleDiscountOptions() {
        this.setState(state => ({ discountOptionsOpen: !state.discountOptionsOpen }));
    }
}
