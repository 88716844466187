import 'typeface-roboto';

import { createMuiTheme } from '@material-ui/core';

import colors from '../../../lib/src/styles/colors';
import { SCREEN_PADDING } from '../styles/base';

export const theme = createMuiTheme({
    palette: {
        common: {
            black: colors.matte_black,
            white: colors.white
        },
        background: {
            paper: colors.grey_05,
            default: colors.white
        },
        primary: {
            main: colors.white,
            dark: colors.white,
            contrastText: colors.dark_blue
        },
        secondary: {
            main: colors.white,
            dark: colors.white,
            contrastText: colors.teal_900
        },
        error: {
            light: colors.red_50,
            main: colors.red_500,
            dark: colors.red_900,
            contrastText: colors.matte_black
        },
        text: {
            primary: colors.matte_black,
            secondary: colors.grey_01,
            disabled: colors.grey_05,
            hint: colors.red_50
        },
        action: {
            selected: colors.red_50,
            hover: colors.teal_50,
            disabled: colors.teal_900
        }
    },
    overrides: {
        MuiButton: {
            contained: {
                '&.Mui-disabled': {
                    color: colors.grey_02
                }
            }
        },
        MuiTabs: {
            indicator: {
                backgroundClip: 'content-box',
                backgroundColor: colors.navy_blue,
                border: '0 solid transparent',
                borderWidth: `0 ${SCREEN_PADDING}px`,
                boxSizing: 'border-box'
            }
        },
        MuiTab: {
            root: {
                display: 'flex',
                flex: 1,
                maxWidth: 'none',
                padding: `0 ${SCREEN_PADDING}px`
            },
            textColorInherit: {
                opacity: 0.3
            }
        },
        MuiFab: {
            root: {
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0), 0px 1px 18px 0px rgba(0,0,0,0)'
            }
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: colors.teal_500
            },
            colorSecondary: {
                color: colors.navy_blue
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: '0 !important',
                marginBottom: '0 !important'
            }
        },

        MuiInputBase: {
            inputTypeSearch: {
                fontFamily: 'Roboto-Medium',
                color: colors.beige_light
            }
        },
        MuiInput: {
            root: {
                display: 'block',
            },
            underline: {
                '&:before': {
                    borderBottom: '0px !important'
                },
                '&:after': {
                    borderBottom: '0px'
                },
                '&:hover:not($disabled):before': {
                    borderBottom: '0px'
                }
            }
        },
        MuiLink: {
            root: {
                cursor: 'pointer',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '0.875rem',
                fontWeight: 500,
                letterSpacing: '0.02857em',
                display: 'inline-block',
                lineHeight: 2,
                textTransform: 'uppercase',
                '&:hover': {
                    textDecoration: 'none !important'
                },
                '& img': {
                    verticalAlign: 'middle'
                }
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: colors.white
            },
            rounded: {
                backgroundColor: colors.grey_05
            }
        },
        MuiGrid: {
            container: {
                overflow: 'hidden'
            }
        },
        MuiDialog: {
            paperScrollPaper: {
                maxHeight: '100%'
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: colors.white,
                '-webkit-overflow-scrolling': 'auto'
            }
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: colors.matte_black
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 700,
            lg: 701,
            xl: 1200
        }
    }
});
