import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors, { avatarColors } from '../../../../lib/src/styles/colors';
import statusConfig from '../../../../lib/src/styles/statusConfig';
import ContactEntity, { ContactGroup, ContactPerson, ContactStatus } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedAccountProps } from '../../Account';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import Icon from '../common/Icon';
import { DiskButton } from '../common/IconButton';
import Image from '../common/Image';
import MediaGalleryModal from '../details/MediaGalleryModal';
import { RegularText, TitleText } from '../text';
import StatusMarker from './StatusMarker';

export const clickableEntry = css`
    cursor: pointer;
    transition: background-color 300ms;

    &:hover {
        background-color: ${colors.grey_05};
    }
`;

const ContactContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: ${GRID_SIZE * 9}px;
    padding: 0 ${SCREEN_PADDING}px;

    &.clickable {
        ${clickableEntry};
    }
`;

const ContactPictureBackground = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

const ContactPictureContainer = styled.div<{ size: number }>`
    border-radius: ${props => props.size / 2}px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: ${props => props.size}px;
    overflow: hidden;
    width: ${props => props.size}px;
`;

const ContactPictureImage = styled(Image)`
    height: 100%;
    width: 100%;
`;

const ContactPictureLabel = styled(TitleText)`
    color: ${colors.white};
    text-align: center;
`;

const ContactName = styled(TitleText)`
    display: flex;
    flex: 1;
    margin: 0 ${GRID_SIZE * 2}px;
    max-height: 3rem; /* = 2 * font-size * line-height */
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ContactLabelContainer = styled.div<{ size: number }>`
    align-items: center;
    background-color: ${colors.grey_05};
    border-radius: ${props => props.size / 2}px;
    display: flex;
    flex-direction: row;
    height: ${props => props.size}px;
    margin-right: ${GRID_SIZE}px;
    margin-top: ${GRID_SIZE}px;
    padding-right: ${GRID_SIZE}px;

    &.clickable {
        cursor: pointer;
        transition: background-color 300ms;

        &:hover {
            background-color: ${colors.grey_04};
        }
    }
`;

const ContactLabelName = styled(RegularText)`
    color: ${colors.matte_black};
    margin: 0 ${GRID_SIZE / 2}px;
`;

class ContactPicture extends React.PureComponent<{ contact: Partial<ContactPerson>; height: number; width: number }> {
    public render() {
        const { contact, height, width } = this.props;
        const isDeleted = contact.status === 'deleted';
        const backgroundColor = isDeleted ? colors.grey_05 : contact.color;

        return (
            <ContactPictureBackground style={{ backgroundColor, height, width }}>
                {isDeleted ? (
                    <Icon src={require('../../assets/svg/avatar_disabled.svg')} height={height} width={width} color={colors.grey_03}/>
                ) : contact.photoURL ? (
                    <ContactPictureImage src={contact.photoURL}/>
                ) : (
                    <ContactPictureLabel style={{ fontSize: Math.min(16 /* = FontSize.title */, Math.min(height, width) * 0.4) }}>
                        {this.props.contact.displayInitials}
                    </ContactPictureLabel>
                )}
            </ContactPictureBackground>
        );
    }
}

interface ContactBaseProps {
    contact: ContactEntity;
    status?: ContactStatus;
    className?: string;
    style?: CSSProperties;
}

interface ContactAvatarProps extends ContactBaseProps {
    size: number;
    highlightFavorite?: boolean;
}

@inject('account')
@observer
export class ContactAvatar extends React.Component<ContactAvatarProps> {
    private static dimensionFactors = [
        [[1, 1]],
        [[0.5, 1], [0.5, 1]],
        [[0.5, 1], [0.5, 0.5], [0.5, 0.5]],
        [[0.5, 0.5], [0.5, 0.5], [0.5, 0.5], [0.5, 0.5]]
    ];

    private get injected() {
        return this.props as ContactAvatarProps & InjectedAccountProps;
    }

    public render() {
        const { contact, status, highlightFavorite, size, className, style } = this.props;
        const { contacts } = this.injected.account;
        const members = (contact instanceof ContactGroup)
            ? contact.members.map(memberUid => contacts.get(memberUid) || ContactPerson.createDeleted())
            : [ contact as ContactPerson ];
        const pictureCount = Math.min(members.length, ContactAvatar.dimensionFactors.length);
        const pictureFactors = ContactAvatar.dimensionFactors[pictureCount - 1];
        const pictureMembers = members.slice(0, pictureCount) as Partial<ContactPerson>[];
        const statusConfigData = status && statusConfig(status);

        if (members.length > pictureCount) {
            const surplus = Math.min(members.length - pictureCount + 1, 99);

            pictureMembers.splice(-1, 1, {
                displayInitials: `+${surplus}`,
                color: avatarColors[surplus % avatarColors.length]
            });
        }

        return (
            <div className={className} style={{ margin: size / 6, position: 'relative', ...style}}>
                <ContactPictureContainer size={size}>
                    {pictureMembers.length ? pictureMembers.map((contact, index) => (
                        <ContactPicture
                            key={index}
                            contact={contact}
                            height={size * pictureFactors[index][1]}
                            width={size * pictureFactors[index][0]}
                        />
                    )) : (
                        <DiskButton
                            icon={require('../../assets/svg/friend.svg')}
                            diskSize={size}
                            diskColor={colors.grey_04}
                            color={colors.grey_01}
                        />
                    )}
                </ContactPictureContainer>
                {highlightFavorite !== false && contact.isFavorite && (
                    <div style={{ bottom: 0, position: 'absolute', right: 0 }}>
                        <img src={require('../../assets/svg/badge_star.svg')} style={{ verticalAlign: 'middle' }} />
                    </div>
                )}
                {statusConfigData && (
                    <StatusMarker {...statusConfigData} border={GRID_SIZE / 4} size={size / 3} />
                )}
            </div>
        );
    }
}

interface ContactEntryProps extends ContactBaseProps {
    onPress?: (contact: ContactEntity, target?: HTMLElement) => void;
}

abstract class ContactEntryBase extends React.PureComponent<ContactEntryProps> {
    @bind
    protected handlePress(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.props.onPress) {
            this.props.onPress(this.props.contact, event.currentTarget);
        }
    }

    @bind
    protected handleAvatarPress(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const contact = this.props.contact;

        if (contact instanceof ContactPerson && contact.photoURL) {
            event.stopPropagation();
            MediaGalleryModal.open([{ url: contact.photoURL }]);
        }
    }
}

export default {
    Medium: class extends ContactEntryBase {
        public render() {
            const { contact, onPress, className, style, children } = this.props;
            const isCurrentUser = (Env.firebase.auth().currentUser?.uid === contact.key);
            const isDeleted = (contact.status === 'deleted');
            const clickHandler = isDeleted ? undefined : this.handlePress;
            const classes = className ? [ className ] : [];

            if (clickHandler && onPress) {
                classes.push('clickable');
            }

            return (
                <ContactContainer onClick={clickHandler} className={classes.join(' ')} style={style}>
                    <div onClick={this.handleAvatarPress} style={{ cursor: 'pointer' }}>
                        <ContactAvatar {...this.props} size={GRID_SIZE * 6}/>
                    </div>
                    <ContactName>
                        {isDeleted ? Env.i18n.t('DeletedUser') : contact.name}
                        {isCurrentUser && ` (${Env.i18n.t('You')})`}
                    </ContactName>
                    {children}
                </ContactContainer>
            );
        }
    },
    Small: class extends ContactEntryBase {
        public render() {
            const { contact, onPress, className, style } = this.props;
            const height = GRID_SIZE * 4;
            const classes = className ? [ className ] : [];

            if (onPress) {
                classes.push('clickable');
            }

            return (
                <ContactLabelContainer onClick={this.handlePress} size={height} className={classes.join(' ')} style={style}>
                    <ContactAvatar contact={contact} size={height * 0.75} highlightFavorite={false}/>
                    <ContactLabelName>
                        {contact.name.split(' ')[0]}
                    </ContactLabelName>
                    <RemoveCircleOutlineIcon style={{ color: colors.matte_black }} />
                </ContactLabelContainer>
            );
        }
    }
};
