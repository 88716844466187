import { Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { bind } from 'decko';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Payment from 'payment';
import React, { ChangeEvent } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import {
    formatCardNumber,
    getCardMeta,
    getMangoPayCardType,
    LUNCHIT_ENABLED,
    LUNCHIT_URL,
    paymentError,
    showPaymentError
} from '../../../../lib/src/helpers/Payment';
import Validate, { Validation } from '../../../../lib/src/helpers/Validate';
import { CardData, MangoPayValidationResult, SupportedCardType } from '../../../../lib/src/managers/PaymentManager';
import colors from '../../../../lib/src/styles/colors';
import List from '../../../../lib/src/types/List';
import { CardTypeAlias } from '../../../../lib/src/types/models/Payment';
import { InjectedAccountProps } from '../../Account';
import LinkTo from '../../helpers/LinkTo';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE } from '../../styles/base';
import { PrimaryButton, SecondaryButton } from '../button';
import RedirectFrame from '../common/RedirectFrame';
import ScreenModal, { ScreenModalProps } from '../common/ScreenModal';
import Checkbox from '../forms/Checkbox';
import Input, { InputValue } from '../forms/Input';
import { RegularText } from '../text';

const CardContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 0 ${GRID_SIZE * 2}px ${GRID_SIZE * 4}px;
    position: relative; // needed for <RedirectFrame>
`;

const GridContainer = styled(Grid).attrs({
    container: true
})`
    align-content: flex-start;
    align-items: flex-start;
    margin: ${GRID_SIZE * 2}px 0 -${GRID_SIZE}px;
`;

// somehow using styled component instead caused warnings...
const GridItem = (props: { fullWidth?: boolean; children: React.ReactNode })  => (
    <Grid item={true} xs={12} sm={props.fullWidth ? 12 : 6}>
        {props.children}
    </Grid>
);

const LunchitImg = styled.img`
    vertical-align: text-top;
    width: 90px;
`;

type Props = ScreenModalProps;

interface State {
    redirectUrl?: string;
}

@inject('account', 'payment')
@observer
export default class CardRegistrationModal extends React.PureComponent<Props, State> {
    public readonly state: State = {};

    private firstNameInputRef = React.createRef<Input>();
    private lastNameInputRef = React.createRef<Input>();
    private cardNumberInputRef = React.createRef<Input>();
    private cardExpirationDateInputRef = React.createRef<Input>();
    private cardCvvInputRef = React.createRef<Input>();

    private cardData: CardData = {
        cardNumber: '',
        cardExpirationDate: '',
        cardCvx: '',
        cardType: SupportedCardType.CB_VISA_MASTERCARD
    };

    @observable
    private cardTypeAlias?: CardTypeAlias;

    @observable
    private firstNameValid?: boolean;

    @observable
    private lastNameValid?: boolean;

    @observable
    private mangoPayValidationResult?: MangoPayValidationResult;

    @observable
    private isLunchit = false;

    private get injected() {
        return this.props as Props & InjectedAccountProps & InjectedPaymentProps;
    }

    @bind
    private validateFirstName(firstName: InputValue) {
        const validation = Validate.firstName(firstName.toString());

        this.firstNameValid = validation.valid;

        return validation;
    }

    @bind
    private validateLastName(lastName: InputValue) {
        const validation = Validate.lastName(lastName.toString());

        this.lastNameValid = validation.valid;

        return validation;
    }

    private validateViaMangoPay(prevalidation: Validation, mangoPayErrorMessage: string) {
        let validation = prevalidation;

        if (validation.valid) {
            this.mangoPayValidationResult = this.injected.payment.validateCard(this.cardData);

            const mangoPayError = this.mangoPayValidationResult?.error;

            if (mangoPayError?.ResultMessage === mangoPayErrorMessage) {
                const error = paymentError(mangoPayError, false);

                if (error) {
                    validation = Validation.error(error);
                }
            }
        }

        return validation;
    }

    @bind
    private validateCardNumber(cardNumber: InputValue) {
        return this.validateViaMangoPay(Validate.cardNumber(cardNumber.toString()), 'CARD_NUMBER_FORMAT_ERROR');
    }

    @bind
    private validateExpirationDate(cardExpirationDate: InputValue) {
        return this.validateViaMangoPay(Validate.expirationDate(cardExpirationDate.toString()), 'PAST_EXPIRY_DATE_ERROR');
    }

    @bind
    private validateCvv(cardCvx: InputValue) {
        return this.validateViaMangoPay(Validate.cvv(cardCvx.toString()), 'CVV_FORMAT_ERROR');
    }

    @bind
    private updateNumberChange(event: ChangeEvent<HTMLInputElement>) {
        const { formatted, selectionStart, selectionEnd, cardNumber } = formatCardNumber(
            event.target.value,
            event.target.selectionStart,
            event.target.selectionEnd
        );

        event.target.value = formatted;
        event.target.selectionStart = selectionStart;
        event.target.selectionEnd = selectionEnd;

        this.cardData.cardNumber = cardNumber;

        const cardMeta = getCardMeta(cardNumber);

        if (cardMeta?.type) {
            this.cardData.cardType = getMangoPayCardType(cardMeta.type);
            this.cardTypeAlias = cardMeta?.type;
        }
    }

    @bind
    private updateCardExpirationDate(cardExpirationDate: InputValue) {
        const domNode = ReactDOM.findDOMNode(this.cardExpirationDateInputRef.current) as Element;
        const htmlInput = domNode?.querySelector('input');

        if (htmlInput) {
            Payment.formatCardExpiry(htmlInput);
        }

        const cardExpirationDateValue = cardExpirationDate.toString();
        const { month, year } = Payment.fns.cardExpiryVal(cardExpirationDateValue);

        if (month && year) {
            this.cardData.cardExpirationDate = `${month.toString().padStart(2, '0')}${year.toString().substr(2, 2)}`;
        }
    }

    @bind
    private updateCardCvv(cvv: InputValue) {
        this.cardData.cardCvx = cvv.toString();
    }

    @bind
    private close() {
        this.props.modalRef?.current?.close();
    }

    @bind
    private async submit() {
        const cardTypeAlias = this.isLunchit ? CardTypeAlias.LUNCHIT : this.cardTypeAlias!;
        const firstName = this.firstNameInputRef.current?.validateValue() as string;
        const lastName = this.lastNameInputRef.current?.validateValue() as string;

        this.cardNumberInputRef.current?.validateValue();
        this.cardExpirationDateInputRef.current?.validateValue();
        this.cardCvvInputRef.current?.validateValue();

        if (this.mangoPayValidationResult?.success) {
            this.props.modalRef?.current?.waitFor('', async () => {
                try {
                    const redirectUrl = await this.injected.payment.registerCard(cardTypeAlias, this.cardData, firstName, lastName);

                    if (redirectUrl) {
                        this.setState({ redirectUrl });
                    } else {
                        this.close();
                    }
                } catch (error) {
                    showPaymentError(error);
                }
            });
        }
    }

    @bind
    private handleRedirectReturn({ preAuthorizationId }: List<string>) {
        if (preAuthorizationId) {
            this.props.modalRef?.current?.waitFor('', async () => {
                try {
                    await this.injected.payment.finishCardRegistration({ preAuthorizationId });
                    this.close();
                } catch (error) {
                    showPaymentError(error);
                } finally {
                    this.setState({ redirectUrl: undefined });
                }
            });
        }
    }

    @bind
    private toggleLunchit() {
        this.isLunchit = !this.isLunchit;

        if (this.isLunchit) {
            Env.logEvent('lunchit_for_payment_info');
        }
    }

    @bind
    private openLunchitUrl(event: React.MouseEvent) {
        event.stopPropagation();
        LinkTo.url(LUNCHIT_URL, '_blank');
    }

    @bind
    private resetForm() {
        this.firstNameInputRef.current?.reset();
        this.lastNameInputRef.current?.reset();
        this.cardNumberInputRef.current?.reset();
        this.cardExpirationDateInputRef.current?.reset();
        this.cardCvvInputRef.current?.reset();
    }

    @computed
    private get valid() {
        return !!this.mangoPayValidationResult?.success
            && !!this.cardTypeAlias
            && !!(this.injected.payment.userExists || (this.firstNameValid && this.lastNameValid));
    }

    private renderCta() {
        if (!this.state.redirectUrl) {
            const SubmitButton: typeof PrimaryButton = this.valid ? PrimaryButton : SecondaryButton;

            return (
                <SubmitButton onClick={this.submit} style={{ marginTop: 0 }}>
                    {Env.i18n.t('Save')}
                </SubmitButton>
            );
        }
    }

    public render() {
        const { redirectUrl } = this.state;
        const { firstName, lastName } = this.injected.account.data;

        return (
            <ScreenModal
                ref={this.props.modalRef}
                title={Env.i18n.t('AddCreditCard')}
                onBeforeClose={this.resetForm}
                FooterComponent={this.renderCta()}
            >
                <CardContainer>
                    <GridContainer>
                        {!this.injected.payment.userExists && (
                            <>
                                <GridItem>
                                    <Input
                                        ref={this.firstNameInputRef}
                                        placeholder={Env.i18n.t('FirstName')}
                                        defaultValue={firstName}
                                        validate={this.validateFirstName}
                                        nextInput={this.lastNameInputRef}
                                        autoCapitalize="none"
                                    />
                                </GridItem>
                                <GridItem>
                                    <Input
                                        ref={this.lastNameInputRef}
                                        placeholder={Env.i18n.t('LastName')}
                                        defaultValue={lastName}
                                        validate={this.validateLastName}
                                        nextInput={this.cardNumberInputRef}
                                        autoCapitalize="none"
                                    />
                                </GridItem>
                            </>
                        )}
                        <GridItem fullWidth={true}>
                            <Input
                                ref={this.cardNumberInputRef}
                                placeholder={Env.i18n.t('CardNumber')}
                                validate={this.validateCardNumber}
                                onChange={this.updateNumberChange}
                                nextInput={this.cardExpirationDateInputRef}
                                autoCapitalize="none"
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                type="text"
                                ref={this.cardExpirationDateInputRef}
                                placeholder={`${Env.i18n.t('CardExpirationDate')} (MM / YY)`}
                                validate={this.validateExpirationDate}
                                onChangeText={this.updateCardExpirationDate}
                                nextInput={this.cardCvvInputRef}
                                autoCapitalize="none"
                                style={{ marginRight: GRID_SIZE }}
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                ref={this.cardCvvInputRef}
                                placeholder={Env.i18n.t('CardCvv')}
                                validate={this.validateCvv}
                                onChangeText={this.updateCardCvv}
                                onSubmitEditing={this.submit}
                                autoCapitalize="none"
                                style={{ marginLeft: GRID_SIZE }}
                            />
                        </GridItem>
                    </GridContainer>
                    {LUNCHIT_ENABLED && (
                        <Checkbox checked={this.isLunchit} onToggle={this.toggleLunchit} style={{ marginTop: GRID_SIZE * 2 }}>
                            <LunchitImg src={require('../../assets/svg/logo_lunchit.svg')} alt="" />
                            <RegularText style={{ marginTop: GRID_SIZE }}>
                                {Env.i18n.t('CardLunchitHint')}
                                <Link style={{ textTransform: 'none', color: colors.teal_900 }} onClick={this.openLunchitUrl}>
                                    {Env.i18n.t('LearnMore')}
                                </Link>
                            </RegularText>
                        </Checkbox>
                    )}
                    <RedirectFrame url={redirectUrl} onReturn={this.handleRedirectReturn} />
                </CardContainer>
            </ScreenModal>
        );
    }
}
