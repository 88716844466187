import { bind } from 'decko';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { getActiveTeaser, handleTeaserAction, Teaser as TeaserModel } from '../../../../lib/src/types/models/Teaser';
import { InjectedApiProps } from '../../Api';
import LinkTo from '../../helpers/LinkTo';
import UserLocation from '../../helpers/UserLocation';
import { GRID_SIZE } from '../../styles/base';

interface MediaProps {
    enabled?: boolean;
}

const TeaserVideo = styled.video.attrs({
    autoPlay: true,
    loop: true,
    muted: true
})<MediaProps>`
    background: #000;
    cursor: ${props => props.enabled ? 'pointer' : 'inherit'};
    display: block;
    width: 100%;
`;

const TeaserImage = styled.img<MediaProps>`
    background-color: ${colors.white};
    cursor: ${props => props.enabled ? 'pointer' : 'inherit'};
    display: block;
    max-width: 100%;
    min-width: 100%;
    object-fit: contain;
`;

// @ts-ignore ts(2615)
const FallbackImage = styled(TeaserImage).attrs({
    src: require('../../assets/png/fallback_teaser.png')
})`
    height: ${GRID_SIZE * 16}px;
    object-fit: cover;
`;

interface State {
    teaser?: TeaserModel;
}

@inject('api')
@observer
export default class Teaser extends React.Component<{}, State> {
    public readonly state: State = {};

    private teasersReactionDisposer?: IReactionDisposer;

    private get injected() {
        return this.props as InjectedApiProps;
    }

    public async componentDidMount() {
        this.teasersReactionDisposer = reaction(
            () => this.injected.api.teasers.list,
            teasers => UserLocation.get().then(userLocation =>
                // TODO: update on change of `userLocation`?
                this.setState({ teaser: getActiveTeaser(teasers, userLocation) })
            ),
            { fireImmediately: true }
        );
    }

    public componentWillUnmount() {
        if (this.teasersReactionDisposer) {
            this.teasersReactionDisposer();
        }
    }

    public render() {
        const { teaser } = this.state;

        if (teaser) {
            const uri = teaser.videoUrl || teaser.imageUrl;
            const Media = (teaser.videoUrl ? TeaserVideo : TeaserImage) as typeof TeaserVideo;

            return (
                <Media src={uri} onClick={this.handleTeaserPress} enabled={!!teaser.actionUrl} />
            );
        }

        return (
            <FallbackImage />
        );
    }

    @bind
    private handleRoute(url: string, params?: any) {
        let hash = `#${url.toLowerCase()}`;;

        if (params) {
            Object.values(params).forEach((param: any) => {
                if (param instanceof RestaurantEntry) {
                    hash += `/${param.routingName}`;
                }
                else {
                    hash += `/${params.key || param}`;
                }
            });
        }

        window.location.hash = hash;
    }

    @bind
    private async handleTeaserPress() {
     handleTeaserAction(this.state.teaser, this.injected.api, LinkTo.url, this.handleRoute);
    }
}
