import styled from 'styled-components';

import Env from '../../../../lib/src/Env';

const BackgroundImage = styled.div<{ src: string }>`
    background-image: ${props => `url(${props.src}), url(${Env.assets.fallbackImage})`};
    background-position: center;
    background-size: cover;
`;

export default BackgroundImage;
