import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { bind } from 'decko';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { GRID_SIZE } from '../../styles/base';
import { PrimaryButton } from '../button';
import Icon, { IconType } from './Icon';
import { CloseButton } from './Modal';

const AlertButtons = styled(DialogContent)`
    display: flex;
    flex-direction: row;

    &&& > * {
        margin: ${GRID_SIZE}px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
`;

const Line = styled.span`
    display: block;
    min-height: 1em;
`;

interface Action {
    icon?: IconType;
    label: string;
    action?: () => (boolean | void);
}

export interface AlertProps {
    title: string;
    children?: ReactNode;
    actions: Action[];
    cancelable?: boolean;
}

interface Props extends AlertProps {
    onClose: () => void;
}

class Alert extends React.PureComponent<Props> {
    public render() {
        const { cancelable, onClose, title, actions, children } = this.props;
        const isCancelable = cancelable !== false;
        const message = typeof children === 'string' ? this.nl2br(children) : children;

        return (
            <Dialog
                open={true}
                onClose={onClose}
                disableBackdropClick={!isCancelable}
                disableEscapeKeyDown={!isCancelable}
                style={{ zIndex: 1500 }}
            >
                <DialogTitle>
                    {this.nl2br(title)}
                    {isCancelable && (
                        <CloseButton onClick={onClose} />
                    )}
                </DialogTitle>
                {message && (
                    <DialogContent>
                        {message}
                    </DialogContent>
                )}
                <AlertButtons>
                    {actions.map(this.renderAction)}
                </AlertButtons>
            </Dialog>
        );
    }

    @bind
    private renderAction(action: Action, index: number) {
        return (
            <PrimaryButton key={index} onClick={() => this.handleActionClick(action)}>
                {action.icon && (
                    <Icon src={action.icon} style={{ marginRight: GRID_SIZE }} />
                )}
                {action.label}
            </PrimaryButton>
        );
    }

    private handleActionClick(action: Action) {
        let doClose = true;

        if (action.action) {
            doClose = action.action() !== false;
        }

        if (doClose) {
            this.props.onClose();
        }
    }

    private nl2br(text: string) {
        return text.split(/\n/).map((line, index) => (
            <Line key={index}>
                {line}
            </Line>
        ));
    }
}

interface Alerts {
    alerts: AlertProps[];
}

export default class GlobalAlert extends React.PureComponent<Alerts, Alerts> {
    public readonly state: Alerts = {
        alerts: this.props.alerts
    };

    public alert(props: AlertProps) {
        this.setState(state => ({
            alerts: [ props, ...state.alerts ]
        }));
    }

    public render() {
        return (
            <>
                {this.state.alerts.reverse().map((props, index) => (
                    <Alert key={index} {...props} onClose={this.closeLatest} />
                ))}
            </>
        );
    }

    @bind
    private closeLatest() {
        this.setState(state => ({
            alerts: state.alerts.slice(1)
        }));
    }
}
