import { bind } from 'decko';
import { observable } from 'mobx';
import moment from 'moment';

import Cart from '../store/Cart';
import InvitationDraft from '../store/InvitationDraft';
import ContactEntity from '../types/models/ContactEntity';
import Invitation from '../types/models/Invitation';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';

type ApiType = ApiManager<AccountManager<ApiType>>;

export const FALLBACK_ORIGIN = 'Invitations'; // will be transformed for web

export interface InjectedInvitationDraftProps {
    invitationDraft: InvitationDraftManager;
}

// TODO: do we still need this or can we simply pass around an `InvitationDraft`?
export default class InvitationDraftManager {
    private _api: ApiType;

    @observable
    private _draft: InvitationDraft = new InvitationDraft(FALLBACK_ORIGIN);

    public constructor(api: ApiType) {
        this._api = api;
    }

    public get() {
        return this._draft;
    }

    public create(fromOrigin: string, from: Partial<Invitation> = {}, presetDay = moment(), forCart?: Cart) {
        this._draft = new InvitationDraft(fromOrigin, from, presetDay, forCart);

        return this._draft;
    }

    @bind
    public setAttendees(entities?: ContactEntity[]) {
        this._draft.attendees = entities
            ? this._api.account.flattenContactEntites(entities).sort(ContactEntity.compare)
            : entities;
    }

    @bind
    public async complete() {
        const { forCart } = this._draft;
        const invitation = await this._draft.send();

        if (forCart) {
            forCart.setMeetUp(invitation as any);
        }

        return invitation;
    }
}
