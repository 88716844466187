import { bind } from 'decko';
import { computed, observable } from 'mobx';
import moment, { Moment } from 'moment';

import HttpRequest from '../helpers/HttpRequest';
import { assert } from '../helpers/Validate';
import ContactEntity, { ContactPerson } from '../types/models/ContactEntity';
import Invitation from '../types/models/Invitation';
import RestaurantEntry from '../types/models/RestaurantEntry';
import Cart from './Cart';

export default class InvitationDraft {
    @observable
    protected _invitation: Partial<Invitation>;

    @observable
    private _dirty: boolean;

    private _origin: string;

    private _presetDate: Moment;

    private _chatKey?: string;

    private _replacedChatMessageKey?: string;

    public readonly forCart?: Cart;

    public constructor(origin: string, from: Partial<Invitation> = {}, presetDay = moment(), forCart?: Cart) {
        const now = moment();

        this._origin = origin;
        this._presetDate = presetDay.hours(now.hours()).minutes(now.minutes());
        this._invitation = from;
        this._dirty = !this.isComplete(); // TODO: rather check for something like key/id (indicating `from` comes from db)
        this.forCart = forCart;
    }

    public get origin() {
        return this._origin;
    }

    @computed
    public get presetDate() {
        return this.date || this._presetDate.toDate();
    }

    @computed
    public get date() {
        return this._invitation.date;
    }

    public set date(date: Date | undefined) {
        this.dirty = this.dirty || this._invitation.date?.getTime() !== date?.getTime();
        this._invitation.date = date;
    }

    @computed
    public get key() {
        return this._invitation.key;
    }

    @computed
    public get restaurant() {
        return this._invitation.restaurant;
    }

    public set restaurant(restaurant: RestaurantEntry | undefined) {
        this._invitation.restaurant = restaurant;
        this.dirty = true;
    }

    @computed
    public get attendees() {
        return this._invitation.attendees;
    }

    public set attendees(attendees: ContactPerson[] | undefined) {
        this._invitation.attendees = attendees;
        this.dirty = true;
    }

    @computed
    public get isOwn() {
        return this._invitation.isOwn;
    }

    public set isTakeAway(isTakeAway) {
        this.dirty = this.dirty || this._invitation.isTakeAway !== isTakeAway;
        this._invitation.isTakeAway = isTakeAway;
    }

    @computed
    public get isTakeAway() {
        return this._invitation.isTakeAway;
    }

    public set chatKey(chatKey: string | undefined) {
        this._chatKey = chatKey;
    }

    @computed
    public get chatKey() {
        return this._chatKey;
    }

    public set replacedChatMessageKey(replacedChatMessageKey: string | undefined) {
        this._replacedChatMessageKey = replacedChatMessageKey;
    }

    @computed
    public get replacedChatMessageKey() {
        return this._replacedChatMessageKey;
    }

    @computed
    public get dirty() {
        return this._dirty;
    }

    // TODO: needed? Let's try to get rid of this!
    public set dirty(dirty: boolean) {
        this._dirty = dirty;
    }

    @bind
    public isComplete() {
        return !!(this.date && this.restaurant && this.attendees);
    }

    @bind
    public async send() {
        assert(this.isComplete(), 'Invitation draft is incomplete');
        assert(!this.key, 'Invitation draft is aready send');

        const response = await HttpRequest.put('/api/invitations', {
            date: this.date!.toISOString(),
            restaurant: this.restaurant!.key,
            // TODO: @PHILIPP unknown attendees will just be skipped, maybe we want to cancel invitation?
            attendees: this._invitation.attendees!.map(attendee => attendee.key),
            isTakeAway: this._invitation.isTakeAway,
            chatKey: this._chatKey,
            replacedChatMessageKey: this._replacedChatMessageKey,
        });
        const partialInvitation = JSON.parse(response);

        this._invitation = {
            ...this._invitation,
            ...partialInvitation
        };

        return this._invitation as Invitation;
    }
}
