import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { switchColors, SwitchColorVariants } from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import { TitleText } from '../text';

const Button = styled.div<{ selected?: boolean, variant: SwitchColorVariants }>`
    align-items: center;
    background-color: ${props => props.selected ? switchColors[props.variant].selectedBackgroundColor : switchColors[props.variant].backgroundColor};
    border-color: ${props => props.selected ? switchColors[props.variant].selectedBorderColor : switchColors[props.variant].borderColor};
    border-radius: 45px;
    border-style: solid;
    border-width: 1px;
    color: ${props => props.selected ? switchColors[props.variant].selectedTextColor : switchColors[props.variant].textColor};
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    margin: ${GRID_SIZE}px ${GRID_SIZE / 2}px;
    padding: ${GRID_SIZE}px;
`;

// @ts-ignore ts(2615)
const ButtonIcon = styled(Icon)`
    margin-left: ${GRID_SIZE - 1}px;
`;

const ButtonLabel = styled(TitleText)`
    align-items: center;
    color: currentColor;
    display: flex;
    flex-direction: row;
    margin: 0 ${GRID_SIZE}px;
    text-align: center;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    icon?: string | React.ComponentClass;
    textStyle?: CSSProperties;
    variant?: 'light' | 'dark';
    selected?: boolean;
}

/**
 * Fully controlled component. You need to handle the `selected` value externally.
 */
export default class Switch extends React.PureComponent<Props> {
    public render() {
        const { textStyle, icon: Icon, children, ...props } = this.props;

        const variant = props.variant || 'light'

        return (
            <Button {...props} variant={variant}>
                {Icon && (typeof Icon === 'string' ? (
                    <ButtonIcon src={Icon} />
                ) : (
                    <Icon />
                ))}
                <ButtonLabel style={textStyle}>
                    {children}
                </ButtonLabel>
            </Button>
        );
    }
}
