import MoreVertical from '@material-ui/icons/MoreVert';
import { bind } from 'decko';
import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { formatDayAndTime } from '../../../../lib/src/helpers/formatting';
import colors from '../../../../lib/src/styles/colors';
import statusConfig from '../../../../lib/src/styles/statusConfig';
import { ContactPerson } from '../../../../lib/src/types/models/ContactEntity';
import Invitation from '../../../../lib/src/types/models/Invitation';
import { isInvitationExpired } from '../../../../lib/src/types/models/UserInvitation';
import { InjectedAccountProps } from '../../Account';
import { GRID_SIZE } from '../../styles/base';
import Icon from '../common/Icon';
import { LargeCardBase } from '../restaurants/LargeCard';
import { RegularText, SublineText } from '../text';

const InvitationContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const ToggleButton = styled(Icon).attrs({
    color: colors.navy_blue,
    src: MoreVertical
})`
    cursor: pointer;
    margin-right: ${GRID_SIZE * -2}px;
`;

const InfoText = styled(SublineText)`
    align-self: center;
    white-space: nowrap;
`;

const AttendeesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const AttendeeContainer = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-top: ${GRID_SIZE * 2}px;
    padding-right: ${GRID_SIZE}px;
    width: ${100 / 3}%;
`;

const AttendeeStatus = styled.div`
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: ${GRID_SIZE * 2}px;
    justify-content: center;
    margin-right: ${GRID_SIZE / 2}px;
    width: ${GRID_SIZE * 2}px;
`;

const AttendeeStatusIcon = styled(Icon).attrs({
    size: GRID_SIZE * 1.8
})``;

const AttendeeName = styled(RegularText).attrs({
    numberOfLines: 1
})`
    color: ${colors.matte_black};
    flex: 1;
    font-weight: 300;
`;

const OrderButton = styled.div`
    align-items: center;
    color: ${props => props.onClick ? colors.navy_blue : colors.grey_03};
    display: flex;
    margin-right: ${GRID_SIZE * 2}px;
`;

const OrderButtonLabel = styled(RegularText)`
    color: inherit;
    font-size: ${GRID_SIZE * 1.5}px;
    margin-left: ${GRID_SIZE / 2}px;
`;

const OrderButtonIcon = styled(Icon).attrs({
    src: require('../../assets/svg/cart.svg'),
    size: GRID_SIZE * 1.5
})``;

interface Props {
    invitation: Invitation;
    onPress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, invitation: Invitation) => void;
    onAddOrder?: (invitation: Invitation) => void;
    onOpenOrder?: (orderId: string) => void;
}

@inject('account')
export default class InvitationCard extends React.PureComponent<Props> {
    private get injected() {
        return this.props as Props & InjectedAccountProps;
    }

    private get attendees() {
        const { invitation, account } = this.injected;
        const userId = account.user?.uid;
        const attendees = invitation.attendees.map(attendee => ({
            ...attendee,
            displayName: (attendee.key === userId) ? Env.i18n.t('You') : attendee.displayName.split(' ')[0]
        } as ContactPerson));

        return attendees.sort((a, b) =>
            (Number(b.key === userId) - Number(a.key === userId)) || a.displayName.localeCompare(b.displayName)
        )
    }

    public render() {
        const { invitation, account } = this.injected;
        const status = account.getMyStatus(invitation.attendees);
        const statusConfigData = statusConfig(invitation.status || 'pending');
        // TODO: extract to also use in ActionSheet on Invitations screen
        const accepted = (status === 'accepted' && invitation.status === 'accepted')
            // TODO: show explanation alert in these cases?
            && invitation.restaurant?.hasPayment && invitation.restaurant?.isStillOpenToday;
        const action = accepted ? this.getIconPressHandler(invitation.order) : undefined;

        return (
            <InvitationContainer onClick={this.handlePress}>
                <ToggleButton />
                <LargeCardBase
                    restaurant={invitation.restaurant}
                    badgeLabel={statusConfigData.text}
                    badgeColor={statusConfigData.diskColor}
                    afterTitleContent={(
                        <InfoText>
                            {Env.i18n.t('InvitationCardSubline', { date: formatDayAndTime(invitation.date) })}
                        </InfoText>
                    )}
                    iconContent={(
                        <OrderButton onClick={action}>
                            <OrderButtonIcon />
                            <OrderButtonLabel>
                                {Env.i18n.t(invitation.order ? 'ShowOrder' : 'AddOrder')}
                            </OrderButtonLabel>
                        </OrderButton>
                    )}
                    style={{ cursor: 'pointer', flex: 1 }}
                >
                    <AttendeesContainer>
                        {this.attendees.map(this.renderAttendee)}
                    </AttendeesContainer>
                </LargeCardBase>
            </InvitationContainer>
        );
    }

    @bind
    private renderAttendee({ key, status, displayName }: ContactPerson) {
        const { color, diskColor, icon } = statusConfig(status || 'pending');

        return (
            <AttendeeContainer key={key}>
                <AttendeeStatus style={{ backgroundColor: diskColor }}>
                    <AttendeeStatusIcon src={icon} color={color} />
                </AttendeeStatus>
                <AttendeeName>
                    {displayName}
                </AttendeeName>
            </AttendeeContainer>
        );
    }

    @bind
    private handlePress(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const { invitation, onPress } = this.props;

        if (onPress) {
            event.persist(); // needed to pass correct target
            onPress(event, invitation);
        }
    }

    @bind
    private getIconPressHandler(orderId?: string) {
        const { invitation, onAddOrder, onOpenOrder } = this.props;

        if (orderId) {
            if (onOpenOrder) {
                return () => onOpenOrder(orderId);
            }
        } else if (onAddOrder && !isInvitationExpired(invitation)) {
            return () => onAddOrder(invitation);
        }
    }
}
