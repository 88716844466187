import Env from '../Env';
import AccountManager from '../managers/AccountManager';
import ApiManager from '../managers/ApiManager';
import { ContactPerson } from '../types/models/ContactEntity';
import Invitation from '../types/models/Invitation';
import UserInvitation from '../types/models/UserInvitation';
import TransactionList, { TransactionDocument, TransactionQuery } from './TransactionList';

type ApiType = ApiManager<AccountManager<ApiType>>;

export default class InvitationList extends TransactionList<UserInvitation, Invitation> {
    private userId: string;
    private api: ApiType;

    constructor(userId: string, api: ApiType) {
        super('date');

        this.userId = userId;
        this.api = api;
        this.startWatching();
    }

    protected get collectionRef() {
        return Env.firebase.firestore()
            .collection('users').doc(this.userId)
            .collection('invitations') as firebase.firestore.CollectionReference<UserInvitation>
    }

    protected buildQuery() {
        return this.collectionRef as TransactionQuery<UserInvitation>;
    }

    protected async createItem(doc: TransactionDocument<UserInvitation>): Promise<Invitation> {
        const { userId, api } = this;
        const item = doc.data();

        return {
            ...item,
            key: doc.id,
            restaurant: await api.getRestaurant(item.restaurant),
            date: this.getDate(doc),
            attendees: item.attendees.map(({ uid, status }) => {
                const attendee = ((uid === userId) ? api.account.currentContactPerson : api.account.contacts.get(uid))
                    || ContactPerson.createDeleted();

                return attendee.withStatus(status || 'pending');
            })
        };
    }
}
