import { DataListEntry } from '../../store/DataList';

export enum CardTypeAlias {
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    MAESTRO = 'MAESTRO',
    LUNCHIT = 'LUNCHIT',
    PAYPAL = 'PAYPAL'
}

export interface Card {
    typeAlias: CardTypeAlias;
}

export interface Cards {
    [cardId: string]: Card;
}

export interface PaymentUser extends DataListEntry {
    mangoPayData?: {
        creditedWalletId: string;
        defaultCardId?: string;
        userId: string;
        cards?: Cards;
    };
    type: 'USER' | 'RESTAURANT' | 'COMPANY';
}

export interface MangoPayTransaction {
    transactionId?: string;
}

export interface PayPalTransaction {
    token?: string;
    PayerID?: string; // only if order was confirmed
}
