import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import Arrays from '../../../../lib/src/helpers/Arrays';
import { GRID_SIZE } from '../../styles/base';
import Switch from './Switch';

// TODO: extract common style with Switch.Button and simplify Button style
const Group = styled.div`
    display: flex;
    flex-direction: row;
    margin: ${GRID_SIZE}px ${GRID_SIZE / 2}px;
`;

const Button = styled(Switch)`
    &&& {
        flex: 1;
        margin: 0 -1px;

        &:first-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        &:last-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
    }
`;

interface Props<T> {
    values: T[];
    selected?: T[];
    renderIcon?: (value: T, index?: number) => string | undefined;
    renderLabel?: (value: T, index?: number) => string;
    onClick?: (value: T, index?: number) => void;
    onChange?: (selected: T[]) => void;
    single?: boolean;
    style?: CSSProperties;
    className?: string;
}

/**
 * Fully controlled component. You need to handle the `selected` value externally.
 */
export default class SwitchGroup<T> extends React.PureComponent<Props<T>> {
    public render() {
        const { selected, values, style, className } = this.props;

        return (
            <Group className={className} style={style}>
                {values.map((value, index) => (
                    <Button
                        key={index}
                        onClick={() => this.toggle(value, index)}
                        selected={selected?.includes(value)}
                        icon={this.renderIcon(value, index)}
                    >
                        {this.renderLabel(value, index)}
                    </Button>
                ))}
            </Group>
        );
    }

    private renderIcon(value: T, index: number) {
        const { renderIcon } = this.props;

        return renderIcon ? renderIcon(value, index) : undefined;
    }

    private renderLabel(value: T, index: number) {
        const { renderLabel } = this.props;

        return renderLabel ? renderLabel(value, index) : String(value);
    }

    private toggle(value: T, index: number) {
        const { selected, single, onChange, onClick } = this.props;

        if (onClick) {
            onClick(value, index);
        }

        if (onChange) {
            onChange(single ? [ value ] : Arrays.toggle(selected?.slice() || [], value));
        }
    }
}
