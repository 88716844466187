import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { bind } from 'decko';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { RegularText, TitleText } from '../text';
import { BUTTON_ICON_SIZE } from './Icon';

export interface ScreenModalProps {
    modalRef?: React.RefObject<ScreenModal>;
}

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

// @ts-ignore ts(2615)
const Modal = styled(ContentContainer)`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 300ms ease-out;
    z-index: 2100;

    &.closed {
        transform: translateY(100%);
    }
`;

const Content = styled(ContentContainer)`
    overflow: auto;
`;

const Backdrop = styled.div`
    min-height: ${GRID_SIZE * 15}px;
`;

const Background = styled.div`
    background: ${colors.white};
    border-top-left-radius: ${GRID_SIZE}px;
    border-top-right-radius: ${GRID_SIZE}px;
    box-shadow: 0 0 ${GRID_SIZE}px rgba(0, 0, 0, .4);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const Header = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding: ${GRID_SIZE}px;
`;

// @ts-ignore ts(2615)
const Title = styled(RegularText)`
    color: ${colors.navy_blue};
    flex: 1;
    margin-left: ${BUTTON_ICON_SIZE}px;
    padding-top: ${GRID_SIZE / 2}px;
    text-align: center;
`;

const CIRCLE_LOADER_COLOR = '#58D6C8';

// see https://codepen.io/scottloway/pen/zqoLyQ
const CircleLoader = styled.div<{ size?: number }>`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: ${CIRCLE_LOADER_COLOR};
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: ${props => props.size || GRID_SIZE * 4}px;
    height: ${props => props.size || GRID_SIZE * 4}px;
`;

const CircleLoaderWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    padding: 50px;
`;

const CircleLoaderText = styled(TitleText)`
    color: ${CIRCLE_LOADER_COLOR};
    font-size: 1.3em;
    margin-top: ${GRID_SIZE * 4}px;
    text-align: center;
`;

interface Props {
    title?: string;
    FooterComponent?: React.ReactElement;
    onBeforeOpen?: () => Promise<any> | void;
    onAfterOpen?: () => void;
    onBeforeClose?: () => Promise<any> | void;
    onAfterClose?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

interface State {
    open: boolean;
    loadingHint?: string; // TODO: use
}

export default class ScreenModal extends React.PureComponent<Props, State> {
    public readonly state: State = {
        open: false
    };

    public async open() {
        const { onBeforeOpen, onAfterOpen } = this.props;

        if (onBeforeOpen) {
            await onBeforeOpen();
        }

        this.setState({ open: true }, onAfterOpen);
    }

    @bind
    public async close() {
        const { onBeforeClose, onAfterClose } = this.props;

        if (onBeforeClose) {
            await onBeforeClose();
        }

        this.setState({ open: false }, onAfterClose);
    }

    public waitFor<T>(loadingHint: string, promise: Promise<T> | (() => Promise<T>)) {
        this.setState({ loadingHint });

        if (typeof promise === 'function') {
            promise = promise();
        }

        return promise.finally(() => this.setState({ loadingHint: undefined }));
    }

    public render() {
        const { title, FooterComponent, className, style, children } = this.props;
        const { open, loadingHint } = this.state;
        const isLoading = loadingHint !== undefined;

        // TODO: return `null` for closed modal (after closing transition) for performance?
        return (
            <Modal className={open ? 'open' : 'closed'}>
                <Content>
                    <Backdrop onClick={this.close} />
                    <Background className={className} style={style}>
                        <Header>
                            <Title>
                                {title}
                            </Title>
                            <IconButton onClick={this.close} size="small">
                                <Close style={{ color: colors.matte_black, transform: `scale(${20 / BUTTON_ICON_SIZE})` }} />
                            </IconButton>
                        </Header>
                        {isLoading ? (
                            <CircleLoaderWrapper>
                                <CircleLoader size={100} />
                                <CircleLoaderText>
                                    {loadingHint}
                                </CircleLoaderText>
                            </CircleLoaderWrapper>
                        ) : (
                            children
                        )}
                    </Background>
                </Content>
                {!isLoading && FooterComponent}
            </Modal>
        );
    }
}
