import { Tab, Tabs } from '@material-ui/core';
import { bind } from 'decko';
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { RegularText } from '../text';

export const TabView = styled.div`
    box-sizing: border-box;
    padding: 0 ${SCREEN_PADDING}px;
`;

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100%; /* needed to make Slider work correctly */
`;

const TabLabel = styled(RegularText)`
    color: ${colors.matte_black};
    font-size: 1rem;
    margin: 0;
    padding: ${GRID_SIZE * 2}px;
    text-transform: none;
`;

interface Props {
    tabs: string[];
    onChange?: (index: number) => void;
    height?: 'adaptive' | 'full' | 'auto';
}

interface State {
    slide: number;
}

export default class TabViewPager extends React.PureComponent<Props, State> {
    public readonly state: State = {
        slide: 0
    };

    private slider = React.createRef<Slider>();

    public render() {
        const { tabs, height, children } = this.props;

        return (
            <Container>
                <Tabs value={this.state.slide} onChange={this.handleTabChange} variant="scrollable" scrollButtons="off">
                    {tabs.map((menuType, index) => (
                        <Tab
                            key={index}
                            label={
                                <TabLabel>
                                    {menuType}
                                </TabLabel>
                            }
                            disableRipple={tabs.length < 2}
                        />
                    ))}
                </Tabs>
                <Slider
                    ref={this.slider}
                    arrows={false}
                    dots={false}
                    infinite={false}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    adaptiveHeight={height === 'adaptive'}
                    beforeChange={this.beforeChange}
                    className={height === 'full' ? 'full-height' : undefined}
                >
                    {children}
                </Slider>
            </Container>
        );
    }

    @bind
    public setTab(index: number) {
        this.setState({ slide: index });
        this.slider.current?.slickGoTo(index);
    }

    @bind
    private handleTabChange(event: React.ChangeEvent<{}>, slide: number) {
        this.setTab(slide);

        if (this.props.onChange) {
            this.props.onChange(slide);
        }
    }

    @bind
    private beforeChange(currentSlide: number, nextSlide: number) {
        this.setState({ slide: nextSlide });
    }
}
