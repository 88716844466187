import { Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { VerifiedUserOutlined } from '@material-ui/icons';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { GRID_SIZE, SCREEN_PADDING } from 'src/styles/base';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { breakAtSpecialChars } from '../../../../lib/src/helpers/formatting';
import colors, { alpha } from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { InjectedAuthProps } from '../../Auth';
import Alert from '../../helpers/Alert';
import LinkTo from '../../helpers/LinkTo';
import Icon, { IconType } from '../common/Icon';
import MediaGalleryModal from '../details/MediaGalleryModal';
import { GreyProfileBackground, ProfileCachedImage, ProfilePictureContainer } from '../social/ProfileCachedImage';
import { RegularText, TitleText } from '../text';

const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: ${GRID_SIZE * 3.5}px;
`;

const DisplayNameText = styled(TitleText)`
    color: ${colors.white};
    font-size:  1.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Section = styled.div`
    background-color: ${alpha(colors.white, 0.3)};
    border-radius: ${GRID_SIZE}px;
    box-shadow: 0 0 ${GRID_SIZE / 2}px rgba(0,0,0,0.1);
    flex-shrink: 0;
    margin-top: ${GRID_SIZE * 3}px;
    overflow: hidden;
`;

const DrawerButtonLabel = styled(RegularText)`
    color: inherit;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0.4px;
    margin-left: ${GRID_SIZE}px;
`;

const DrawerButton = styled(Button)`
    &&& {
        border-radius: 0;
        border-top: 0.7px solid ${alpha(colors.white, 0.15)};
        color: ${colors.matte_black};
        cursor: pointer;
        justify-content: flex-start;
        padding: ${GRID_SIZE * 2}px;
        text-transform: none;
        width: 100%;

        &:first-child {
            border-top: none;
        }
    }
`;

const PartnerButton = styled(DrawerButton)`
    &&& {
        border-color: ${alpha(colors.white, 0.3)};
        color: ${colors.white};
        justify-content: center;
        margin: ${GRID_SIZE * 5}px ${GRID_SIZE * 3}px;
        width: auto;
    }
`;

interface DrawerEntry {
    label: string;
    icon: IconType;
    action: () => void;
    hideFor?: boolean;
}

interface Props {
    open?: boolean
    onClose?: () => void;
    onOpenModal: (modal: string, params?: any) => void;
}

@inject('api', 'auth')
@observer
export default class MenuDrawer extends React.Component<Props> {
    public static readonly theme = createMuiTheme({
        overrides: {
            MuiDrawer: {
                paper: {
                    backdropFilter: 'blur(10px)',
                    background: alpha(colors.white, 0.25),
                    boxSizing: 'border-box',
                    padding: `0 ${SCREEN_PADDING}px`,
                    width: 310
                },
            },
        },
    });

    private get injected() {
        return this.props as Props & InjectedApiProps & InjectedAuthProps;
    }

    public render() {
        const { open, onClose } = this.props;
        const { loggedIn, verified, user, data } = this.injected.api.account;
        const title = data.displayName || user?.email || undefined;
        // TODO: which entries should be hidden if not logged in?
        const userEntries: DrawerEntry[] = [
            {
                label: Env.i18n.t('Inbox'),
                icon: require('../../assets/svg/mail.svg'),
                action: this.openChat
            },
            {
                label: Env.i18n.t('Orders'),
                icon: require('../../assets/svg/fastfood.svg'),
                action: this.openOrders
            },
            {
                label: Env.i18n.t('Invitations'),
                icon: require('../../assets/svg/feather_coffee.svg'),
                action: this.openInvitations
            },
            {
                label: Env.i18n.t('Contacts'),
                icon: require('../../assets/svg/friend.svg'),
                action: this.openContacts
            },
            {
                label: Env.i18n.t('Vouchers'),
                icon: require('../../assets/svg/gift.svg'),
                action: this.openVoucher
            },
        ];
        const settingsEntries: DrawerEntry[] = [
            {
                label: Env.i18n.t('Login'),
                icon: require('../../assets/svg/exit.svg'),
                action: this.login,
                hideFor: loggedIn
            },
            {
                label: Env.i18n.t('VerifyEmail'),
                icon: VerifiedUserOutlined,
                action: this.openProfile,
                hideFor: !loggedIn || verified
            },
            {
                label: Env.i18n.t('Settings'),
                icon: require('../../assets/svg/settings.svg'),
                action: this.openSettings
            },
            {
                label: Env.i18n.t('Logout'),
                icon: require('../../assets/svg/exit.svg'),
                action: this.logout,
                hideFor: !loggedIn
            },
        ];
        const helpEntries: DrawerEntry[] = [
            {
                label: Env.i18n.t('Help'),
                icon: require('../../assets/svg/support.svg'),
                action: this.openSupport
            },
        ];

        return (
            <MuiThemeProvider theme={MenuDrawer.theme}>
                <Drawer open={open} onClose={onClose}>
                    <HeaderContainer>
                        <div onClick={this.viewProfilePicture} style={{ cursor: 'pointer', marginRight: GRID_SIZE * 2, flexDirection: 'row', alignSelf: 'center' }}>
                            {this.renderProfilePicture()}
                        </div>
                        {loggedIn && (
                            <DisplayNameText>
                                {breakAtSpecialChars(title)}
                            </DisplayNameText>
                        )}
                    </HeaderContainer>
                    {this.renderSection(userEntries)}
                    {this.renderSection(settingsEntries)}
                    {this.renderSection(helpEntries)}
                    <PartnerButton onClick={LinkTo.partner}>
                        <Icon size={16} color={colors.white} src={require('../../assets/svg/heart_half.svg')} />
                        <DrawerButtonLabel>
                            {Env.i18n.t('BecomeAPartner')}
                        </DrawerButtonLabel>
                    </PartnerButton>
                </Drawer>
            </MuiThemeProvider>
        );
    }

    private renderSection(entries: DrawerEntry[]) {
        return (
            <Section>
                {entries.filter(({ hideFor }) => !hideFor).map(({ label, icon, action }) => (
                    <DrawerButton key={label} onClick={action}>
                        <Icon size={16} color={colors.grey_01} src={icon} />
                        <DrawerButtonLabel>
                            {label}
                        </DrawerButtonLabel>
                    </DrawerButton>
                ))}
            </Section>
        );
    }

    @bind
    private openChat() {
        this.props.onOpenModal('chatinbox');
    }

    @bind
    private openOrders() {
        this.props.onOpenModal(this.injected.api.account.loggedIn ? 'orders': 'registration');
    }

    @bind
    private openSupport() {
        this.props.onOpenModal('support');
    }

    @bind
    private openProfile() {
        this.props.onOpenModal('profile');
    }

    @bind
    private openSettings() {
        this.props.onOpenModal('settings');
    }

    @bind
    private editProfilePicture() {
        this.props.onOpenModal('editpicture');
    }

    @bind
    private openVoucher() {
        this.props.onOpenModal(this.injected.api.account.loggedIn ? 'vouchers' : 'registration');
    }

    @bind
    private openInvitations() {
        this.props.onOpenModal(this.injected.api.account.loggedIn ? 'invitations' : 'registration');
    }

    @bind
    private openContacts() {
        this.props.onOpenModal(this.injected.api.account.loggedIn ? 'contacts' : 'registration');
    }

    @bind
    private login() {
        this.props.onOpenModal('registration');
    }

    @bind
    private logout() {
        if (this.injected.api.account.loggedIn) {
            Alert.confirm(
                Env.i18n.t('Logout'),
                Env.i18n.t('LogoutMessage'),
                confirmed => {
                    if (confirmed) {
                        this.injected.api.waitFor(this.injected.auth.logout())
                            .then(() => {
                                Env.snackbar.success(Env.i18n.t('SuccessLogout'));
                            })
                            .catch(() => undefined);
                    }
                }
            );
        }
    }

    @bind
    private viewProfilePicture() {
        const { loggedIn, data } = this.injected.api.account;

        if (loggedIn) {
            if (data?.photoURL) {
                // TODO: re-activate for contacts: "edit" link in gallery?
                // const action = { icon: Icons.SecondaryEdit, action: this.editProfilePicture };

                MediaGalleryModal.open([{ url: data.photoURL }]);
            } else {
                this.editProfilePicture();
            }
        }
    }

    @bind
    private renderProfilePicture() {
        const { photoURL } = this.injected.api.account.data;
        return photoURL ? (
            <ProfilePictureContainer>
                <ProfileCachedImage src={photoURL} />
            </ProfilePictureContainer>
        ) : (
                <GreyProfileBackground>
                    <Icon size={56} color={colors.grey_03} src={require('../../assets/svg/social.svg')} />
                </GreyProfileBackground>
            );
    }
}
