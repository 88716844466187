import { bind } from 'decko';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import SearchBar from '../forms/SearchBar';
import FlatList, { FlatListProps } from './FlatList';

const ListContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ListFilterContainer = styled.div`
    margin-top: ${GRID_SIZE}px;
    padding: ${GRID_SIZE * 1.5}px ${SCREEN_PADDING}px;
`;

type Filter<T> = (item: T) => boolean;

interface Props<T> extends FlatListProps<T> {
    onSearch?: (input: string) => Filter<T> | undefined;
    onSearchClear?: () => void;
    /** If the `data` prop of this component is empty, the search bar is hidden by default, unless `forceSearchBar` is `true` */
    forceSearchBar?: boolean;
    ListNoResultComponent?: React.ReactElement;
    additionalHeaderContent?: React.ReactNode;
}

interface State<T> {
    filter?: Filter<T>;
}

@observer
export default class FilteredFlatList<T> extends React.PureComponent<Props<T>, State<T>> {
    public readonly state: State<T> = {};

    @computed
    private get hasSearchBar() {
        return this.props.forceSearchBar || (this.props.data.length > 0 && this.props.onSearch);
    }

    public render() {
        const { data, style, className, ListEmptyComponent, ListNoResultComponent, ...props } = this.props;
        let filteredData = data.slice();

        if (this.state.filter) {
            filteredData = filteredData.filter(this.state.filter);
        }

        /*
         * We don't set the SearchBar as ListHeaderComponent since it would disappear on an empty list.
         * Fixing the FlatList to show header (and footer) with empty state proved to be extremely difficult.
         * Due to this the SearchBar is sticky now, which might provide an even better UX.
         */
        return (
            <ListContainer className={className} style={style}>
                {this.hasSearchBar && (
                    <ListFilterContainer>
                        <SearchBar onInput={this.filterData} onClear={this.filterData}/>
                    </ListFilterContainer>
                )}
                {this.props.additionalHeaderContent && (
                    <div style={{ padding: `${GRID_SIZE}px` }}>
                        {this.props.additionalHeaderContent}
                    </div>
                )}
                <FlatList<T>
                    {...props}
                    data={filteredData}
                    ListEmptyComponent={(this.state.filter && ListNoResultComponent) || ListEmptyComponent}
                    style={{ display: 'flex', flex: 1 }}
                />
            </ListContainer>
        );
    }

    @bind
    private filterData(byInput?: string) {
        const { onSearch, onSearchClear } = this.props;

        if (onSearch) {
            const input = byInput?.trim();
            const filter = input ? onSearch(input) : undefined;

            if (!input && onSearchClear) {
                onSearchClear();
            }

            this.setState({ filter });
        }
    }
}
