import FilteredDataList from '../store/FilteredDataList';
import LatLng from '../types/LatLng';
import List from '../types/List';
import RestaurantEntry from '../types/models/RestaurantEntry';
import RestaurantsBackend from './RestaurantsBackend';

const STEP_SIZE_KM = 1;
const DEFAULT_RADIUS_KM = 10;
const MAX_RADIUS_KM = 100;
const MIN_RESTAURANT_NUMBER = 15; // until this number of restaurants is reached, `radiusKm` is at least `DEFAULT_RADIUS_KM`

export default class RestaurantStream {
    private _closed = false;
    private _limit: number;
    private _list: FilteredDataList<RestaurantEntry>;

    public constructor(list: FilteredDataList<RestaurantEntry>, maxRestaurants: number) {
        this._list = list;
        this._limit = maxRestaurants;
    }

    public close() {
        if (!this._closed) {
            this._closed = true;
            this._list.resolve();
        }
    }

    public loadByLocation(location: LatLng, radiusKm: number, currentLocation?: LatLng) {
        if (this._closed) {
            throw new Error('This stream has been closed, you need to create a new one.');
        }

        const createBy = 'initialData';
        const searchLocation = location;
        const userLocation = currentLocation || location;
        const maxRadius = Math.min(radiusKm || DEFAULT_RADIUS_KM, MAX_RADIUS_KM);
        const minRadius = Math.max(maxRadius, DEFAULT_RADIUS_KM);
        const loaded: List<true> = {};
        let count = 0;
        const loadRadius = async (radius: number) => {
            const currentMaxRadius = (count < MIN_RESTAURANT_NUMBER) ? minRadius : maxRadius;

            if (!this._closed && count < this._limit && radius <= currentMaxRadius) {
                const restaurants = await RestaurantsBackend.getRestaurantsByLocation(location, radius);
                const addRestaurants: RestaurantEntry[] = [];

                // check it again after the async fetching of restaurants, it could be changed while that was running
                if (this._closed) {
                    return;
                }

                restaurants.forEach(initialData => {
                    if (!loaded[initialData.key]) {
                        loaded[initialData.key] = true;
                        addRestaurants.push(new RestaurantEntry({ createBy, initialData }, searchLocation, userLocation));
                        count++;
                    }
                });

                this._list.add(...addRestaurants);

                loadRadius(radius + STEP_SIZE_KM);
            } else {
                this.close();
            }
        };

        loadRadius(STEP_SIZE_KM);
    }
}
