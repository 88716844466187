import React from 'react';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components';

import ContactEntity from '../../../../lib/src/types/models/ContactEntity';
import { GRID_SIZE } from '../../styles/base';
import ContactEntry from './ContactEntry';

const MAXHEIGHT = 100; // ~2 1/2 rows

const Chips = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: ${GRID_SIZE}px;
    max-height: ${MAXHEIGHT}px;
    overflow: auto;
`;

interface Props {
    contacts: ContactEntity[];
    toggleContact: (entity: ContactEntity) => void;
}

export default class ContactChips extends React.PureComponent<Props> {
    private static ID = 'contact-chips';

    private scrollView = React.createRef<HTMLDivElement>();

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.contacts !== this.props.contacts) {
            animateScroll.scrollToBottom({
                containerId: ContactChips.ID,
                duration: 300
            });
        }
    }

    public render() {
        return (
            <Chips ref={this.scrollView} id={ContactChips.ID}>
                {this.props.contacts.map((contact, index) => (
                    <ContactEntry.Small
                        key={index}
                        onPress={() => this.props.toggleContact(contact)}
                        contact={contact}
                    />
                ))}
            </Chips>
        );
    }
}
