import React from 'react';
import styled from 'styled-components';

import { theme } from '../../theme';
import { RegularText, TitleText } from '../text';

const EmptyStateContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 1em;

    ${theme.breakpoints.down('sm')} {
        flex-wrap: wrap;
    }
`;

interface Props {
    title: string;
    message: string;
}

export default class EmptyIndicator extends React.PureComponent<Props> {
    public render() {
        return (
            <EmptyStateContainer>
                <img src={require('../../assets/svg/empty_state_menu.svg')} />
                <div>
                    <TitleText>
                        {this.props.title}
                    </TitleText>
                    <RegularText>
                        {this.props.message}
                    </RegularText>
                </div>
            </EmptyStateContainer>
        );
    }
}
