import { CircularProgress } from '@material-ui/core';
import { bind } from 'decko';
import React from 'react';

import { GRID_SIZE } from '../../styles/base';
import { PrimarySmallButton } from '../button';
import Icon, { BUTTON_ICON_SIZE, IconType } from '../common/Icon';

interface Props {
    onPress: (event: React.MouseEvent<any, MouseEvent>) => Promise<any>;
    icon: IconType;
    size?: number;
    style?: React.CSSProperties;
    className?: string;
}

interface State {
    loading?: boolean;
}

export default class LoadingIconButton extends React.PureComponent<Props, State> {
    public readonly state: State = {};

    public render() {
        const { icon, size = BUTTON_ICON_SIZE, style, className, children } = this.props;

        return (
            <PrimarySmallButton onClick={this.handleClick} style={style} className={className}>
                <span style={{ display: 'flex', marginRight: GRID_SIZE / 2, height: size, width: size }}>
                    {this.state.loading ? (
                        <CircularProgress size={size * .75} />
                    ) : (
                        <Icon src={icon} size={size} />
                    )}
                </span>
                {children}
            </PrimarySmallButton>
        );
    }

    @bind
    private handleClick(event: React.MouseEvent<any, MouseEvent>) {
        this.setState({ loading: true });
        this.props.onPress(event).then(() => this.setState({ loading: false }));
    }
}
