import Env from '../../Env';
import GoogleMapsApi from '../../helpers/GoogleMapsApi';
import Locations from '../../helpers/Locations';
import RestaurantDetails, { RestaurantFilterConfig, } from '../../helpers/RestaurantDetails';
import AccountManager from '../../managers/AccountManager';
import ApiManager from '../../managers/ApiManager';
import CurrentLocation from '../../store/CurrentLocation';
import { DataListEntry } from '../../store/DataList';

export interface Teaser extends DataListEntry {
    priority: number;
    imageUrl?: string;
    videoUrl?: string;
    /** Can be a URL, a route or a serialized command */
    actionUrl?: string;
    daysOfWeek?: Array<number>;
    location?: {
        lat: number;
        lng: number;
        distance: number;
    };
}

type ApiType = ApiManager<AccountManager<ApiType>>;
type ActionHandler = (actionUrl: string, params?: any) => void;

export function getActiveTeaser(fromTeasers: Teaser[], userLocation?: CurrentLocation) {
    const dayOfWeek = new Date().getDay();

    return fromTeasers
        .filter(({ daysOfWeek, location, videoUrl, imageUrl }) =>
            (videoUrl || imageUrl) &&
            (daysOfWeek?.includes(dayOfWeek) !== false) &&
            (!location || (userLocation &&
                location.distance >= GoogleMapsApi.getDistance(Locations.normalize(location), userLocation.coordinate)
            ))
        )
        .sort((a, b) => b.priority - a.priority)
        .shift();
}

export async function handleTeaserAction(teaser: Teaser | undefined, api: ApiType, handleUrl: ActionHandler, handleRoute: ActionHandler) {
    const { actionUrl, imageUrl } = teaser || {};

    if (actionUrl) {
        Env.logEvent('teaser_pressed', { imageUrl });

        if (actionUrl.includes('//')) {
            handleUrl(actionUrl);
        } else if (actionUrl.includes(':')) {
            parseCommand(actionUrl, api, handleRoute);
        }
        else {
            handleRoute(actionUrl);
        }
    }
}


async function parseCommand(serializedCommand: string, api: ApiType, handleRoute: ActionHandler) {
    const [command, ...args] = serializedCommand.split(':');

    switch (command) {
        case 'set-filter':
            let filter: RestaurantFilterConfig | undefined;

            switch (args[0]) {
                case 'discount':
                    filter = RestaurantDetails.discount(api).find(({ key }) => key === 'Discounts');
                    break;
            }

            if (filter) {
                api.filters = {
                    property: [filter]
                };
            }
            break;

        case 'open-restaurant':
            const restaurant = await api.getRestaurant(args[0]);

            if (restaurant) {
                handleRoute('Details', { restaurant });
            }
            break;
    }
}