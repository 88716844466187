import Constants from '../Constants';
import Env from '../Env';
import List from '../types/List';

const DEFAULT_ERROR = {
    message: 'HTTP request not ok',
    code: 500
};

export class HttpRequestError extends Error {
    public static readonly NOT_ACCEPTABLE = 406;
    public static readonly CONFLICT = 409;
    public static readonly GONE = 410;

    public readonly code: number;

    // TODO: rather copy `code` and `message` from error and store `status` in extra field?
    public static async fromResponse(response: Response, request?: string) {
        const error = await response.text();

        console.warn(`${request}\n${error}`);

        return new this(error, response.status);
    }

    constructor(message = DEFAULT_ERROR.message, code = DEFAULT_ERROR.code) {
        super(message);

        this.code = code;
    }

    toString() {
        return `${this.code}: ${this.message}`;
    }
}

export default class HttpRequest {
    static async getAuthHeaders() {
        const currentUser = Env.firebase.auth().currentUser;
        const token = currentUser
            ? await currentUser.getIdToken()
            : '';

        return {
            Authorization: `Bearer ${token}`
        };
    }

    static get(route: string) {
        return this.request(route, 'GET', undefined);
    }

    static put(route: string, params?: object) {
        return this.request(route, 'PUT', params);
    }

    static post(route: string, params?: object) {
        return this.request(route, 'POST', params);
    }

    static patch(route: string, params?: object) {
        return this.request(route, 'PATCH', params);
    }

    static delete(route: string, params?: object) {
        return this.request(route, 'DELETE', params);
    }

    static async postBlob(route: string, blob: ArrayBuffer) {
        const headers = {
            'Content-Type': 'application/octet-stream'
        };

        return this.fetch(route, 'POST', headers, blob);
    }

    static async fetch(route: string, method: string, requestHeaders: List<string>, body?: string | ArrayBuffer) {
        const headers = {
            ...(await this.getAuthHeaders()),
            ...requestHeaders
        };
        const options = Object.assign({ method, headers }, body ? { body } : null);
        const host = route.match(/^https?:\/\//i) ? '' : Constants.BACKEND_HOST;
        const response = await fetch(`${host}${route}`, options);

        if (!response.ok) {
            throw await HttpRequestError.fromResponse(response, `${method} ${route}`);
        }

        return response.text();
    }

    private static async request(route: string, method: string, params?: object) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            dataType: 'json'
        };
        const body = params ? JSON.stringify(params) : undefined;

        return this.fetch(route, method, headers, body);
    }
}
