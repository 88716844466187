import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { GRID_SIZE } from '../../styles/base';
import Image from '../common/Image';
import { TitleText } from '../text';
import RestaurantMeta from './RestaurantMeta';

interface HighlightPremium {
    premium?: boolean;
}

const Row = styled.div<HighlightPremium>`
    background-color: ${props => props.premium ? colors.navy_blue : colors.white};
    border-bottom-left-radius: ${GRID_SIZE / 2}px;
    border-bottom-right-radius: ${GRID_SIZE / 2}px;
    display: flex;
    flex-direction: row;
`;

const StyledTitleText = styled(TitleText) <HighlightPremium>`
    color: ${props => props.premium ? colors.white : colors.matte_black};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;


const InfoImage = styled(Image)`
    flex-shrink: 0;
    height: ${GRID_SIZE * 5}px;
    margin-right: ${GRID_SIZE * 2.5}px;
    object-fit: contain;
    width: ${GRID_SIZE * 5}px;
`;

const InfoBox = styled.div`
    min-width: 0;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    restaurant: RestaurantEntry;
    hidePremiumHighlight?: boolean;
}

@observer
export default class RestaurantInfo extends React.PureComponent<Props> {
    public render() {
        const { restaurant, hidePremiumHighlight, ...props } = this.props;
        const isPremium = !hidePremiumHighlight && restaurant.isPremium;

        return (
            <Row premium={isPremium} {...props}>
                <InfoImage alt={restaurant.name} src={restaurant.logo?.url || ''} />
                <InfoBox>
                    <StyledTitleText premium={isPremium}>
                        {restaurant.name}
                    </StyledTitleText>
                    <RestaurantMeta restaurant={restaurant} displayOpeningTime={true} />
                </InfoBox>
            </Row>
        );
    }
}
