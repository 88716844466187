import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import {
    DatePicker as MuiDatePicker,
    DateTimePicker as MuiDateTimePicker,
    TimePicker as MuiTimePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { bind } from 'decko';
import moment, { Moment } from 'moment';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Validate, { Validation } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import Alert from '../../helpers/Alert';

interface Props {
    preselected?: Date;
    validate?: (date: Date) => Validation;
    onChange: (date: Date) => void;
    onCancel?: () => void;
    only?: 'date' | 'time';
    min?: Date;
}

interface State {
    open: boolean;
    selected: Date;
}

export default class DatePicker extends React.PureComponent<Props, State> {
    public static readonly theme = createMuiTheme({
        palette: {
            primary: {
                main: colors.teal_900 // header, buttons, highlights
            },
            secondary: {
                main: colors.white // header tab indicator
            },
            text: {
                primary: colors.matte_black, // general text
                hint: colors.grey_03 // weekday captions, disabled days/years/etc.
            }
        }
    });

    private minDate = moment(this.props.min);
    private initialDate = this.props.preselected || this.minQuarterHour();

    public readonly state: State = {
        open: false,
        selected: this.initialDate
    };

    @bind
    public open() {
        this.setState({ open: true });
    }

    public render() {
        const Picker = this.getPickerComponent();

        return (
            <MuiThemeProvider theme={DatePicker.theme}>
                <Picker
                    open={this.state.open}
                    value={this.state.selected}
                    onChange={this.handleConfirm}
                    onClose={this.handleCancel}
                    disablePast={true}
                    minDate={this.minQuarterHour()}
                    maxDate={moment().add(2, 'weeks').toDate()}
                    showTodayButton={true}
                    ampm={false}
                    todayLabel={Env.i18n.t('Today')}
                    okLabel={Env.i18n.t('Ok')}
                    cancelLabel={Env.i18n.t('Cancel')}
                    maxDateMessage={Env.i18n.t('ErrorDateLaterTwoWeeks')}
                    minDateMessage={Env.i18n.t('ErrorDatePast')}
                    style={{ display: 'none' }}
                />
            </MuiThemeProvider>
        );
    }

    private getPickerComponent() {
        switch (this.props.only) {
            case 'date':
                return MuiDatePicker;
            case 'time':
                return MuiTimePicker;
            default:
                return MuiDateTimePicker;
        }
    }

    @bind
    private handleConfirm(date: MaterialUiPickersDate) {
        const selected = (date as Moment).toDate();

        this.setState({ selected, open: false }, () => {
            const { validate, onChange } = this.props;
            let validation = Validate.date(selected);

            if (validation.valid && validate) {
                validation = validate(selected);
            }

            if (!validation.valid) {
                Alert.notify(Env.i18n.t('InvalidDateTitle'), validation.message, true, this.open);
            } else {
                if (validation.message) {
                    Alert.notify(Env.i18n.t('InvalidDateInfo'), validation.message);
                }

                onChange(selected);
            }
        });
    }

    @bind
    private handleCancel() {
        this.setState({
            open: false,
            selected: this.initialDate
        });

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    private minQuarterHour() {
        return moment(this.minDate)
            .add(15 - (this.minDate.minute() % 15), 'minutes')
            .startOf('minute')
            .toDate();
    }
}
