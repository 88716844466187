import { bind } from 'decko';

import Env from '../Env';
import LatLng from '../types/LatLng';
import { Restaurant } from '../types/lunchnow';
import RestaurantEntry, { InitialRestaurantResponseModel } from '../types/models/RestaurantEntry';
import Cache from './Cache';
import HttpRequest from './HttpRequest';

export default abstract class RestaurantsBackend {
    private static cache = new Cache<RestaurantEntry>(1, 'hour');

    @bind
    public static getRestaurantById(restaurantId: string, location?: LatLng, userLocation?: LatLng) {
        return this.resolveRestaurantPromise(this.cache.get(restaurantId, () => this.loadRestaurantById(restaurantId)), location, userLocation);
    }

    @bind
    public static getRestaurantByRoutingName(routingName: string, location?: LatLng, userLocation?: LatLng) {
        return this.resolveRestaurantPromise(
            this.cache.find(entry => entry.data?.routingName === routingName, () => this.loadRestaurantByRoutingName(routingName)),
            location,
            userLocation
        );
    }

    @bind
    public static async getRestaurantsByLocation(location: LatLng, radius: number) {
        const restaurantsJson = await HttpRequest.post('/api/restaurants/loadByLocation', { location, radius });

        return JSON.parse(restaurantsJson) as InitialRestaurantResponseModel[];
    }

    @bind
    private static async resolveRestaurantPromise(promise: Promise<RestaurantEntry | undefined>, location?: LatLng, userLocation?: LatLng) {
        try {
            const restaurantEntry = await promise;

            restaurantEntry?.updateDistance(location, userLocation);

            return restaurantEntry;
        } catch (error) {
            return;
        }
    }

    @bind
    private static async loadRestaurantById(id: string) {
        const restaurantEntry = new RestaurantEntry({
            createBy: 'key',
            key: id
        });

        await restaurantEntry.getRestaurantData();

        return restaurantEntry;
    }

    @bind
    private static async loadRestaurantByRoutingName(routingName: string) {
        const restaurantSnap = await Env.partnerFirebase.firestore().collection('restaurants')
            .where('routingName', '==', routingName).limit(1).get()
            .then(querySnapshot => querySnapshot.docs[0]);
        const restaurant = restaurantSnap.data() as Restaurant;

        return new RestaurantEntry({
            createBy: 'data',
            key: restaurantSnap.id,
            restaurant
        });
    }
}
