import List from '../types/List';

export function sanitizeUrl(url: string) {
    let fullUrl = url;

    if (!fullUrl.startsWith('http')) {
        fullUrl = fullUrl.replace(/^\/*/, 'http://');
    }

    return fullUrl;
}

export function createUrl(path: string, params?: object) {
    let url = path;

    if (params) {
        url += '?' + queryString(params);
    }

    return url;
}

export function queryString(params: object) {
    return Object.entries(params)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
        .join('&');
}

export function queryParams(query: string): List<string> {
    const map = {};

    if (query) {
        query.split('&').forEach(token => {
            const [ key, ...values ] = token.split('=');

            if (values.length) {
                map[key] = decodeURI(values.join('='));
            }
        });
    }

    return map;
}

export function parseUrl(url: string) {
    const [ protocol, urlWithoutProtocol ] = url.split('//');
    const [ urlWithoutHash, hash ] = urlWithoutProtocol.split('#');
    const [ urlWithoutQuery, query ] = urlWithoutHash.split('?');
    const [ host, ...path ] = urlWithoutQuery.split('/');
    const params = queryParams(query);

    return { protocol, host, path, params, hash };
}

// TODO: the hashbang (#!) can be removed later, as it was depracted by Google
export function deeplinkRoute() {
    const url = new URL(location.href.replace('/#!', ''));

    return `${url.pathname.replace('/search/', '')}${url.search.replace('?', '')}`;
}
