import React from 'react';

import colors from '../../../../lib/src/styles/colors';
import { DiskButton } from '../common/IconButton';

interface Props {
    icon: string;
    size: number;
    border: number;
    diskColor?: string;
    color?: string;
}

export default class StatusMarker extends React.PureComponent<Props> {
    public render() {
        const { icon, size, border, diskColor, color } = this.props;

        return (
            <DiskButton
                color={color || colors.white}
                diskColor={diskColor || colors.matte_black}
                diskSize={size}
                icon={icon}
                size={size * 0.9}
                style={{
                    border: `${border}px solid ${colors.white}`,
                    bottom: border * -1,
                    position: 'absolute',
                    right: border * -1
                }}
            />
        );
    }
}
