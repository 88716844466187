import { inject, observer } from 'mobx-react';
import React from 'react'
import { bind } from 'decko';

import { InjectedApiProps } from '../../Api';
import { InjectedChatProps } from '../../../../lib/src/managers/ChatManager';
import ContactEntity from '../../../../lib/src/types/models/ContactEntity';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import ScreenModal, { ScreenModalProps } from '../common/ScreenModal';
import Env from '../../../../lib/src/Env';
import { GRID_SIZE } from 'src/styles/base';
import ContactList from '../contacts/ContactList';
import Arrays from '../../../../lib/src/helpers/Arrays';
import Constants from '../../../../lib/src/Constants';
import { ErrorText } from '../text';
import Checkbox from '../forms/Checkbox';
import { PrimaryButton } from '../button';
import InviteDatePicker from '../invitations/InviteDatePicker';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import { ChatMessageType } from '../../../../lib/src/types/models/Chat';

interface Props extends ScreenModalProps {
    restaurant?: RestaurantEntry;
    onNavigate: (route: string, params?: string[]) => void;
}

interface State {
    selection: ContactEntity[];
    fadeOutUnselectedContacts: boolean;
    showMaxAttendeesWarning: boolean;
    sendInvitation: boolean;
}

@inject('api', 'chatManager', 'invitationDraft')
@observer
export default class ShareRestaurantModal extends React.PureComponent<Props, State> {
    private datePickerRef = React.createRef<InviteDatePicker>();

    public readonly state: State = {
        selection: [],
        fadeOutUnselectedContacts: false,
        showMaxAttendeesWarning: false,
        sendInvitation: false
    }

    private get injected() {
        return this.props as Props & InjectedApiProps & InjectedChatProps & InjectedInvitationDraftProps;
    }

    private renderCta() {
        return (
            <PrimaryButton onClick={this.submit}>
                {Env.i18n.t('Send')}
            </PrimaryButton>
        )
    }

    render() {
        const { modalRef } = this.props;
        const { selection, fadeOutUnselectedContacts, showMaxAttendeesWarning } = this.state;
        const { contacts } = this.injected.api.account;
        const padding = `0 ${GRID_SIZE * 2}px ${GRID_SIZE * 10}px`;

        return (
            <ScreenModal
                ref={modalRef}
                title={Env.i18n.t('CreateInvitation')}
                style={{ padding }}
                FooterComponent={this.renderCta()}
            >
                {showMaxAttendeesWarning && (
                    <ErrorText>
                        {Env.i18n.t('MaxAttendeesWarning', { count: Constants.MAX_ATTENDEES - 1 })}
                    </ErrorText>
                )}

                <ContactList
                    contacts={contacts.list}
                    onItemPress={this.toggleContactSelection}
                    selection={selection.map(contact => contact.key)}
                    fadeOutUnselectedContacts={fadeOutUnselectedContacts}
                    additionalHeaderContent={(
                        // TODO: adjust style
                        <Checkbox
                            checked={this.state.sendInvitation}
                            onToggle={(checked) => this.setState({ sendInvitation: checked })}
                        >
                            {Env.i18n.t('CreateInvitation')}
                        </Checkbox>
                    )}
                />

                <InviteDatePicker ref={this.datePickerRef} onConfirm={this.applySelectionAndContinue} />
            </ScreenModal>
        );
    }

    @bind
    private async submit() {
        const { invitationDraft } = this.injected;

        if (this.state.sendInvitation) {
            const draft = invitationDraft.create('ShareRestaurant');

            draft.restaurant = this.props.restaurant;

            invitationDraft.setAttendees(Arrays.clean([this.injected.api.account.currentContactPerson, ...this.state.selection]));

            this.datePickerRef.current?.open()
        } else if (this.injected.restaurant?.key) {
            await this.shareAndNavigate('restaurant', this.injected.restaurant.key);
        }
    }

    @bind
    private toggleContactSelection(contact: ContactEntity) {
        this.setState(state => {
            const newSelection = Arrays.toggle(state.selection.slice(), contact);
            const selection = (newSelection.length > Constants.MAX_ATTENDEES - 1) ? state.selection : newSelection;
            const showMaxAttendeesWarning = (newSelection.length >= Constants.MAX_ATTENDEES - 1);
            const fadeOutUnselectedContacts = (selection.length >= Constants.MAX_ATTENDEES - 1);

            return { selection, showMaxAttendeesWarning, fadeOutUnselectedContacts };
        });
    }

    @bind
    private async applySelectionAndContinue() {
        const { invitationDraft } = this.injected;

        this.props.modalRef?.current?.close();

        const invitation = await invitationDraft.complete();

        await this.shareAndNavigate('invitation', invitation.key);
    }

    private async shareAndNavigate(type: ChatMessageType, key: string) {
        const chatKey = await this.injected.chatManager.sendContentMessage(
            this.injected.api.account.flattenContactEntites(this.state.selection).map(contactPerson => contactPerson.key),
            type,
            key,
        );

        this.props.onNavigate('chatmessages', [chatKey]);
    }
}
