import { bind } from 'decko';
import { action, autorun, computed, observable } from 'mobx';
import moment from 'moment';
import semver from 'semver';

import Constants from '../Constants';
import Env from '../Env';
import Locations from '../helpers/Locations';
import { RestaurantFilterConfig, RestaurantTagConfig } from '../helpers/RestaurantDetails';
import RestaurantsBackend from '../helpers/RestaurantsBackend';
import RestaurantStream from '../helpers/RestaurantStream';
import { logError } from '../helpers/Validate';
import CurrentLocation from '../store/CurrentLocation';
import DataList, { DataListEntry } from '../store/DataList';
import RestaurantList from '../store/RestaurantList';
import LatLng from '../types/LatLng';
import List from '../types/List';
import { MealType as BaseMealType, VatType } from '../types/lunchnow';
import { OrderItem } from '../types/models/Order';
import RestaurantEntry, { MealEntry, RestTime, Tag } from '../types/models/RestaurantEntry';
import { Teaser } from '../types/models/Teaser';
import AccountManager from './AccountManager';

export interface FilterLists {
    text?: RestaurantFilterConfig[];
    price?: RestaurantFilterConfig[];
    property?: RestaurantFilterConfig[];
}

interface VersionInfo {
    requiredVersion: string;
    maintenance?: boolean;
}

export interface MealType extends BaseMealType {
    baseName: string;
}

export type Menu = Array<{ baseName: string, name: string, entries: List<MealEntry>}>;

type AccountType = AccountManager<ApiManager<AccountType>>;

export default abstract class ApiManager<A extends AccountType> {
    public readonly ifReady: Promise<any>;

    @observable
    private _isInitialized = false;

    @observable
    private _accessDenied = false;

    @observable
    protected _account: A;

    private _startOfTomorrow = moment().add(1, 'day').startOf('day');
    private _schedule = Promise.resolve<any>(undefined);
    private _unsubscribeVersionCheck?: () => void;
    private _dayTimeout: any;

    @observable
    private _showIndicator = false;

    @observable
    private _location = new CurrentLocation(Locations.normalize({}));

    private _geoStream?: RestaurantStream;
    private _restaurants: RestaurantList = new RestaurantList(this);
    private _tags = new DataList<Tag>();
    private _restTimes = new DataList<RestTime>();
    private _vatTypes = new DataList<VatType & DataListEntry>();
    private _teasers = new DataList<Teaser>();

    @observable
    private _mealTypes: List<MealType> = {};

    @observable
    private _filters: FilterLists = {
        text: [],
        price: [],
        property: []
    };

    @observable
    private _tagFilter?: RestaurantTagConfig;

    @observable
    public showLunchList = true;

    @computed
    public get account() {
        return this._account;
    }

    @computed
    public get isInitialized() {
        return this._isInitialized;
    }

    @computed
    public get isAccessible() {
        return !this._accessDenied;
    }

    @computed
    public get isWaiting() {
        return this._showIndicator;
    }

    @computed
    public get location() {
        return this._location;
    }

    @computed
    public get restaurants() {
        return this._restaurants;
    }

    public get tags() {
        return this._tags;
    }

    @computed
    public get mealTypes() {
        return this._mealTypes;
    }

    @computed
    public get vatTypes() {
        return this._vatTypes;
    }

    public get restTimes() {
        return this._restTimes;
    }

    @computed
    public get teasers() {
        return this._teasers;
    }

    @computed
    public get filters() {
        return this._filters;
    }

    public set filters(filters: FilterLists) {
        this._filters = filters;
    }

    @computed
    public get tagFilter() {
        return this._tagFilter;
    }

    @computed
    public get filterCount() {
        return (this.filters.property?.length || 0)
            + Number(this.filters.text?.length! > 0)
            + Number(this.filters.price?.length! > 0);
    }

    @computed
    public get lunchMealTypeKeys() {
        return Object.entries(this.mealTypes)
            .filter(([ _, type ]) => Constants.LUNCH_MEAL_TYPES.includes(type.baseName))
            .map(([ typeKey ]) => typeKey);
    }

    constructor(account: A, waitFor: Promise<any> = Promise.resolve()) {
        this._account = account;
        account.setApi(this);

        // this reaction is never disposed
        autorun(() => {
            this.restaurants.setRestaurantFilters('text', 'every', this.filters.text);
            this.restaurants.setRestaurantFilters('price', 'some', this.filters.price);
            this.restaurants.setRestaurantFilters('properties', 'every', this.filters.property);
            this.restaurants.setRestaurantFilters('tag', 'every', this.tagFilter && [ this.tagFilter ]);
        });

        // this listener is never disposed
        this.addForegroundListener(this.checkDay, true);

        this.ifReady = waitFor.then(this.initialize);
    }

    public async getRestaurant(idOrRoutingName: string, userLocation?: LatLng) {
        let restaurant = await RestaurantsBackend.getRestaurantById(idOrRoutingName, this._location.coordinate, userLocation);

        if (!restaurant) {
            restaurant = await RestaurantsBackend.getRestaurantByRoutingName(idOrRoutingName, this._location.coordinate, userLocation);
        }

        return restaurant;
    }

    public setLocation(location: CurrentLocation, radiusKm = 10, origin?: LatLng) {
        if (this._geoStream) {
            this._geoStream.close();
        }

        this._location = CurrentLocation.copy(location);
        this._restaurants.reset();
        this._geoStream = new RestaurantStream(this._restaurants, 100);
        this._geoStream.loadByLocation(location.coordinate, radiusKm, origin);
    }

    public schedule<T>(callback: () => Promise<T>) {
        this._schedule = this._schedule.then(callback);

        return this._schedule as Promise<T>;
    }

    // FIXME: somehow `this.schedule` isn't working with 3rd party sign-in anymore... (because of rnfirebase v6???)
    /**
     * Displays the `GlobalIndicator` while the given `promise` is executed.
     */
    @action
    public async waitFor<T>(promise: Promise<T> | (() => Promise<T>)) {
        // return this.schedule(async () => {
            this._showIndicator = true;

            try {
                if (typeof promise === 'function') {
                    promise = promise();
                }

                return await promise;
            } catch (error) {
                throw error;
            } finally {
                this._showIndicator = false;
            }
        // });
    }

    @bind
    public getRestTimeMessage(restaurant: RestaurantEntry) {
        return (this.restTimes.get(restaurant.data?.restTime?.reference.id)?.translations || {})[Env.i18n.currentLocale().slice(0, 2)];
    }

    @bind
    public groupMealsByType(meals: MealEntry[]) {
        const menu: Menu = [];
        const mealsByType: List<List<MealEntry>> = {};

        meals.forEach(meal => {
            const mealType = this.mealTypes[meal.typeKey || '']?.baseName;

            if (mealType) {
                mealsByType[mealType] = mealsByType[mealType] || {};
                mealsByType[mealType]![meal.key] = meal;
            }
        });

        Object.values(this.mealTypes).forEach(type => {
            if (mealsByType[type.baseName] && Object.values(mealsByType[type.baseName]).length) {
                menu.push({ baseName: type.baseName, name: type.name, entries: mealsByType[type.baseName] });
            }
        });

        return menu;
    }

    /**
     * @returns Disposer function
     */
    public abstract addForegroundListener(listener: (foreground: boolean) => void, fireImmediately?: boolean): () => void;

    /**
     * This data is not refreshed until the app is restarted.
     */
    @bind
    protected async setupStaticData(): Promise<any> {
        const languageCode = Env.currentLanguageCode();
        const db = Env.partnerFirebase.firestore();

        return Promise.all([
            db.collection('tags').orderBy('popularity', 'desc').limit(100).get().then(
                snapshot => this._tags.set(
                    ...snapshot.docs
                        .map(doc => Object.assign({}, doc.data(), { key: doc.id }) as Tag)
                        // FIXME: temporary until we have better taste/tag handling! Use addQuerySnapshotChildren then
                        .filter(({ name }) => ![ 'vegan', 'vegetarian', 'halal', 'kosher' ].includes(name))
                )
            ),
            db.collection('meal_types').orderBy('order').get().then(querySnapshot =>
                querySnapshot.forEach(snapshot => {
                    const { order, translations, ...mealType } = snapshot.data();

                    mealType.baseName = mealType.name;
                    mealType.name = translations[languageCode] || mealType.name;
                    this._mealTypes[snapshot.id] = mealType as MealType;
                })
            ),
            db.collection('rest_time_types').get().then(snapshot =>
                this._restTimes.addQuerySnapshotChildren(snapshot, true)
            ),
            db.collection('vat_types').get().then(snapshot => {
                this._vatTypes.addQuerySnapshotChildren(snapshot, true)
            }),
            new Promise(resolve =>
                // listener is never disposed
                Env.firebase.firestore().collection('teasers').onSnapshot(snapshot => {
                    this._teasers.addQuerySnapshotChildren(snapshot, true);
                    resolve(null);
                })
            ),
        ]);
    }

    @bind
    protected async initialize() {
        if (!this._isInitialized) {
            await Promise.all([
                this.checkVersion(),
                this.setupStaticData()
            ]);

            this._isInitialized = true;
        }
    }

    protected denyAccess(title: string, message: string, buttonLabel: string, buttonAction?: () => any) {
        if (!this._accessDenied) {
            this._accessDenied = true;
            Env.alert(
                title,
                message,
                [{
                    label: buttonLabel,
                    action: async () => {
                        if (this._accessDenied) {
                            if (buttonAction) {
                                await buttonAction();
                            }

                            this._accessDenied = false;
                            // Call recursively to ensure the app never gets past this point
                            this.denyAccess(title, message, buttonLabel, buttonAction);
                        }
                    }
                }],
                false
            );
        }
    }

    protected handleMaintenance() {
        this.denyAccess(Env.i18n.t('MaintenanceTitle'), Env.i18n.t('MaintenanceMessage'), Env.i18n.t('TryAgain'));
    }

    protected abstract handleOutdatedVersion(): void;

    protected abstract restart(): void;

    @bind
    private checkVersion() {
        const majorVersion = String(semver.major(Env.info.version));

        if (this._unsubscribeVersionCheck) {
            this._unsubscribeVersionCheck();
        }

        this._unsubscribeVersionCheck = Env.firebase.firestore().collection('version_infos').doc(majorVersion).onSnapshot(snapshot => {
            try {
                const versionInfo = snapshot.data() as VersionInfo | undefined;

                if (versionInfo?.maintenance) {
                    return this.handleMaintenance();
                }

                if (!versionInfo?.requiredVersion) {
                    throw new Error('Bad version');
                }

                const semRequiredVersion = semver.coerce(versionInfo.requiredVersion);

                if (!semRequiredVersion) {
                    throw new Error('Bad version');
                } else if (semver.gt(semRequiredVersion, Env.info.version)) {
                    return this.handleOutdatedVersion();
                }
            } catch (error) {
                logError('Api.checkVersion', error);
            }

            this._accessDenied = false;
        });
    }

    @bind
    private checkDay(isActive: boolean) {
        clearTimeout(this._dayTimeout);

        if (isActive) {
            const msLeftToday = this._startOfTomorrow.diff(Date.now());

            if (msLeftToday > 0) {
                this._dayTimeout = setTimeout(this.restart, msLeftToday);
            } else {
                this.restart();
            }
        }
    }

    /**
     * Returned prices are in cents.
     */
    public getItemVat(item: OrderItem, isTakeAway?: boolean) {
        const vatType = this.vatTypes.get(item.vatType!);

        return isTakeAway ? vatType?.takeAway : vatType?.default;
    }

    public toggleTag(tag?: RestaurantTagConfig) {
        this.showLunchList = false;
        this._tagFilter = (!tag || this._tagFilter === tag) ? undefined : tag;
    }

    @bind
    public toggleLunchList() {
        this.showLunchList = !this.showLunchList;
        this._tagFilter = undefined;
    }
}
