import { Link } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import LinkTo from '../../helpers/LinkTo';
import Icon from '../common/Icon';

// @ts-ignore ts(2615)
const LinkIcon = styled(Icon).attrs({
    src: require('../../assets/svg/ic_primary_openinnewwindow.svg'),
    color: colors.navy_blue
})`
    margin-right: 12px;
`;

interface Props {
    restaurant: RestaurantEntry;
}

export default class WebsiteLink extends React.PureComponent<Props> {
    public render() {
        const url = this.props.restaurant.data?.contact?.web || '';

        return url ? (
            <Link color="inherit" style={{ margin: '20px 0' }} onClick={() => LinkTo.url(url, '_blank')}>
                <LinkIcon />
                {Env.i18n.t('GoToWebsite')}
            </Link>
        ) : null;
    }
}
