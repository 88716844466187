import Share from '@material-ui/icons/Share';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import ContactEntity from '../../../../lib/src/types/models/ContactEntity';
import { InjectedAccountProps } from '../../Account';
import { SCREEN_PADDING } from '../../styles/base';
import ActionButton from '../common/ActionButton';
import Modal, { ModalProps } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import TabViewPager from '../common/TabViewPager';
import { EmptyContactsList, EmptyGroupsList } from '../social/EmptyList';
import ContactsTab from './ContactsTab';

@inject('account', 'invitationDraft')
@observer
export default class Contacts extends Modal {
    private actionButtonRef = React.createRef<ActionButton>();
    private options = [
        {
            label: Env.i18n.t('AddGroup'),
            icon: require('../../assets/svg/friend.svg'),
            action: this.addNewGroup
        },
        {
            label: Env.i18n.t('SearchContact'),
            icon: require('../../assets/svg/add_friend.svg'),
            action: this.searchContact
        },
        {
            label: Env.i18n.t('ConnectUser'),
            icon: Share,
            action: this.shareMeAsContact
        }
    ];

    private get injected() {
        return this.props as ModalProps & InjectedAccountProps & InjectedInvitationDraftProps;
    }

    protected async hydrateParams(params: string[]) {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.account.loggedIn).get();
    }

    public render() {
        const { contacts, contactGroups } = this.injected.account;

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={Env.i18n.t('Contacts')} onClose={this.close} />
                <FullSizeContent>
                    <SafariFix.ColumnStretchWrapper>
                        <TabViewPager
                            height="full"
                            tabs={[
                                Env.i18n.t('AllContacts', { count: contacts.list.length }),
                                Env.i18n.t('Groups', { count: contactGroups.list.length })
                            ]}
                        >
                            {this.createTab(contacts.list, EmptyContactsList)}
                            {this.createTab(contactGroups.list, EmptyGroupsList)}
                        </TabViewPager>
                    </SafariFix.ColumnStretchWrapper>
                    <ActionButton
                        ref={this.actionButtonRef}
                        options={this.options}
                        closeOnSelect={true}
                        style={{ margin: SCREEN_PADDING }}
                    />
                </FullSizeContent>
            </Screen>
        );
    }

    private createTab(contacts: ContactEntity[], ListEmptyComponent: typeof EmptyContactsList) {
        return (
            <ContactsTab
                contacts={contacts}
                onNavigate={this.redirectTo}
                onInvite={this.inviteContact}
                ListEmptyComponent={(
                    <ListEmptyComponent onPress={this.openContactOptions}/>
                )}
            />
        );
    }

    @bind
    private inviteContact(contact?: ContactEntity) {
        const { invitationDraft, account } = this.injected;

        invitationDraft.create(this.props.navigation.getHash());

        if (contact) {
            invitationDraft.setAttendees(Arrays.clean([ account.currentContactPerson, contact ]));
        }

        Env.logEvent('meetup_from_contactlist');
        this.redirectTo('invitationrestaurant');
    }

    @bind
    private openContactOptions() {
        this.actionButtonRef.current?.open();
    }

    @bind
    private addNewGroup() {
        const { account } = this.injected;

        account.discardGroupDraft();

        const { groupDraft } = account;

        groupDraft.isFavorite = false;
        groupDraft.key = undefined;

        this.redirectTo('editgroup');
    }

    @bind
    private searchContact() {
        this.redirectTo('addcontacts');
    }

    @bind
    private async shareMeAsContact() {
        const { data } = this.injected.account;

        if (data.connectionLink) {
            const name = data.displayName || '';
            const url = data.connectionLink;
            const code = data.connectionCode;

            Env.share(Env.i18n.t('ShareContactMessage', { name, url, code }));
        } else {
            Env.snackbar.error(Env.i18n.t('ErrorUnknown'));
        }
    }
}
