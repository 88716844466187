import Expand from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import Icon, { IconType } from '../common/Icon';
import { RegularText } from '../text';

export const ICON_COLOR = colors.teal_900;
export const ICON_SIZE = 16;

const Container = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;

// @ts-ignore ts(2615)
const Label = styled(RegularText)`
    color: ${ICON_COLOR};
    margin: 0 ${GRID_SIZE}px;
`;

const ExpandIconContainer = styled.div<{ expanded?: boolean }>`
    ${props => props.expanded && css`
        transform: rotate(180deg);
    `}
`;

const ExpandIcon = styled(Expand).attrs({
    htmlColor: ICON_COLOR
})`
    display: block !important; // needed so baseline alignment doesn't interfere with the transformation
`;

interface Props {
    label: string;
    icon: IconType;
    expanded?: boolean;
    onPress?: () => void;
}

export default class LinkButton extends React.PureComponent<Props> {
    public render() {
        const { label, icon, expanded, onPress } = this.props;

        return (
            <Container onClick={onPress}>
                <Icon src={icon} color={ICON_COLOR} size={ICON_SIZE} />
                <Label>
                    {label}
                </Label>
                {expanded !== undefined && (
                    <ExpandIconContainer expanded={expanded}>
                        <ExpandIcon />
                    </ExpandIconContainer>
                )}
            </Container>
        );
    }
}
