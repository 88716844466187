import { Fab } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';

interface Props {
    onPress?: () => void;
    direction?: 'down' | 'up';
    style?: React.CSSProperties;
    className?: string;
}

export default class ToEndButton extends React.PureComponent<Props> {
    public render() {
        const { onPress, direction, style, className, children } = this.props;
        const Icon = direction === 'down' ? KeyboardArrowDown : KeyboardArrowUp;

        return (
            <div style={{ height: 0, position: 'relative', ...style }} className={className}>
                <div style={{ bottom: 0, position: 'absolute', right: 0 }}>
                    <Fab onClick={onPress} size="large" style={{ backgroundColor: colors.white }}>
                        <Icon style={{ height: GRID_SIZE * 5, width: '100%' }} />
                    </Fab>
                    {children}
                </div>
            </div>
        );
    }
}
