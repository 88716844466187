import { bind } from 'decko';
import React from 'react';
import styled from 'styled-components';

import { queryParams } from '../../../../lib/src/helpers/url';
import colors from '../../../../lib/src/styles/colors';
import List from '../../../../lib/src/types/List';

const ExternalFrame = styled.iframe.attrs({
    sandbox: 'allow-forms allow-popups allow-same-origin allow-scripts'
})<{ hidden?: boolean }>`
    background: ${colors.white};
    border: none;
    height: ${props => props.hidden ? 0 : '100%'};
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

interface Props {
    url?: string;
    onReturn: (params: List<string>) => void;
}

interface State {
    showExternalUrl?: boolean;
}

export default class RedirectFrame extends React.PureComponent<Props, State> {
    public readonly state: State = {};

    private frameRef = React.createRef<HTMLIFrameElement>();
    private historyLength = 0;

    @bind
    private handlePageLoad() {
        this.historyLength++;

        try {
            const query = this.frameRef.current?.contentWindow?.location?.search || '';

            this.setState({ showExternalUrl: false });

            if (query) {
                const params = queryParams(query.substr(1));

                // necessary to remove iframe history
                if (this.historyLength > 1) {
                    // not using `window.history.length` as it has a maximum (e.g. 50 for Chrome)
                    window.history.go(1 - this.historyLength);
                }

                // needed to enable history changes in callback
                setTimeout(() => this.props.onReturn(params), 500);
            }
        } catch (error) {
            // different origin
            if (error.name === 'SecurityError') {
                this.setState({ showExternalUrl: true });
            } else {
                throw error;
            }
        }
    }

    public render() {
        // same origin is initially needed to avoid page reload when removing frame history
        const url = this.props.url || '/empty';

        return (
            <ExternalFrame ref={this.frameRef} src={url} hidden={!this.state.showExternalUrl} onLoad={this.handlePageLoad} />
        );
    }
}
