import { bind } from 'decko';
import { observer } from 'mobx-react';
import * as React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { SCREEN_PADDING } from '../../styles/base';
import BackgroundImage from '../common/BackgroundImage';
import MediaGalleryModal from './MediaGalleryModal';

export const COLLAPSED_HEADER_HEIGHT = '2.95rem'; // = 1.5rem * 1.3 + 1rem

const HEADER_HEIGHT = 200;
const DRAG_THRESHOLD = 10;

// @ts-ignore ts(2615)
const HeaderContainer = styled(BackgroundImage)`
    background-color: ${colors.matte_black};
    height: ${HEADER_HEIGHT}px;
    margin: ${SCREEN_PADDING * -1}px ${SCREEN_PADDING * -1}px 0;
    position: relative;
    z-index: 2000;
`;

const HeaderGalleryImage = styled(BackgroundImage)`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: ${HEADER_HEIGHT}px;
    justify-content: center;
    width: 100%;

    &:active {
        cursor: grabbing;
    }
`;

type CapturedMouseEvent = React.MouseEvent<HTMLDivElement, MouseEvent>;

interface Props {
    restaurant: RestaurantEntry;
}

interface State {
    mediaSlide: number;
}

@observer
export default class Header extends React.PureComponent<Props, State> {
    private static sliderSettings = {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1
    };

    public readonly state: State = {
        mediaSlide: 0
    };

    private dragStartX = -1;

    public render() {
        const { restaurant } = this.props;
        const isPartner = restaurant.type !== CooperationType.NonPartner;
        const uri = isPartner ? restaurant.midSizeFirstImage : require('../../assets/jpg/non-partner-header.jpg');
        const hasGallery = isPartner && restaurant.media.length > 0;

        return (
            <HeaderContainer src={uri}>
                {hasGallery && (
                    <Slider {...Header.sliderSettings} afterChange={this.trackSlide}>
                        {restaurant.media.map((medium, index) => (
                            // the extra `<div>` is necessary since `Slider` overrides the `style` attributes of its children
                            <div key={index}>
                                <HeaderGalleryImage src={medium.url || ''} onMouseDown={this.captureClick} onMouseUp={this.openGallery(index)}>
                                    {medium.youtubeId && (
                                        <img alt="" src={require('../../assets/svg/ic_btn_play_big.svg')} />
                                    )}
                                </HeaderGalleryImage>
                            </div>
                        ))}
                    </Slider>
                )}
                {/* this is the overlay: */}
                <div className="details-header-content" />
            </HeaderContainer>
        );
    }

    @bind
    private trackSlide(mediaSlide: number) {
        this.setState({ mediaSlide });
    }

    // needed to avoid click on drag
    @bind
    private captureClick(event: CapturedMouseEvent) {
        this.dragStartX = event.clientX;
    }

    private isClick(event: CapturedMouseEvent) {
        if (this.dragStartX < 0 || Math.abs(this.dragStartX - event.clientX) < DRAG_THRESHOLD) {
            this.dragStartX = -1;

            return true;
        }
    };

    private openGallery(galleryIndex: number) {
        return (event: CapturedMouseEvent) =>
            this.isClick(event) && MediaGalleryModal.open(this.props.restaurant.media || [], galleryIndex);
    }
}
