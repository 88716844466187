import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import { JSX } from '../common/Screen';
import { Headline, ScreenHeadline } from '../text';

export const HeaderWrapper = styled.div`
    padding:  0 ${GRID_SIZE * 2}px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: ${GRID_SIZE * 2}px 25px;
`;

export const H2 = styled(Headline)`
    margin: ${GRID_SIZE * 2}px 0;
    font-size: 1.4rem;
    line-height: 1.3em;
`;

export const Hr = styled.hr`
    height: 1px;
    border: none;
    margin: ${GRID_SIZE * 2}px 0;
    width: 100%;
    background-color: ${colors.grey_03};
`;

export const MeetUpDate = styled.span`
    display: block;
    white-space: normal;
`;

export const PaymentCard = styled.div`
    display: flex;
    flex: 0;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${colors.white};
    margin-bottom: ${GRID_SIZE * 2}px;
    padding: ${GRID_SIZE * 2}px ${GRID_SIZE * 4}px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const ScreenTitle = styled(ScreenHeadline)`
    &.MuiButtonBase-root {
        text-transform: none;
        margin-left: 8px;
    }
    margin: 0;
`;

export function textWithJsx(text: string, jsx: JSX) {
    const parts: any[] = text.split('%{JSX}');

    return parts.map((part: string, index: number) => {
        return (
            <span key={`key-${index}`}>
                {index > 0 && jsx}
                <span>{part}</span>
            </span>
        );
    });
}
