import { bind } from 'decko';
import * as React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList as List } from 'react-window';

import { DynamicListProps } from './DynamicList';

export interface FlatListProps<T> extends DynamicListProps<T> {
    getItemLayout: (item: T, index: number) => number;
    ListHeaderLayout?: number;
    ListFooterLayout?: number;
}

// TODO: always render header and footer and make parent component hiding them if needed
/** If `data` is empty, only `ListEmptyComponent` is rendered (no `ListHeader-` or `ListFooterComponent`) */
export default class FlatList<T> extends React.PureComponent<FlatListProps<T>> {
    private listRef = React.createRef<List>();

    // somehow works most reliably when calculated on the fly...
    private get itemCount() {
        return this.props.data.length
            + Number(!!this.props.ListHeaderComponent)
            + Number(!!this.props.ListFooterComponent);
    }

    @bind
    public scrollToOffset(offset: number) {
        this.listRef.current?.scrollTo(offset);
    }

    @bind
    public scrollToItem(item: T) {
        this.scrollToIndex(this.props.data.indexOf(item));
    }

    @bind
    public scrollToIndex(index: number) {
        this.listRef.current?.scrollToItem(index + Number(!!this.props.ListHeaderComponent));
    }

    @bind
    public scrollToEnd() {
        this.listRef.current?.scrollToItem(this.itemCount, 'end');
    }

    public componentDidMount() {
        if (this.props.ListHeaderComponent && this.props.ListHeaderLayout === undefined) {
            console.warn(`A FlatList with a ListHeaderComponent also needs a set ListHeaderLayout prop or the header won't be visible!`);
        }

        if (this.props.ListFooterComponent && this.props.ListFooterLayout === undefined) {
            console.warn(`A FlatList with a ListFooterComponent also needs a set ListFooterLayout prop or the header won't be visible!`);
        }
    }

    public render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                {this.props.data.length > 0
                    ? (
                        <AutoSizer>
                            {dimensions => (
                                <List
                                    ref={this.listRef}
                                    {...dimensions}
                                    itemCount={this.itemCount}
                                    itemSize={this.calculateRowHeight}
                                    itemKey={index => index} // necessary (despite alledgedly default) to re-render correctly
                                >
                                    {this.renderRow}
                                </List>
                            )}
                        </AutoSizer>
                    )
                    : this.props.ListEmptyComponent
                }
            </div>
        );
    }

    private determineItem<U>(index: number, onItem: (item: T, index: number) => U, onHeader: () => U, onFooter: () => U) {
        const itemIndex = index - Number(!!this.props.ListHeaderComponent);

        if (this.props.ListHeaderComponent && index === 0) {
            return onHeader();
        } else if (this.props.ListFooterComponent && itemIndex >= this.props.data.length) {
            return onFooter();
        } else {
            return onItem(this.props.data[itemIndex], itemIndex);
        }
    }

    @bind
    private renderRow(props: ListChildComponentProps): React.ReactElement {
        return (
            <div style={props.style}>
                {this.determineItem(
                    props.index,
                    (item, index) => this.props.renderItem(item, index),
                    () => this.props.ListHeaderComponent,
                    () => this.props.ListFooterComponent
                )}
            </div>
        );
    }

    @bind
    private calculateRowHeight(index: number) {
        return this.determineItem(
            index,
            this.props.getItemLayout,
            () => this.props.ListHeaderLayout || 0,
            () => this.props.ListFooterLayout || 0
        );
    }
}
