import * as firebase from 'firebase';
import { computed, observable } from 'mobx';
import moment from 'moment';

import Timestamps from '../types/Timestamps';
import DataList, { DataListEntry } from './DataList';
import Perishable from './Perishable';

export const ITEMS_PER_PAGE = 5;

export type TransactionTime = 'past' | 'future';
export type TransactionDocument<M> = firebase.firestore.QueryDocumentSnapshot<M>;
export type TransactionQuery<M> = firebase.firestore.Query<M> | firebase.firestore.CollectionReference<M>;

export default abstract class TransactionList<M extends {}, D extends DataListEntry = M & DataListEntry> {
    private _pastList = new DataList<D>();
    private _futureList = new DataList<D>();

    private _dateField: keyof M;
    private _watchingStartDate: Date;
    private _fixDateDelimiter: boolean;
    private _pastPages: number;
    private _futureUnsubscriber?: () => void;
    private _expiredFutureItems: Perishable<number>;
    private _transactionDocs: TransactionDocument<M>[] = [];

    @observable
    private _hasNextPastPage: boolean;

    @computed
    public get hasNextPastPage() {
        return this._hasNextPastPage;
    }

    /**
     * @param fixedDateDelimiter Custom date separating future and past transactions.
     *      If set, future transactions are not automatically changed to past transactions once they expire
     */
    constructor(dateField: keyof M, fixedDateDelimiter?: Date) {
        this._dateField = dateField;
        this._watchingStartDate = fixedDateDelimiter || new Date();
        this._fixDateDelimiter = (fixedDateDelimiter !== undefined);
        this._pastPages = 0;
        this._hasNextPastPage = true;
        this._expiredFutureItems = new Perishable<number>(() => {
            const now = new Date();
            const expiredFutureItems = this._transactionDocs.filter(doc => this.getDate(doc) <= now).length;
            const nextToExpire = this._transactionDocs[expiredFutureItems];

            return {
                value: expiredFutureItems,
                expires: nextToExpire && moment(this.getDate(nextToExpire))
            };
        });
    }

    public list(time?: TransactionTime) {
        return computed(() => (
            time ? (
                (time === 'past') ?
                    this._futureList.list.slice(0, this._expiredFutureItems.value).reverse().concat(this._pastList.list) :
                    this._futureList.list.slice(this._expiredFutureItems.value)
            ) : this._futureList.list.concat(this._pastList.list)
        )).get();
    }

    public async getItem(docId: string) {
        const item = this.list().find(item => item.key === docId);

        if (item) {
            return item;
        }

        const itemSnap = await this.collectionRef.doc(docId).get();
        return itemSnap.exists ? this.createItem(itemSnap as TransactionDocument<M>) : undefined
    }

    public loaded(time: TransactionTime) {
        return computed(() => ((time === 'past') ? this._pastList : this._futureList).loaded).get();
    }

    public async loadNextPastPage() {
        this._pastPages++;

        const { docs } = await this.getQuery('past').get();

        this._hasNextPastPage = ((docs.length - ITEMS_PER_PAGE) % ITEMS_PER_PAGE === 1);

        if (this._hasNextPastPage) {
            docs.pop();
        }

        const items = await Promise.all(docs.slice(this._pastList.list.length).map(doc => this.createItem(doc)));

        this._pastList.add(...items);
        this._pastList.resolve();
    }

    public stopWatching() {
        this._expiredFutureItems.release();

        if (this._futureUnsubscriber) {
            this._futureUnsubscriber();
        }
    }

    protected startWatching() {
        this.stopWatching();
        this._pastList.reset();
        this._futureList.reset();
        this._pastPages = 0;
        this._hasNextPastPage = true;

        if (!this._fixDateDelimiter) {
            this._watchingStartDate = new Date();
        }

        this._futureUnsubscriber = this.getQuery('future').onSnapshot(async ({ docs }) => {
            const items = await Promise.all(docs.map(doc => this.createItem(doc)));

            this._futureList.set(...items);
            this._futureList.resolve();

            if (!this._fixDateDelimiter) {
                this._expiredFutureItems.release();
                this._transactionDocs = docs;
                this._expiredFutureItems.initialize();
            }
        });
        this.loadNextPastPage();
    }

    protected getDate(doc: TransactionDocument<M>) {
        return Timestamps.toDate(doc.data()[this._dateField] as any);
    }

    protected abstract get collectionRef(): firebase.firestore.CollectionReference<M>;

    protected abstract buildQuery(): TransactionQuery<M>;

    protected abstract createItem(doc: TransactionDocument<M>): Promise<D>;

    private getQuery(time: TransactionTime) {
        const past = (time === 'past');
        let query = this.buildQuery()
            .orderBy(String(this._dateField), past ? 'desc' : 'asc')
            .startAt(this._watchingStartDate);

        if (past) {
            // sadly can't just query for next page, as the field ordered by is not unique...
            // query for one more to check if more pages exist
            query = query.limit((this._pastPages * ITEMS_PER_PAGE) + 1);
        }

        return query;
    }
}
