import { bind } from 'decko';
import { inject } from 'mobx-react';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Validate, { Validation } from '../../../../lib/src/helpers/Validate';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import { getDefaultOrderTime } from '../../../../lib/src/types/models/Order';
import { InjectedAccountProps } from '../../Account';
import Alert from '../../helpers/Alert';
import DatePicker from '../forms/DatePicker';

interface Props {
    onConfirm?: () => void;
    onCancel?: () => void;
}

@inject('account', 'invitationDraft')
export default class InviteDatePicker extends React.Component<Props> {
    private datePickerRef = React.createRef<DatePicker>();

    private get injected() {
        return this.props as Props & InjectedAccountProps & InjectedInvitationDraftProps;
    }

    public open() {
        this.datePickerRef.current?.open();
    }

    public render() {
        const { presetDate, forCart } = this.injected.invitationDraft.get();
        const selectedDate = forCart ? getDefaultOrderTime().toDate() : presetDate;

        return (
            <DatePicker
                ref={this.datePickerRef}
                preselected={selectedDate}
                onChange={this.handleConfirm}
                onCancel={this.handleCancel}
                validate={this.validate}
                only={forCart ? 'time' : undefined}
            />
        );
    }

    @bind
    private validate(date: Date) {
        const { restaurant, forCart } = this.injected.invitationDraft.get();

        if (!restaurant) {
            return Validation.success;
        }

        return forCart
            ? Validate.orderTime(forCart, date, this.injected.account)
            : Validate.restaurantDate(restaurant, date);
    }

    @bind
    private handleConfirm(date: Date) {
        Env.logEvent('confirm_meetup_date');

        const draft = this.injected.invitationDraft.get();

        draft.date = date;

        Alert.confirmInvitationTakeAway(isTakeAway => {
            draft.isTakeAway = isTakeAway;

            if (this.props.onConfirm) {
                this.props.onConfirm();
            }
        });
    }

    @bind
    private handleCancel() {
        Env.logEvent('cancel_meetup_date');

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
}
