// TODO: use for other screens and replace the old ScreenHeader

import { IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { Headline } from '../text';
import { JSX } from './Screen';

const HeaderContainer = styled.div`
    display: flex;
    padding: ${SCREEN_PADDING}px;
    background-color: ${colors.navy_blue};
    color: ${colors.white};
`;

const HeaderContent = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    height: 100%;
`;

const ContentContainer = styled.div`
    margin: ${GRID_SIZE * -1.5}px 0 ${GRID_SIZE * 1.5}px;
`;

interface Props {
    title: string | JSX;
    onClose?: () => void;
    onBack?: () => void;
}

export default class ScreenHeader extends React.PureComponent<Props> {
    public render() {
        return (
            <HeaderContainer>
                <HeaderContent>
                    <ButtonContainer>
                        {this.props.onClose && (
                            <IconButton onClick={this.props.onClose} size="small">
                                <Close style={{ color: colors.white }} />
                            </IconButton>
                        )}
                        {this.props.onBack && (
                            <IconButton onClick={this.props.onBack} size="small">
                                <ArrowBack style={{ color: colors.white }} />
                            </IconButton>
                        )}
                    </ButtonContainer>
                    <Headline style={{ color: colors.white, margin: 0 }}>
                        {this.props.title}
                    </Headline>
                    {this.props.children && (
                        <ContentContainer>
                            {this.props.children}
                        </ContentContainer>
                    )}
                </HeaderContent>
            </HeaderContainer>
        );
    }
}
