import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import { bind } from 'decko';
import * as React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import List from '../../../../lib/src/types/List';
import { GRID_SIZE } from '../../styles/base';

const Button = styled.div`
    cursor: pointer;
    display: flex;
    height: ${GRID_SIZE * 2}px;
    padding: ${GRID_SIZE / 2}px;
    position: absolute;
    top: ${GRID_SIZE / 2}px;
    width: ${GRID_SIZE * 2}px;

    && > svg {
        height: 100%;
        width: 100%;
    }
`;

export const CloseButton = styled(Button).attrs({
    'aria-label': 'close',
    children: <Close />
})`
    right: ${GRID_SIZE / 2}px;
`;

export const BackButton = styled(Button).attrs({
    'aria-label': 'back',
    children: <ArrowBack />
})`
    left: ${GRID_SIZE / 2}px;
`;

export const PARAM_DELIMITER = '/';

export interface ModalProps {
    params: string[];
    navigation: ModalNavigation;
}

export interface ModalState<P> {
    params: Partial<P>;
}

export type ModalClass = React.ComponentType<ModalProps>;

export default abstract class Modal<P = {}, S extends ModalState<P> = ModalState<P>> extends React.Component<ModalProps, S> {
    public readonly state: S = {
        params: {}
    } as S;

    protected handleParams() {
        return new Promise<void>(resolve =>
            this.hydrateParams(this.props.params).then(params => this.setState({ params }, resolve))
        );
    }

    @bind
    protected redirectTo(modalKey: string, params?: string[] | List<boolean>, replace = false) {
        const paramsArray = [];

        if (Array.isArray(params)) {
            paramsArray.push(...params);
        } else if (params) {
            Object.entries(params).forEach(([ name, flag ]) => {
                if (flag) {
                    paramsArray.push(name);
                }
            });
        }

        this.props.navigation.open(modalKey, paramsArray, replace);
    }

    @bind
    protected close() {
        this.props.navigation.close();
    }

    @bind
    protected back() {
        this.props.navigation.back();
    }

    /**
     * Translates the array of strings taken from the URL into an object
     * of the interface `P`
     *
     * @param params Array of strings
     */
    protected abstract hydrateParams(params: string[]): Promise<P>;

    /**
     * Determines based on the received params if the modal should be rendered
     *
     * @param params (Partial) object of interface `P`
     */
    protected abstract validateParams(params: Partial<P>): boolean;

    protected paramsAreValid() {
        return !!this.state.params && this.validateParams(this.state.params);
    }

    public componentDidMount() {
        this.handleParams();
    }

    public componentDidUpdate(prevProps: ModalProps) {
        /*
         * We need this check here as `React.PureComponent.shouldComponentUpdate()` is needed to handle state changes,
         * (esp. in `handleParams()`) but doesn't block equal but not identical `params` props.
         */
        if (prevProps.params.join(PARAM_DELIMITER) !== this.props.params.join(PARAM_DELIMITER)) {
            this.handleParams();
        }
    }
}

interface ModalNavigationProps {
    modals: List<ModalClass>;
}

interface ModalNavigationState {
    activeModal?: {
        modal: ModalClass,
        params: string[]
    };
}

export class ModalNavigation extends React.PureComponent<ModalNavigationProps, ModalNavigationState> {
    private static hashChangeHandlers: Array<() => void> = [];

    public readonly state: ModalNavigationState = {};

    @bind
    private static handleHashChange() {
        this.hashChangeHandlers.forEach(handler => handler());
    }

    public getHash() {
        return window.location.hash.substr(1);
    }

    public open(modalKey: string, params: string[] = [], replace = false) {
        const hash = this.props.modals[modalKey]
            ? [ modalKey, ...params ].join(PARAM_DELIMITER)
            : undefined;

        if (hash) {
            if (replace) {
                window.history.replaceState(null, '', `${window.location.origin}${window.location.pathname}#${hash}`);
            } else {
                window.location.hash = hash;
            }
        }
    }

    @bind
    public close() {
        window.location.hash = '';
    }

    @bind
    public back(steps = 1) {
        window.history.go(Math.min(-1, -steps));
    }

    @bind
    public parseHash() {
        const [ modalKey, ...params ] = this.getHash().split(PARAM_DELIMITER);

        return { modalKey, params };
    }

    public componentDidMount() {
        if (window.onhashchange && window.onhashchange !== ModalNavigation.handleHashChange) {
            console.error('window.onhashchange has already been set!');
        } else {
            if (!window.onhashchange) {
                window.onhashchange = ModalNavigation.handleHashChange;
            }

            Arrays.add(ModalNavigation.hashChangeHandlers, this.openModalByHash);
            this.openModalByHash();
        }
    }

    public componentWillUnmount() {
        Arrays.remove(ModalNavigation.hashChangeHandlers, this.openModalByHash);

        if (ModalNavigation.hashChangeHandlers.length < 1) {
            window.onhashchange = null;
        }
    }

    public render() {
        if (!this.state.activeModal) {
            return null;
        }

        const ModalComponent = this.state.activeModal.modal;

        return (
            <ModalComponent params={this.state.activeModal.params} navigation={this} />
        );
    }

    @bind
    private openModalByHash() {
        const { modalKey, params } = this.parseHash();
        const modal = this.props.modals[modalKey];
        let activeModal;

        if (modal) {
            Env.logEvent(`show_${modalKey}`);
            activeModal = { modal, params };
        }

        this.setState({ activeModal });
    }
}

