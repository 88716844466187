import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';

import Constants from '../../../../lib/src/Constants';
import Env from '../../../../lib/src/Env';
import { assert, logError } from '../../../../lib/src/helpers/Validate';
import ContactEntity, { ContactGroup, ContactPerson, countContacts } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryFab } from '../button';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import { ErrorText } from '../text';
import ContactChips from './ContactChips';
import ContactList from './ContactList';

interface State extends ModalState<{}> {
    selection: ContactEntity[];
    users: ContactPerson[];
    inputValid?: boolean;
    searching?: boolean;
    error?: string;
    fadeOutUnselectedContacts: boolean;
}

@inject('api')
export default class EditGroup extends Modal<{}, State> {
    public readonly state: State = {
        selection: [],
        users: [],
        params: {},
        fadeOutUnselectedContacts: false
    };

    private get injected() {
        return this.props as ModalProps & InjectedApiProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified).get();
    }

    public componentDidMount() {
        super.componentDidMount();

        const selection = this.injected.api.account.getGroupDraftMembers();
        const errorState = this.getMaxAttendeesErrorState(selection);

        this.setState({
            selection,
            ...errorState
        });
    }

    public render() {
        const { name } = this.injected.api.account.groupDraft;
        const title = name ? `${Env.i18n.t('ContactGroupEditMembers')}: ${name}` : Env.i18n.t('AddGroup');
        const FabIcon = name ? Check : ArrowForward;

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={title} onBack={this.back}>
                    <ContactChips contacts={this.state.selection} toggleContact={this.toggleContactSelection}/>
                    {this.state.error && (
                        <ErrorText>
                            {this.state.error}
                        </ErrorText>
                    )}
                </ScreenHeader>
                <FullSizeContent style={{ marginTop: GRID_SIZE * -1 }}>
                    <ContactList
                        contacts={this.injected.api.account.contacts.list}
                        selection={this.state.selection.map(contact => contact.key)}
                        onItemPress={this.toggleContactSelection}
                        filterBy={this.filterForPersons}
                        fadeOutUnselectedContacts={this.state.fadeOutUnselectedContacts}
                    />
                    <PrimaryFab onClick={this.apply} style={{ margin: SCREEN_PADDING }}>
                        <FabIcon />
                    </PrimaryFab>
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private toggleContactSelection(contact: ContactEntity) {
        this.setState(state => {
            const newSelection = this.toggle(state.selection, contact);
            const selection = (countContacts(newSelection) > Constants.MAX_ATTENDEES - 1) ? state.selection : newSelection;
            const errorState = this.getMaxAttendeesErrorState(selection)

            return { selection, ...errorState };
        });
    }

    private toggle(selection: ContactEntity[], contact: ContactEntity) {
        if (selection.some(selected => selected.key === contact.key)) {
            return selection.filter(selected => selected.key !== contact.key);
        } else {
            return [ ...selection, contact ];
        }
    }

    @bind
    private apply() {
        try {
            assert(this.state.selection.length > 1, Env.i18n.t('ErrorTooFewContacts'));

            const groupMembers: string[] = [];
            const { groupDraft } = this.injected.api.account;

            this.state.selection.forEach(contact => {
                if (contact instanceof ContactGroup) {
                    groupMembers.push(...contact.members);
                } else if (contact instanceof ContactPerson && contact.status !== 'deleted') {
                    groupMembers.push(contact.key);
                }
            });

            groupDraft.members = groupMembers;

            if (groupDraft.key) {
                this.injected.api.waitFor(this.injected.api.account.sendGroupDraft())
                    .then(() => {
                        this.back();
                        Env.snackbar.success(Env.i18n.t('SuccessGroupUpdated'));
                    })
                    .catch(error => {
                        logError('EditGroup.apply', error);
                        Env.snackbar.error(Env.i18n.t('ErrorGroupNotUpdated'));
                    });
            } else {
                this.redirectTo('editgroupname');
            }
        } catch (error) {
            this.setState({ error });
        }
    }

    @bind
    private filterForPersons(contact: ContactEntity) {
        return contact instanceof ContactPerson;
    }

    @bind
    private getMaxAttendeesErrorState(selection: Array<ContactEntity>): Pick<State, 'fadeOutUnselectedContacts' | 'error'> {
        const fadeOutUnselectedContacts = (countContacts(selection) >= Constants.MAX_ATTENDEES - 1);
        const error = fadeOutUnselectedContacts ? Env.i18n.t('MaxGroupMembersWarning', { count: Constants.MAX_ATTENDEES - 1 }) : undefined;

        return { fadeOutUnselectedContacts, error };
    }
}
