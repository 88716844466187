import * as MangoPay from 'mangopay2-nodejs-sdk';
import moment from 'moment';

import Env from '../../Env';
import { DataListEntry } from '../../store/DataList';
import List from '../List';
import { VatTypeId } from '../lunchnow';
import { Timestamp } from '../Timestamps';
import RestaurantEntry from './RestaurantEntry';

/** Hours before the meet-up */
export const ORDER_CANCELABLE_UNTIL = 24;

/** Min minutes an order must be sheduled in advance */
export const ORDER_ADVANCEMENT = 10;

/** Estimated max minutes needed to complete the order process */
export const ORDER_PROCESS_ETA = 5;

export function getMinOrderDate() {
    return moment().add(ORDER_ADVANCEMENT + ORDER_PROCESS_ETA, 'minutes');
}

export function getDefaultOrderTime() {
    return moment.max(getMinOrderDate(), moment().hours(12));
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface OrderDraft extends Omit<PartialBy<Order, 'key'>, 'fee' | 'feePercentage' | 'payInId' | 'orderId' | 'status' | 'meetUpDate' | 'timestamp' | 'isTakeAway'> {
    status: 'DRAFT';
    meetUpDate: Date | null;
    isTakeAway?: boolean;
}

export interface DiscountRef {
    id: string;
    value: number;
    name: List<string>;
}

export interface OrderItem {
    key: string;
    mealKey?: string;
    amount: number;
    name: string;
    description: string;
    /** price as integer: 450 is 4,50 € */
    price: number;
    refundedAmount?: number;
    vatType?: VatTypeId;
    vat?: number;
    comments?: string;
    discount?: DiscountRef;
    isSoldOut?: boolean;
}

export interface OrderRefund {
    price: number;
    reason: string;
    refundId: string;
}

export type OrderVatByType = {
    [key in VatTypeId]: number;
}

export interface OrderRating {
    stars: number;
}

export type PaymentOption = 'card' | 'paypal';

export interface OrderData {
    paymentOption: PaymentOption;
    debitedCardId: string;
    fee: number;
    feePercentage: number;
    items: OrderItem[];
    meetUp?: string;
    meetUpDate: Timestamp;
    payInId: string;
    orderId: string;
    restaurantId: string;
    status:  MangoPay.transaction.TransactionStatus | 'DRAFT';
    /** price as integer: 450 is 4,50 € */
    totalPrice: number;
    transactionId?: string;
    // @deprecated: one VAT for the order is not used anymore, there are different vat types
    vat?: number;
    vatByType?: OrderVatByType;
    isTakeAway?: boolean;
    timestamp: Timestamp;
    refunded?: Array<OrderRefund>;
    accepted?: boolean;
    rating?: OrderRating;
    ratingTipMailSent?: boolean;
    customer?: {
        firstName: string,
        lastName: string
    };
    discount?: DiscountRef;
}

export interface Order extends DataListEntry, Omit<OrderData, 'restaurantId' | 'meetUpDate'> {
    meetUpDate: Date;
    restaurant?: RestaurantEntry;
}

export function getOrderItemPrice(item: OrderItem) {
    return item.amount * item.price + (item.discount?.value || 0);
}

export type VatPrices = { [key in VatTypeId]: number }

export function getVatPercentage(vat: number) {
    return Math.round(vat * 100);
}

/**
 * Returned price is in cents.
 */
export function getVatPrice(vat: number, price: number) {
    const netPrice = price / (1 + vat);

    return Math.round(netPrice * vat);
}

export function getOrderItemVatPrice(item: OrderItem) {
    return getVatPrice(item.vat!, getOrderItemPrice(item));
}

/**
 * Returned price is in cents.
 */
export function getTotalVatPrice(items: Array<OrderItem>) {
    return Object.values(items).reduce((sum, item) => sum + getOrderItemVatPrice(item), 0);
}

export function computePrice(items: OrderItem[], from: { [key: string]: number }) {
    return Object.keys(from).reduce(
        (sum, key) => {
            const item = items.find(i => i.key === key);

            return sum + (item ? from[key] * item.price : 0);
        },
        0
    );
}

export function computeRefundedPrice(order?: Order | OrderDraft | null) {
    return (order?.refunded || []).reduce((sum, item) => sum + (item?.price || 0), 0);
}

export function logOrder(eventName: string, order?: Order | OrderDraft, itemCounts?: List<number>, eventParams?: List<any>) {
    let params: List<any> = {};

    if (order) {
        const { key, restaurant, items } = order;
        const itemsToLog = !itemCounts ? items : items.map(item => ({ ...item, amount: itemCounts[item.key] }));
        const CURRENCY = 'EUR';
        const ITEMS = itemsToLog.filter(item => !!item.amount).map(item => {
            const QUANTITY = item.amount;
            const PRICE = item.price / 100;
            const value = QUANTITY * PRICE;
            const tax = getOrderItemVatPrice(item) / 100;

            return { ITEM_ID: item.key, QUANTITY, CURRENCY, PRICE, VALUE: value, TAX: tax };
        });
        const VALUE = ITEMS.reduce((sum, item) => sum + item.VALUE, 0);
        const TAX = getTotalVatPrice(order.items) / 100; // factor 100 needed, because `getVatPrice()` rounds

        params = { orderKey: key, resKey: restaurant?.key, CURRENCY, VALUE, TAX, ITEMS };
    }

    Env.logEvent(eventName, { ...eventParams, ...params });
}

export function computeVatEntries(order: Order | OrderDraft) {
    const { vatByType, items, discount, totalPrice } = order;
    const entries: { vatType: VatTypeId, vat: number, vatValue: number }[] = [];

    if (vatByType) {
        const discountPercentage = totalPrice / (totalPrice - (discount?.value || 0)); // FIXME: correct for absolute discounts?

        Object.entries(vatByType).forEach(([ key, vat ]) => {
            const vatType = key as VatTypeId;
            const vatTypeItems = items.filter(item => item.vatType === vatType);
            const vatValue = vatTypeItems.reduce((sum, item) => sum + getOrderItemVatPrice(item), 0) * discountPercentage;

            if (vatValue !== 0) {
                entries.push({ vatType, vat, vatValue });
            }
        });
    }

    return entries;
}
