import { bind } from 'decko';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { animateScroll } from 'react-scroll';
import { InjectedApiProps } from 'src/Api';
import styled from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Env from '../../../../lib/src/Env';
import { InjectedChatProps } from '../../../../lib/src/managers/ChatManager';
import { EnhancedChatMessage } from '../../../../lib/src/types/models/Chat';
import colors from '../../../../lib/src/styles/colors';
import Timestamps from '../../../../lib/src/types/Timestamps';
import { GRID_SIZE } from '../../styles/base';
import ToEndButton from '../button/ToEndButton';
import EmptyListIndicator from '../common/EmptyListIndicator';
import { DiskButton } from '../common/IconButton';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import NewScreenHeader from '../common/NewScreenHeader';
import Screen from '../common/Screen';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import InviteDatePicker from '../invitations/InviteDatePicker';
import { ContactPerson, ContactStatus } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import { UserContactsEntry } from '../../../../lib/src/types/models/User';
import { DataListEntry } from '../../../../lib/src/store/DataList';
import Input from '../forms/Input';
import { Badge, RegularText } from '../text';
import Backend from '../../../../lib/src/helpers/Backend';
import { formatDayAndTime } from '../../../../lib/src/helpers/formatting';
import UserInvitation, { isInvitationExpired } from '../../../../lib/src/types/models/UserInvitation';
import { InjectedPaymentProps } from '../../Payment';
import { CANCELATION_DEADLINE_MINUTES } from '../../../../lib/src/types/models/Invitation';
import LoadingIndicator from '../common/LoadingIndicator';
import statusConfig from '../../../../lib/src/styles/statusConfig';
import { NotificationsActive } from '@material-ui/icons';

interface ChatMessageBoxProps {
    isOwn?: boolean;
}

const ChatMessageWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    overflow: auto;
    padding-bottom: ${GRID_SIZE}px;
`;

const ChatMessageRow = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChatMessageBox = styled.div<ChatMessageBoxProps>`
    display: flex;
    flex-direction: column;
    align-self: ${props => props.isOwn ? 'flex-end' : 'flex-start'};
    margin: ${GRID_SIZE}px;
    max-width: 80%;
`;

const ChatMessageBackground = styled.div`
    background-color: ${colors.white};
    border-radius: ${GRID_SIZE}px;
    position: relative;
`;

// @ts-ignore ts(2615)
const Timestamp = styled(RegularText)`
    margin-top: ${GRID_SIZE / 2}px;
    align-self: flex-end;
    color: ${colors.description_grey};
    font-size: 10px;
`;

const NewMessageIndicator = styled.div`
    background-color: ${colors.red_500};
    border-radius: ${GRID_SIZE}px;
    height: ${GRID_SIZE * 2}px;
    position: absolute;
    right: 0;
    top: 0;
    width: ${GRID_SIZE * 2}px;
`;

const InputContainer = styled.div`
    background-color: ${colors.white};
    display: flex;
    flex-direction: row;
    padding: ${GRID_SIZE}px;
`;

const SubmitButton = styled(DiskButton).attrs({
    icon: require('../../assets/svg/send.svg'),
    diskSize: 32,
    size: 18,
    color: colors.white
})<{ active?: boolean }>`
    background-color: ${props => props.active ? colors.teal_300 : colors.teal_50};
    margin-left: ${GRID_SIZE}px;
    margin-top: ${GRID_SIZE * 1.5}px;
`;

const LogoContainer = styled.div`
    flex-shrink: 0;
    height: 50px;
    margin: ${GRID_SIZE * 2}px;
    margin-right: 10px;
    width: 50px;
    border: 1px solid ${colors.light_border};
    border-radius: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const RestaurantNameText = styled(RegularText)`
    font-size: 16px;
    font-weight: 600;
    color: ${colors.navy_blue};
`;

const CuisineText = styled(RegularText)`
    font-weight: 300;
`;

const InvitationStatus = styled.div`
    font-size: 14px;
    background: rgba(255, 255, 255, 0.85);
    position: absolute;
    width: 100%;
    bottom: 0;
    line-height: 30px;
    text-align: center;
`;

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: ${colors.light_border};
    margin: -0.5px;
    padding-bottom: 1px;
`;

const Button = styled(MaterialButton)<{ contentColor?: string, hasRightBorder?: boolean }>`
    &&& {
        flex: 1;
        border-radius: 0;
        ${props => props.contentColor && `color: ${props.contentColor};`}
        margin: 0.5px;
        min-width: calc(50% - 1px);
        background: ${colors.white};
    }
`;

interface Params {
    chatKey?: string;
}

interface State extends ModalState<Params> {
    newMessages: boolean;
    newMessagesFromOthers: boolean;
    showScrollButton: boolean;
    lastSeenMessageKey?: string;
}

@inject('api', 'chatManager', 'invitationDraft', 'payment')
@observer
export default class ChatMessagesScreen extends Modal<Params, State> {
    public readonly state: State = {
        newMessages: false,
        newMessagesFromOthers: false,
        showScrollButton: false,
        params: {}
    };

    private static CHAT_ID = 'chat-messages';

    @observable
    private message = '';

    private chatInputRef = React.createRef<Input>();
    private datePickerRef = React.createRef<InviteDatePicker>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedChatProps & InjectedInvitationDraftProps & InjectedPaymentProps;
    }

    protected async hydrateParams(params: string[]) {
        if (!params.length) {
            return {};
        }

        const chatKey = params[0];
        const { chatManager } = this.injected;

        chatManager.initalizeChatsOverview(); // needed because inbox screen doesn't stay opened
        chatManager.initalizeChat(chatKey);

        return { chatKey };
    }

    protected validateParams(params: Params) {
        return computed(() => this.injected.api.account.loggedIn).get();
    }

    public componentWillUnmount() {
        this.injected.chatManager.uninitalizeChat();
    }

    render() {
        const { currentChatKey, messages, lastMessageKey } = this.injected.chatManager;
        const backgroundColor = messages.length ? colors.light_background : colors.white;

        if (!currentChatKey) {
            return null;
        }

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.close}
                fullHeight={true}
                HeaderComponent={this.renderHeader}
                FooterComponent={this.renderFooter}
                contentStyle={{ backgroundColor, padding: 0 }}
            >
                {/* TODO: use DynamicList */}
                <ChatMessageWrapper onScroll={this.checkScrollPosition} id={ChatMessagesScreen.CHAT_ID}>
                    {messages.length ? (
                        <>
                            {/* not using FlatList because of variable item height */}
                            {messages.slice().reverse().map(this.renderChatMessage)}
                        </>
                    ) : (
                        <EmptyListIndicator
                            waitFor={Promise.resolve()}
                            icon={require('../../assets/svg/empty_state_user.svg')}
                            hint={Env.i18n.t('NoChatMessages')}
                        />
                    )}
                </ChatMessageWrapper>
                {this.state.showScrollButton && (
                    <ToEndButton style={{ bottom: 10, right: 10 }} onPress={this.scrollToBottom} direction="down">
                        {(lastMessageKey !== this.state.lastSeenMessageKey) && (
                            <NewMessageIndicator />
                        )}
                    </ToEndButton>
                )}

                <InviteDatePicker ref={this.datePickerRef} onConfirm={this.applySelectionAndContinue} />
            </Screen>
        );
    }

    @computed
    private get renderHeader() {
        const { chatManager } = this.injected;
        const participants = chatManager.participants[chatManager.currentChatKey!] || []
        // TODO: for now just use one, design for more users needs to be done
        const otherParticipants = chatManager.getOtherParticipants(participants)
        const firstParticipant = otherParticipants ? otherParticipants[0] : undefined;
        let title = firstParticipant?.displayName || '';

        if (otherParticipants.length > 1) {
            title += ` (+ ${otherParticipants.length - 1})`
        }

        return (
            <NewScreenHeader title={title} onBack={this.back} />
        );
    }

    @computed
    private get renderFooter() {
        return (
            <InputContainer>
                <Input
                    ref={this.chatInputRef}
                    placeholder={Env.i18n.t('YourMessage')}
                    sanitizeValue={false}
                    onChange={this.storeMessage}
                    onSubmitEditing={this.sendMessage}
                    maxLength={100}
                    style={{ flex: 1 }}
                />
                <SubmitButton onClick={this.sendMessage} active={this.message.length > 0} />
            </InputContainer>
        );
    }

    @bind
    private renderRestaurantCard(item: EnhancedChatMessage) {
        const { api } = this.injected;
        const { restaurant } = item;

        if (!restaurant) {
            return null;
        }

        const buttons: Array<{
            contentColor?: string;
            onPress: () => void;
            label: string;
            icon?: React.ReactNode;
        }> = [];

        const cuisine = restaurant.tagIds?.map(tagId => api.tags.get(tagId)).find(tag => tag?.type === 'cuisine');
        const firstGalleryImage = restaurant.media.find(media => media.type === 'image');
        let statusConfigData;

        if (item.invitation) {
            const userStatus = api.account.getMyStatus(item.invitation.attendees || []);
            const isExpired = isInvitationExpired(item.invitation);
            const isDisabled = (item.invitation.status === 'declined') || (item.invitation.status === 'canceled');
            const hasOtherAttendeesWithoutOrder = item.invitation.attendees.some(attendee => attendee.uid !== api.account.user?.uid && !attendee.hasOrdered);
            statusConfigData = statusConfig(item.invitation.status || 'pending');

            if (!isExpired && (userStatus === 'accepted') && (item.invitation.status === 'accepted') && restaurant?.hasPayment && restaurant?.isStillOpenToday && !item.order) {
                buttons.push({
                    label: Env.i18n.t('DoOrder'),
                    contentColor: colors.accepted,
                    onPress: () => this.addOrder(item.invitation!, item.restaurant!)
                });
            } else if (item.order) {
                buttons.push({
                    label: Env.i18n.t('ShowOrder'),
                    contentColor: colors.accepted,
                    onPress: () => this.showOrder(item)
                });
            }

            if (!isExpired && !isDisabled && userStatus === 'pending') {
                buttons.push({
                    label: Env.i18n.t('Accept'),
                    contentColor: colors.accepted,
                    onPress: () => this.updateStatus(item.contentId, 'accepted', item.key)
                });
            }

            if (!isExpired && !isDisabled && !item.order && (userStatus !== 'declined') && !moment().add(CANCELATION_DEADLINE_MINUTES, 'minutes').isAfter(Timestamps.toDate(item.invitation.date))) {
                buttons.push({
                    label: Env.i18n.t('Decline'),
                    contentColor: colors.declined,
                    onPress: () => this.updateStatus(item.contentId, 'declined', item.key)
                });
            }

            if (!item.reminderSent && !isExpired && item.invitation.isOwn && item.invitation.status === 'accepted' && hasOtherAttendeesWithoutOrder) {
                buttons.push({
                    label: Env.i18n.t('SendReminder'),
                    icon: <NotificationsActive style={{ fontSize: 16, marginRight: GRID_SIZE / 2 }} />,
                    contentColor: colors.gold,
                    onPress: () => this.injected.chatManager.sendReminder(item.key)
                });
            }
        } else {
            buttons.push({
                label: Env.i18n.t('ArrangeMeetup'),
                onPress: () => this.arrangeMeetup(restaurant, item.key)
            });
        }

        const cuisineText = cuisine?.translations[Env.currentLanguageCode()] || cuisine?.name;

        return (
            <div>
                <div style={{ cursor: 'pointer', width: '100%', lineHeight: 0 }} onClick={() => { this.onRestaurantPress(restaurant) }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {!!restaurant?.logo?.url && (
                            <LogoContainer style={{ backgroundImage: `url(${restaurant.logo.url})` }} />
                        )}

                        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center' }}>
                            <RestaurantNameText>
                                {restaurant.name}
                            </RestaurantNameText>

                            {!!cuisineText && (
                                <CuisineText>
                                    {cuisineText}
                                </CuisineText>
                            )}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: GRID_SIZE, marginRight: GRID_SIZE }}>
                            <KeyboardArrowRight style={{ fontSize: '32px', color: colors.indigo }} />
                        </div>
                    </div>

                    <div style={{ position: 'relative' }}>
                        {firstGalleryImage && (
                            <img src={firstGalleryImage.url} style={{ maxWidth: '100%' }} />
                        )}

                        {!!item.invitation?.date && (
                            <InvitationStatus>
                                {!!statusConfigData && (
                                    <Badge style={{ position: 'absolute', backgroundColor: statusConfigData.diskColor || colors.grey_01 }}>
                                        {statusConfigData.text}
                                    </Badge>
                                )}
                                {formatDayAndTime(Timestamps.toDate(item.invitation.date))}
                            </InvitationStatus>
                        )}
                    </div>
                </div>

                <div style={{ overflow: 'hidden' }}>
                    <Buttons>
                        {buttons.map((button, key) => (
                            <Button key={key} contentColor={button.contentColor} onClick={button.onPress} hasRightBorder={key < buttons.length - 1}>
                                {button.icon}
                                {button.label}
                            </Button>
                        ))}
                    </Buttons>
                </div>
            </div>
        )
    }

    @bind
    private renderChatMessage(item: EnhancedChatMessage) {
        const messageTimestampMoment = moment(item.timestamp ? Timestamps.toDate(item.timestamp) : undefined);
        const isToday = messageTimestampMoment.isSame(moment(), 'day');
        const timeFormat = isToday ? Env.i18n.t('TimeFormat') : `${Env.i18n.t('DayFormat')}, ${Env.i18n.t('TimeFormat')}`;

        let content;

        if (item.content === 'restaurant' || (item.content === 'invitation' && item.restaurant)) {
            content = this.renderRestaurantCard(item);
        } else {
            content = (
                <RegularText style={{ padding: GRID_SIZE }}>
                    {item.content === 'invitation' ? Env.i18n.t('SharedInvitationRemoved') : item.content}
                </RegularText>
            );
        }

        return (
            <ChatMessageRow key={item.key}>
                <ChatMessageBox key={item.key} isOwn={item.isOwn}>
                    <ChatMessageBackground>
                        {content}

                        {this.injected.chatManager.loadingMessagesKeys.has(item.key) && (
                            <LoadingIndicator position="absolute" />
                        )}
                    </ChatMessageBackground>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Timestamp>
                            {messageTimestampMoment.format(timeFormat)}
                        </Timestamp>
                    </div>
                </ChatMessageBox>
            </ChatMessageRow>
        );
    }

    @bind
    private onRestaurantPress(restaurant: RestaurantEntry) {
        this.redirectTo('details', [restaurant.routingName || '']);
    }

    @bind
    private arrangeMeetup(restaurant: RestaurantEntry, chatMessageKey: string) {
        const { chatManager, invitationDraft } = this.injected;

        if (!chatManager.currentChatKey) {
            return;
        }

        const draft = invitationDraft.create('ChatMessages');

        draft.restaurant = restaurant;
        draft.chatKey = chatManager.currentChatKey;
        draft.replacedChatMessageKey = chatMessageKey;

        // invite all members of the current chat to the invitation
        const participants = chatManager.participants[chatManager.currentChatKey] || [];

        // the invitation draft wants to have ContactEntity so let's construct that from the participants...
        const newAttendees = participants.map(participant => new ContactPerson({ key: participant.key, isFavorite: false } as UserContactsEntry & DataListEntry, participant.displayName));

        invitationDraft.setAttendees(newAttendees);

        this.datePickerRef.current?.open();
    }

    @bind
    private async applySelectionAndContinue() {
        const { invitationDraft } = this.injected;

        const draft = invitationDraft.get();

        if (draft.replacedChatMessageKey) {
            this.injected.chatManager.setMessageLoading(draft.replacedChatMessageKey);
        }

        await invitationDraft.complete();
    }

    @bind
    private async updateStatus(invitationId: string | undefined, status: ContactStatus, messageKey: string) {
        if (!invitationId) {
            return;
        }

        const isDecline = status === 'declined';

        if (isDecline) {
            const confirmed = await new Promise((resolve) => {
                Env.alert(
                    Env.i18n.t('Decline'),
                    Env.i18n.t('ConfirmInvitationDenial'),
                    [
                        {
                            label: Env.i18n.t('Ok'),
                            action: () => resolve(true)
                        },
                        {
                            label: Env.i18n.t('Cancel'),
                            action: () => resolve(false)
                        }
                    ]
                );
            });

            if (!confirmed) {
                return;
            }
        }

        try {
            this.injected.chatManager.setMessageLoading(messageKey)
            await Backend.updateInvitationStatus(invitationId, status);
        } catch (error) {
            Env.snackbar.error(Env.i18n.t('ErrorChangeAttendance'));
        }
    }

    @bind
    private clearChatInput() {
        this.chatInputRef.current?.setValue('');
        this.message = '';
    }

    @bind
    private storeMessage(event: ChangeEvent<HTMLInputElement>) {
        this.message = event.target.value;
    }

    @bind
    private async sendMessage() {
        if (this.message) {
            await this.injected.chatManager.addMessage(this.message);
            this.clearChatInput();
            this.scrollToBottom();
        }
    }

    @bind
    private scrollToBottom() {
        animateScroll.scrollToBottom({
            containerId: ChatMessagesScreen.CHAT_ID,
            duration: 300
        });
    }

    @bind
    private checkScrollPosition(event: React.UIEvent<HTMLDivElement>) {
        const { chatManager } = this.injected;
        const element = event.currentTarget;
        const bottomReached = element.scrollTop >= 0;
        const topReached = element.scrollHeight - (element.scrollTop * -1) - element.clientHeight <= 0;
        const showScrollButton = !bottomReached;

        if (topReached) {
            chatManager.loadMore();
        }

        if (bottomReached) {
            chatManager.resetLimit();
        }

        this.setState(({ newMessages, newMessagesFromOthers, lastSeenMessageKey}) => ({
            showScrollButton,
            newMessages: showScrollButton && newMessages,
            newMessagesFromOthers: showScrollButton && newMessagesFromOthers,
            lastSeenMessageKey: (bottomReached || !lastSeenMessageKey) ? chatManager.lastMessageKey : lastSeenMessageKey
        }));
    }

    @bind
    private async addOrder(invitation: UserInvitation, restaurant: RestaurantEntry) {
        if (restaurant) {
            const cart = this.injected.payment.getCart(restaurant);

            cart?.setMeetUp(invitation);
            this.redirectTo('details', [restaurant?.routingName || '']);
        }
    }

    @bind
    private async showOrder(chatMessage: EnhancedChatMessage) {
        if (chatMessage.order) {
            this.redirectTo('order', [chatMessage.order.key]);
        }
    }
}
