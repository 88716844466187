import { inject, observer } from 'mobx-react';
import React from 'react';

import { InjectedApiProps } from '../../Api';
import LoadingIndicator from './LoadingIndicator';

@inject('api')
@observer
export default class GlobalIndicator extends React.Component {
    private get injected() {
        return this.props as InjectedApiProps;
    }

    public render() {
        return this.injected.api.isWaiting ? (
            <LoadingIndicator />
        ) : null;
    }
}
