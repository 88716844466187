import { IconButton as MuiIconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors from '../../../../lib/src/styles/colors';
import DiscountEntry from '../../../../lib/src/types/models/DiscountEntry';
import { InjectedAccountProps } from '../../Account';
import LinkTo from '../../helpers/LinkTo';
import { GRID_SIZE } from '../../styles/base';
import Modal, { ModalProps } from '../common/Modal';
import SafariFix from '../common/SafariFix';
import Screen from '../common/Screen';
import { Header, HeaderWrapper, PaymentCard, ScreenTitle } from '../payment/Styled';
import { ErrorText, RegularText, TitleText } from '../text';

const Link = styled.a`
    color: ${colors.teal_900};
    cursor: pointer;
`;

const VoucherList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const VOUCHER_BORDER_WIDTH = GRID_SIZE / 4;

const VoucherContainer = styled.div<{ selected?: boolean }>`
    align-items: center;
    background-color: ${props => props.selected ? colors.teal_50 : colors.white};
    border: ${VOUCHER_BORDER_WIDTH}px solid ${colors.matte_black};
    border-radius: ${GRID_SIZE}px;
    display: flex;
    flex-direction: row;
    margin: ${GRID_SIZE * 2}px 0;
    padding: ${GRID_SIZE}px;
    cursor: pointer;
`;

const DENT_SIZE = 10;

const VoucherContent = styled.div`
    border-right: ${VOUCHER_BORDER_WIDTH}px dotted ${colors.matte_black};
    flex: 1;
    margin-right: ${GRID_SIZE * 2}px;
    padding-left: ${GRID_SIZE}px;
    padding-right: ${GRID_SIZE * 2}px;
    position: relative;

    /* dents */
    &::before,
    &::after {
        background-color: ${colors.grey_05};
        border: ${VOUCHER_BORDER_WIDTH}px solid ${colors.matte_black};
        box-sizing: border-box;
        content: '';
        height: ${DENT_SIZE / 2 + VOUCHER_BORDER_WIDTH}px;
        position: absolute;
        right: ${(DENT_SIZE + VOUCHER_BORDER_WIDTH) / -2}px;
        width: ${DENT_SIZE}px;
    }

    &::before {
        border-bottom-left-radius: ${DENT_SIZE / 2}px;
        border-bottom-right-radius: ${DENT_SIZE / 2}px;
        border-top-width: 0px;
        top: ${GRID_SIZE * -1 - VOUCHER_BORDER_WIDTH}px;
    }

    &::after {
        border-bottom-width: 0px;
        border-top-left-radius: ${DENT_SIZE / 2}px;
        border-top-right-radius: ${DENT_SIZE / 2}px;
        bottom: ${GRID_SIZE * -1 - VOUCHER_BORDER_WIDTH}px;
    }
`;

const VoucherText = styled(TitleText)`
    color: ${colors.matte_black};
    font-family: Recoleta-Bold;
`;

const VoucherDate = styled(ErrorText)`
    color: ${colors.teal_900};
    margin-top: ${GRID_SIZE}px;
    text-align: right;
`;

const VoucherDiscount = styled(VoucherText)`
    width: 110px;
    text-align: center;
    color: ${colors.teal_900};
    font-size: 2.5rem;
`;

@inject('account')
@observer
export default class Vouchers extends Modal {
    private get injected() {
        return this.props as ModalProps & InjectedAccountProps;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.account.verified).get();
    }

    @bind
    private showTerms(event: React.MouseEvent) {
        event.stopPropagation();
        LinkTo.gdpr(); // TODO: change to TnC!
    }

    @bind
    private renderHeader() {
        return (
            <HeaderWrapper>
                <Header>
                    <MuiIconButton onClick={this.close} size="small">
                        <Close style={{ color: colors.matte_black }} />
                    </MuiIconButton>
                </Header>
            </HeaderWrapper>
        );
    }

    @bind
    private renderVoucher(discount: DiscountEntry) {
        const { name, descriptionText, expiryHint, valueText } = discount;

        return (
            <VoucherContainer
                selected={this.injected.account.data.selectedDiscountId === discount.key}
                onClick={() => this.injected.account.setSelectedDiscountId(discount.key)}
            >
                <VoucherContent>
                    <VoucherText>
                        {name[Env.currentLanguageCode()]}
                    </VoucherText>
                    <RegularText>
                        {descriptionText}
                    </RegularText>
                    {expiryHint && (
                        <VoucherDate>
                            {expiryHint}
                        </VoucherDate>
                    )}
                </VoucherContent>
                <VoucherDiscount>
                    {valueText}
                </VoucherDiscount>
            </VoucherContainer>
        );
    }

    public render() {
        const { list } = this.injected.account.accountDiscounts;

        return (
            <Screen
                open={this.paramsAreValid()}
                className="payment paper"
                dividers={false}
                handleClose={this.close}
                HeaderComponent={this.renderHeader}
                fullHeight
            >
                <SafariFix.OverflowWrapper>
                    <PaymentCard>
                        <ScreenTitle>
                            {Env.i18n.t('Vouchers')}
                        </ScreenTitle>
                        <RegularText style={{ marginTop: '.5em' }}>
                            {Env.i18n.t('VouchersDescription')}
                            {' '}
                            {Env.i18n.t('TnCLabelBeforeLink')}
                            <Link onClick={this.showTerms}>
                                {Env.i18n.t('TnCLink')}
                            </Link>
                            {Env.i18n.t('TnCLabelAfterLink')}
                    </RegularText>
                    </PaymentCard>
                    <VoucherList>
                        {list.map((discount, index) => (
                            <li key={index}>
                                {this.renderVoucher(discount)}
                            </li>
                        ))}
                    </VoucherList>
                </SafariFix.OverflowWrapper>
            </Screen>
        );
    }
}
