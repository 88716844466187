import { bind } from 'decko';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

import Env from '../../../../lib/src/Env';
import { MealEntryWithRestaurant } from '../../../../lib/src/store/RestaurantList';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import DynamicList from '../common/DynamicList';
import EmptyListIndicator from '../common/EmptyListIndicator';
import Teaser from '../common/Teaser';
import { Headline } from '../text';
import MealCard from './MealCard';
import RestaurantCard from './RestaurantCard';
import TagSelectionBar from './TagSelectionBar';

type ItemType = RestaurantEntry | MealEntryWithRestaurant;

interface Props {
    onItemSelect: (item: RestaurantEntry) => void;
    onOpenModal: (modal: string, params?: any) => void;
}

@inject('api')
@observer
export default class RestaurantsList extends React.Component<Props> {
    private listRef = React.createRef<DynamicList<ItemType>>();
    private filterReactionDisposer?: IReactionDisposer;

    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    public componentDidMount() {
        const { api } = this.injected;

        this.filterReactionDisposer = reaction(
            () => [ api.filters, api.tagFilter, api.showLunchList ],
            () => this.listRef.current?.scrollToOffset(0)
        );
    }

    public componentWillUnmount() {
        if (this.filterReactionDisposer) {
            this.filterReactionDisposer();
        }
    }

    public render() {
        const { restaurants, showLunchList } = this.injected.api;
        const entries: ItemType[] = showLunchList ? restaurants.lunchMeals : restaurants.sortedFilteredList

        return (
            <DynamicList<ItemType> ref={this.listRef}
                data={entries}
                renderItem={this.renderItem}
                ListHeaderComponent={(
                    <>
                        <Teaser />
                        <TagSelectionBar onOpenModal={this.props.onOpenModal} />
                        <Headline style={{ padding: `0 ${GRID_SIZE * 2}px` }}>
                            {Env.i18n.t('NearYou')}
                        </Headline>
                    </>
                )}
                ListEmptyComponent={this.renderEmptyList()}
            />
        )
    }

    @bind
    private renderItem(entry: ItemType) {
        const { onItemSelect } = this.props;

        return (entry instanceof RestaurantEntry)
            ? (
                <RestaurantCard.Large key={entry.key} restaurant={entry} onPress={onItemSelect} />
            )
            : (
                <MealCard key={entry.key} meal={entry} restaurant={entry.restaurant} onPress={() => onItemSelect(entry.restaurant)} />
            );
    }

    private renderEmptyList() {
        const { restaurants } = this.injected.api;

        return (
            <EmptyListIndicator
                waitFor={restaurants.loaded && !restaurants.pending}
                icon={require('../../assets/svg/empty_state_menu.svg')}
                hint={Env.i18n.t('NoMatchingResults')}
            />
        );
    }
}
