import styled, { css } from 'styled-components';

import colors, { alpha } from '../../../../lib/src/styles/colors';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';

export const RegularText = styled.p`
    color: ${colors.grey_02};
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.2px;
    line-height: 1.5;
    margin: 0;
`;

export const DescriptionText = styled.p`
    color: ${colors.grey_02};
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin: 0;
`;

export const SublineText = styled(RegularText)`
    line-height: 1rem;
    letter-spacing: 0.4px;
    color: ${colors.grey_02};
`;

export const TitleText = styled(RegularText)`
    color: ${colors.matte_black};
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.4px;
`;

export const GreyTitleText = styled(TitleText)`
    color: ${colors.grey_01};
`;

export const HeadlineFilter = styled(TitleText)`
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 1.8rem;
    letter-spacing: 0.0075em;
    line-height: 1.6;
    color: ${colors.dark_blue};
`;

export const Headline = styled(TitleText)`
    font-family: 'Circular-Bold';
    font-size: 1.8rem;
    letter-spacing: 0.0075em;
    line-height: 1.6;
    margin: 20px 0;
`;

export const SubHeadline = styled(Headline)`
    font-size: 1.4rem;
    margin: 16px 0;
`;

export const ScreenHeadline = styled(Headline)`
    font-size: 2rem;
    line-height: 1.3;
`;

export const SectionHeadline = styled(RegularText)`
    color: ${colors.navy_blue};
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    margin: ${SCREEN_PADDING}px 0;
`;

export const SectionLabel = styled(Headline)`
    font-family: 'Roboto-Bold', sans-serif;
    color: ${colors.dark_blue};
    font-size: 1.5rem;
    font-weight: 200;
    margin: 30px 0 10px;
`;

export const ErrorText = styled(RegularText)`
    color: ${colors.warn};
    font-size: 12px;
    line-height: 1.1;
    margin-top: 12px;
`;

export const Warning = styled(ErrorText)`
    background-color: ${alpha(colors.warn, .125)};
    margin-bottom: ${GRID_SIZE}px;
    margin-top: 0;
    padding: ${GRID_SIZE}px ${SCREEN_PADDING}px;
`;

export const ImprintText = styled(RegularText)`
    color: ${colors.grey_03};
    line-height: 16px;
`;

export const ListFooterHint = styled(ImprintText)`
    margin: ${SCREEN_PADDING}px;
    text-align: center;
`;

const linkStyle = css`
    color: ${colors.teal_900};
    cursor: pointer;
`;

export const Link = styled.span`
    ${linkStyle};
`;

export const TealLink = styled(RegularText)`
    ${linkStyle};
    align-self: flex-start;
    padding: 0 ${GRID_SIZE}px;

    p & {
        display: inline;
        padding: 0;
    }
`;

const BADGE_LINE_HEIGHT = 12;
export const BADGE_FONT_SIZE = BADGE_LINE_HEIGHT * .75;

export const Badge = styled(DescriptionText)`
    border-radius: ${BADGE_LINE_HEIGHT + GRID_SIZE}px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: ${colors.white};
    font-size: ${BADGE_FONT_SIZE}px;
    line-height: ${BADGE_LINE_HEIGHT}px;
    margin: ${GRID_SIZE / 2}px;
    margin-left: 0;
    overflow: hidden;
    padding: ${GRID_SIZE / 2}px ${GRID_SIZE * 2}px;
`;
