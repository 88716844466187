import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import { getCardAlias, getCardLogo } from '../../../../lib/src/helpers/Payment';
import Cart from '../../../../lib/src/store/Cart';
import colors from '../../../../lib/src/styles/colors';
import { PaymentOption } from '../../../../lib/src/types/models/Order';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE } from '../../styles/base';
import SafariFix from '../common/SafariFix';
import ScreenModal from '../common/ScreenModal';
import { SectionHeadline, TealLink, TitleText } from '../text';
import CardRegistrationModal from './CardRegistrationModal';

const AliasText = styled(TitleText)`
    font-size: 0.9em;
    white-space: nowrap;
`;

const CardItemWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
`;

const PaymentIcon = styled.img`
    transform: scale(0.7);
    margin-left: ${GRID_SIZE * 2}px;
    max-width: 100px;
`;

const StyledRadioLabel = styled(FormControlLabel)`
    &&& {
        margin-right: 0;
        display: flex;

        & > span:last-child {
            overflow: hidden;
        }
    }
`;

const AddCardLink = styled(TealLink)`
   align-self: center;
   color: ${colors.grey_02};
   margin: ${GRID_SIZE * 4}px 0;
   text-align: center;
`;

interface PaymentSelectOption {
    type: PaymentOption;
    cardId?: string;
}

interface PaymentOptionProps {
    paymentOption: PaymentSelectOption;
}

@inject('payment')
class PaymentOptionItem extends React.PureComponent<PaymentOptionProps> {
    private get injected() {
        return this.props as unknown as PaymentOptionProps & InjectedPaymentProps;
    }

    public render() {
        const { paymentOption } = this.props;
        const cardResponse = (paymentOption.type === 'card' && this.injected.payment.getCard(paymentOption.cardId)) || undefined;
        const paymentIconSrc = getCardLogo(paymentOption.type, cardResponse);

        return (
            <CardItemWrapper>
                <AliasText>
                    {paymentOption.type === 'card' ? getCardAlias(cardResponse?.mangoPayData) : 'PayPal'}
                </AliasText>
                <PaymentIcon src={paymentIconSrc} />
            </CardItemWrapper>
        );
    }
}

interface Props {
    cart: Cart;
}

@inject('payment')
@observer
export default class PaymentOptions extends React.PureComponent<Props> {
    private get injected() {
        return this.props as Props & InjectedPaymentProps;
    }

    private cardRegistrationModalRef = React.createRef<ScreenModal>();

    @computed
    private get paymentOptions() {
        const cards: Array<PaymentSelectOption> = (this.injected.payment.cards || []).map(card => ({
            type: 'card',
            cardId: card.mangoPayData.Id
        }));

        const paymentOptions: Array<PaymentSelectOption> = [
            ...cards,
            { type: 'paypal' }

        ];

        return paymentOptions;
    }

    public render() {
        const { cart } = this.props;

        const selectedPaymentOption = this.paymentOptions.findIndex(paymentSelectOption =>
            (paymentSelectOption.type === 'paypal' && cart.paymentOption === 'paypal') ||
            (paymentSelectOption.type === 'card' && cart.paymentOption === 'card' && paymentSelectOption.cardId === cart.debitedCardId)
        );

        return (
            <>
                <SafariFix.OverflowWrapper>
                    <SectionHeadline>
                        {Env.i18n.t('PaymentOptionsSelect')}
                    </SectionHeadline>
                    <RadioGroup onChange={this.handleChange} value={selectedPaymentOption}>
                        <Grid container spacing={2}>
                            {this.paymentOptions.map((paymentOption, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <StyledRadioLabel
                                        label={(
                                            <PaymentOptionItem paymentOption={paymentOption} />
                                        )}
                                        control={(
                                            <Radio color="default" value={index} />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                    <AddCardLink onClick={this.addCard}>
                        {Env.i18n.t('AddPaymentMethod')}
                    </AddCardLink>
                </SafariFix.OverflowWrapper>
                <CardRegistrationModal modalRef={this.cardRegistrationModalRef} />
            </>
        );
    }

    @bind
    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const paymentSelectOption = this.paymentOptions[event.target.value];

        this.props.cart.paymentOption = paymentSelectOption.type;
        this.props.cart.debitedCardId = paymentSelectOption.cardId || '';
    }

    @bind
    private addCard() {
        Env.logEvent('show_cardregistration_from_orderdraft');
        this.cardRegistrationModalRef.current?.open();
    }
}
