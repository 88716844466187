import { debounce } from 'decko';
import { action, computed, observable } from 'mobx';

import List from '../types/List';
import DataList, { DataListEntry, Snapshot } from './DataList';

type Filter<T> = (entry: T) => boolean;
type Comparator<T> = (a: T, b: T) => number;

export default class FilteredDataList<T extends DataListEntry> extends DataList<T> {
    @observable
    private _filters: List<Filter<T>> = {};

    @observable
    private _filteredList: T[] = [];

    private comparator?: Comparator<T>;

    @computed
    public get filteredList() {
        return this._filteredList;
    }

    @debounce(800)
    @action
    public updateFilteredList() {
        const filters = Object.values(this._filters);

        this._filteredList = this._list.slice().filter(item => filters.every(filter => filter(item)));
        this._pending = false;
    }

    public orderBy(comparator?: Comparator<T>) {
        this.comparator = comparator;

        if (this.comparator) {
            this._list = this._list.slice().sort(this.comparator);
        }

        this.updateFilteredList();
    }

    @action
    public add(...items: T[]) {
        if (items.length) {
            this.set(...this._list.concat(items));
        }
    }

    @action
    public set(...items: T[]) {
        const newList = items.slice();

        if (this.comparator) {
            newList.sort(this.comparator);
        }

        this._list = newList;
        this.updateFilteredList();
    }

    @action
    public addQuerySnapshotChildren(snapshot: Snapshot, override = false) {
        super.addQuerySnapshotChildren(snapshot, override);
        this.updateFilteredList();
    }

    @action
    public reset() {
        this._filteredList = [];

        return super.reset();
    }

    @action
    public addFilter(key: string, filter: Filter<T>) {
        this._filters[key] = filter;
        this.updateFilteredList();
    }

    @action
    public removeFilter(key: string) {
        if (this._filters[key]) {
            delete this._filters[key];
            this.updateFilteredList();
        }
    }

    @action
    public setFilter(key: string, filter?: Filter<T>) {
        if (filter) {
            this.addFilter(key, filter);
        } else {
            this.removeFilter(key);
        }
    }
}
