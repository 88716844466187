import { DataListEntry } from '../../store/DataList';
import { ContactPerson } from './ContactEntity';
import RestaurantEntry from './RestaurantEntry';

export const CANCELATION_DEADLINE_MINUTES = 15;

export type InvitationStatus = 'accepted' | 'declined' | 'deleted' | 'canceled';

export default interface Invitation extends DataListEntry {
    restaurant?: RestaurantEntry;
    attendees: ContactPerson[];
    date: Date;
    isOwn?: boolean;
    isTakeAway?: boolean;
    tableNumber?: string;
    status?: InvitationStatus;
    order?: string;
}
