import { bind } from 'decko';
import React from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import colors, { alpha } from '../../../../lib/src/styles/colors';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import Alert from '../../helpers/Alert';
import LinkTo from '../../helpers/LinkTo';
import {  GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { DiskButton } from '../common/IconButton';
import Distance from '../restaurants/Distance';
import { RegularText, SectionHeadline } from '../text';
import StaticMap from './StaticMap';

const AddressText = styled(RegularText)`
    margin-bottom: ${SCREEN_PADDING}px;
`;

const AddressTitle = styled(AddressText)`
    color: ${colors.matte_black};
    font-weight: bold;
`;

const ContactButton  = styled(DiskButton).attrs({
    diskColor: colors.white,
    diskSize: GRID_SIZE * 5,
    color: colors.dark_blue
})`
    box-shadow: 0px 4px 6px ${alpha(colors.matte_black, 0.16)};
    margin-bottom: ${GRID_SIZE}px;
`;

interface Props {
    restaurant: RestaurantEntry;
    onOpenMoia?: () => void;
}

export default class Contact extends React.PureComponent<Props> {
    @bind
    private showPhoneNumber() {
        Alert.confirmPhoneCall(this.props.restaurant);
    }

    @bind
    private email() {
        LinkTo.email(this.props.restaurant.data?.contact?.email!);
    }

    public render() {
        const restaurant = this.props.restaurant.data;

        if (!restaurant) {
            return null;
        }

        return (
            <div>
                <SectionHeadline>
                    {Env.i18n.t('Address')}
                </SectionHeadline>
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <AddressTitle>
                            {restaurant.name}
                        </AddressTitle>
                        <AddressText>
                            {`${restaurant.address.street} ${restaurant.address.streetNumber}`}
                            <br />
                            {`${restaurant.address.postalCode} ${restaurant.address.locality}`}
                        </AddressText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {restaurant.contact?.phoneNumber && (
                            <ContactButton icon={require('../../assets/svg/phone.svg')}  onClick={this.showPhoneNumber} />
                        )}
                        {restaurant.contact?.email && (
                            <ContactButton icon={require('../../assets/svg/mail.svg')}  onClick={this.email} />
                        )}
                    </div>
                </div>
                <Distance restaurant={this.props.restaurant} onOpenMoia={this.props.onOpenMoia} />
                <StaticMap restaurant={this.props.restaurant} style={{ marginTop: SCREEN_PADDING }} />
            </div>
        );
    }
}
