import React from 'react';
import styled from 'styled-components';

import colors from '../../../../lib/src/styles/colors';
import { GRID_SIZE } from '../../styles/base';
import Icon, { IconType } from './Icon';

export const BaseButton = styled.div`
    cursor: pointer;
`;

interface Props {
    icon: IconType;
    color?: string;
    size?: number;
    className?: string;
    style?: React.CSSProperties;
    title?: string;
    onClick?: () => void;
}

// TODO: use Material UI component instead? (esp. for hover effect and shadow)
export default class IconButton extends React.PureComponent<Props> {
    public render() {
        const { icon, color, size, ...containerProps } = this.props;

        return (
            <BaseButton {...containerProps}>
                <Icon size={size} color={color} src={icon} />
            </BaseButton>
        );
    }
}

export const DiskButton = styled(IconButton)<{ diskColor?: string; diskSize?: number }>`
    align-items: center;
    background-color: ${props => props.diskColor};
    border-radius: ${props => props.diskSize || 0}px;
    display: flex;
    flex: 0 0 ${props => props.diskSize}px;
    height: ${props => props.diskSize}px;
    justify-content: center;
    width: ${props => props.diskSize}px;
`;

DiskButton.defaultProps = {
    diskColor: colors.white,
    diskSize: GRID_SIZE * 7
};

// @ts-ignore ts(2615)
export const FeatureButton = styled(DiskButton).attrs({
    diskColor: colors.white,
    diskSize: GRID_SIZE * 4
})`
    margin-left: ${GRID_SIZE}px;
`;

const LinkButtonBase = styled(DiskButton).attrs({
    diskSize: GRID_SIZE * 6
})`
    position: absolute;
    bottom: ${GRID_SIZE * 3}px;
    right: ${GRID_SIZE * 3}px;
`;

export const LinkButton = styled(LinkButtonBase).attrs({
    color: colors.teal_900,
    diskColor: colors.teal_50
})``;

export const DarkLinkButton = styled(LinkButtonBase).attrs({
    color: colors.teal_50,
    diskColor: colors.teal_900
})``;

// @ts-ignore ts(2615)
export const FunctionButton = styled(DiskButton).attrs({
    diskSize: GRID_SIZE * 5,
    diskColor: colors.grey_05,
    color: colors.matte_black
})`
    margin-right: ${GRID_SIZE * 2}px;
`;
