import List from '../types/List';

function findIndex<T>(list: List<T>, predicate: (item: T) => boolean | undefined) {
    return (Object.entries(list).find(([ _, item ]) => predicate(item)) || [])[0];
}

const Lists = {
    cardinalityOf: (list: List<any>) => Object.keys(list).length,
    isEmpty: (list: List<any>) => Object.keys(list).length < 1,
    has: (list: List<any>, field: string) => Object.keys(list).includes(field),
    index: <T>(list: List<T>, index: number) => Object.values(list)[index] as T | undefined,
    findIndex
};

export default Lists;
