import Edit from '@material-ui/icons/BorderColorOutlined';
import { bind } from 'decko';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import { getFormattedPrice } from '../../../../lib/src/helpers/Payment';
import Cart from '../../../../lib/src/store/Cart';
import colors from '../../../../lib/src/styles/colors';
import { VatTypeId } from '../../../../lib/src/types/lunchnow';
import {
    computeRefundedPrice,
    computeVatEntries,
    DiscountRef,
    getVatPercentage,
    getVatPrice,
    Order,
    OrderDraft,
    OrderItem
} from '../../../../lib/src/types/models/Order';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import IconButton from '../common/IconButton';
import Input, { InputValue } from '../forms/Input';
import { RegularText } from '../text';
import Counter from './Counter';
import LinkButton, { ICON_COLOR, ICON_SIZE } from './LinkButton';
import { Hr } from './Styled';

const COUNTER_WIDTH = GRID_SIZE * 10;

const OrderItems = styled.div`
    width: 100%;
`;

interface CancelledProp {
    cancelled?: boolean;
}

const cancelledCss = css`
    color: ${colors.red_900};
    font-weight: bold;
`;

// @ts-ignore ts(2615)
const ItemText = styled(RegularText)<CancelledProp>`
    color: ${colors.matte_black};
    ${props => props.cancelled && cancelledCss}
`;

const BoldItemText = styled(ItemText)`
    font-weight: bold;
`;

const DiscountText = styled(ItemText)<CancelledProp>`
    color: ${colors.warn};
    ${props => props.cancelled && cancelledCss}
`;

const ItemPrice = styled(RegularText)<CancelledProp>`
    margin-right: ${GRID_SIZE}px;
    ${props => props.cancelled && cancelledCss}
`;

const EditButton = styled(IconButton).attrs({
    color: ICON_COLOR,
    icon: Edit,
    size: ICON_SIZE
})`
    margin-left: ${GRID_SIZE}px;
`;

const Row = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${GRID_SIZE}px;
    margin-top: ${GRID_SIZE / 2}px;
`;

const Cell = styled.div<{ last?: boolean }>`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-right: ${GRID_SIZE}px;

    ${props => props.last && css`
        align-items: center;
        margin-left: auto;
        margin-right: 0;
        margin-top: ${GRID_SIZE / 2}px;
    `}
`;

const CancelledItemsTitle = styled(RegularText)`
    color: ${colors.red_900};
    font-weight: bold;
    margin: ${SCREEN_PADDING}px 0;
    text-transform: uppercase;
    text-align: center;
`;

const commentMargin = css`
    margin: ${GRID_SIZE}px 0 ${GRID_SIZE * 2}px;
`;

const CommentInput = styled(Input).attrs({
    forceFocussed: true,
    maxLength: 140
})`
    ${commentMargin};
`;

const Comment = styled(RegularText)`
    ${commentMargin};
`;

interface ItemDetailsPropsBase {
    cancelled?: boolean;
}

class DiscountDetails extends React.Component<ItemDetailsPropsBase & { discount: DiscountRef }> {
    public render() {
        const { cancelled, discount } = this.props;

        return (
            <Row>
                <Cell>
                    <DiscountText cancelled={cancelled}>
                        {discount.name[Env.currentLanguageCode()]}
                    </DiscountText>
                </Cell>
                <Cell last>
                    <DiscountText cancelled={cancelled}>
                        {getFormattedPrice(discount.value)}
                    </DiscountText>
                </Cell>
            </Row>
        );
    }
}

interface ItemDetailsProps extends ItemDetailsPropsBase {
    item: OrderItem;
    onChangeAmount?: (item: OrderItem, value: number) => void;
    onCommentsChange?: (key: string, comments: string) => void;
}

@observer
class OrderItemDetails extends React.Component<ItemDetailsProps> {
    @observable
    private editingComments = false;

    @bind
    private toggleEditing() {
        this.editingComments = !this.editingComments;
    }

    @bind
    private setComments(value: InputValue) {
        if (this.props.onCommentsChange) {
            this.props.onCommentsChange(this.props.item.key, value.toString());
        }
    }

    @bind
    private changeAmount(amount: number) {
        const { item, onChangeAmount } = this.props;

        if (onChangeAmount) {
            onChangeAmount(item, amount);
        }
    }

    public render() {
        const { item, cancelled, onChangeAmount, onCommentsChange } = this.props;
        const sumPrice = item.amount * item.price;

        if (!item.amount) {
            return null;
        }

        return (
            <>
                <Row>
                    <Cell>
                        {onChangeAmount && (
                            <Counter value={item.amount} onChange={this.changeAmount} style={{ width: COUNTER_WIDTH }} />
                        )}
                        {!onChangeAmount && (
                            <ItemText cancelled={cancelled} style={{ marginRight: GRID_SIZE }}>
                                {`${item.amount} ×`}
                            </ItemText>
                        )}
                        <BoldItemText cancelled={cancelled}>
                            {item.name}
                        </BoldItemText>
                        {onCommentsChange && (
                            <EditButton onClick={this.toggleEditing} />
                        )}
                    </Cell>
                    <Cell last>
                        <ItemText cancelled={cancelled}>
                            {getFormattedPrice(sumPrice)}
                        </ItemText>
                    </Cell>
                </Row>
                {item.discount && (
                    <DiscountDetails discount={item.discount} cancelled={cancelled} />
                )}
                {this.editingComments ? (
                    <CommentInput
                        placeholder={Env.i18n.t('Comments')}
                        onChangeText={this.setComments}
                        onSubmitEditing={this.toggleEditing}
                        defaultValue={item.comments}
                    />
                ) : !!item.comments && (
                    <Comment>
                        {Env.i18n.t('Comments')}: {item.comments}
                    </Comment>
                )}
            </>
        );
    }
}

interface Props {
    order: Order | OrderDraft;
    editable?: boolean;
    onReturnToMenu?: (mealTypeBaseName?: string) => void;
}

@observer
export default class OrderDetails extends React.Component<Props> {
    @bind
    private setItemComments(key: string, comments: string) {
        const found = this.props.order.items.find(item => item.key === key);

        if (found) {
            found.comments = comments;
        }
    }

    @bind
    private changeAmount(item: OrderItem, value: number) {
        const found = this.props.order.items.find(_item => _item.key === item.key);

        if (found) {
            found.amount = value;
        }
    }

    private renderVatEntry(vatType: VatTypeId | undefined, vat: number, vatPrice: number) {
        return (
            <Row key={vatType}>
                <Cell>
                    <RegularText>
                        {Env.i18n.t('Vat', { percentage: getVatPercentage(vat) })}
                        {vatType === VatTypeId.DRINK ? ` (${Env.i18n.t('Drinks')})` : ''}
                    </RegularText>
                </Cell>
                <Cell last>
                    <RegularText>
                        {getFormattedPrice(vatPrice)}
                    </RegularText>
                </Cell>
            </Row>
        );
    }

    private renderVatEntries() {
        const { order } = this.props;

        return !order.items.length ? null : (
            <>
                {computeVatEntries(order).map(({ vatType, vat, vatValue }) =>
                    this.renderVatEntry(vatType, vat, vatValue)
                )}
                {/* fallback for old orders */}
                {order.vat &&
                    this.renderVatEntry(undefined, order.vat, getVatPrice(order.vat, order.totalPrice))
                }
            </>
        );
    }

    public render() {
        const { order, editable, onReturnToMenu, children } = this.props;
        const isCancelled = order.items.some(item => item.refundedAmount);
        const isEditable = !isCancelled && editable;
        const refundedPrice = computeRefundedPrice(order);
        const suggestDrinks = isEditable && (order instanceof Cart) && order.shouldSuggestDrinks;

        return (
            <div>
                {isCancelled && (
                    <CancelledItemsTitle>
                        {Env.i18n.t('CancellationAbbr')}
                    </CancelledItemsTitle>
                )}
                <OrderItems>
                    {order.items.length
                        ? order.items.map(item => (
                            <OrderItemDetails
                                key={item.key}
                                item={item}
                                cancelled={isCancelled}
                                onCommentsChange={isEditable ? this.setItemComments : undefined}
                                onChangeAmount={isEditable ? this.changeAmount : undefined}
                            />
                        ))
                        : isEditable && (
                            <RegularText>
                                {Env.i18n.t('EmptyCartMessage')}
                                {onReturnToMenu && (
                                    <span onClick={() => onReturnToMenu()} style={{ color: colors.teal_900, cursor: 'pointer' }}>
                                        {' '}
                                        {Env.i18n.t('EmptyCartLink')}
                                    </span>
                                )}
                            </RegularText>
                        )
                    }
                    {order.discount && (
                        <DiscountDetails discount={order.discount} cancelled={isCancelled} />
                    )}
                </OrderItems>
                {isCancelled && (
                    <Row>
                        <Cell last>
                            <ItemText cancelled={true}>
                                {'−' + getFormattedPrice(refundedPrice)}
                            </ItemText>
                        </Cell>
                    </Row>
                )}
                {suggestDrinks && (
                    <Row>
                        <Cell last style={{ marginLeft: 0 }}>
                            <LinkButton
                                icon={require('../../assets/svg/drink.svg')}
                                label={Env.i18n.t('AddDrink')}
                                onPress={() => onReturnToMenu!('Getränke')}
                            />
                        </Cell>
                    </Row>
                )}
                <Hr/>
                {this.renderVatEntries()}
                <Row>
                    <Cell>
                        <BoldItemText>
                            {Env.i18n.t('TotalPrice')}
                        </BoldItemText>
                    </Cell>
                    <Cell last>
                        <BoldItemText>
                            {getFormattedPrice(order.totalPrice - refundedPrice)}
                        </BoldItemText>
                    </Cell>
                </Row>
                {children}
            </div>
        );
    }
}
