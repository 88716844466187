import AccessTime from '@material-ui/icons/AccessTime';
import { bind } from 'decko';
import { observer } from 'mobx-react';
import React from 'react';
import styled, { css } from 'styled-components';

import Env from '../../../../lib/src/Env';
import { formatDayAndTime } from '../../../../lib/src/helpers/formatting';
import InvitationDraft from '../../../../lib/src/store/InvitationDraft';
import { CooperationType } from '../../../../lib/src/types/lunchnow';
import { isInvitationExpired } from '../../../../lib/src/types/models/UserInvitation';
import { GRID_SIZE } from '../../styles/base';
import { FunctionButton } from '../common/IconButton';
import ScreenHeader from '../common/ScreenHeader';
import RestaurantMeta from '../restaurants/RestaurantMeta';
import { RegularText, TealLink } from '../text';
import InviteDatePicker from './InviteDatePicker';

const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -20px;
    margin-left: ${GRID_SIZE * 2}px;
    position: relative;
    z-index: 1;
`;

const detailsLinkStyle = css`
    margin-top: ${GRID_SIZE}px;
    padding: 0;
`;

const DetailsLink = styled(TealLink)`
    ${detailsLinkStyle};
`;

const NoDetailsHint = styled(RegularText)`
    ${detailsLinkStyle};
`;

interface Props {
    draft: InvitationDraft;
    onCancel: () => void;
    onNavigate: (route: string, params?: string[]) => void;
}

@observer
export default class DetailsHeader extends React.Component<Props> {
    private datePickerRef = React.createRef<InviteDatePicker>();

    @bind
    private showDatePicker() {
        this.datePickerRef.current?.open();
    }

    @bind
    private addContacts() {
        this.props.onNavigate('invitationcontacts');
    }

    @bind
    private openDetails() {
        const { draft, onNavigate } = this.props;

        if (draft.restaurant) {
            onNavigate('details', [ draft.restaurant.data!.routingName, 'invitation' ]);
        }
    }

    public render() {
        const { draft, onCancel } = this.props;
        const restaurant = draft.restaurant;
        const AddFriend = require('../../assets/svg/add_friend.svg');

        if (!restaurant?.data) {
            return null;
        }

        return (
            <>
                {!isInvitationExpired(draft) && (
                    <Toolbar>
                        <FunctionButton icon={AccessTime} onClick={this.showDatePicker}/>
                        <FunctionButton icon={AddFriend} onClick={this.addContacts} style={{ marginRight: 0 }} />
                    </Toolbar>
                )}
                <ScreenHeader
                    onBack={onCancel}
                    title={
                        <>
                            {formatDayAndTime(draft.date)}
                            <br />
                            {restaurant.name}
                        </>
                    }
                >
                    {restaurant?.location && (
                        <>
                            <RestaurantMeta restaurant={restaurant!} displayOpeningTime={false}>
                                {Env.i18n.t(draft.isTakeAway ? 'TakeAway' : 'InStore')}
                            </RestaurantMeta>
                            {restaurant.type === CooperationType.NonPartner ? (
                                <NoDetailsHint>
                                    {Env.i18n.t('ViewNonPartner')}
                                </NoDetailsHint>
                            ) : (
                                <DetailsLink onClick={this.openDetails}>
                                    {Env.i18n.t('ViewRestaurant')}
                                </DetailsLink>
                            )}
                        </>
                    )}
                </ScreenHeader>
                <InviteDatePicker ref={this.datePickerRef} />
            </>
        );
    }
}
