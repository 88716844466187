import { CircularProgress } from '@material-ui/core';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import HttpRequest from '../../../../lib/src/helpers/HttpRequest';
import { assert, logError } from '../../../../lib/src/helpers/Validate';
import colors from '../../../../lib/src/styles/colors';
import { InjectedAccountProps } from '../../Account';
import { GRID_SIZE, InteractiveArea } from '../../styles/base';
import { SecondarySmallButton } from '../button';
import Icon, { BUTTON_ICON_SIZE } from '../common/Icon';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import StatusMarker from '../contacts/StatusMarker';
import { GreyTitleText, RegularText } from '../text';
import { PROFILE_PICTURE_SIZE, ProfileImageBackground } from './ProfileCachedImage';

const DropzoneArea = styled(InteractiveArea)`
    cursor: pointer;
`;

interface State extends ModalState<{}> {
    loading: boolean;
    showDropzone: boolean;
}

@inject('account')
@observer
export default class EditPicture extends Modal<{}, State> {
    public readonly state: State = {
        loading: false,
        showDropzone: false,
        params: {}
    };

    private get injected() {
        return this.props as ModalProps & InjectedAccountProps;
    }

    private getFileBuffer(file: File) {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                if (reader.result) {
                    resolve(reader.result as ArrayBuffer);
                } else {
                    reject();
                }
            });
            reader.readAsArrayBuffer(file);
        });
    }

    // TODO: resize the image? See https://zocada.com/compress-resize-images-javascript-browser/
    @bind
    private async uploadFile(acceptedFiles: File[], rejectedFiles: File[]) {
        try {
            assert(rejectedFiles.length < 1, 'Das Dateiformat wird nicht unterstützt. Bitte wähle eine Bild-Datei aus');

            if (acceptedFiles.length) {
                this.setState({ showDropzone: false, loading: true });
                await HttpRequest.postBlob('/api/users/avatar/', await this.getFileBuffer(acceptedFiles[0]));
                this.back();
                Env.snackbar.success(Env.i18n.t('SuccessProfilePictureUpload'));
            }
        } catch (error) {
            Env.snackbar.error(typeof error === 'string' ? error : Env.i18n.t('ErrorProfilePictureUpload'));
        } finally {
            this.setState({ loading: false });
        }
    }

    @bind
    private changePicture() {
        this.setState({ showDropzone: true });
    }

    @bind
    private async deletePicture() {
        if (this.injected.account.data.photoURL) {
            try {
                this.setState({ loading: true });
                await HttpRequest.delete('/api/users/avatar/');
                this.back();
                Env.snackbar.success(Env.i18n.t('SuccessProfilePictureDeleted'));
            } catch (error) {
                logError('EditProfilePictureScreen.deletePicture', error);
                Env.snackbar.error(Env.i18n.t('ErrorProfilePictureDeleted'));
            } finally {
                this.setState({ loading: false });
            }
        }
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.account.loggedIn).get();
    }

    public render() {
        const avatarUri = this.injected.account.data.photoURL;

        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close}>
                <ScreenHeader title={Env.i18n.t('EditProfilePicture')} onBack={this.back}>
                    <GreyTitleText style={{ marginBottom: GRID_SIZE * 5 }}>
                        {Env.i18n.t('UploadProfilePictureHint')}
                    </GreyTitleText>
                </ScreenHeader>
                {(this.state.showDropzone || !avatarUri) && !this.state.loading ? (
                    <Dropzone accept="image/*" multiple={false} onDrop={this.uploadFile}>
                        {({ getRootProps, getInputProps }) => (
                            <DropzoneArea {...getRootProps()}>
                                <input {...getInputProps()} />
                                <RegularText>
                                    <Icon src={require('../../assets/svg/upload.svg')} size={BUTTON_ICON_SIZE * 2} />
                                </RegularText>
                                <RegularText>
                                    Verschiebe eine Bild-Datei hierher oder klicke hier, um eine Datei auszuwählen.
                                </RegularText>
                            </DropzoneArea>
                        )}
                    </Dropzone>
                ) : (
                    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <div
                            onClick={this.changePicture}
                            style={{ cursor: 'pointer', position: 'relative', width: PROFILE_PICTURE_SIZE }}
                        >
                            <ProfileImageBackground src={avatarUri || ''}>
                                {this.state.loading && (
                                    <CircularProgress style={{ height: '100%', width: '100%' }} />
                                )}
                            </ProfileImageBackground>
                            {avatarUri && (
                                <StatusMarker
                                    color={colors.matte_black}
                                    diskColor={colors.grey_05}
                                    icon={require('../../assets/svg/edit.svg')}
                                    border={GRID_SIZE / 2}
                                    size={BUTTON_ICON_SIZE / 0.9}
                                />
                            )}
                        </div>
                        {avatarUri && (
                            <SecondarySmallButton onClick={this.deletePicture}>
                                {Env.i18n.t('DeletePicture')}
                            </SecondarySmallButton>
                        )}
                    </div>
                )}
            </Screen>
        );
    }
}
