import { bind } from 'decko';
import { computed, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';
import RestaurantDetails, { RestaurantTagConfig } from '../../../../lib/src/helpers/RestaurantDetails';
import colors from '../../../../lib/src/styles/colors';
import { InjectedApiProps } from '../../Api';
import { GRID_SIZE } from '../../styles/base';
import Switch from '../forms/Switch';

type CustomEntry  = 'all' | 'lunch' | 'more';

const Container = styled(ScrollContainer)`
    background-color: ${colors.navy_blue};
    display: flex;
    overflow: auto;
    position: sticky;
    top: -1px; // subtracting 1px extra to avoid occasional gap
    z-index: 1;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: ${GRID_SIZE}px;
    padding-right: ${GRID_SIZE}px;
`;

interface Props {
    onOpenModal: (modal: string, params?: any) => void;
}

@inject('api')
@observer
export default class TagSelectionBar extends React.Component<Props> {
    private readonly FIRST_TAGS_COUNT = 6;

    @observable
    private otherSelectedTag?: RestaurantTagConfig;

    private disposeTagChangeReaction: () => void;

    constructor(props: Props) {
        super(props);

        this.disposeTagChangeReaction = reaction(
            () => this.injected.api.tagFilter,
            tagFilter => {
                if (tagFilter && this.cuisines.slice(this.FIRST_TAGS_COUNT).some(({ key }) => key === tagFilter.key)) {
                    this.otherSelectedTag = tagFilter;
                }
            }
        );
    }

    private get injected() {
        return this.props as Props & InjectedApiProps;
    }

    public componentWillUnmount() {
        this.disposeTagChangeReaction();
    }

    render() {
        return (
            // `hideScrollbars` also disables trackpad scrolling, scrollbars are hidden by global ::-webkit-scrollbar style
            <Container hideScrollbars={false}>
                <Content>
                    {this.selectionEntries.map(this.renderTag)}
                </Content>
            </Container>
        );
    }

    @bind
    private renderTag(item: RestaurantTagConfig | CustomEntry) {
        const { api } = this.injected;

        if (item instanceof RestaurantTagConfig) {
            return (
                <Switch key={item.key} variant="dark" selected={api.tagFilter === item} onClick={() => api.toggleTag(item)}>
                    {item.toString()}
                </Switch>
            );
        } else if (item === 'all') {
            return (
                <Switch key={item} variant="dark" selected={!api.tagFilter && !api.showLunchList} onClick={() => api.toggleTag()}>
                    {Env.i18n.t('All')}
                </Switch>
            );
        } else if (item === 'lunch') {
            return (
                <Switch key={item} variant="dark" selected={api.showLunchList} onClick={api.toggleLunchList}>
                    {Env.i18n.t('LunchSpecials')}
                </Switch>
            );
        } else if (item === 'more') {
            return (
                <Switch key={item} variant="dark" onClick={this.openTagSelectionScreen}>
                    {Env.i18n.t('More')}
                </Switch>
            );
        }

        return null;
    }

    @bind
    private openTagSelectionScreen() {
        this.props.onOpenModal('tagselection');
    }

    @computed
    private get selectionEntries(): (RestaurantTagConfig | CustomEntry)[] {
        return [
            'all', // show all nearby restaurants
            'lunch', // show only the lunch deals
            ...this.cuisines.slice(0, this.FIRST_TAGS_COUNT),
            ...this.otherSelectedTag ? [ this.otherSelectedTag ] : [],
            'more' // open screen with all tag filters
        ];
    }

    @computed
    private get cuisines() {
        return RestaurantDetails.cuisines(this.injected.api);
    }
}
