import { firestore } from 'firebase';

import List from './List';
import { Timestamp } from './Timestamps';

/*
 * Can't be named as declaration file as there is no corresponding module
 */
// Code generated by protoc-gen-tstypes. DO NOT EDIT.

export enum CooperationType {
    Restaurant = 'restaurant',
    Fitness = 'fitness',
    Beauty = 'beauty',
    Places = 'places',
    NonPartner = 'non-partner'
}

export interface Restaurant {
    activeSince?: firestore.Timestamp;
    address: Address;
    contact?: Contact;
    cuisines?: Array<firestore.DocumentReference>;
    company?: firestore.DocumentReference;
    description?: string;
    details?: Detail;
    location: Location;
    logo?: Medium;
    lunchTimes?: Times;
    meals?: Array<Meal>;
    meanPrice?: number;
    media?: Array<Medium>;
    name: string;
    offers?: Array<Offer>;
    openingHours?: Times;
    orderForerunMinutes?: number;
    onlyTakeAway?: boolean;
    premiumEnd?: firestore.Timestamp;
    premiumStart?: firestore.Timestamp;
    restTime?: RestTime;
    routingName: string;
    sharingURL?: List<string>;
    tags?: Array<firestore.DocumentReference>;
    type: CooperationType;
    mangoPayData?: {
        userId?: string;
        creditedWalletId?: string;
    };
}

export interface Allergen {
    acronym?: string;
    name?: string;
}

export interface Cuisine {
    name?: string;
}

export interface Additive {
    acronym?: string;
    name?: string;
}

export interface Attribute {
    name?: string;
}

export enum VatTypeId {
    FOOD = 'FOOD',
    DRINK = 'DRINK'
}

export interface MealType {
    name: string;
    order: number;
    translations: List<string>;
    secondary?: boolean;
    vatType: VatTypeId;
}

interface Contact {
    email?: string;
    faxNumber?: string;
    phoneNumber?: string;
    web?: string;
}

interface AddressPlusCode {
    compoundCode?: string;
    globalCode?: string;
}

interface Address {
    country?: string;
    formattedAddress?: string;
    locality?: string;
    placeId?: string;
    plusCode?: AddressPlusCode;
    postalCode?: string;
    street?: string;
    streetNumber?: string;
}

export interface Detail {
    active?: boolean; // NOTE: requited for display
    bookatableId?: string;
    handicap?: boolean;
    parkingExternal?: boolean;
    parkingRestaurant?: boolean;
    paymentAE?: boolean;
    paymentDC?: boolean;
    paymentEC?: boolean;
    paymentMC?: boolean;
    paymentVS?: boolean;
    paymentPP?: boolean;
    paymentGP?: boolean;
    paymentAP?: boolean;
    paymentCash?: boolean;
    premiumPartner?: boolean;
    reservationEnabled?: boolean;
    seatsInside?: number;
    seatsOutside?: number;
    smokingInside?: boolean;
    smokingOutside?: boolean;
    sodexo?: boolean;
    takeawayEnabled?: boolean;
    ticketRestaurant?: boolean;
    widgetEnabled?: boolean;
    wifi?: boolean;
}

interface LocationGeoPoint {
    latitude?: number;
    longitude?: number;
}

interface Location {
    geohash?: string;
    geopoint?: LocationGeoPoint;
}

export enum Medium_Type {
    image = 'image',
    video = 'video',
    menu = 'menu'
}

export interface Medium {
    dateCreated?: Timestamp;
    name?: string;
    storageRef?: string;
    storageUrl?: string;
    type?: Medium_Type;
    url?: string;
    youtubeId?: string;
}

export interface TimesTimeSpan {
    end?: string;
    start?: string;
}

export interface Times {
    1?: Array<TimesTimeSpan>;
    2?: Array<TimesTimeSpan>;
    3?: Array<TimesTimeSpan>;
    4?: Array<TimesTimeSpan>;
    5?: Array<TimesTimeSpan>;
    6?: Array<TimesTimeSpan>;
    7?: Array<TimesTimeSpan>;
}

export interface Meal {
    additives?: Array<firestore.DocumentReference>;
    allergens?: Array<firestore.DocumentReference>;
    attributes?: Array<firestore.DocumentReference>;
    availability?: Array<string>;
    cuisines?: Array<firestore.DocumentReference>;
    currency?: string;
    description?: string;
    name?: string;
    order?: number;
    price?: number;
    type?: firestore.DocumentReference;
    fromMeal?: firestore.DocumentReference
}

export interface Offer {
    meals?: Array<Meal>;
}

export interface RestTime {
    end?: firestore.Timestamp;
    reference: firestore.DocumentReference;
    start?: firestore.Timestamp;
    translations?: List<string>;
}

export enum AffiliateCodeType {
    MOIA_25 = 'MOIA_25',
    MOIA_50 = 'MOIA_50'
}

export interface AffiliateCode {
    type: AffiliateCodeType;
    code: string;
    used: Timestamp | number | false;
    validUntil: Timestamp | number;
    userKey?: string;
}

export interface Company {
    customerId: number;
    name: string;
    restaurants: Array<firestore.DocumentReference<Restaurant>>;
}

export interface Discount {
    type: 'smart-price' | 'compensation' | 'promotion' | 'loyalty' | 'gift-card' | 'goodie';
    mealRef?: firestore.DocumentReference<Meal>;
    restaurantRef?: firestore.DocumentReference<Restaurant>;
    companyRef?: firestore.DocumentReference<Company>;
    platform?: boolean;
    start?: firestore.Timestamp;
    end?: firestore.Timestamp;
    quantity: number;
    /** User IDs */
    usedBy: Array<string>;
    /** User IDs. `''` = all users. */
    owners: Array<string> | false;
    discount: number;
    discountType: 'absolute' | 'percent' | 'override';
    /** in [0.0, 1.0] */
    paidByUs: number;
    name: List<string>;
    imageUrl?: string;
}

export interface VatType {
    default: number;
    takeAway: number;
}
