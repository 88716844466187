import { bind } from 'decko';
import MangoPay from 'mangopay-cardregistration-js-kit';
import mangoPayTypes from 'mangopay2-nodejs-sdk';
import { computed, observable, reaction } from 'mobx';

import Timestamps from '../../../lib/src/types/Timestamps';
import Constants from '../Constants';
import Env from '../Env';
import { getPaymentUser, getPaymentUserRef, handleHttpResponse } from '../helpers/Firestore';
import HttpRequest, { HttpRequestError } from '../helpers/HttpRequest';
import { getCartId, paymentError } from '../helpers/Payment';
import { assert } from '../helpers/Validate';
import Cart from '../store/Cart';
import { logOrder, Order, OrderData, OrderRating, PaymentOption } from '../types/models/Order';
import { Card, CardTypeAlias, MangoPayTransaction, PaymentUser, PayPalTransaction } from '../types/models/Payment';
import RestaurantEntry from '../types/models/RestaurantEntry';
import AccountManager from './AccountManager';
import ApiManager from './ApiManager';

type CardPreAuthorizationData = mangoPayTypes.cardPreAuthorization.CardPreAuthorizationData;
type CardRegistrationData = mangoPayTypes.cardRegistration.CardRegistrationData;
type MangoCardData = mangoPayTypes.card.CardData;

export type ExternalTransactionParams = Partial<MangoPayTransaction & PayPalTransaction>;

export interface PayPalOrderResponse {
    links?: Array<{
        rel: string;
        href: string;
    }>;
    [key: string]: any;
}

export type RefundReasonType =
    'no_time' |
    'too_much' |
    'mistake' |
    'restaurant_declined' |
    'restaurant_declined_sold_out' |
    'restaurant_declined_no_capacity' |
    'restaurant_declined_no_reaction' |
    null;


/** Subset of `mangoPayTypes.card.CardType` */
export enum SupportedCardType {
    CB_VISA_MASTERCARD = 'CB_VISA_MASTERCARD',
    MAESTRO = 'MAESTRO'
}

export type CardData = MangoPay.CardData;

interface CreateCardRegistrationResponse {
    PreregistrationData: string;
    AccessKey: string;
    CardRegistrationURL: string;
    Id: string;
}

export interface GetCardsResponse {
    /** from database */
    data: Card;
    /** from mangopay */
    mangoPayData: MangoCardData;
}

export interface MangoPayValidationResult {
    success?: boolean;
    error?: MangoPay.Error;
}

type ApiType = ApiManager<AccountManager<ApiType>>;

export type OrderTime = 'past' | 'future';

 export default abstract class PaymentManager<Api extends ApiType> {
    public static readonly ORDERS_PER_PAGE = 5;

    protected _api: Api;
    protected awaitPaymentUser: Promise<void>;

    private unsubscribeOrders: Partial<Record<OrderTime, () => void>> = {};

    @observable
    private _carts = new Map<string, Cart>();

    @observable
    private _paymentUser: PaymentUser | null;

    @observable
    private _cards?: Array<GetCardsResponse>;

    @computed
    public get userExists() {
        return !!this._paymentUser?.mangoPayData;
    }

    @computed
    public get cards() {
        return this._cards;
    }

    @computed
    public get defaultCardId() {
        return this._paymentUser?.mangoPayData?.defaultCardId;
    }

    constructor(api: Api, waitFor: Promise<any> = Promise.resolve()) {
        this._api = api;
        this._paymentUser = null;

        MangoPay.cardRegistration.baseURL = Constants.MANGOPAY_HOST;
        MangoPay.cardRegistration.clientId = Constants.MANGOPAY_CLIENT_ID;

        this.awaitPaymentUser = new Promise(resolve => {
            waitFor.then(() => {
                // this reaction is never disposed
                reaction(
                    () => this._api.account.user,
                    async () => {
                        if (this._api.account.loggedIn) {
                            // keeping the carts, since user is always anonymous before
                            await this.refresh();
                            resolve();
                        } else {
                            this.reset();
                        }
                    },
                    { fireImmediately: true }
                );
            });
        });
    }

    private serializeOrder(order: Order): OrderData {
        const { restaurant, key, meetUpDate, meetUp, ...orderData } = order;

        return {
            ...orderData,
            restaurantId: restaurant?.key || '',
            meetUp: meetUp || '',
            meetUpDate: meetUpDate.getTime()
        };
    }

    @bind
    private async deserializeOrder(data: OrderData, key: string): Promise<Order | undefined> {
        const { restaurantId, meetUpDate, ...orderData } = data;
        const restaurant = await this._api.getRestaurant(restaurantId, this._api.location.userLocationCoordinate);

        return {
            ...orderData,
            key,
            restaurant,
            meetUpDate: Timestamps.toDate(meetUpDate)
        };
    }

    private async refresh() {
        this._paymentUser = await getPaymentUser(this._api.account.user!.uid) || null;
        await this.getCards();
    }

    private async reset() {
        this._paymentUser = null;
        this._cards = undefined;
        this._carts = new Map<string, Cart>();

        Object.values(this.unsubscribeOrders).forEach(unsubscribeOrder => unsubscribeOrder && unsubscribeOrder());
    }

    private async finishCardPaymentTransaction(id: string) {
        const payIn = await this.getPayIn(id);

        if (payIn.Status === 'FAILED') {
            throw payIn;
        } else if (payIn.Status === 'SUCCEEDED') {
            const response = await HttpRequest.put(`/api/payment/orders/${payIn.Id}/status`);

            return this.deserializeOrder(JSON.parse(response) as OrderData, id);
        }

        throw null;
    }

    private finishPayPalPaymentTransaction(payInId: string) {
        return HttpRequest.post(`/api/payment/orders/${payInId}/capture`);
    }

    public async loadOrder(by: 'payInId' | 'key', id: string) {
        const userId = this._paymentUser?.key;

        if (userId) {
            let query = getPaymentUserRef(userId).collection('orders');
            let orderSnap: firebase.firestore.DocumentSnapshot;

            if (by === 'payInId') {
                const result = await query.where('payInId', '==', id).get();
                orderSnap = result.docs[0];
            } else {
                orderSnap = await query.doc(id).get();
            }

            if (orderSnap) {
                return this.deserializeOrder(orderSnap.data() as OrderData, orderSnap.id);
            }
        }
    }

    public validateCard(cardData: CardData): MangoPayValidationResult {
        const validationResult: MangoPay.ValidateCardDataResult = MangoPay.cardRegistration._validateCardData(cardData);

        if (validationResult === true) {
            return {
                success: true
            };
        } else {
            return {
                error: validationResult
            };
        }
    }

    public async registerCard(cardTypeAlias: CardTypeAlias, cardData: CardData, firstName?: string, lastName?: string) {
        Env.logEvent('add_payment_info', { PAYMENT_TYPE: cardTypeAlias });

        const { cardType } = cardData;

        if (!this.userExists) {
            // if there is no payment user, we first need to create it
            await HttpRequest.post('/api/payment/createNaturalUser', { firstName, lastName });
            await this.refresh();
        }

        const createCardRegistrationResponse = await handleHttpResponse<CreateCardRegistrationResponse>(
            HttpRequest.post('/api/payment/cardRegistration', { cardType })
        );

        MangoPay.cardRegistration.init({
            cardRegistrationURL: createCardRegistrationResponse.CardRegistrationURL,
            preregistrationData: createCardRegistrationResponse.PreregistrationData,
            accessKey: createCardRegistrationResponse.AccessKey,
            Id: createCardRegistrationResponse.Id
        });

        const cardRegistrationResult: CardRegistrationData = await new Promise(async (resolve, reject) =>
            MangoPay.cardRegistration.registerCard(cardData, resolve, reject)
        );
        const cardId = cardRegistrationResult.CardId;

        return this.finishCardRegistration({ cardId, cardTypeAlias });
    }

    public async finishCardRegistration(params: { cardId?: string, preAuthorizationId?: string, cardTypeAlias?: CardTypeAlias }) {
        const response = await handleHttpResponse<CardPreAuthorizationData>(
            HttpRequest.post('/api/payment/finishCardRegistration', params)
        );
        const redirectUrl = (response.SecureModeReturnURL && response.Status === 'CREATED') ? response.SecureModeRedirectURL : undefined;

        if (!redirectUrl) {
            this.refresh();
        }

        return redirectUrl;
    }

    public async getCards() {
        try {
            if (this.userExists) {
                this._cards = await handleHttpResponse(HttpRequest.get('/api/payment/cards'));
            }
        } catch (error) {
            if (!(error instanceof HttpRequestError)) {
                console.warn(error);
            }
        }
    }

    public getCard(cardId?: string) {
        return this.cards?.find(card => card.mangoPayData.Id === cardId);
    }

    public getPaymentTypeAlias(paymentOption?: PaymentOption, cardId?: string): CardTypeAlias | undefined {
        if (!paymentOption) {
            return
        }

        if (paymentOption === 'paypal') {
            return CardTypeAlias.PAYPAL;
        }

        return this.getCard(cardId)?.data.typeAlias;
    }

    public async deactivateCard(cardId: string) {
        Env.logEvent('remove_payment_info', { PAYMENT_TYPE: this.getPaymentTypeAlias('card', cardId) });

        await HttpRequest.post('/api/payment/deactivateCard', { cardId });
        await this.refresh();
    }

    public async setDefaultCard(cardId: string) {
        await HttpRequest.post('/api/payment/setDefaultCard', { cardId });
        await this.refresh();
    }

    /**
     * Creates and returns a new `Cart` for `restaurant` if none exists.
     * Otherwise, the existing `Cart` is returned.
     *
     * @param restaurant
     */
    public getCart(restaurant: RestaurantEntry) {
        if (restaurant?.hasPayment) {
            const cartId = getCartId(restaurant);
            let cart = this._carts.get(cartId);

            if (!cart) {
                let paymentOption: PaymentOption = this.defaultCardId ? 'card' : 'paypal';
                const defaultCardId = this.defaultCardId || (this._cards || [])[0]?.mangoPayData.Id;

                cart = new Cart(paymentOption, defaultCardId, restaurant, this._api);
                this._carts.set(cartId, cart);
            }

            return cart;
        }
    }

    public setCart(cart: Cart) {
        const cartId = getCartId(cart.restaurant);

        this._carts.get(cartId)?.release();
        this._carts.set(cartId, cart);
    }

    public get carts() {
        return this._carts;
    }

    public resetCart(restaurant: RestaurantEntry) {
        const cartId = getCartId(restaurant);

        this._carts.get(cartId)?.release();
        this._carts.delete(cartId); // triggers update more reliably than just re-setting the map entry
        this.getCart(restaurant); // needed to set a new empty cart
    }

    private async getPayIn(payInId: string) {
        const getPayInResponse = await HttpRequest.get(`/api/payment/payIn/${payInId}`);

        return JSON.parse(getPayInResponse) as mangoPayTypes.payIn.PayInData;
    }

    public async order(restaurant: RestaurantEntry) {
        const cart = this.getCart(restaurant);

        try {
            logOrder('purchase', cart, undefined, {
                PAYMENT_TYPE: this.getPaymentTypeAlias(cart?.paymentOption, cart?.debitedCardId),
                isTakeAway: !!cart?.isTakeAway,
                withMeetUp: !!cart?.meetUp
            });

            assert(restaurant?.key, 'Restaurant undefined');
            assert(cart?.isValid, 'Cart invalid');

            const order = cart!.createOrder();
            let redirectUrl: string | undefined;

            cart!.stopSynchronization();

            const response = await HttpRequest.post('/api/payment/orders', this.serializeOrder(order as Order));
            const paymentResponse = JSON.parse(response) as mangoPayTypes.payIn.CardDirectPayInData | PayPalOrderResponse;

            if (cart?.paymentOption === 'card' && paymentResponse.Status === 'FAILED') {
                throw paymentResponse;
            }

            if (order.paymentOption === 'card') {
                redirectUrl = paymentResponse.SecureModeRedirectURL;
            } else if (order.paymentOption === 'paypal') {
                redirectUrl = (paymentResponse as PayPalOrderResponse).links?.find(link => link.rel === 'approve')?.href;
            }

            if (redirectUrl) {
                return redirectUrl;
            } else {
                const newOrder = await this.loadOrder('payInId', paymentResponse.Id);

                assert(newOrder, Env.i18n.t('ErrorPayment_OrderNotFound'));

                if (newOrder!.restaurant) {
                    this.resetCart(newOrder!.restaurant);
                }

                return newOrder!;
            }
        } catch (error) {
            cart?.startSynchronization();
            Env.alert(Env.i18n.t('ErrorPayment_AlertTitle'), paymentError(error));
        }
    }

    public async saveRating(order: Order, rating: OrderRating) {
        try {
            await HttpRequest.post(`/api/payment/orders/${order.key}`, { rating })
        } catch (error) {
            return Promise.reject(paymentError(error));
        }
    }

    public async finishExternalTransaction({ transactionId, token, PayerID }: ExternalTransactionParams) {
        const payInId = transactionId || token;

        if (payInId) {
            try {
                const order = await this.loadOrder('payInId', payInId);
                let success = false;

                assert(order, Env.i18n.t('ErrorPayment_OrderNotFound'));
                assert(order!.restaurant, Env.i18n.t('ErrorPayment_RestaurantUndefined'));

                if (order!.paymentOption === 'card' && transactionId) {
                    await this.finishCardPaymentTransaction(transactionId);
                    success = true;
                } else if (order!.paymentOption === 'paypal' && token && PayerID) {
                    await this.finishPayPalPaymentTransaction(token);
                    success = true;
                }

                if (success) {
                    if (order!.restaurant) {
                        this.resetCart(order!.restaurant);
                    }

                    return order;
                }
            } catch (error) {
                Env.alert(Env.i18n.t('ErrorPayment_AlertTitle'), paymentError(error));
            }
        }
    }

    public validateCart(cart: Cart) {
        return computed(() =>
            cart.isValid && ((cart.paymentOption !== 'card') || this.cards?.some(card => card.mangoPayData.Id === cart.debitedCardId))
        ).get();
    }
}
