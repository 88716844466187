import de from '../../../lib/src/locales/de/de';
import List from '../../../lib/src/types/List';

export default abstract class I18n {
    public static readonly defaultLocale = 'de_DE';

    public static readonly hourFormat = 24; // TODO: localize

    public static t(key: string, values: List<any> = {}) {
        const translations: string | List<string> = de[key];
        let translation = '';

        if (translations !== undefined) {
            if (typeof translations === 'object') {
                const FALLBACK_COUNT_KEY = 'other';
                let countKey = FALLBACK_COUNT_KEY;

                switch (Number(values.count)) {
                    case 1: countKey = 'one'; break;
                }

                translation = translations[countKey] || translations[FALLBACK_COUNT_KEY];
            } else {
                translation = translations;
            }

            Object.entries(values).forEach(([ placeholder, value ]) => {
                translation = translation.replace(new RegExp(`%\\{${placeholder}\\}`, 'g'), value);
            });
        } else {
            translation = `MISSING TRANSLATION FOR "${key}"!`;
        }

        return translation;
    }

    public static currentLocale() {
        return this.defaultLocale; // TODO: localize
    }
}
