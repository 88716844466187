import MailOutline from '@material-ui/icons/MailOutline';
import { bind } from 'decko';
import { computed, IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import { formatDayAndTime } from '../../../../lib/src/helpers/formatting';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import InvitationList from '../../../../lib/src/store/InvitationList';
import { ContactStatus } from '../../../../lib/src/types/models/ContactEntity';
import Invitation, { CANCELATION_DEADLINE_MINUTES } from '../../../../lib/src/types/models/Invitation';
import { isInvitationExpired } from '../../../../lib/src/types/models/UserInvitation';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import ActionButton from '../common/ActionButton';
import ActionSheet from '../common/ActionSheet';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import TransactionsList from '../common/TransactionsList';
import { EmptyInvitationsList } from '../social/EmptyList';
import InvitationCard from './InvitationCard';

interface State extends ModalState<{}> {
    invitations?: InvitationList;
    selectedInvitation?: Invitation;
}

@inject('api', 'invitationDraft', 'payment')
@observer
export default class Invitations extends Modal<{}, State> {
    public readonly state: State = {
        params: {}
    };

    private invitationsUnsubscriber?: IReactionDisposer;
    private actionSheetRef = React.createRef<ActionSheet>();
    private actionButtonRef = React.createRef<ActionButton>();
    private options = [
        {
            label: Env.i18n.t('NewMeetup'),
            icon: MailOutline,
            action: this.startInvitation
        }
    ];

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedInvitationDraftProps & InjectedPaymentProps;
    }

    protected async hydrateParams(params: string[]) {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.loggedIn).get();
    }

    public componentDidMount() {
        super.componentDidMount();

        const { api } = this.injected;

        this.invitationsUnsubscriber = reaction(
            () => api.account.user?.uid,
            userId => this.setState({ invitations: userId ? new InvitationList(userId, api) : undefined }),
            { fireImmediately: true }
        );
    }

    public componentWillUnmount() {
        if (this.invitationsUnsubscriber) {
            this.invitationsUnsubscriber();
            this.state.invitations?.stopWatching();
        }
    }

    public render() {
        return (
            <Screen open={this.paramsAreValid()} handleClose={this.close} fullHeight={true}>
                <ScreenHeader title={Env.i18n.t('Invitations')} onClose={this.close} />
                <FullSizeContent>
                    <TransactionsList<Invitation>
                        transactions={this.state.invitations}
                        renderItem={this.renderInvitation}
                        ListEmptyComponent={this.renderEmptyList}
                        contentContainerStyle={{ paddingTop: GRID_SIZE * 2 }}
                    />
                    {this.renderActionSheet()}
                    <ActionButton
                        ref={this.actionButtonRef}
                        options={this.options}
                        closeOnSelect={true}
                        style={{ margin: SCREEN_PADDING }}
                    />
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private renderInvitation(invitation: Invitation) {
        return (
            <InvitationCard invitation={invitation} onPress={this.selectInvitation} onAddOrder={this.addOrder} onOpenOrder={this.showOrder} />
        );
    }

    @bind
    private renderEmptyList() {
        return (
            <EmptyInvitationsList onPress={this.startInvitation} />
        );
    }

    private renderActionSheet() {
        const invitation = this.state.selectedInvitation;

        if (!invitation) {
            return null;
        }

        const { api } = this.injected;
        const { attendees, restaurant, date, isOwn, status, order } = invitation;
        const userStatus = api.account.getMyStatus(attendees);
        const isExpired = isInvitationExpired(invitation);
        const isDisabled = (status === 'declined') || (status === 'canceled');

        return (
            <ActionSheet
                ref={this.actionSheetRef}
                title={`${restaurant?.name}, ${formatDayAndTime(date)}`}
                onClose={this.unselectInvitation}
                options={[
                    {
                        label: Env.i18n.t('AcceptInvitation'),
                        action: () => this.updateInvitationStatus('accepted'),
                        hideFor: isExpired || isDisabled || (userStatus !== 'pending')
                    },
                    {
                        label: Env.i18n.t(isOwn ? 'CancelInvitation' : 'DeclineInvitation'),
                        action: () => this.updateInvitationStatus('declined'),
                        destructive: true,
                        hideFor: isExpired || isDisabled || !!order || (userStatus === 'declined')
                            || moment().add(CANCELATION_DEADLINE_MINUTES, 'minutes').isAfter(date)
                    },
                    {
                        label: Env.i18n.t('ShowChat'),
                        action: this.showChat
                    },
                    {
                        label: Env.i18n.t('AddOrder'),
                        action: this.addOrder,
                        hideFor: isExpired || !!order || (userStatus !== 'accepted') || (status !== 'accepted')
                            || !restaurant?.hasPayment || !restaurant?.isStillOpenToday
                    },
                    {
                        label: Env.i18n.t('ShowOrder'),
                        action: this.showOrder,
                        hideFor: !order
                    },
                    {
                        label: Env.i18n.t('ShowRestaurantDetails'),
                        action: this.showRestaurant
                    }
                ]}
            />
        )
    }

    @bind
    private selectInvitation(event: React.MouseEvent<HTMLDivElement, MouseEvent>, selectedInvitation: Invitation) {
        this.setState({ selectedInvitation }, () => this.actionSheetRef.current?.show(event.target as HTMLDivElement));
    }

    @bind
    private unselectInvitation() {
        this.setState({ selectedInvitation: undefined });
    }

    @bind
    private startInvitation() {
        this.injected.invitationDraft.create(this.props.navigation.getHash());

        Env.logEvent('meetup_from_meetuplist'); // TODO: docu
        this.redirectTo('invitationrestaurant');
    }

    private handleSelectedInvitation<T>(action: (invitation: Invitation) => T) {
        const { selectedInvitation } = this.state;

        if (selectedInvitation) {
            return action(selectedInvitation);
        }
    }

    @bind
    private async addOrder(toInvitation?: Invitation) {
        const invitation = toInvitation || this.state.selectedInvitation;
        const restaurant = invitation?.restaurant;

        if (restaurant?.routingName) {
            const cart = this.injected.payment.getCart(restaurant);

            cart?.setMeetUp(invitation);
            this.redirectTo('details', [ restaurant.routingName ]);
        }
    }

    @bind
    private async showOrder(orderId?: string) {
        const orderKey = orderId || this.handleSelectedInvitation(({ order }) => order);

        if (orderKey) {
            this.redirectTo('order', [ orderKey ]);
        }
    }

    @bind
    private async showChat() {
        this.handleSelectedInvitation(invitation => {
            // TODO: waiting for LU-892
        });
    }

    @bind
    private async showRestaurant() {
        this.handleSelectedInvitation(({ restaurant }) => {
            if (restaurant?.routingName) {
                this.redirectTo('details', [ restaurant.routingName ]);
            }
        });
    }

    @bind
    private updateInvitationStatus(status: ContactStatus) {
        this.handleSelectedInvitation(async ({ key, isOwn }) => {
            if (status === 'declined') {
                const confirmed = await new Promise(resolve => {
                    const title = Env.i18n.t(isOwn ? 'CancelInvitation' : 'DeclineInvitation');
                    const message = Env.i18n.t(isOwn ? 'ConfirmCancelInvitation' : 'ConfirmDeclineInvitation');

                    Alert.confirm(title, message, resolve);
                });

                if (!confirmed) {
                    return;
                }
            }

            await this.injected.api.waitFor(Backend.updateInvitationStatus(key, status));
        });
    }
}
