import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Alert from 'src/helpers/Alert';

import Env from '../../../../lib/src/Env';
import Backend from '../../../../lib/src/helpers/Backend';
import ContactEntity, { ContactEntityFlags, ContactGroup } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedAccountProps } from '../../Account';
import { PrimarySmallButton, SecondarySmallButton } from '../button';
import ActionSheet from '../common/ActionSheet';
import ContactList from './ContactList';

interface Props {
    contacts: ContactEntity[];
    onNavigate: (route: string, params?: string[]) => void;
    onInvite: (contact: ContactEntity) => void;
    ListEmptyComponent?: React.ReactElement;
}

interface State {
    selectedContact?: ContactEntity;
}

@inject('account')
@observer
export default class ContactsTab extends React.Component<Props, State> {
    public readonly state: State = {};

    private actionSheetRef = React.createRef<ActionSheet>();
    private contactListRef = React.createRef<ContactList>();

    private get injected() {
        return this.props as Props & InjectedAccountProps;
    }

    public render() {
        const { selectedContact } = this.state;
        const selectedIsGroup = selectedContact instanceof ContactGroup;

        return (
            <>
                <ContactList
                    ref={this.contactListRef}
                    contacts={this.props.contacts}
                    renderItemContent={this.renderContact}
                    onItemPress={this.selectContact}
                    ListEmptyComponent={this.props.ListEmptyComponent}
                    dependsOn={this.injected.account.blockingUsersCount}
                />
                <ActionSheet
                    ref={this.actionSheetRef}
                    title={selectedContact?.name}
                    onClose={this.cancelEditContact}
                    options={[
                        {
                            label: Env.i18n.t('ContactMakeFavorite'),
                            action: this.setFavoriteStatus(true),
                            hideFor: selectedContact?.isFavorite
                        },
                        {
                            label: Env.i18n.t('ContactRemoveFavorite'),
                            action: this.setFavoriteStatus(false),
                            hideFor: !selectedContact?.isFavorite
                        },
                        {
                            label: Env.i18n.t('ContactGroupEditName'),
                            action: this.editGroupName,
                            hideFor: !selectedIsGroup
                        },
                        {
                            label: Env.i18n.t('ContactGroupEditMembers'),
                            action: this.editGroupMembers,
                            hideFor: !selectedIsGroup
                        },
                        {
                            label: Env.i18n.t('ContactGroupRemove'),
                            action: this.removeContactGroup,
                            hideFor: !selectedIsGroup
                        },
                        {
                            label: Env.i18n.t('ContactRemove'),
                            action: this.removeContact,
                            hideFor: selectedIsGroup
                        },
                        {
                            label: Env.i18n.t('ContactBlock'),
                            action: this.blockContact,
                            destructive: true,
                            hideFor: selectedIsGroup
                        }
                    ]}
                />
            </>
        );
    }

    @bind
    private cancelEditContact() {
        this.setState({ selectedContact: undefined });
    }

    @bind
    private selectContact(selectedContact: ContactEntity, target?: HTMLElement) {
        if (!this.state.selectedContact) {
            this.setState({ selectedContact }, () => this.actionSheetRef.current?.show(target));
        }
    }

    @bind
    private async handlePromise(
        promiseMaker: (contact: ContactEntity) => Promise<any>,
        successMessage: string,
        errorMessage: string
    ) {
        const contact = this.state.selectedContact;

        if (contact) {
            this.contactListRef.current?.waitFor(contact, promiseMaker, successMessage, errorMessage);
        }
    }

    @bind
    private setFavoriteStatus(favoriteStatus: boolean) {
        return () => this.handlePromise(
            contact => this.injected.account.updateFlag(contact, ContactEntityFlags.IS_FAVORITE, favoriteStatus),
            Env.i18n.t(favoriteStatus ? 'SuccessFavoriteSet' : 'SuccessFavoriteUnset'),
            Env.i18n.t('ErrorFavoriteNotSet')
        );
    }

    @bind
    private async blockContact() {
        this.handlePromise(
            contact => Backend.blockUsers([contact.key]),
            // TODO: re-activate for contacts?
            // {
            //     title: Env.i18n.t('SuccessBlockedUserSet'),
            //     label: Env.i18n.t('ViewBlockedUsers'),
            //     action: this.viewBlockedUsers
            // },
            Env.i18n.t('SuccessBlockedUserSet'),
            Env.i18n.t('ErrorBlockedUserNotSet')
        );
    }

    @bind
    private async removeContact() {
        this.handlePromise(
            contact => Backend.removeContacts([contact.key]),
            Env.i18n.t('SuccessContactsRemoved', { count: 1 }),
            Env.i18n.t('ErrorContactsNotRemoved', { count: 1 })
        );
    }

    @bind
    private editGroupMembers() {
        const group = this.state.selectedContact;

        if (group instanceof ContactGroup) {
            this.injected.account.loadGroupDraft(group);

            this.props.onNavigate('editgroup');
        }
    }

    @bind
    private editGroupName() {
        const group = this.state.selectedContact;

        if (group instanceof ContactGroup) {
            this.injected.account.loadGroupDraft(group);

            this.props.onNavigate('editgroupname');
        }
    }

    @bind
    private async removeContactGroup() {
        if (this.state.selectedContact instanceof ContactGroup) {
            this.handlePromise(
                group => this.injected.account.removeGroup(group as ContactGroup),
                Env.i18n.t('SuccessGroupRemoved'),
                Env.i18n.t('ErrorGroupNotRemoved')
            );
        }
    }

    private handleContactButtonClick(handler: () => void) {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handler();
        };
    }

    @bind
    private renderContact(contact: ContactEntity) {
        const isBlocking = this.injected.account.isBlockedBy(contact);

        return isBlocking ? (
            <SecondarySmallButton onClick={this.handleContactButtonClick(() => Alert.notify('', Env.i18n.t('UninvitableUser', contact)))}>
                {Env.i18n.t('Invite')}
            </SecondarySmallButton>
        ) : (
            <PrimarySmallButton onClick={this.handleContactButtonClick(() => this.props.onInvite(contact))}>
                {Env.i18n.t('Invite')}
            </PrimarySmallButton>
        );
    }
}
