import React from 'react';

import Env from '../../../../lib/src/Env';
import RestaurantEntry from '../../../../lib/src/types/models/RestaurantEntry';
import { GRID_SIZE } from '../../styles/base';
import ScreenModal, { ScreenModalProps } from '../common/ScreenModal';
import AdditionalDetails from './AdditionalDetails';
import Contact from './Contact';
import Description from './Description';
import OpeningHours from './OpeningHours';
import WebsiteLink from './WebsiteLink';

interface Props extends ScreenModalProps {
    restaurant: RestaurantEntry;
    onOpenMoia?: () => void;
}

export default class InfoModal extends React.PureComponent<Props> {
    public render() {
        const { restaurant, onOpenMoia, modalRef } = this.props;
        const padding = `0 ${GRID_SIZE * 2}px ${GRID_SIZE * 10}px`;

        return (
            <ScreenModal ref={modalRef} title={Env.i18n.t('RestaurantInfoTitle')} style={{ padding }}>
                <Contact restaurant={restaurant} onOpenMoia={onOpenMoia} />
                <Description restaurant={restaurant} />
                <WebsiteLink restaurant={restaurant} />
                <OpeningHours restaurant={restaurant} />
                <AdditionalDetails restaurant={restaurant} />
            </ScreenModal>
        );
    }
}
