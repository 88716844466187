import { DataListEntry } from '../../store/DataList';
import { ContactPerson } from './ContactEntity';

export interface UserResponse {
    uid: string;
    displayName: string;
    photoURL?: string;
}

// TODO: move to ContactPerson?
export function createPersonFromResponse(response: UserResponse) {
    return ContactPerson.createFrom(response.uid, response.displayName, response.photoURL);
}

export interface TagResponse {
    id: string;
    translation: string;
    name: string;
}

export interface PlaceResponse extends DataListEntry {
    description: string;
    id: string;
    place_id: string;
    structured_formatting: {
        main_text: string,
        secondary_text: string
    };
}

export default interface PlacesResponse extends DataListEntry {
    status: string;
    predictions: PlaceResponse[];
    terms: object[];
    types: string[];
    results: GeocodeResponse[];
}

export type AddressComponentType =
    | 'street_number' | 'route' | 'political' | 'sublocality' | 'sublocality_level_1' | 'locality'
    | 'administrative_area_level_1' | 'country' | 'postal_code' | 'street_address';

interface AddressComponent {
    long_name: string;
    short_name: string;
    types: AddressComponentType[];
}

export interface Coords {
    lat: number;
    lng: number;
}

export interface GeocodeResponse {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: {
        location: Coords;
        location_type?: string;
    };
    place_id: string;
    plus_code: {
        compound_code: string;
        global_code: string;
    };
    types: AddressComponentType[];
}

export interface AddressResponse {
    formattedAddress?: string;
    street?: string;
    streetNumber?: string;
    postalCode?: string;
    locality?: string;
    country?: string;
}

export interface MealResponse extends DataListEntry {
    description: string;
    name: string;
    restaurantId: string;
    mealTypeId: string;
}
