import * as React from 'react';
import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

import Env from '../../../../lib/src/Env';

const DefaultImage = styled.img`
    object-fit: cover;
    overflow: hidden;
`;

export default class Image extends React.PureComponent<ImgHTMLAttributes<HTMLImageElement>> {
    public render() {
        return (
            <DefaultImage
                {...this.props}
                style={this.props.style}
                onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = Env.assets.fallbackImage;
                }}
            />
        );
    }
}
