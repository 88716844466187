import { DialogTitle } from '@material-ui/core';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Constants from '../../../../lib/src/Constants';
import Env from '../../../../lib/src/Env';
import Arrays from '../../../../lib/src/helpers/Arrays';
import { InjectedInvitationDraftProps } from '../../../../lib/src/managers/InvitationDraftManager';
import colors from '../../../../lib/src/styles/colors';
import ContactEntity, { ContactGroup, countContacts } from '../../../../lib/src/types/models/ContactEntity';
import { InjectedApiProps } from '../../Api';
import Alert from '../../helpers/Alert';
import { GRID_SIZE } from '../../styles/base';
import { PrimaryButton } from '../button';
import EmptyListIndicator from '../common/EmptyListIndicator';
import { DiskButton } from '../common/IconButton';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen, { FullSizeContent } from '../common/Screen';
import ScreenHeader from '../common/ScreenHeader';
import ContactChips from '../contacts/ContactChips';
import ContactList from '../contacts/ContactList';
import { EmptyContactsList } from '../social/EmptyList';
import { ErrorText, ListFooterHint, SublineText } from '../text';
import InviteDatePicker from './InviteDatePicker';

interface State extends ModalState<{}> {
    selection: ContactEntity[];
    showMaxAttendeesWarning: boolean;
    fadeOutUnselectedContacts: boolean;
}

const WRAP_THRESHOLD = 850;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-left: 6%;
    white-space: nowrap;

    @media (max-width: ${WRAP_THRESHOLD}px) {
        flex-wrap: wrap;
        margin-left: 5%;
    }
`;

// @ts-ignore ts(2615)
const ActionButton = styled(DiskButton).attrs({
    diskColor: colors.teal_50,
    diskSize: GRID_SIZE * 6

})`
    &&& {
        border-color: ${colors.grey_03};
        margin-top: 4%;
    }
`;

@inject('api', 'invitationDraft')
export default class InvitationContacts extends Modal<{}, State> {
    public readonly state: State = {
        selection: [],
        params: {},
        showMaxAttendeesWarning: false,
        fadeOutUnselectedContacts: false
    };

    private datePickerRef = React.createRef<InviteDatePicker>();

    private get injected() {
        return this.props as ModalProps & InjectedApiProps & InjectedInvitationDraftProps;
    }

    @computed
    private get updating() {
        return !!this.injected.invitationDraft.get().attendees?.length;
    }

    protected async hydrateParams() {
        return {};
    }

    protected validateParams() {
        return computed(() => this.injected.api.account.verified && !this.injected.invitationDraft.get().key).get();
    }

    public componentDidMount() {
        super.componentDidMount();

        const draft = this.injected.invitationDraft.get();
        const { user } = this.injected.api.account;
        const selection = (draft.attendees && draft.dirty)
            ? draft.attendees.filter(attendee => attendee.key !== user!.uid)
            : [];

        this.setState({ selection });
    }

    public render() {
        const { contactEntityList } = this.injected.api.account;
        const { selection, showMaxAttendeesWarning, fadeOutUnselectedContacts } = this.state;

        return (
            <Screen
                open={this.paramsAreValid()}
                handleClose={this.handleClose}
                fullHeight={true}
                FooterComponent={this.renderCta}
            >
                <HeaderContainer>
                    <ScreenHeader title={Env.i18n.t('InviteContacts')} onBack={this.back}>
                        <ContactChips contacts={selection} toggleContact={this.toggleContactSelection} />
                        {selection.length <= 0 && (
                            <SublineText>
                                {Env.i18n.t('AddContactCTA')}
                            </SublineText>
                        )}
                        {showMaxAttendeesWarning && (
                            <ErrorText>
                                {Env.i18n.t('MaxAttendeesWarning', { count: Constants.MAX_ATTENDEES - 1 })}
                            </ErrorText>
                        )}
                    </ScreenHeader>
                    <ActionButton icon={require('../../assets/svg/add.svg')} onClick={this.searchContact} />
                </HeaderContainer>
                <FullSizeContent style={{ marginTop: GRID_SIZE * -1.5 }}>
                    <ContactList
                        contacts={contactEntityList}
                        filterBy={this.isInvitable}
                        onItemPress={this.toggleContactSelection}
                        selection={selection.map(contact => contact.key)}
                        fadeOutUnselectedContacts={fadeOutUnselectedContacts}
                        ListEmptyComponent={this.renderEmptyList()}
                        ListFooterComponent={
                            <ListFooterHint>
                                {Env.i18n.t('UninvitableUsersHint')}
                            </ListFooterHint>
                        }
                    />
                    <InviteDatePicker ref={this.datePickerRef} onConfirm={this.applySelectionAndContinue} />
                </FullSizeContent>
            </Screen>
        );
    }

    @bind
    private renderCta() {
        return (
            <DialogTitle>
                <PrimaryButton onClick={this.confirmSelection}>
                    {Env.i18n.t(this.updating ? 'UpdateAttendees' : 'ArrangeMeetup')}
                </PrimaryButton>
            </DialogTitle>
        );
    }

    @bind
    private handleClose() {
        Alert.confirmInvitationDiscard(this.injected.invitationDraft.get(), confirmed => {
            if (confirmed) {
                this.close();
            }
        });
    }

    @bind
    private confirmSelection() {
        if (this.updating) {
            this.applySelectionAndContinue();
        } else {
            this.datePickerRef.current?.open();
        }
    }

    @bind
    private async applySelectionAndContinue() {
        const { invitationDraft, api } = this.injected;
        const draft = invitationDraft.get();
        const me = api.account.currentContactPerson;
        const newAttendees = this.state.selection.map(contact => contact.withStatus('pending'));

        if (!draft.dirty && draft.attendees) {
            newAttendees.push(...draft.attendees);
        } else if (me) {
            newAttendees.push(me);
        }

        invitationDraft.setAttendees(newAttendees);

        this.redirectTo('invitationdraft');
    }

    @bind
    private searchContact() {
        this.redirectTo('addcontacts');
    }

    @bind
    private toggleContactSelection(contact: ContactEntity) {
        this.setState(state => {
            const newSelection = Arrays.toggle(state.selection.slice(), contact);
            const newNumberOfAttendees = countContacts(newSelection);
            const showMaxAttendeesWarning = (newNumberOfAttendees >= Constants.MAX_ATTENDEES - 1);
            const selection = (newNumberOfAttendees > Constants.MAX_ATTENDEES - 1) ? state.selection : newSelection;
            const numberOfAttendees = countContacts(selection);
            const fadeOutUnselectedContacts = (numberOfAttendees >= Constants.MAX_ATTENDEES - 1);

            return { selection, showMaxAttendeesWarning, fadeOutUnselectedContacts };
        });
    }

    private renderEmptyList() {
        return this.injected.api.account.contacts.empty ? (
            <EmptyContactsList onPress={() => this.redirectTo('addcontacts')} />
        ) : (
            <EmptyListIndicator
                waitFor={true}
                icon={require('../../assets/svg/empty_state_user.svg')}
                hint={Env.i18n.t('AllUsersInvited')}
            />
        );
    }

    @bind
    private isInvitable(contact: ContactEntity): boolean {
        const draft = this.injected.invitationDraft.get();
        const group = contact instanceof ContactGroup ? contact as ContactGroup : undefined;
        const isInvitableGroup = group?.members.map(key => ({ key } as ContactEntity)).some(this.isInvitable);

        return isInvitableGroup || (
            !group &&
            // not blocking
            !this.injected.api.account.isBlockedBy(contact) &&
            // draft or not yet attendee
            (draft.dirty || !draft.attendees?.some(attendee => attendee.key === contact.key))
        );
    }
}
