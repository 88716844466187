import { bind } from 'decko';
import { computed, observable, reaction } from 'mobx';
import moment, { Moment } from 'moment';

import Env from '../../Env';
import Arrays from '../../helpers/Arrays';
import { formatDuration, formatNumber, formatTimeSpan } from '../../helpers/formatting';
import GoogleMapsApi from '../../helpers/GoogleMapsApi';
import { getNormalizedOpeningHours } from '../../helpers/Validate';
import { DataListEntry } from '../../store/DataList';
import { PerishableValue } from '../../store/Perishable';
import LatLng from '../LatLng';
import List from '../List';
import { CooperationType, Meal, Medium, Medium_Type, Restaurant, Times, TimesTimeSpan } from '../lunchnow';
import { ORDER_ADVANCEMENT, ORDER_PROCESS_ETA } from './Order';
import { AddressResponse } from './Response';

export interface Tag extends DataListEntry {
    name: string;
    type: string;
    color: string;
    popularity: number;
    translations: List<string>;
}

export interface RestTime extends DataListEntry {
    translations: List<string>;
}

export enum TransportationType {
    WALKING = 'WALKING',
    BIKING = 'BIKING'
}

interface TransportationInfo {
    distanceInMeters: number;
    distanceDescription: string;
    transportationType: TransportationType;
    isMoiaEnabled: boolean;
    durationDescription: string;
}

export type MealEntry = Meal & DataListEntry & {
    typeKey?: string;
};

export interface InitialRestaurantResponseModel {
    key: string;
    name: string;
    type: string;
    location: LatLng;
    routingName: string;
    logo?: {
        url: string;
        type: string;
    };
    media?: Array<Medium>;
    tagIds: Array<string>;
    address: AddressResponse;
    premiumStart?: number;
    premiumEnd?: number;
    onlyTakeAway?: boolean;
}

interface RestaurantEntryCreateByKey {
    createBy: 'key';
    key: string;
}

interface RestaurantEntryCreateByInitialData {
    createBy: 'initialData';
    initialData: InitialRestaurantResponseModel;
}

interface RestaurantEntryCreateByData {
    createBy: 'data';
    restaurant: Restaurant;
    key: string;
}

type RestaurantEntryCreateData = RestaurantEntryCreateByKey | RestaurantEntryCreateByInitialData | RestaurantEntryCreateByData;

interface OpenDay {
    date: Moment;
    openingHours: TimesTimeSpan[];
}

type OpeningHoursType = 'TodayUntil' | 'TodayAt' | 'Tomorrow' | 'ThisWeek' | 'NextWeek' | 'Later';

interface OpeningHours {
    date: Moment;
    type: OpeningHoursType;
    nextOpeningHours?: TimesTimeSpan;
    open: boolean;
}

type MealListener = (meals: MealEntry[]) => void;

const MIN_EATING_MINUTES = 30;
// Parantheses seem necessary to keep VSCode from removing the constants import...
const ORDER_ADVANCEMENT_MINUTES = (ORDER_ADVANCEMENT) + (ORDER_PROCESS_ETA) + MIN_EATING_MINUTES;

// TODO: logically we should move this file to src/store
export default class RestaurantEntry implements DataListEntry {
    public readonly key: string;

    private _initialData?: InitialRestaurantResponseModel;
    private _mealListeners: MealListener[] = [];
    private _mealListenerDisposer?: () => void;

    @observable
    private _dataPending = false;

    @observable
    private _data?: Restaurant;

    @observable
    private _offers?: MealEntry[];

    @observable
    public initialDistanceKm?: number; // distance to the search location, used for sorting

    @observable
    public distanceToUserLocationKm?: number; // distance to user location, used for travel time estimation

    constructor(data: RestaurantEntryCreateData, searchLocation?: LatLng, userLocation?: LatLng) {
        switch (data.createBy) {
            case 'key':
                this.key = data.key as string;
                break;
            case 'initialData':
                this.key = data.initialData.key;
                this._initialData = data.initialData;
                break;
            case 'data':
                this.key = data.key;
                this._data = data.restaurant;
                break;
            default:
                throw new Error('Invalid data for creating the RestaurantEntry');
        }

        this.updateDistance(searchLocation, userLocation);

        reaction(
            () => this._offers,
            offers => {
                if (offers) {
                    this._mealListeners.forEach(listener => listener(offers));
                }
            }
        );
    }

    public static getMediaUrl(medium: Medium) {
        switch (medium.type) {
            case Medium_Type.image:
                return this.midSizeImage(medium);
            case Medium_Type.video:
                return `https://img.youtube.com/vi/${medium.youtubeId}/hqdefault.jpg`;
            default:
                return medium.url;
        }
    }

    public static getVideoUrl(medium: Medium) {
        return medium.youtubeId
            ? `https://www.youtube.com/embed/${medium.youtubeId}?modestbranding=1&iv_load_policy=3&rel=0`
            : undefined;
    }

    private static midSizeImage(medium?: Medium) {
        return medium?.url?.replace(/(\.jpg)?$/i, '_1280_color.jpg') || Env.assets.fallbackImage;
    }

    private static createMealFromSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
        const data = snapshot.data() as Meal;

        return {
            ...data,
            key: snapshot.id,
            typeKey: data.type?.id
        } as MealEntry;
    }

    private static createMealsFromSnapshot(snapshot: firebase.firestore.QuerySnapshot) {
        return snapshot.docs
            .map(this.createMealFromSnapshot)
            .sort((a, b) => (b.order || 0) - (a.order || 0));
    }

    public static compareByDistance(restaurant1: RestaurantEntry, restaurant2: RestaurantEntry) {
        return (restaurant1.initialDistanceKm || 0) - (restaurant2.initialDistanceKm || 0);
    }

    public async getRestaurantData(searchLocation?: LatLng) {
        this._dataPending = true;

        const restaurantSnap = await this.ref.get();

        if (!restaurantSnap.exists) {
            throw new Error(`restaurant ${this.key} not found`);
        }

        this._data = (restaurantSnap!.data() || {}) as Restaurant;
        this._dataPending = false;

        this.updateDistance(searchLocation);
    }

    public updateDistance(searchLocation?: LatLng, userLocation?: LatLng) {
        if (this.location && searchLocation) {
            this.initialDistanceKm = GoogleMapsApi.getDistance(this.location, searchLocation);
            this.distanceToUserLocationKm = userLocation ? GoogleMapsApi.getDistance(this.location, userLocation) : this.initialDistanceKm;
        }
    }

    /**
     * @returns unsubscribe function
     */
    public addMealsListener(listener: MealListener, fireImmediately = true) {
        const startListening = !this._mealListeners.length;

        this._mealListeners = Arrays.add(this._mealListeners, listener);

        if (startListening) {
            this._mealListenerDisposer = this.getMealsQuery().onSnapshot(querySnapshot =>
                this._offers = RestaurantEntry.createMealsFromSnapshot(querySnapshot)
            );
        } else if (this._offers && fireImmediately) {
            listener(this._offers);
        }

        return () => {
            this._mealListeners = Arrays.remove(this._mealListeners, listener);

            if (!this._mealListeners.length && this._mealListenerDisposer) {
                this._mealListenerDisposer();
            }
        };
    }

    public getOpeningHours(forDate = moment()) {
        const { openingHours } = this.data || {};

        return (openingHours && openingHours[forDate.isoWeekday() as keyof Times]) || [];
    }

    public isResting(date = moment()) {
        const { start, end } = this.data?.restTime || {};

        return start && end && date.isBetween(start.toDate(), end.toDate());
    }

    public getLunchTimes() {
        const { lunchTimes } = this.data || {};

        return (lunchTimes && lunchTimes[moment().isoWeekday() as keyof Times]) || [];
    }

    public getOpeningHoursHint(): PerishableValue<string> {
        const { date, type } = this.getNextOpeningHours();
        const value = (type === 'TodayUntil')
            ? date.format(Env.i18n.t('OpenTodayUntil'))
            : Env.i18n.t('Opens', { date: date.format(Env.i18n.t(`DateFormat_${type}`)) });

        return { value, expires: date };
    }

    public getClosingHint(): PerishableValue<string> {
        const { date, open } = this.getNextOpeningHours();
        let value: string | undefined;
        let expires = date;

        if (open) {
            const closesSoonAfter = moment(date).subtract(ORDER_ADVANCEMENT_MINUTES, 'minutes');

            if (closesSoonAfter.isBefore()) {
                value = `${Env.i18n.t('ClosesSoon')}\n`;
            } else {
                expires = closesSoonAfter;
            }
        } else {
            value = `${Env.i18n.t('Closed')}\n`;
        }

        return { value, expires };
    }

    @bind
    public validateTimeForOrdering(now = moment()): PerishableValue<string> {
        const { date, type, nextOpeningHours } = this.getNextOpeningHours(undefined, now);
        const orderForerun = this.data?.orderForerunMinutes || 0;

        if (type === 'TodayAt') {
            if (orderForerun > 0 && moment(now).add(orderForerun, 'minutes').isSameOrAfter(date)) { // already orderable
                const expires = this.setTime(moment(date), nextOpeningHours!.end).subtract(ORDER_ADVANCEMENT_MINUTES, 'minutes');

                return { expires };
            }
        } else if (type === 'TodayUntil') {
            const expires = moment(date).subtract(ORDER_ADVANCEMENT_MINUTES, 'minutes');

            if (expires.isAfter(now)) { // still orderable
                return { expires };
            } else { // try again after current hours
                return this.validateTimeForOrdering(moment(date).add(1, 'minute'));
            }
        }

        // currently closed
        const expires = moment(date).subtract(orderForerun, 'minutes');
        const value = Env.i18n.t('ClosedForOrdering', { date: expires.format(Env.i18n.t(`DateFormat_${type}`)) });

        return { value, expires };
    }

    @bind
    public validateLunchTimeForOrdering(): PerishableValue<string> {
        const lunchTimes = this.getLunchTimes();
        let value: string | undefined;
        let expires: Moment | undefined;

        if (lunchTimes.length) {
            const orderForerun = this.data?.orderForerunMinutes || 0;
            const { time, open } = this.getNextTime(lunchTimes, moment(), orderForerun, ORDER_ADVANCEMENT_MINUTES);

            if (time) {
                expires = this.setTime(moment(), open ? time.end : time.start);
            }

            if (!time || !open) {
                value = Env.i18n.t('LunchNotAvailable', { times: lunchTimes.map(formatTimeSpan).join(', ') });
            }
        }

        return { value, expires };
    }

    private getNextOpeningHours(forDay = moment(), now = moment()): OpeningHours {
        const { date, openingHours } = this.getNextOpenDay(forDay);

        if (this.isResting(date)) { // resting, try next day after that
            return this.getNextOpeningHours(moment(this.data!.restTime!.end!.toDate()).add(1, 'day'));
        } else {
            const daysFromNow = date.diff(moment(now).startOf('day'), 'days');
            let nextOpeningHours: TimesTimeSpan | undefined = openingHours[0];
            let open = false;
            let type: OpeningHoursType = 'Later'; // most unspecific fallback

            if (daysFromNow === 0) { // is today
                const nextOpeningHoursToday = this.getNextTime(openingHours, now);

                if (nextOpeningHoursToday.time) { // (will) open today
                    nextOpeningHours = nextOpeningHoursToday.time;
                    open = nextOpeningHoursToday.open;
                    type = open ? 'TodayUntil' : 'TodayAt';
                    this.setTime(date, open ? nextOpeningHours.end : nextOpeningHours.start);
                } else { // closed for today, try tomorrow
                    return this.getNextOpeningHours(moment(now).add(1, 'day'));
                }
            } else {
                this.setTime(date, nextOpeningHours?.start);

                if (daysFromNow === 1) { // opens tomorrow
                    type = 'Tomorrow';
                } else if (daysFromNow < 7) { // opens this week
                    type = 'ThisWeek';
                } else if (daysFromNow < 14) { // opens next week
                    type = 'NextWeek';
                }
            }

            return { date, type, nextOpeningHours, open };
        }
    }

    /**
     * @param times Timespans to search (ordered from earliest to latest)
     * @param now Moment to consider as now
     * @param forerunMinutes Minutes to to virtually turn back the timespan's start time
     * @param advancementMinutes  Minutes to to virtually turn back the timespan's end time
     */
    private getNextTime(times: TimesTimeSpan[], now = moment(), forerunMinutes = 0, advancementMinutes = 0) {
        const startNow = moment(now).add(forerunMinutes, 'minutes').format('HH:mm');
        const endNow = moment(now).add(advancementMinutes, 'minutes').format('HH:mm');
        const time = times.find(({ end }) => end! > endNow);

        return { time, open: (time?.start! < startNow) };
    }

    /**
     * `date` is mutated.
     */
    private setTime(date: Moment, time?: string) {
        if (time) {
            const [ hours, minutes ] = time.split(':').map(Number);

            date.hours(hours);
            date.minutes(minutes);
        }

        return date;
    }

    private getNextOpenDay(afterDay = moment()): OpenDay {
        let openingHours: TimesTimeSpan[] = [];
        let date = afterDay;

        for (let count = 0; count < 7; count++) {
            date = moment(afterDay).add(count, 'days');
            openingHours = this.getOpeningHours(date);

            if (openingHours.length > 0) {
                break;
            }
        }

        return { date, openingHours };
    }

    private get ref() {
        return Env.partnerFirebase.firestore().collection('restaurants').doc(this.key);
    }

    @computed
    public get data() {
        // if data has loaded, return it
        if (this._data) {
            return this._data;
        }

        // if it is not loading, load it
        // the component (that needs to have @observer) will then get the requested data and render again
        if (!this._dataPending) {
            this.getRestaurantData();
        }
    }

    @computed
    public get offers() {
        if (!this._offers) {
            this.getMealsQuery().get().then(snapshot => this._offers = RestaurantEntry.createMealsFromSnapshot(snapshot));
        }

        return this._offers;
    }

    @computed
    public get hasOrderableMeals() {
        return this.hasPayment && !!this.offers?.length;
    }

    @computed
    public get isPremium() {
        const premiumStart = this._data?.premiumStart?.toMillis() || this._initialData?.premiumStart;
        const premiumEnd = this._data?.premiumEnd?.toMillis() || this._initialData?.premiumEnd;

        if (premiumStart && premiumEnd) {
            const start = moment.utc(premiumStart);
            const end = moment.utc(premiumEnd).endOf('day');

            return moment().isBetween(start, end);
        }
    }

    @computed
    public get location(): LatLng | undefined {
        if (this._data?.location.geopoint?.latitude && this._data?.location.geopoint?.longitude) {
            return {
                latitude: this._data.location.geopoint.latitude,
                longitude: this._data.location.geopoint.longitude
            };
        } else {
            return this._initialData?.location;
        }
    }

    @computed
    public get isNew() {
        if (!this.data?.activeSince) {
            return false;
        }

        const activeSinceDate = moment(this.data.activeSince.toDate());

        return moment().diff(activeSinceDate, 'months') <= 3;
    }

    @computed
    public get routingName() {
        return this._data?.routingName || this._initialData?.routingName;
    }

    @computed
    public get name() {
        return this._data?.name || this._initialData?.name;
    }

    @computed
    public get media() {
        return this._data?.media || this._initialData?.media || [] as Array<Medium>;
    }

    @computed
    public get logo() {
        return this._data?.logo || this._initialData?.logo as Medium | undefined;
    }

    @computed
    public get type() {
        return this._data?.type || this._initialData?.type || CooperationType.Restaurant;
    }

    @computed
    public get tagIds() {
        return this._data?.tags ? this._data.tags.map((tag) => tag.id) : this._initialData?.tagIds;
    }

    @computed
    public get address() {
        return this._data?.address || this._initialData?.address;
    }

    @computed
    public get onlyTakeAway() {
        return this._data?.onlyTakeAway || this._initialData?.onlyTakeAway;
    }

    @computed
    public get midSizeFirstImage() {
        return RestaurantEntry.midSizeImage(this.firstPhotoMedia);
    }

    @computed
    public get firstPhotoMedia() {
        return this.media && (Object.values(this.media).find(medium => medium.type === Medium_Type.image) || this.logo);
    }

    @computed
    public get firstVideoMedia() {
        return this.media && Object.values(this.media).find(medium => medium.type === Medium_Type.video);
    }

    @computed
    public get transportationInfo() {
        const WALKING_KM_PER_HOUR = 4;
        const BIKING_KM_PER_HOUR = 20;

        const transportationInfo: TransportationInfo = {
            distanceInMeters: 0,
            distanceDescription: '',
            transportationType: TransportationType.WALKING,
            isMoiaEnabled: false,
            durationDescription: ''
        };

        const distanceInMeters = this.distanceToUserLocationKm !== undefined
            ? this.distanceToUserLocationKm * 1000
            : undefined;

        if (distanceInMeters === undefined) { // to allow 0 we can't simply check for truthiness here
            transportationInfo.distanceDescription = Env.i18n.t('FarAway');
        } else {
            transportationInfo.distanceDescription = distanceInMeters <= 950
                ? Env.i18n.t('Meters', { meters: Math.ceil(distanceInMeters / 50) * 50 })
                : Env.i18n.t('Kilometers', { kilometers: formatNumber(Math.ceil(distanceInMeters / 100) / 10) });
            let minutes = Math.ceil(distanceInMeters / WALKING_KM_PER_HOUR * 60 / 1000);

            if (minutes >= 8 && this.address?.locality === 'Hamburg') {
                transportationInfo.isMoiaEnabled = true;
            }

            if (minutes > 15) {
                minutes = Math.ceil(distanceInMeters / BIKING_KM_PER_HOUR * 60 / 1000);
                transportationInfo.transportationType = TransportationType.BIKING;
            }

            transportationInfo.durationDescription = formatDuration(minutes);
        }

        return transportationInfo;
    }

    @computed
    public get hasPayment() {
        const paymentData = this.data?.mangoPayData;

        return !!(paymentData?.userId && paymentData?.creditedWalletId);
    }

    @computed
    public get isStillOpenToday() {
        const closingTimestamps = getNormalizedOpeningHours(this, moment()).map(({ end }) => {
            if (end) {
                const [ hour, minute ] = end.split(':').map(Number);

                return moment().set({ hour, minute, second: 0, millisecond: 0 }).valueOf();
            }

            return 0;
        });

        return Math.max(...closingTimestamps) > new Date().valueOf();
    }

    private getMealsQuery() {
        return this.ref.collection('offers').where('formattedDate', '==', moment().format('YYYY-MM-DD'));
    }
}
