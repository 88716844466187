import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

import { WhiteOverlay } from '../../styles/base';

interface Props {
    position?: 'absolute' | 'fixed'
}

export default class LoadingIndicator extends React.PureComponent<Props> {
    public render() {
        const position = this.props.position || 'fixed'

        return (
            <WhiteOverlay style={{ position }}>
                <CircularProgress style={{ display: 'inline-block', position: 'relative' }} />
            </WhiteOverlay>
        );
    }
}
