import LatLng from '../types/LatLng';

export default class Locations {
    public static readonly fallbackLocation: LatLng = {
        latitude: 53.55,
        longitude: 9.99
    };

    public static normalize(location: any): LatLng {
        return {
            latitude: this.getFirstDefined([ '_latitude', 'latitude', '_lat', 'lat' ], location) || this.fallbackLocation.latitude,
            longitude: this.getFirstDefined([ '_longitude', 'longitude', '_long', 'long', 'lng' ], location) || this.fallbackLocation.longitude
        };
    }

    public static toString(location: LatLng) {
        return location.latitude + ',' + location.longitude;
    }

    public static isFallback(location: LatLng) {
        return location.latitude === this.fallbackLocation.latitude && location.longitude === this.fallbackLocation.longitude;
    }

    private static getFirstDefined(properties: string[], ofObject?: object) {
        if (ofObject) {
            const definedProperty = properties.find(propery => ofObject[propery] !== undefined);

            return definedProperty && ofObject[definedProperty];
        }
    }
}
