import * as React from 'react';
import styled from 'styled-components';

import GoogleMapsApi from '../../../../lib/src/helpers/GoogleMapsApi';
import LatLng from '../../../../lib/src/types/LatLng';
import BackgroundImage from '../common/BackgroundImage';

// @ts-ignore ts(2615)
const MapContainer = styled(BackgroundImage)<{ aspectRatio: number }>`
    padding-top: ${props => `${props.aspectRatio * 100}%`};
    position: relative;
    width: 100%;
    z-index: 1000;
`;

const MapContent = styled.div`
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    location: LatLng;
    zoom: number;
    height: number;
    width: number;
}

export default class MapImage extends React.PureComponent<Props> {
    private mapUri: string;
    private aspectRatio: number;
    private divProps: React.HTMLAttributes<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        const { location, zoom, height, width, ...divProps } = this.props;

        this.mapUri = GoogleMapsApi.getImageUrl(width, height, location, zoom);
        this.aspectRatio = height / width;
        this.divProps = divProps;
    }

    public render() {
        return (
            <MapContainer src={this.mapUri} aspectRatio={this.aspectRatio} {...this.divProps}>
                <MapContent>
                    {this.props.children}
                </MapContent>
            </MapContainer>
        );
    }
}
