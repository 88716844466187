import { Coords } from 'google-map-react';
import React from 'react';

type MarkerType = 'red' | 'teal' | 'premium';

interface Props extends Coords {
    onClick?: () => void;
    markerType?: MarkerType;
    secondary?: boolean;
}

export default class Marker extends React.PureComponent<Props> {
    private static markerImages: { [k in MarkerType]: string } = {
        red: require('../../assets/png/red_marker.png'),
        teal: require('../../assets/png/teal_marker.png'),
        premium: require('../../assets/png/premium_marker.png')
    };

    public render() {
        const style = {
            width: 12,
            height: 12
          }
        
          if (this.props.markerType === 'teal') {
            style.width = 12 ;
            style.height = 16 ;
          }

          if (this.props.secondary) {
              style.width = 10;
              style.height = 10;
          }
        
        const opacity = this.props.secondary ? 0.7 : 1;
        const cursor = this.props.onClick ? 'pointer' : undefined;

        return (
            <div onClick={this.props.onClick} style={{ cursor, opacity }}>
                <img style={style} src={Marker.markerImages[this.props.markerType || 'red']} />
            </div>
        );
    }
}
