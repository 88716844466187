import { bind } from 'decko';

import Env from '../Env';
import LatLng from '../types/LatLng';
import { CooperationType } from '../types/lunchnow';
import { ContactStatus } from '../types/models/ContactEntity';
import { CANCELATION_DEADLINE_MINUTES } from '../types/models/Invitation';
import { createPersonFromResponse, MealResponse, TagResponse, UserResponse } from '../types/models/Response';
import RestaurantEntry, { InitialRestaurantResponseModel } from '../types/models/RestaurantEntry';
import HttpRequest, { HttpRequestError } from './HttpRequest';
import { logError } from './Validate';

export default abstract class Backend {
    @bind
    public static async searchForUsers(byKeyword: string) {
        return (await this.getArray<UserResponse>(`/api/search/contactableUsers/${byKeyword.toLocaleLowerCase()}`))
            .map(createPersonFromResponse);
    }

    @bind
    public static async searchForBlockableUsers(byKeyword: string) {
        return (await this.getArray<UserResponse>(`/api/search/blockableUsers/${byKeyword.toLocaleLowerCase()}`))
            .map(createPersonFromResponse);
    }

    @bind
    public static async searchForRandomUsers(limit: number) {
        return (await this.getArray<UserResponse>(`/api/search/randomUsers/${limit}`))
            .map(createPersonFromResponse);
    }

    @bind
    public static async searchForRestaurants(byKeyword: string, type: CooperationType, currentLocation?: LatLng) {
        const createBy = 'initialData';
        const typeMap = {
            [CooperationType.Restaurant]: 'partners',
            [CooperationType.NonPartner]: 'nonPartners'
        };
        const searchType = typeMap[type];
        const initialRestaurantData = searchType
            ? await this.getArray<InitialRestaurantResponseModel>(`/api/search/${searchType}/${byKeyword.toLocaleLowerCase()}`)
            : [];

        return initialRestaurantData.map(initialData => new RestaurantEntry({ createBy, initialData }, currentLocation));
    }

    @bind
    public static searchForMeals(byKeyword: string) {
        return this.getArray<MealResponse>(`/api/search/meals/${byKeyword.toLocaleLowerCase()}`);
    }

    @bind
    public static searchForTags(byKeyword: string) {
        return this.getArray<TagResponse>(`/api/search/tags/${byKeyword.toLocaleLowerCase()}`);
    }

    @bind
    public static importContacts(emails: string[]) {
        return HttpRequest.patch('/api/contacts/import', { emails })
            .then(response => JSON.parse(response).added as number)
            .catch(logError('Backend.importContacts'));
    }

    @bind
    public static async addContacts(contacts: string[]) {
        await HttpRequest.patch('/api/contacts/add', { contacts });
    }

    public static async putConnectUsers(users: string[]) {
        await HttpRequest.put('/api/users/connect', { users });
    }

    public static async putConnectCode(code: string) {
        await HttpRequest.put('/api/users/connect', { code });
    }

    @bind
    public static async removeContacts(contacts: string[]) {
        await HttpRequest.patch('/api/contacts/remove', { contacts });
    }

    @bind
    public static async deleteAccount() {
        await HttpRequest.put('/api/users/deleteMe');
    }

    @bind
    public static async blockUsers(users: string[]) {
        await HttpRequest.patch('/api/users/block', { users });
    }

    @bind
    public static async unblockUsers(users: string[], addAsContacts: boolean) {
        await HttpRequest.patch('/api/users/unblock', { users, addAsContacts });
    }

    @bind
    public static async verifyEmail(code: string) {
        const resultJson = await HttpRequest.post('/api/users/verifyEmail', { code });
        const result = JSON.parse(resultJson);

        return !!result.success;
    }

    @bind
    public static async resendEmailVerification() {
        await HttpRequest.post('/api/users/resendEmailVerification');
    }

    @bind
    public static async updateInvitationStatus(invitationId: string, status: ContactStatus) {
        try {
            await HttpRequest.patch(`/api/invitations/${invitationId}`, { status });
        } catch (error) {
            let title: string | undefined;
            let message: string | undefined;

            switch (error.code) {
                case HttpRequestError.NOT_ACCEPTABLE:
                    title = Env.i18n.t('InvitationDeadlineExceededTitle');
                    message = Env.i18n.t('InvitationDeadlineExceededText', { minutes: CANCELATION_DEADLINE_MINUTES });
                break;
                case HttpRequestError.CONFLICT:
                    title = Env.i18n.t('InvitationHasOrdersTitle');
                    message = Env.i18n.t('InvitationHasOrdersText');
                break;
                case HttpRequestError.GONE:
                    title = Env.i18n.t('InvitationDeletedTitle');
                    message = Env.i18n.t('InvitationDeletedText');
                break;
            }

            if (title) {
                Env.alert(title, message, [{ label: Env.i18n.t('Ok') }], false);
            } else {
                logError('Backend.updateInvitationStatus', error);
                Env.snackbar.error(Env.i18n.t('ErrorChangeAttendance'));
            }
        }
    }

    private static async getArray<T>(url: string) {
        const array = [];

        try {
            const result = await HttpRequest.get(url);

            array.push(...(JSON.parse(result) as T[]));
        } catch (error) {
            logError('Backend.getArray', error);
        }

        return array;
    }
}
