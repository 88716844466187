function normalize(color: string) {
    let colorCode = color.substr(1);

    if (colorCode.length < 6) {
        const c = colorCode.split('');

        colorCode = c[0] + c[0] + c[1] + c[1] + c[2] + c[2];
    }

    return '#' + colorCode;
}

export function alpha(color: string, alphaValue: number) {
    const maxValue = 255;
    const value = Math.max(0, Math.min(maxValue, Math.round(maxValue * alphaValue)));
    let alphaHex = value.toString(16);

    if (alphaHex.length === 1) {
        alphaHex = '0' + alphaHex;
    }

    return normalize(color) + alphaHex;
}

// TODO: use semantic color constants!
const colors = {
    affiliate_blue: '#7fadbd',
    beige: '#F9EACC',
    beige_light:'#FFF7E6',
    red_50: '#ffdfdd',
    red_100: '#ffbfba',
    red_200: '#ff9e98',
    red_300: '#ff8e86',
    red_400: '#ff756b',
    red_500: '#ff5e53',
    red_600: '#f75249',
    red_700: '#ee423f',
    red_800: '#d82727',
    red_900: '#c60000',
    teal_50: '#ccf5f4',
    teal_100: '#99ebe9',
    teal_200: '#66e2df',
    teal_300: '#4cdcd9',
    teal_400: '#24d5d1',
    teal_500: '#00cec9',
    teal_600: '#00c2bd',
    teal_700: '#00b2ae',
    teal_800: '#00a09c',
    teal_900: '#00938f',
    matte_black: '#212121',
    grey_01: '#666666',
    grey_02: '#999999',
    grey_03: '#d0d0d0',
    grey_04: '#e6e6e6',
    grey_05: '#f7f7f7',
    grey_06: '#B7B8CA',
    description_grey: '#c1c1c1',
    white: '#ffffff',
    cream: '#ffeaa7', // TODO: can this be replaced by beige or beige_light?
    prince: '#7986cb',
    success: '#48e0a4',
    warn: '#fb3958',
    royal_blue: '#204E70',
    navy_blue: '#151b3d',
    light_blue: '#B5B7C9',
    dark_blue: '#22263D',
    light_background: '#eeeeee',
    discount: '#FF3B30',
    light_border: '#F0F0F0',
    indigo: '#5E5CE6',
    pending: '#ffe880',
    accepted: '#6bd68d',
    declined: '#f56962',
    gold: '#ffd587',
    banner: '#DE857E'
};

export default colors;

export const avatarColors = [
    '#a494ce',
    '#a7cae8',
    '#fccf79',
    '#bd9084',
    '#d5d3a0',
    '#e2acbe',
    '#92d4c7',
    '#f0a594',
    '#dcca98',
    '#b2c499',
    '#e7b382',
    '#a8aec1',
    '#d8adae',
    '#dcd54a',
    '#966f87',
    '#8dafcf'
];

// Switch component color variants
export type SwitchColorVariants = 'light' | 'dark'

export interface SwitchColors {
    textColor: string;
    selectedTextColor: string;
    backgroundColor: string;
    selectedBackgroundColor: string;
    borderColor: string;
    selectedBorderColor: string;
}

export const switchColors: Record<SwitchColorVariants, SwitchColors> = {
    light: {
        textColor: colors.matte_black,
        selectedTextColor: colors.teal_900,
        backgroundColor: colors.white,
        selectedBackgroundColor: colors.teal_50,
        borderColor: colors.grey_04,
        selectedBorderColor: colors.teal_50
    },
    dark: {
        textColor: colors.beige_light,
        selectedTextColor: colors.navy_blue,
        backgroundColor: 'transparent',
        selectedBackgroundColor: colors.beige_light,
        borderColor: 'transparent',
        selectedBorderColor: colors.beige_light,
    }
}
