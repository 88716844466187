import { bind } from 'decko';
import * as React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';

const ListContainer = styled.div`
    box-sizing: border-box;
    overflow-y: scroll;
    scroll-behavior: smooth;
`;

export interface DynamicListProps<T> {
    data: T[];
    renderItem: (item: T, index: number) => React.ReactElement;
    ListEmptyComponent?: React.ReactElement;
    ListHeaderComponent?: React.ReactElement;
    ListFooterComponent?: React.ReactElement;
    className?: string;
    style?: React.CSSProperties;
}

export default class DynamicList<T> extends React.PureComponent<DynamicListProps<T>> {
    private listRef = React.createRef<HTMLDivElement>();

    @bind
    public scrollToOffset(offset: number) {
        this.listRef.current?.scrollTo({ top: offset });
    }

    @bind
    public scrollToItem(item: T) {
        this.scrollToIndex(this.props.data.indexOf(item));
    }

    @bind
    public scrollToIndex(index: number) {
        this.listRef.current?.children[index + Number(!!this.props.ListHeaderComponent)].scrollIntoView();
    }

    @bind
    public scrollToEnd() {
        this.listRef.current?.lastElementChild?.scrollIntoView();
    }

    public render() {
        const { data, ListHeaderComponent, ListFooterComponent, ListEmptyComponent, className, style } = this.props;

        return (
            <AutoSizer>
                {dimensions => (
                    <ListContainer ref={this.listRef} className={className} style={{ ...dimensions, ...style }}>
                        {ListHeaderComponent}
                        {data.length ? data.map(this.renderItem) : ListEmptyComponent}
                        {ListFooterComponent}
                    </ListContainer>
                )}
            </AutoSizer>
        );
    }

    @bind
    private renderItem(item: T, index: number) {
        const { key = index } = item as any;

        return (
            <div key={key}>
                {this.props.renderItem(item, index)}
            </div>
        );
    }
}
