import { bind } from 'decko';

import Env from '../../lib/src/Env';
import { FirebaseError, logError } from '../../lib/src/helpers/Validate';
import AuthManager, { Credentials } from '../../lib/src/managers/AuthManager';
import Account from './Account';

export interface InjectedAuthProps {
    auth: Auth;
}

export default class Auth extends AuthManager<Account> {
    @bind
    public register(email: string, password: string, visible: boolean) {
        return Env.firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(() => this.handleRegistration())
            .then(() => this._account.schedule(() => this._account.setUserFlag('visible', visible)));
    }

    // TODO: if possible, move to super class later
    public async logout() {
        // Delete FCM Token for this device
        // await this._account.toggleFcmToken(false); // TODO: re-activate for notifications

        // TODO: re-activate for storage
        // // e.g. open contacts to add
        // LocalData.clearUserDependentStorage();

        // overriding user instead of actual log-out to be always signed in
        await super.logout();
    }

    @bind
    protected async handleRegistration(credentials?: Credentials) {
        const user = Env.firebase.auth().currentUser!;
        const userDoc = Env.firebase.firestore().collection('users').doc(user.uid);
        const userSnapshot  = await userDoc.get();
        const newAccount = !userSnapshot.data()?.email;

        if (newAccount) {
            await this.createUserData(Env.firebase.firestore.Timestamp.now(), credentials);
        }

        return newAccount;
    }

    // TODO: if possible, move to super class later
    @bind
    protected async registerWithCredential(credentials?: Credentials) {
        let newAccount = false;

        if (!credentials) {
            return Promise.reject('Cancelled by user');
        }

        await Env.firebase.auth()
            .signInWithCredential(credentials.authCredential!)
            .then(async () => {
                newAccount = await this.handleRegistration(credentials);
            })
            .then(this._account.schedulePolicyAcceptanceCheck);

        return newAccount;
    }

    @bind
    protected getGoogleCredential(reauthenticate?: boolean) {
        return this.getProviderCredential(new Env.firebase.auth.GoogleAuthProvider(), reauthenticate);
    }

    @bind
    protected getFacebookCredential(reauthenticate?: boolean) {
        return this.getProviderCredential(new Env.firebase.auth.FacebookAuthProvider(), reauthenticate);
    }

    @bind
    protected getAppleCredential() {
        // not supported yet for web
        return Promise.resolve(undefined);
    }

    @bind
    private async getProviderCredential(provider: firebase.auth.AuthProvider, reauthenticate?: boolean) {
        let authCredential: firebase.auth.OAuthCredential | undefined;
        let userCredential: firebase.auth.UserCredential | undefined;

        try {
            if (reauthenticate && this.user) {
                userCredential = await this.user.reauthenticateWithPopup(provider);
            } else {
                userCredential = await Env.firebase.auth().signInWithPopup(provider);
            }

            authCredential = userCredential.credential as firebase.auth.OAuthCredential;
        } catch (error) {
            if (error.code !== FirebaseError.POPUP_CLOSED) {
                logError('Auth.getProviderCredential', error);
            }

            throw error;
        }

        return this.createCredentials(authCredential, userCredential);
    }
}
