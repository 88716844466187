import { inject, observer } from 'mobx-react';
import React from 'react'
import styled from 'styled-components';
import moment from 'moment';

import { InjectedChatProps } from '../../../../../lib/src/managers/ChatManager';
import colors from '../../../../../lib/src/styles/colors';
import { Chat } from '../../../../../lib/src/types/models/Chat'
import Timestamps from '../../../../../lib/src/types/Timestamps';
import { GRID_SIZE } from '../../../styles/base';
import { TitleText, RegularText } from '../../text';
import { GreyProfileBackground, ProfileCachedImage, ProfilePictureContainer } from '../../social/ProfileCachedImage';
import Icon from '../../common/Icon';
import Env from '../../../../../lib/src/Env';

interface ChatCardProps {
    chat: Chat;
    onPress?: () => void;
}

const Card = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${GRID_SIZE * 4}px;
    cursor: pointer;
`;

const ImageContainer = styled.div`
    margin-right: ${GRID_SIZE * 2}px;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
`;

const NameText = styled(TitleText)`
    margin-bottom: ${GRID_SIZE}px;
    color: ${colors.navy_blue};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ContentText = styled(TitleText)`
    margin-bottom: ${GRID_SIZE}px;
    color: ${colors.navy_blue};
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const TimeAgoText = styled(RegularText)`
    color: ${colors.description_grey};
    font-size: 12px;
`;

@inject('chatManager')
@observer
export default class ChatCard extends React.PureComponent<ChatCardProps> {
    private get injected() {
        return this.props as ChatCardProps & InjectedChatProps;
    }

    render() {
        const { chat, onPress } = this.props;
        const { chatManager } = this.injected;

        const participants = chatManager.participants[chat.key] || [];
        const lastMessage = chatManager.lastMessages[chat.key];

        // TODO: for now just use one, design for more users needs to be done
        const otherParticipants = chatManager.getOtherParticipants(participants)
        const firstParticipant = otherParticipants ? otherParticipants[0] : undefined;
        let title = firstParticipant?.displayName || '';

        if (otherParticipants.length > 1) {
            title += ` (+ ${otherParticipants.length - 1})`
        }

        let lastMessageContent = lastMessage?.content;

        if (lastMessage?.contentId) {
            if (lastMessage.content === 'restaurant') {
                lastMessageContent = Env.i18n.t('SharedRestaurant')
            } else if (lastMessage.content === 'invitation') {
                lastMessageContent = Env.i18n.t('SharedInvitation')
            }
        }

        return (
            <Card onClick={onPress}>
                <ImageContainer>
                    {firstParticipant?.photoURL ? (
                        <ProfilePictureContainer>
                            <ProfileCachedImage src={firstParticipant.photoURL} />
                        </ProfilePictureContainer>
                    ) : (
                        <GreyProfileBackground>
                            <Icon size={56} color={colors.grey_03} src={require('../../../assets/svg/social.svg')} />
                        </GreyProfileBackground>
                    )}
                </ImageContainer>
                <Content>
                    <NameText>{title}</NameText>
                    <ContentText>{lastMessageContent}</ContentText>
                    {lastMessage?.timestamp && (
                        <TimeAgoText>{moment.duration(moment(Timestamps.toDate(lastMessage.timestamp)).diff(moment())).humanize(true)}</TimeAgoText>
                    )}
                </Content>
            </Card>
        )
    }
}
