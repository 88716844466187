import { computed } from 'mobx';

import GoogleMapsApi from '../helpers/GoogleMapsApi';
import Locations from '../helpers/Locations';
import LatLng from '../types/LatLng';
import PlacesResponse, { PlaceResponse } from '../types/models/Response';
import RestaurantEntry from '../types/models/RestaurantEntry';

interface CurrentLocationData {
    coordinate: LatLng;
    isUserLocation?: boolean;
    name: string;
}

export default class CurrentLocation {
    public readonly coordinate;
    public readonly isUserLocation;
    public readonly name;

    @computed
    public get isSet() {
        return !Locations.isFallback(this.coordinate);
    }

    @computed
    public get userLocationCoordinate() {
        if (this.isSet && this.isUserLocation) {
            return this.coordinate;
        }
    }

    public static async withAutomaticName(coordinate: LatLng, isUserLocation?: boolean) {
        const latlng = Locations.toString(coordinate);
        const responseJson: PlacesResponse = await GoogleMapsApi.get('geocode/json', { latlng });
        let name: string | undefined;

        if (responseJson.status !== 'ZERO_RESULTS') {
            name = GoogleMapsApi.getAddressString(responseJson.results);
        } else {
            console.warn('Couldn\'t find address');
        }

        return new CurrentLocation(coordinate, name, isUserLocation);
    }

    public static fromRestaurant(restaurant: RestaurantEntry) {
        return new CurrentLocation(Locations.normalize(restaurant.location), restaurant.name);
    }

    public static async fromGooglePlace({ place_id }: PlaceResponse) {
        const responseJson: PlacesResponse = await GoogleMapsApi.get('geocode/json', { place_id });

        return new CurrentLocation(
            Locations.normalize(responseJson.results[0].geometry.location),
            GoogleMapsApi.getAddressString(responseJson.results)
        );
    }

    public static copy({ coordinate, name, isUserLocation }: CurrentLocation | CurrentLocationData) {
        return new CurrentLocation({ ...coordinate }, name, isUserLocation);
    }

    constructor(coordinate: LatLng, name = '', isUserLocation = false) {
        this.coordinate = coordinate;
        this.name = name;
        this.isUserLocation = isUserLocation;
    }

    public toJson(): CurrentLocationData {
        const { coordinate, name } = this;

        return { coordinate, name };
    }

    public isSameAs({ coordinate }: CurrentLocation) {
        return (coordinate.latitude === this.coordinate.latitude) && (coordinate.longitude === this.coordinate.longitude);
    }
}
