import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import React from 'react';

import Env from '../../../../lib/src/Env';
import { formatNumber } from '../../../../lib/src/helpers/formatting';
import { ExternalTransactionParams } from '../../../../lib/src/managers/PaymentManager';
import Cart from '../../../../lib/src/store/Cart';
import List from '../../../../lib/src/types/List';
import { Order, OrderDraft } from '../../../../lib/src/types/models/Order';
import { InjectedPaymentProps } from '../../Payment';
import { GRID_SIZE, SCREEN_PADDING } from '../../styles/base';
import { PrimaryButton, SecondaryButton } from '../button';
import RedirectFrame from '../common/RedirectFrame';
import ScreenModal, { ScreenModalProps } from '../common/ScreenModal';
import OrderDetails from './OrderDetails';
import PaymentOptions from './PaymentOptions';
import PlaceAndTimeOptions from './PlaceAndTimeOptions';
import VoucherOptions from './VoucherOptions';

interface Props extends ScreenModalProps {
    cart: Cart;
    onReturn: (mealTypeBaseName: string) => void;
    onNavigate: (route: string, params?: string[]) => void;
}

interface State {
    redirectUrl?: string;
    showHint?: boolean;
}

@inject('payment')
@observer
export default class CartModal extends React.PureComponent<Props, State> {
    public readonly state: State = {};

    private get injected() {
        return this.props as Props & InjectedPaymentProps;
    }

    public render() {
        const { cart, modalRef } = this.props;
        const { redirectUrl } = this.state;
        const title = Env.i18n.t('CartForRestaurant', { restaurant: cart.restaurant.name });
        const padding = `0 ${GRID_SIZE * 2}px ${redirectUrl ? 0 : GRID_SIZE * 3}px`;

        return (
            <ScreenModal
                ref={modalRef}
                title={title}
                onBeforeOpen={this.handlePayPalRedirect}
                onAfterClose={this.closeExternalPage}
                FooterComponent={this.renderFooter()}
                style={{ padding }}
            >
                {!!redirectUrl ? (
                    <RedirectFrame url={redirectUrl} onReturn={this.handleExternalPage} />
                ) : (
                    <div style={{ marginTop: SCREEN_PADDING }}>
                        <OrderDetails order={cart} editable={true} onReturnToMenu={this.returnToMenu}>
                            <VoucherOptions cart={cart} />
                        </OrderDetails>
                        <PlaceAndTimeOptions cart={cart} showHint={this.state.showHint}/>
                        <PaymentOptions cart={cart} />
                    </div>
                )}
            </ScreenModal>
        );
    }

    private renderFooter() {
        if (!this.state.redirectUrl) {
            const { cart } = this.props;
            const valid = this.injected.payment.validateCart(cart);
            const Button: typeof PrimaryButton = valid ? PrimaryButton : SecondaryButton;
            const action = valid ? this.order : this.disabledOrderButtonPress;

            return (
                <Button onClick={action} style={{ marginTop: 0 }}>
                    {Env.i18n.t('OrderForPrice', { value: `${formatNumber(cart.totalPrice / 100, 2)} €` })}
                </Button>
            );
        }
    }

    @bind
    private returnToMenu(mealTypeBaseName?: string) {
        if (mealTypeBaseName) {
            this.props.onReturn(mealTypeBaseName);
        }

        this.close();
    }

    private close() {
        this.props.modalRef?.current?.close();
    }

    @bind
    private handlePayPalRedirect() {
        const { token, PayerID } = qs.parse(window.location.search.substr(1)) as List<string | undefined>;

        if (token) {
            // remove the paypal token from the url, to not trigger the payment again when opening another cart
            window.history.replaceState({}, '', `${window.location.pathname}${window.location.hash}`);

            // if the payment was done, there is a PayerID, otherwise it was canceled
            if (PayerID) {
                this.handleExternalPage({ token, PayerID });
            } else {
                this.showConfirmation(this.props.cart);
            }
        }
    }

    private showConfirmation(forOrder: Order | OrderDraft) {
        this.close();
        this.props.onNavigate('order', [ forOrder.key! ]);
    }

    @bind
    private order() {
        if (this.props.cart.shouldSuggestDrinks) {
            Env.alert(Env.i18n.t('AddDrinkTitle'), '', [
                {
                    label: Env.i18n.t('Yes'),
                    action: () => this.returnToMenu('Getränke')
                },
                {
                    label: Env.i18n.t('No'),
                    action: this.createOrder
                }
            ]);
        } else {
            this.createOrder();
        }
    }

    @bind
    private disabledOrderButtonPress() {
        this.setState({showHint: true});
    }

    @bind
    private createOrder() {
        const { modalRef, cart } = this.props;

        modalRef?.current?.waitFor(Env.i18n.t('OrderAnimationText'), () =>
            this.injected.payment.order(cart.restaurant).then(orderOrUrl => {
                if (orderOrUrl) {
                    if (typeof orderOrUrl === 'string') {
                        if (cart.paymentOption === 'paypal') {
                            // we need to redirect in the parent frame
                            this.injected.payment.externalRedirect = true;
                            window.location.href = orderOrUrl;
                        } else {
                            this.setState({ redirectUrl: orderOrUrl });
                        }
                    } else {
                        this.showConfirmation(orderOrUrl);
                    }
                }
            })
        );
    }

    @bind
    private closeExternalPage() {
        this.setState({ redirectUrl: undefined });
    }

    @bind
    private handleExternalPage(params: ExternalTransactionParams) {
        this.props.modalRef?.current?.waitFor(Env.i18n.t('OrderAnimationText'), () =>
            this.injected.payment.finishExternalTransaction(params).then(order => {
                if (order) {
                    this.showConfirmation(order);
                }
            })
        );
        this.closeExternalPage();
    }
}
