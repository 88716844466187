import Env, { Assets } from '../Env';
import { ContactStatus } from '../types/models/ContactEntity';
import colors from './colors';

interface StatusConfigData {
    color: string;
    diskColor: string;
    icon: keyof Assets['icons'];
    text: string;
}

const statusConfigs: { [key in ContactStatus]: StatusConfigData } = {
    selected: {
        color: colors.white,
        diskColor: colors.matte_black,
        icon: 'Check',
        text: ''
    },
    pending: {
        color: colors.white,
        diskColor: colors.pending,
        icon: 'BadgeRequest',
        text: 'InvitationPending'
    },
    accepted: {
        color: colors.white,
        diskColor: colors.accepted,
        icon: 'BadgeCheck',
        text: 'InvitationAccepted'
    },
    declined: {
        color: colors.white,
        diskColor: colors.declined,
        icon: 'BadgeDeclined',
        text: 'InvitationDeclined'
    },
    canceled: {
        color: colors.white,
        diskColor: colors.declined,
        icon: 'BadgeDeclined',
        text: 'InvitationCanceled'
    },
    deleted: {
        color: colors.white,
        diskColor: colors.declined,
        icon: 'BadgeDeclined',
        text: 'DeletedUser'
    }
};

export default function statusConfig(status: ContactStatus) {
    const { icon, color, diskColor, text } = statusConfigs[status];

    return {
        color,
        diskColor,
        icon: Env.assets.icons[icon],
        text: text && Env.i18n.t(text)
    };
}
