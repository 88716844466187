import { IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { bind } from 'decko';
import { inject, observer } from 'mobx-react';
import React from 'react';

import colors from '../../../../lib/src/styles/colors';
import { Order as OrderModel } from '../../../../lib/src/types/models/Order';
import { InjectedPaymentProps } from '../../Payment';
import Modal, { ModalProps, ModalState } from '../common/Modal';
import Screen from '../common/Screen';
import OrderConfirmation from './OrderConfirmation';
import { Header, HeaderWrapper } from './Styled';

interface Params {
    order?: OrderModel;
}

type State = ModalState<Params>;

@inject('payment')
@observer
export default class Order extends Modal<Params, State> {
    private get injected() {
        return this.props as ModalProps & InjectedPaymentProps;
    }

    protected async hydrateParams(params: string[]) {
        return {
            order: await this.injected.payment.loadOrder('key', params[0])
        };
    }

    protected validateParams() {
        return !!this.state.params.order;
    }

    public render() {
        const { order } = this.state.params;

        return (
            <Screen
                open={this.paramsAreValid()}
                className="paper payment"
                handleClose={this.close}
                HeaderComponent={this.renderHeader}
                dividers={false}
                fullHeight={true}
            >
                {order && (
                    <OrderConfirmation order={order} onViewRestaurant={order.restaurant && this.viewRestaurant} />
                )}
            </Screen>
        );
    }

    @bind
    private renderHeader() {
        return (
            <HeaderWrapper>
                <Header>
                    <IconButton onClick={this.back} size="small">
                        <ArrowBack style={{ color: colors.matte_black }} />
                    </IconButton>
                </Header>
            </HeaderWrapper>
        );
    }

    @bind
    private viewRestaurant() {
        this.redirectTo('details', [ this.state.params.order!.restaurant!.data!.routingName ]);
    }
}
